/*
High Order Component to verify a route is safe to access.

Wrapping a container with this HOC will make that container secure, thereby making
it safe to access when:
- the app boots (entering `my.madecomfy.com.au/sensitive/route` into the address
  bar, or clicking on a link in an email, etc)
- a user navigates within the app (clicking a link/button)

`checkStatus` is the core function that achieves this.
It checks all prerequisites for a user to view any route within the app,
to do so it calls validateToken if needed which requires up to 3 network calls to get the data required:
- get user session
- get property summary including how many properties the user owns, and if that is only one property:
- get its property code

*/

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import canUserAccessPath from "./canUserAccessPath";
import getRedirect from "./getRedirect";
import Loading from "Components/Loading";
import { validateToken } from "Actions/authentication/validateToken";

const withSessionCheck = (WrappedComponent: any) => {
  class Check extends Component<any> {
    state = {
      checkPending: true,
    };

    isComponentMounted = false;

    componentDidMount() {
      this.isComponentMounted = true;
      this.checkStatus();
    }

    componentWillUnmount() {
      this.isComponentMounted = false;
    }

    async checkStatus() {
      // always read this.props - destructured references may be stale
      if (!this.props.sessionStatus.user) {
        // no existing user, this is a hard refresh or initial load
        await this.props.validateToken();
      }
      // see if validateToken has populated the session
      const { user } = this.props.sessionStatus;

      // check the user is ok
      if (!user) {
        // still no user, no need to check further, this will redirect to login
        return this.doRedirect();
      }

      // everything is loaded, no need to check anymore remote data

      // check if the user is allowed to access the current path
      const isPathAllowed = canUserAccessPath(
        user,
        this.props.location.pathname,
      );
      if (!isPathAllowed) {
        // user can't access this route
        return this.doRedirect();
      }

      // ensure a render is not called on an unmounted component.
      if (this.isComponentMounted === false) {
        // component is unmounted, bail
        return;
      }

      // all checks are good, we can render the Wrapped component...
      this.setState({ checkPending: false });
    }

    doRedirect() {
      const redirect = getRedirect(this.props as any);
      // console.log("doRedirect redirect", redirect);
      return this.props.history.push(redirect);
    }

    render() {
      if (this.state.checkPending) {
        return (
          <div style={{ minHeight: "300px", position: "relative" }}>
            <Loading task="withSessionCheck" />
          </div>
        );
      }
      return <WrappedComponent {...this.props} />;
    }
  }

  function mapDispatchToProps(dispatch: (...args: any) => any) {
    return {
      validateToken: bindActionCreators(validateToken, dispatch),
    };
  }

  function mapStateToProps(state: any) {
    return {
      sessionStatus: state.authentication.sessionStatus,
    };
  }

  return connect(mapStateToProps, mapDispatchToProps)(Check);
};

export default withSessionCheck;

import {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Body,
  FinePrint,
  ParagraphLarge,
  ParagraphSmall,
} from "./Typography";
export {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Body,
  FinePrint,
  ParagraphLarge,
  ParagraphSmall,
};

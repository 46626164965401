import { isOwner } from "./authentication";

export const getOwnerProperty = (user: any) => {
  const {
    propertyStatus: { count, first },
    roles,
  } = user;
  if (user && isOwner(roles) && first && count === 1) {
    // we only redirect users to a property if they are an owner and only have one property...
    return `/properties/${first}`;
  }
  return "/properties";
};

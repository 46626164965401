import React from "react";

const House = () => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4695 2.7799C11.6102 2.63914 11.801 2.56006 12 2.56006C12.199 2.56006 12.3898 2.63914 12.5305 2.7799L22.7197 12.9691C23.0126 13.262 23.4874 13.262 23.7803 12.9691C24.0732 12.6762 24.0732 12.2013 23.7803 11.9084L21 9.12808V4.18878C21 3.77457 20.6642 3.43878 20.25 3.43878H16.5C16.1784 3.43878 15.9041 3.64119 15.7975 3.92557L13.5915 1.71958C13.1695 1.29731 12.597 1.06006 12 1.06006C11.403 1.06006 10.8305 1.29731 10.4085 1.71958L0.21967 11.9084C-0.0732233 12.2013 -0.0732233 12.6762 0.21967 12.9691C0.512563 13.262 0.987437 13.262 1.28033 12.9691L11.4695 2.7799ZM19.5 7.62808L17.25 5.37808V4.93878H19.5V7.62808Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 13.9388C4.5 13.5246 4.16421 13.1888 3.75 13.1888C3.33579 13.1888 3 13.5246 3 13.9388V21.4388H1.5C1.08579 21.4388 0.75 21.7746 0.75 22.1888C0.75 22.603 1.08579 22.9388 1.5 22.9388H22.5C22.9142 22.9388 23.25 22.603 23.25 22.1888C23.25 21.7746 22.9142 21.4388 22.5 21.4388H21V13.9388C21 13.5246 20.6642 13.1888 20.25 13.1888C19.8358 13.1888 19.5 13.5246 19.5 13.9388V21.4388H15V16.1888C15 14.9461 13.9926 13.9388 12.75 13.9388H11.25C10.0074 13.9388 9 14.9461 9 16.1888V21.4388H4.5V13.9388ZM10.5 21.4388H13.5V16.1888C13.5 15.7746 13.1642 15.4388 12.75 15.4388H11.25C10.8358 15.4388 10.5 15.7746 10.5 16.1888V21.4388Z"
    />
  </>
);

export default House;

import React from "react";
import styled, { css } from "styled-components";

import { ErrorMessage } from "@madecomfy/webooi";
import WebooiBaseComponent from "@madecomfy/webooi/dist/BaseComponent";// FIXME: export this properly in webooi
import { withFormField } from "@madecomfy/webooi/dist/form";// FIXME: export this properly in webooi

import DatePicker from "./DatePicker";

const Wrapper = styled.div<any>`
  ${({ error, theme }) =>
    error &&
    css`
      #startDate {
        box-shadow: ${theme.select.alert.shadow};
        border: 1px solid ${theme.select.alert.border};
      }
    `};
`;

class ValidatedDatePicker extends WebooiBaseComponent<any, any> {
  static WebooiType = "ValidatedDatePickerAlpha";

  // TODO could this be the constructor for many webooi components?
  constructor(props: any) {
    super(props);
    this.validate = this.setValidation(props);

    const { defaultValue: value, formValues, sendValue } = props;

    this.state = {
      isValid: this.validate(value),
      value: value,
    };

    if (formValues && sendValue) {
      sendValue(this.state);
    }
  }

  handleChange = (value: {[key: string]: any} | null) => this.checkValidation(value);

  render() {
    // just extract the webooi withFormField props
    const {
      checkValidation,
      errorServer,
      formValues,
      formUpdate,
      update,
      validation,
      ...rest
    } = this.props;

    // this error is currently only supporting the datepicker in single date mode.
    const { errorClient } = this.state;

    return (
      <Wrapper error={errorClient}>
        {/* @ts-ignore FIXME: "month" prop is required but missing here */}
        <DatePicker
          {...rest}
          sendValue={({ startDate, endDate }: any) =>
            this.handleChange(
              startDate && endDate ? { startDate, endDate } : null,
            )
          }
        />
        <ErrorMessage
          error={errorClient}
          testId={`datepicker-${this.props.testId}`}
        />
      </Wrapper>
    );
  }
}

export default withFormField(ValidatedDatePicker);

import React, { useEffect, useState } from "react";
import styled from "styled-components";

import {
  ButtonSet,
  CheckBox,
  Drawer,
  NumberIncrementor,
} from "@madecomfy/webooi";

import MultiSelect from "Components/MultiSelect";

import { H3 } from "Components/Typography";
import { ownersRestore } from "Actions/users/usersRestore";
import { Stars } from "Containers/Booking/BookingReview/Review";
import { userCompanies } from "Actions/users/userCompanies";

const Wrapper = styled.div``;
const WrapperFilter = styled.div`
  h3 {
    margin-top: -24px;
    @media only screen and (max-width: 500px) {
      margin-top: -16px;
    }
  }
  div {
    label:first-child {
      margin-bottom: 16px;
      font-weight: 700;
      line-height: 22px;
    }
  }
`;

const WrapperDrawer = styled.div`
  div[data-test="drawer"] {
    @media (min-width: 475px) {
      width: 450px;
    }
  }
  div > div + button {
    display: flex;
    flex-direction: row-reverse;
    gap: 16px;
    align-items: baseline;
    background: #f4f4f5;
    color: #303336;
    padding: 8px 32px;
    height: 40px;
    border-radius: 4px;
    :focus {
      text-decoration: none;
      color: #303336;
    }
    svg {
      fill: #303336 !important;
      viewbox: "0 0 24 24";
    }
    :hover {
      background: #e9e9ea;
      color: #303336;
    }
  }
  svg:first-child {
    width: 16px;
    height: 16px;
    margin-right: 0 !important;
  }
  div[data-test="checkbox-amenities"] {
    column-count: 2;
  }
  div[data-test="drawer-content"] {
    > div:first-child {
      width: auto;
    }
  }
  span:after {
    top: 2px;
    height: 10px;
    left: 6px;
  }
`;
const Line = styled.p`
  border-top: 1px solid #efefef;
  margin-top: 24px;
  margin-bottom: 24px;
`;
const LineSpacer = styled(Line)`
  margin: 24px -24px 24px -24px;
`;
const Flex = styled.div`
  @media (min-width: 475px) {
    display: flex;
    gap: 16px;
  }
  @media (max-width: 475px) {
    > div {
      margin-bottom: 16px;
    }
  }
  label {
    font-weight: 400 !important;
    line-height: 24px !important;
    margin: 0 !important;
  }
`;
const Label = styled.label`
  font-size: 16px;
  color: #303336;
  display: block;
`;
const WrapperButtonSet = styled.div`
  button {
    margin-right: 16px;
    height: 48px;
    padding: 0 16px;
    border: 1px solid #cbc9cb;
  }
`;
const Star = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  p {
    margin: 0;
  }
`;
const MinNight = styled.div`
  @media (min-width: 475px) {
    > div {
      width: 48%;
    }
  }
`;

export const Filter = ({ isLicensee, isOwner, setFilter }: any) => {
  const [adults, setAdults] = useState(0);
  const [children, setChildren] = useState(0);
  const [activeAdults, setActiveAdults] = useState(0);
  const [activeChildren, setActiveChildren] = useState(0);
  const [filterNumber, setFilterNumber] = useState(0);
  const originalCleaningStatus = [
    { name: "Ready", id: "ready" },
    { name: "Occupied", id: "occupied" },
    { name: "Dirty", id: "dirty" },
    { name: "In-progress", id: "in-progress" },
    { name: "Blocked", id: "blocked" },
  ];
  const [cleaningStatus, setCleaningStatus] = useState([
    ...originalCleaningStatus,
  ]);
  const [owner, setOwner] = useState([]);
  const [partner, setPartner] = useState([]);
  const [partnersList, setPartnersList] = useState(
    partner && partner.length > 0 ? [...partner] : [],
  );

  const [activeValues, setActiveValues] = useState<{ [key: string]: any }>({});
  const [values, setValues] = useState<{ [key: string]: any }>({});
  const [resetVal, setResetVal] = useState(false);
  const [isShowDrawer, setIsShowDrawer] = useState(false);

  useEffect(() => {
    getCompanies();
  }, []);

  useEffect(() => {
    if (isShowDrawer) {
      getActiveFilters();
    }
  }, [isShowDrawer]);

  const getCompanies = async () => {
    const companies = await userCompanies("licensee");
    companies?.forEach((obj: any) => {
      obj.name = obj?.companyName;
      delete obj?.companyName;
      delete obj?.partnerType;
    });
    setPartner(companies);
  };

  useEffect(() => {
    handleFilter(adults + children, "maxGuests");
  }, [adults, children]);

  const [querySegments, setQuerySegments] = useState<{ [key: string]: string }>(
    {},
  );

  const getFilterValues = (value: any) => {
    let filterValues;
    if (
      value === 0 ||
      (Array.isArray(value) && value.length === 0) ||
      (typeof value === "object" &&
        Object.keys(value).filter((key) => value[key]).length === 0)
    ) {
      // If value is 0, remove the queryStringSegment
      filterValues = undefined;
    } else {
      filterValues = Array.isArray(value)
        ? value.join(",")
        : typeof value === "object"
        ? Object.keys(value)
            .filter((key) => value[key])
            .join(",")
        : value;
    }
    return filterValues;
  };

  const handleFilter = (value: any, name: string): void => {
    if (
      name !== "airbnbOverallRatings" &&
      name !== "riskStatuses" &&
      name !== "cleaningStatuses"
    ) {
      setValues({ ...values, [name]: value });
    }
    // Construct query string segment for the specified filter type
    const filterValues = getFilterValues(value);
    if (filterValues !== undefined) {
      const queryStringSegment = `filters[${name}]=${filterValues}`;

      // Update the querySegments object
      setQuerySegments((prevSegments) => ({
        ...prevSegments,
        [name]: queryStringSegment,
      }));
    } else {
      // Remove the queryStringSegment from querySegments object
      setQuerySegments((prevSegments) => {
        const { [name]: _, ...rest } = prevSegments;
        return rest;
      });
    }
  };

  const resetFilter = () => {
    setValues({});
    setAdults(0);
    setChildren(0);
    setResetVal(true);
  };

  const getActiveFilters = () => {
    setValues(activeValues);
    setAdults(activeAdults);
    setChildren(activeChildren);
    setCleaningStatus(originalCleaningStatus);
    setPartnersList(partner && partner.length > 0 ? [...partner] : []);
  };

  return (
    <Wrapper>
      <WrapperDrawer>
        <Drawer
          icon={"lines" as any}
          label={`Filter ${filterNumber > 0 ? `(${filterNumber})` : ""}`}
          content={
            <WrapperFilter data-test="filter-wrap">
              <H3>Filters</H3>
              <LineSpacer />
              <CheckBox
                label="Property type"
                name="propertyTypes"
                options={[
                  {
                    value: "house,studio,townhouse",
                    label: "House",
                  },
                  {
                    value: "apartment",
                    label: "Apartment",
                  },
                ]}
                sendValue={({ value }: any) => {
                  handleFilter(value, "propertyTypes");
                }}
                value={values?.propertyTypes}
                testId="propertyTypes"
              />
              <Line />
              <MinNight>
                <NumberIncrementor
                  defaultValue={0}
                  label="Min nights"
                  labelFormatter={(num) =>
                    num === 0 || (Array.isArray(num) && num.length === 0)
                      ? "Any"
                      : num
                  }
                  max={99}
                  min={0}
                  name="minimumStay"
                  sendValue={({ value }: any) => {
                    handleFilter(value, "minimumStay");
                  }}
                  value={values?.minimumStay || 0}
                  testId="minimumStay"
                />
              </MinNight>
              <Line />
              <div>
                <Label>Guests</Label>
                <Flex>
                  <NumberIncrementor
                    defaultValue={0}
                    label="Adults"
                    labelFormatter={(num) => (num === 0 ? "Any" : num)}
                    max={99}
                    min={0}
                    name="maxGuests"
                    my={0}
                    sendValue={({ value }: any) => {
                      setAdults(value);
                    }}
                    value={adults as any}
                    testId="maxGuests"
                  />
                  <NumberIncrementor
                    defaultValue={0}
                    label="Children"
                    labelFormatter={(num) => (num === 0 ? "Any" : num)}
                    max={99}
                    min={0}
                    name="maxGuests"
                    my={0}
                    sendValue={({ value }: any) => {
                      setChildren(value);
                    }}
                    testId="num-guests"
                    value={children as any}
                  />
                </Flex>
              </div>
              <Line />
              <WrapperButtonSet>
                <ButtonSet
                  label="Bedrooms"
                  options={[
                    { value: "0", label: "Any" },
                    { value: "1", label: "1" },
                    { value: "2", label: "2" },
                    { value: "3", label: "3" },
                    { value: "4", label: "4+" },
                  ]}
                  sendValue={({ value }: any) => {
                    handleFilter(Number(value), "bedrooms");
                  }}
                  value={String(values?.bedrooms ?? "0")}
                />
              </WrapperButtonSet>
              <Line />
              <WrapperButtonSet>
                <ButtonSet
                  label="Bathrooms"
                  options={[
                    { value: "0", label: "Any" },
                    { value: "1", label: "1" },
                    { value: "2", label: "2" },
                    { value: "3", label: "3" },
                    { value: "4", label: "4+" },
                  ]}
                  sendValue={({ value }: any) => {
                    handleFilter(Number(value), "bathrooms");
                  }}
                  value={String(values?.bathrooms ?? "0")}
                />
              </WrapperButtonSet>
              <Line />
              <CheckBox
                label="Property status"
                name="propertyStatuses"
                options={[
                  {
                    value: "onboarding",
                    label: "Onboarding",
                  },
                  {
                    value: "active",
                    label: "Active",
                  },
                  {
                    value: "offboarding",
                    label: "Offboarding",
                  },
                  {
                    value: "inactive",
                    label: "Inactive",
                  },
                ]}
                sendValue={({ value }: any) => {
                  handleFilter(value, "propertyStatuses");
                }}
                value={values?.propertyStatuses}
                testId="propertyStatuses"
              />
              <Line />
              <CheckBox
                label="Property segments"
                name="propertySegments"
                options={[
                  {
                    value: "luxe",
                    label: "Luxe",
                  },
                  {
                    value: "premium",
                    label: "Premium",
                  },
                  {
                    value: "comfort",
                    label: "Comfort",
                  },
                  {
                    value: "essentials",
                    label: "Essentials",
                  },
                ]}
                sendValue={({ value }: any) => {
                  handleFilter(value, "propertySegments");
                }}
                value={values?.propertySegments}
                testId="proeprtySegments"
              />
              <Line />
              <CheckBox
                label="Ratings"
                name="airbnbOverallRatings"
                options={[
                  {
                    value: "1",
                    label: (
                      <Star>
                        <p>1</p>
                        <Stars rating="1" />
                      </Star>
                    ),
                  },
                  {
                    value: "2",
                    label: (
                      <Star>
                        <p>2</p>
                        <Stars rating="2" />
                      </Star>
                    ),
                  },
                  {
                    value: "3",
                    label: (
                      <Star>
                        <p>3</p>
                        <Stars rating="3" />
                      </Star>
                    ),
                  },
                  {
                    value: "4",
                    label: (
                      <Star>
                        <p>4</p>
                        <Stars rating="4" />
                      </Star>
                    ),
                  },
                  {
                    value: "5",
                    label: (
                      <Star>
                        <p>5</p>
                        <Stars rating="5" />
                      </Star>
                    ),
                  },
                  {
                    value: "at-risk",
                    label: "Airbnb at risk",
                  },
                  {
                    value: "Suspended",
                    label: "Airbnb suspended",
                  },
                ]}
                sendValue={({ value }: any) => {
                  setValues({ ...values, ratings: value });
                  const newVal = Object.keys(value).filter((key) => value[key]);
                  // Filter numeric values (1, 2, 3, 4, 5) for airbnbOverallRatings
                  const numericValues = newVal.filter((val) =>
                    ["1", "2", "3", "4", "5"].includes(val),
                  );

                  // Filter "at-risk" and "Suspended" for riskStatuses
                  const riskStatusesValues = newVal.filter(
                    (val) => val === "at-risk" || val === "Suspended",
                  );
                  handleFilter(riskStatusesValues, "riskStatuses");
                  handleFilter(numericValues, "airbnbOverallRatings");
                }}
                testId="airbnbOverallRatings"
                value={values?.ratings || ""}
              />
              <Line />
              <div>
                <Label>Cleaning status</Label>
                <MultiSelect
                  existingItems={values?.cleaningStatuses ?? []}
                  handleTypeAhead={(val) => {
                    if (val.trim() === "") {
                      setCleaningStatus([...originalCleaningStatus]);
                    } else {
                      const filteredStatus = originalCleaningStatus.filter(
                        ({ name }) =>
                          name.toLowerCase().includes(val.toLowerCase()),
                      );
                      setCleaningStatus(filteredStatus);
                    }
                  }}
                  placeholder="Please select"
                  searchResult={cleaningStatus}
                  sendValue={(value) => {
                    const data = value.map((item: any) => item.id);
                    handleFilter(data, "cleaningStatuses");
                    setValues({ ...values, cleaningStatuses: value });
                  }}
                  reset={resetVal}
                  setResetVal={setResetVal}
                  testId="cleaning-status"
                />
              </div>
              <Line />
              <CheckBox
                label="Key type"
                name="propertyType"
                options={[
                  {
                    value: "standard",
                    label: "Standard",
                  },
                  {
                    value: "key-nest",
                    label: "KeyNest",
                  },
                  {
                    value: "convenience-store",
                    label: "Convenience store",
                  },
                  {
                    value: "key-cafe",
                    label: "KeyCafe",
                  },
                ]}
                sendValue={({ value }: any) => {
                  handleFilter(value, "keyTypes");
                }}
                value={values?.keyTypes}
                testId="keyType"
              />
              <Line />
              <CheckBox
                label="Key status"
                name="keyStatus"
                options={[
                  {
                    value: "Unknown",
                    label: "Unknown",
                  },
                  {
                    value: "In Use",
                    label: "In use",
                  },
                  {
                    value: "In Store",
                    label: "In store",
                  },
                  {
                    value: "Key in place",
                    label: "In place",
                  },
                  {
                    value: "Cleaner confirmed key is in place",
                    label: "In place (verification required)",
                  },
                  {
                    value: "Waiting for drop off",
                    label: "Waiting for drop off",
                  },
                ]}
                sendValue={({ value }: any) => {
                  handleFilter(value, "keyStatuses");
                }}
                value={values?.keyStatuses}
                testId="keyStatus"
              />
              <Line />
              <CheckBox
                label="Management"
                name="managementTypes"
                options={[
                  {
                    value: "madecomfy",
                    label: "Madecomfy",
                  },
                  {
                    value: "pro-partner",
                    label: "Partner",
                  },
                ]}
                sendValue={({ value }: any) => {
                  handleFilter(value, "managementTypes");
                }}
                value={values?.managementTypes}
                testId="managementTypes"
              />
              <Line />
              {!isOwner && (
                <>
                  {!isLicensee && (
                    <>
                      <div>
                        <Label>Partner</Label>
                        <MultiSelect
                          existingItems={values?.companies || []}
                          handleTypeAhead={async (val) => {
                            if (partner) {
                              if (val.trim() === "") {
                                setPartnersList([...partner]);
                              } else {
                                const filteredStatus = partner.filter(
                                  ({ name }: any) =>
                                    name
                                      .toLowerCase()
                                      .includes(val.toLowerCase()),
                                );
                                setPartnersList(filteredStatus);
                              }
                            }
                          }}
                          placeholder="Please select"
                          searchResult={
                            partnersList && partnersList.length > 0
                              ? partnersList
                              : []
                          }
                          sendValue={(value: any) => {
                            const data = value.map((item: any) => item.id);
                            handleFilter(data, "companies");
                            setValues({ ...values, companies: value });
                          }}
                          reset={resetVal}
                          setResetVal={setResetVal}
                          testId="partner"
                        />
                      </div>
                      <Line />
                    </>
                  )}

                  <div>
                    <Label>Owner</Label>
                    <MultiSelect
                      existingItems={values?.owners || []}
                      handleTypeAhead={async (val) => {
                        const owners = await ownersRestore(
                          1,
                          10,
                          val,
                          "ROLE_OWNER",
                        );
                        if (owners) {
                          setOwner(owners);
                        }
                      }}
                      placeholder="Please select"
                      searchResult={owner}
                      sendValue={(value: any) => {
                        const data = value.map((item: any) => item.id);
                        handleFilter(data, "owners");
                        setValues({ ...values, owners: [...value] });
                      }}
                      reset={resetVal}
                      setResetVal={setResetVal}
                      testId="owners"
                    />
                  </div>
                  <Line />
                </>
              )}
              <div>
                <Label>Amenities</Label>
                <CheckBox
                  name="amenities"
                  options={[
                    {
                      value: "wireless-internet, pocket-wifi, internet",
                      label: "WiFi",
                    },
                    { value: "air-conditioning", label: "Air conditioning" },
                    {
                      value:
                        "street-parking, disabled-parking-spot, paid-parking-off-premises, free-parking-on-premises",
                      label: "Parking",
                    },
                    {
                      value:
                        "swimming-pool, outdoor-pool, indoor-pool, private-pool",
                      label: "Pool",
                    },
                    { value: "hot-tub", label: "Hot tub" },
                    { value: "central-heating", label: "Heating" },
                    { value: "fireplace", label: "Fireplace" },
                    { value: "kitchen", label: "Kitchen" },
                    { value: "washer", label: "Washing machine" },
                    { value: "balcony", label: "Balcony" },
                    { value: "garden-or-backyard", label: "Garden" },
                    { value: "bbq-grill", label: "BBQ" },
                  ]}
                  sendValue={({ value }: any) => {
                    handleFilter(value, "amenities");
                  }}
                  value={values?.amenities}
                  testId="amenities"
                />
              </div>
            </WrapperFilter>
          }
          textSave="Apply"
          textCancel="Clear All"
          closeOnCancel={false}
          onCancel={resetFilter}
          onOpen={() => setIsShowDrawer(true)}
          onClose={() => setIsShowDrawer(false)}
          onSave={async () => {
            const queryString = Object.values(querySegments).join("&");
            setFilter(queryString);
            setFilterNumber(Object.keys(querySegments).length);
            setActiveAdults(adults);
            setActiveChildren(children);
            setActiveValues(values);
          }}
        />
      </WrapperDrawer>
    </Wrapper>
  );
};

/*

this algorithm errs towards using more memory early on in the pipeline to speed up all future lookups.
this is due to heavy interactions in the calendar (a large tree of React components (Year > Month > Week > Day)).
so, rather than doing any array filtering/searching on mouseover, mouseclick & mouseout,
we immediately have lookup tables to see what data is relevant for that day.

it returns {
  eventsArray, //  an array of all events and vacancies
  eventsMap, //    a lookup table (object) of all events on a given day
  vacanciesMap, // a lookup table of any vacancy on a given day + the earliest and latest vacancies available
}

this code could easily be moved into a service worker if required.

*/

import { PE_PREFIX, UNAVAILABLE } from "Constants/propertyEventTypes";
import { RESERVATION_NOTE } from "Constants/bookingNoteTypes";
import { VACANT, BLOCKED, BOOKED } from "Constants/bookingTypes";
import { YEAR_EARLIEST, YEAR_LATEST } from "./DatePicker";

import { dayYYYYMMDD } from "./utils";

// array of types that cause blocking events
const blockingEventTypes = [BLOCKED, BOOKED, UNAVAILABLE];

// don't allow more than this many events on any given day. 5 is plenty it seems, x2 that to be safe.
const maxRows = 15; // as of 16/03/23 to be on more safer side
const arrayZeroToMax = Array(maxRows)
  .fill(0)
  .map((_, i) => i);

export default function calcRanges({ bookings, events, ignoreEventId }: any) {
  const cleansedEvents: any = cleanseEvents(events);

  // now sort
  const everything = []
    .concat(bookings)
    .concat(cleansedEvents)
    .sort((a: {[key: string]: any}, b: {[key: string]: any}) => {
      if (a.startDate === b.startDate) {
        // if events start at the same date, put the shortest one first.
        return a.endDate < b.endDate ? -1 : 1;
      }
      return a.startDate < b.startDate ? -1 : 1;
    });

  // if specified, filter out any event that is being edited. this is to prevent it clashing with itself!
  const filtered: any =
    typeof ignoreEventId !== "undefined"
      ? everything.filter((item: {[key: string]: any}) => {
          return item.id !== ignoreEventId;
        })
      : everything;

  // now convert events & bookings to single array
  const cleansedAll = cleanseFiltered(filtered);

  // generate the lookup maps,
  // and merge events, bookings & vacancies in a single array
  return getMap(cleansedAll);
}

const cleanseEvents = (events: any[]) => {
  return events
    ? events.map((ev) => {
        const {
          attributes: { end, name, start, status, expiryAt, createdAt, modifiedAt, calendarLink },
          type,
          id,
        } = ev;

        const noTime = "T00:00:00+00:00";
        // verify that all events have no time.
        if (start.substring(10) !== noTime) {
          // eslint-disable-next-line no-console
          console.warn("oops start");
        }
        if (end.substring(10) !== noTime) {
          // eslint-disable-next-line no-console
          console.warn("oops end");
        }

        return {
          startDate: start.substring(0, 10),
          endDate: end.substring(0, 10),
          status,
          id: `${PE_PREFIX}${id}`,
          name,
          type,
          expiryAt,
          createdAt,
          modifiedAt,
          calendarLink,
        };
      })
    : [];
};

const cleanseFiltered = (filtered: any[]) => {
  return filtered.map((item, eventIndex) => {
    // find the first booking note (ie, not payment related notes)
    const foundNote =
      item.notes &&
      item.notes.find(({ status }: { status: string }) => status === RESERVATION_NOTE);
    const note = foundNote?.content;

    // expand the range to double height if there is a note. 1 for note, 1 for booking name/etc
    const height = note ? 2 : 1;
    const cleansed = {
      // always keep startDate, endDate, status & id at top of this object in this order. this is to ease debugging via console etc.
      startDate: item.startDate,
      endDate: item.endDate,
      status: item.status,
      id: item.id,
      height,
      hasEarlyEta: item.hasEarlyEta,
      hasLateEtd: item.hasLateEtd,
      eventIndex, // this is the main identifier to link the lookup Maps with this Array. eventIndex will always equal the array index, so lookup is lighting fast.
      expiryDate: item.expiryAt,
      createdAt: item.createdAt,
      modifiedAt: item.modifiedAt,
      calendarLink: item.calendarLink,
    } as {[key: string]: any};

    // remove `undefined` attributes
    // only defined for bookings
    if (item.createdBy) cleansed.createdBy = item.createdBy;
    if (item.guest) cleansed.guest = item.guest;
    if (item.externalSource) cleansed.externalSource = item.externalSource;
    if (note) cleansed.note = note;
    // only defined for property events
    if (item.name) cleansed.name = item.name;
    if (item.type) cleansed.type = item.type;

    return cleansed;
  });
};

const DATE_EARLIEST = `${YEAR_EARLIEST}-01-01`;
const DATE_LATEST = `${YEAR_LATEST}-12-31`;

export const blankCalendar = {
  eventsMap: {} as {[key: string]: any},
  eventsArray: [
    {
      eventIndex: 0,
      endDate: DATE_LATEST,
      startDate: DATE_EARLIEST,
      status: "VACANT",
    },
  ],
  vacanciesMap: { empty: true }, // special case where there is no data.
};

const getMap = (filtered: any) => {
  const eventsMap: any = {};

  let firstEvent = DATE_LATEST;
  let lastEvent = DATE_EARLIEST;

  if (filtered.length === 0) {
    // there are no events at all for this property.
    return blankCalendar;
  }

  // loop through everything twice, first ignoring single day events.
  // single day events should always go last, unless there is an earlier vacant slot that makes sense for single day events to adopt,
  // alas, we don't know what 'last' is until the whole set has been processed.
  for (var j = 0; j < filtered.length; j++) {
    const item = filtered[j];
    const { height, startDate, endDate } = item;

    const itemIsBlocking = blockingEventTypes.includes(item.status);
    if (itemIsBlocking) {
      // establish bounds to all activity on first loop
      firstEvent = startDate <= firstEvent ? startDate : firstEvent;
      lastEvent = endDate >= lastEvent ? endDate : lastEvent;
    }

    if (startDate === endDate) {
      // ignoring single day events at this stage
      continue;
    }

    let s = new Date(startDate);
    let yIndex = -1;
    let key = startDate;
    while (key <= endDate) {
      const eventStart = startDate === key;
      const eventEnd = endDate === key;
      const eventDuring = !eventStart && !eventEnd;

      const thisEvent = { ...item, eventStart, eventEnd, eventDuring };

      // check if there is any record of this day as yet...
      if (!eventsMap[key]) {
        eventsMap[key] = [];
      }

      if (yIndex === -1) {
        // this block only runs once per event, to establish a yIndex.
        if (eventsMap[key].length === 0) {
          // if there's nothing on this day, we're done... insert at row 0
          yIndex = 0;
        } else {
          // otherwise, find an empty slot.
          yIndex = getFreeRowMulti(thisEvent, eventsMap[key]);
        }
      }

      eventsMap[key].push({
        yIndex,
        eventIndex: j,
        eventDuring,
        eventEnd,
        eventStart,
        height,
      });

      s.setDate(s.getDate() + 1);
      key = dayYYYYMMDD(s);
    }
  }

  // loop through everything a second time, now ignoring multi day events
  for (var k = 0; k < filtered.length; k++) {
    const item = filtered[k];
    const { height, startDate, endDate } = item;

    if (startDate !== endDate) {
      // ignoring multi day events
      continue;
    }

    const key = startDate;

    // always true, true, false for a single day event: eventStart = eventEnd, and there's no day in between
    const eventStart = true;
    const eventEnd = true;
    const eventDuring = false;

    const thisEvent = { ...item, eventStart, eventEnd, eventDuring };

    // check if there is any record of this day as yet...
    if (!eventsMap[key]) {
      eventsMap[key] = [];
    }

    let yIndex = 0;
    if (eventsMap[key].length > 0) {
      // there is activity on this day, find an empty slot
      yIndex = getFreeRowSingle(thisEvent, eventsMap[key]);
    }

    eventsMap[key].push({
      yIndex,
      eventIndex: k,
      eventDuring,
      eventEnd,
      eventStart,
      height,
    });
  }

  const vacanciesMap: any = { firstEvent, lastEvent };

  // append the vacancies on the end, by starting vacancyIndex at end of filtered.
  let vacancyIndex = filtered.length;
  let vacantStart;
  let vacantEnd;
  const eventsArray = [...filtered];

  for (var i = 0; i < filtered.length; i++) {
    const item = filtered[i];

    const itemIsBlocking = blockingEventTypes.includes(item.status);
    if (itemIsBlocking) {
      // establish vacancy range
      vacantStart = item.endDate;

      // loop through all following items to find next blocking date...
      let nextItem = filtered[i + 1];
      let j = 1;
      while (nextItem) {
        const nextItemIsBlocking = blockingEventTypes.includes(nextItem.status);
        if (nextItemIsBlocking) {
          // found the next blocking item...
          vacantEnd = nextItem.startDate;
          // bail from while loop
          nextItem = null;
        } else {
          // keep searching
          nextItem = filtered[i + j];
        }
        j++;
      }

      // only add this vacancy if it is more than zero days long.
      if (vacantStart < vacantEnd) {
        eventsArray.push({
          startDate: vacantStart,
          endDate: vacantEnd,
          status: VACANT,
          eventIndex: eventsArray.length,
        });

        // now establish a lookup table for vacancies.
        let s = new Date(vacantStart);
        let key = vacantStart;
        while (key <= vacantEnd) {
          vacanciesMap[key] = vacancyIndex;
          s.setDate(s.getDate() + 1);
          key = dayYYYYMMDD(s);
        }
        vacancyIndex++;
      }
    }
  }

  return { eventsMap, eventsArray, vacanciesMap };
};

// find slots for an event that spans multiple days.
// loops through all events on a given day to discover what slot(s) are ideal for this event.
const getFreeRowMulti = (thisEvent: {[key: string]: any}, dayEvents: string | any[]) => {
  const availableSlots = [...arrayZeroToMax];
  // first find busy slots and remove them from availableSlots array;
  for (var w = 0; w < dayEvents.length; w++) {
    const otherEvent = dayEvents[w];
    // this is the main difference with getFreeRowSingle - in this case, a row is busy unless the other event has ended.
    // if (otherEvent.eventEnd) {
    // this slot is free
    // continue;
    // }
    const foundIndex = availableSlots.indexOf(otherEvent.yIndex);
    if (foundIndex > -1) {
      availableSlots.splice(foundIndex, otherEvent.height);
    }
  }

  if (thisEvent.height === 1) {
    // height is one, find the lowest available slot.
    return Math.min(...availableSlots);
  }

  // pick the best consecutive slots
  return availableSlots.reduce((acc, slot, arrIndex, arr) => {
    // if we are looking for a position for a double row event; look for 2 consecutive empty slots
    // this will break if rows are ever more than 2 high!!!
    if (arr.includes(slot + 1)) {
      return Math.min(slot, acc);
    }
    return Math.min(slot + 2, acc);
  }, maxRows);
};

// find slot for events that start and finish on the same day.
// similar to getFreeRowMulti, but since single day events are rendered right up to the edges of a cell, this has to be more strict on overlaps
const getFreeRowSingle = (thisEvent: any, dayEvents: string | any[]) => {
  const availableSlots = [...arrayZeroToMax];
  for (var w = 0; w < dayEvents.length; w++) {
    const otherEvent = dayEvents[w];
    const foundIndex = availableSlots.indexOf(otherEvent.yIndex);
    if (foundIndex > -1) {
      availableSlots.splice(foundIndex, otherEvent.height);
    }
  }
  return Math.min(...availableSlots);
};

export const downloadFile = async (blobUrl: string, name?: string) => {
  const link = document.createElement("a");
  link.setAttribute("href", blobUrl);
  link.setAttribute("download", name || "file");
  link.click();
};

export const downloadFileFromUrl = async (url: string, name?: string) => {
  const response = await fetch(url, {
    credentials: "omit",
    mode: "cors",
  });
  const blob = await response.blob();
  const blobUrl = URL.createObjectURL(blob);
  downloadFile(blobUrl, name);
};

export const downloadFileFromUrlWithCredentials = async (url: string, name?: string) => {
  const response = await fetch(url, {
    credentials: "include",
    mode: "cors",
    method: "GET",
  });
  const blob = await response.blob();
  const blobUrl = URL.createObjectURL(blob);
  downloadFile(blobUrl, name);
};

import { CHANNEL_LISTING } from "Constants/actionTypes";

const initialState = {
  error: false,
  errorCode: 0,
  errorMessage: "",
  pending: false,
  success: false,
  accountListings: [],
};

export function accountListingData(
  state = initialState,
  action: { [key: string]: any },
) {
  switch (action.type) {
    case CHANNEL_LISTING.SUCCESS:
      const { accountListings } = action;

      return {
        ...state,
        accountListings,
        pending: false,
        success: true,
      };
    case CHANNEL_LISTING.PENDING:
      return {
        ...state,
        pending: action.status,
      };
    case CHANNEL_LISTING.ERROR:
      return {
        ...state,
        error: true,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        pending: false,
      };
    case CHANNEL_LISTING.RESET:
      return initialState;
    default:
      return state;
  }
}

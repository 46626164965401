import React from "react";
import styled, { css } from "styled-components";

import { titleCase } from "Utils";
import theme, { getColourFromString } from "Constants/theme";

interface IProps {
  colour?: string;
  isGhost?: boolean;
  onClick?: (...args: any) => any;
  status: string;
}

export const Status = styled.span<any>`
  align-items: center;
  align-self: center;
  border-radius: 4px;
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  padding: 2px 8px;
  text-align: center;
  transition: background 0.8s ease-in-out, border 0.2s linear;

  margin-right: 4px;
  & + & {
    // this seems to generate 4x the same style specifier (see console) ???
    margin-top: 2px;
  }

  ${({ colour, isGhost, theme }) => {
    if (isGhost) {
      return css`
        border: 1px solid ${colour};
        background: ${theme.background.white};
        color: ${colour};
      `;
    }
    return css`
      border: 1px solid transparent;
      background: ${colour};
      color: ${theme.text.inverse};
    `;
  }}

  ${({ colour, isGhost, onClick, theme }) =>
    Boolean(onClick) &&
    (isGhost
      ? css`
          cursor: pointer;
          transition: background 0.3s ease-in-out, border 0.1s linear;
          &:hover {
            background: ${colour};
            color: ${theme.text.inverse};
          }
        `
      : css`
          cursor: pointer;
          &:hover {
            border: 1px solid white;
          }
        `)}
`;

export const inferColour = (status: string) => {
  if (/default/i.test(status)) return theme.background.accentTealBold;
  if (/cancel|expired|fail|invalid|rejected|unpaid/i.test(status)) {
    return theme.background.alertBold;
  }
  if (/blocked|deactivated|disconnected|expired|inactive/i.test(status)) {
    return theme.background.subtle3;
  }
  if (/loading|review|refund|received|WFDO|unpaid/i.test(status)) {
    return theme.warnOrange;
  }
  if (
    /active|booked|connected|current|in store|paid|success|valid|verified|refunded/i.test(
      status,
    )
  ) {
    return theme.background.successBold;
  }
  if (/loading|review|refund|received|WFDO/i.test(status)) {
    return theme.background.warningBold;
  }
  if (
    /progres|pending|ready|requested|scheduled|in use|awaiting/i.test(status)
  ) {
    return theme.background.infoBold;
  }
  return theme.background.neutral;
};

const StatusPill: React.FC<IProps> = ({
  isGhost = false,
  onClick,
  status,
  colour: customColour,
}) => {
  const colour = customColour
    ? getColourFromString(customColour)
    : inferColour(status);
  if (typeof status !== "string") {
    // eslint-disable-next-line
    console.warn("StatusPill - invalid status", status);
    status = "invalid";
  }
  // fuzzy `status` logic:
  // if it starts with a lowercase character, or is all uppercase, assume it is an API response
  // so make it `titleCase` for presentation to users
  // otherwise, assume it already has been formatted...
  const firstLetter = status.substring(0, 1);
  const startsWithLowercase = firstLetter === firstLetter.toLowerCase();
  const allUppercase = status === status.toUpperCase();
  const text = startsWithLowercase || allUppercase ? titleCase(status) : status;

  const props = onClick ? { onClick, role: "button" } : {};
  return (
    <Status isGhost={isGhost} colour={colour} {...props}>
      {text}
    </Status>
  );
};

export default StatusPill;

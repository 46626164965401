import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";

import getMenuItems from "./getMenuItems";
import logo from "./assets/logo.svg";
import pro from "./assets/pro.svg";
import proLogo from "./assets/madecomfy_pro.svg";
import MenuBlock from "./MenuBlock";
import theme from "Constants/theme";

import { isAuthorized } from "Utils";
import { ROLE } from "Constants/userRoles";
import { RouteComponentProps } from "react-router";
import { showProductionBanner } from "Components/ProductionBanner/ProductionBanner";

// import theme, { set } from "Constants/theme";
// document.cookie.includes("madecomfy_custom_theme") && set();

const NavigationWrapper = styled.nav<any>`
  background-color: ${theme.white};
  border-right: 2px solid ${theme.border.default};
  display: block;
  width: 92px;
  // flex-basis: 17%;
  height: 100vh;
  padding: 0 16px;
  position: fixed;
  top: 0px;
  z-index: 100;
  transition: all 0.3s ease-in-out;

  ${({ isMenuOpen }) =>
    isMenuOpen &&
    css`
      width: 224px;
    `};
  ${({ productionBanner }) =>
    productionBanner &&
    css`
      height: calc(100vh - 40px);
      top: 40px;
    `};
  @media (max-width: ${({ theme }) => theme.maxMobile}) {
    box-shadow: 0px 4px 10px rgba(53, 55, 60, 0.1);
    height: 64px;
    padding: 16px;
    position: sticky;
    width: auto;
    border: none;
  }
`;
const LogoWrapper = styled.a`
  display: flex;
  justify-content: center;
  height: 50px;
  margin: 0 auto;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.maxMobile}) {
    position: absolute;
    right: 16px;
    top: 0px;
  }
`;

const Logo = styled.img<any>`
  width: 62px;
  height: 27px;
  margin: 24px 0 8px 0;
  transition: all 0.3s ease-in-out;
  ${({ isMenuOpen }) =>
    isMenuOpen &&
    css`
      width: 92px;
      height: 40px;
    `};
`;
const LogoDesktop = styled(Logo)`
  display: block;
  @media (max-width: ${({ theme }) => theme.maxMobile}) {
    display: none;
  }
`;
const LogoMobile = styled(Logo)`
  display: none;
  @media (max-width: ${({ theme }) => theme.maxMobile}) {
    display: block;
    width: 92px;
    height: auto;
  }
`;
const BlockOut = styled.div`
  background: rgba(0, 0, 0, 0.2);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
`;
interface IProps extends RouteComponentProps {
  dispatch?: (...args: any) => any;
  user?: { propertyStatus: { count: number }; roles: any[] };
}

const Navigation: React.FC<IProps> = ({ user }) => {
  if (!user) return null; // no user = no nav required...
  //  const countProperties = user.propertyStatus && user.propertyStatus.count;
  const productionBanner = showProductionBanner({ user });
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [isMenuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };
    document.body.addEventListener("click", handleOutsideClick);
    return () => {
      document.body.removeEventListener("click", handleOutsideClick);
    };
  }, []);
  const isLicensee = isAuthorized(user.roles, [ROLE.LICENSEE]);

  return (
    <>
      {isMenuOpen && <BlockOut />}
      <NavigationWrapper
        id="navigation"
        productionBanner={productionBanner}
        isMenuOpen={isMenuOpen}
        ref={menuRef}
      >
        <LogoWrapper href="/">
          <LogoDesktop
            isMenuOpen={isMenuOpen}
            src={
              isMenuOpen && isLicensee
                ? proLogo
                : isLicensee && !isMenuOpen
                ? pro
                : logo
            }
          />
          <LogoMobile
            isMenuOpen={isMenuOpen}
            src={isLicensee ? proLogo : logo}
          />
        </LogoWrapper>
        {user && (
          <>
            <MenuBlock
              user={user}
              menuItems={getMenuItems({
                roles: user.roles,
              })}
              isMenuOpen={setMenuOpen}
              menuOpen={isMenuOpen}
            />
          </>
        )}
      </NavigationWrapper>
    </>
  );
};

export default Navigation;

import { CREATE_PASSWORD } from "Constants/actionTypes";

const initialState = {
  success: false,
  error: false,
  connectionError: false,
  errorMessage: "",
  pending: false,
  errorCode: "",
};
export function createPasswordStatus(state = initialState, action: {[key: string]: any}) {
  switch (action.type) {
    case CREATE_PASSWORD.SUCCESS:
      return { ...state, success: true };
    case CREATE_PASSWORD.PENDING:
      return { ...state, pending: action.status };
    case CREATE_PASSWORD.ERROR:
      return {
        ...state,
        error: true,
        errorMessage: action.errorMessage,
        errorCode: action.errorCode,
      };
    case CREATE_PASSWORD.CONNECTION_FAILURE:
      return {
        ...state,
        connectionError: true,
        errorMessage: action.errorMessage,
        errorCode: action.errorCode,
      };
    default:
      return state;
  }
}

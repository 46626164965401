import styled from "styled-components";
import React, { useEffect, useState } from "react";

import ComfyContainer from "Components/ComfyContainer";
import { H3 } from "Components/Typography";
import { formatCurrency } from "Utils/methods/currency";

import { merchantAccountsBalances } from "Actions/properties/propertyMerchantAccounts";

const Wrapper = styled(ComfyContainer)`
  background-color: ${({ theme }) => theme.darkPetrol};
  min-height: 100%;
  color: ${({ theme }) => theme.white};
  h4 {
    color: ${({ theme }) => theme.turquoise};
  }
  th {
    font-size: 20px;
    padding: 16px 0;
  }
  td {
    font-size: 18px;
    padding: 8px 0;
  }
  thead tr {
    border-bottom: 1px solid #fff;
  }
  tbody > tr {
    border-bottom: 1px solid #fff;
    &:last-child {
      border: none;
    }
  }
  ul {
    margin: 0;
  }
`;

const Balances = () => {
  const [balance, setBalance] = useState<any>(null);

  const getBalance = async () => {
    const resp = await merchantAccountsBalances();
    setBalance(resp);
  };
  useEffect(() => {
    getBalance();
  }, []);
  if (!balance) return null;
  return (
    <Wrapper>
      <H3 color="theme.lemon">Balances</H3>
      <table>
        <thead>
          <tr>
            <th>Account</th>
            <th>Stripe ID</th>
            <th>Available balance</th>
          </tr>
        </thead>
        <tbody>
          {balance.map((item: { [key: string]: any }) => (
            <tr>
              <td>{item.accountName}</td>
              <td>{item.id}</td>
              <td>
                {item?.amounts?.map((bal: { [key: string]: any }) => (
                  <ul>
                    <li>
                      {formatCurrency(bal.available / 100, 2, bal.currency)}
                    </li>
                  </ul>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Wrapper>
  );
};

export default Balances;

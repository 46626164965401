import "isomorphic-fetch";

import Config from "Config";
import includes from "lodash/includes";
import { CREATE_PASSWORD } from "Constants/actionTypes";
import { INTERNAL_SERVER_ERROR, NOT_FOUND, OK } from "Utils/Network";
import { logException } from "ThirdParty";

export function apiCallPassword(user: {[key: string]: any}) {
  const { token, type, password, passwordConfirm } = user;
  return (dispatch: (...args: any) => any) => {
    dispatch({ type: CREATE_PASSWORD.PENDING, status: true });
    return fetch(Config.API_URL + "v1/public/" + type + "/" + token + "/", {
      credentials: "include",
      mode: "cors",
      method: "POST",
      body: JSON.stringify({
        password,
        passwordConfirm,
      }),
    })
      .then((response) => {
        return response.json().then((json) => {
          if (response.status === OK) {
            dispatch({ type: CREATE_PASSWORD.SUCCESS });
          } else {
            dispatch({
              type: CREATE_PASSWORD.ERROR,
              errorMessage: json.message,
              errorCode: NOT_FOUND,
            });
          }
        });
      })
      .then(() => dispatch({ type: CREATE_PASSWORD.PENDING, status: false }))
      .catch((response) => {
        logException("createPassword", CREATE_PASSWORD);
        dispatch({ type: CREATE_PASSWORD.PENDING, status: false });
        dispatch({
          type: CREATE_PASSWORD.CONNECTION_FAILURE,
          errorCode: INTERNAL_SERVER_ERROR,
        });
      });
  };
}

export function createPassword(user: {[key: string]: any}) {
  const hasValidPath = includes(["change", "confirm"], user.type);
  if (hasValidPath) {
    return apiCallPassword(user);
  } else {
    return (dispatch: (...args: any) => any) =>
      dispatch({ type: CREATE_PASSWORD.ERROR, errorCode: NOT_FOUND });
  }
}

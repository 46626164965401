import styled from "styled-components";

export const WrapperTooltip = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: -webkit-fill-available;
  div[data-test="tooltip"] {
    width: max-content;
  }
`;

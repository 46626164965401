import "isomorphic-fetch";

import {
  getJSON,
} from "Utils/Network";
import { API_URL } from "Config";

export const documentsRestore = async () => {
  try {
    const json = await getJSON(`${API_URL}v3/documents?structure=tree`);
    const { data } = json;
    return data;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn("forms restore err", err);
  }
};

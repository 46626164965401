import "isomorphic-fetch";

import { SESSION } from "Constants/actionTypes";
import Config from "Config";

export function logout() {
  return (dispatch: (...args: any) => any) => {
    return fetch(Config.API_URL + "v1/public/logout/", {
      credentials: "include",
      mode: "cors",
      method: "GET",
    })
      .then((response) => {
        dispatch({ type: SESSION.RESET });
      })
      .catch((response) => {
        dispatch({ type: SESSION.RESET });
      });
  };
}

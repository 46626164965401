import { API_URL } from "Config";
import {
  generateErrorHandler,
  patchJSON,
} from "Utils/Network";

const handleError = generateErrorHandler("NEWSLETTER_ERROR");

export const newsletterUpdate = async (newsletterId: string, params: {[key: string]: any}) => {
    try {
      const url = `${API_URL}v3/newsletters/${newsletterId}`;
      const json = await patchJSON(url, params);
      return { id: json.data.id, ...json.data.attributes };
    } catch (err: any) {
      // eslint-disable-next-line
      console.warn("newsletterUpdate error", err);
      return handleError(err);
    }
  };

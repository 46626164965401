import React, { Component } from "react";
import styled from "styled-components";

import { ALERT_ERROR, Alert, BUTTON_PRIMARY } from "@madecomfy/webooi";

import ComfyContainer from "Components/ComfyContainer";
import EditSettings from "./components/EditSettings";
import { H4 } from "Components/Typography";
import Loading from "Components/Loading";

interface IProps {
  history: any;
  match: { [key: string]: any };
  resetGet: (...args: any) => any;
  users: { [key: string]: any };
  userMeRestore: (...args: any) => any;
  userMeUpdate: (...args: any) => any;
  userRolesRestore: (...args: any) => any;
}

const Wrapper = styled(ComfyContainer)`
  margin: 24px;
`;

class Settings extends Component<IProps> {
  render() {
    const { resetGet, users, userMeRestore } = this.props;
    const { editUserPermissions, editUserStatus } = users;
    const { pending, roles: editUserRoles } = editUserPermissions;
    const {
      connectionError,
      error,
      errorMessage,
      user,
      ownedCompanies,
      ownedProperties,
    } = editUserStatus;

    const canUpdateUser = user?.canUpdate;

    if (error || connectionError) {
      // eslint-disable-next-line
      console.warn("Settings error:", error, connectionError);
      return (
        <ComfyContainer>
          <H4>User info - failed</H4>
          <Alert
            buttons={[
              {
                callback: () => {
                  resetGet();
                  userMeRestore();
                },
                label: "Try again",
                type: BUTTON_PRIMARY,
              },
            ]}
            message={errorMessage || `There was an error loading your info`}
            testId="setting"
            title="Oops"
            type={ALERT_ERROR}
          />
        </ComfyContainer>
      );
    }

    if (pending) {
      return <Loading task="updatingInfo" />;
    }

    return (
      <Wrapper>
        <H4>Settings</H4>
        {user && (
          <EditSettings
            canUpdateUser={canUpdateUser}
            editUserRoles={editUserRoles}
            editUserStatus={editUserStatus}
            userDetails={user}
            ownedCompanies={ownedCompanies}
            ownedProperties={ownedProperties}
            onSubmit={this.handleSubmit.bind(this)}
            reset={resetGet}
          />
        )}
      </Wrapper>
    );
  }

  handleSubmit(updatedDetails: { [key: string]: any }) {
    const { user: updatedUser } = updatedDetails;
    const { userMeUpdate } = this.props;
    userMeUpdate(updatedUser);
  }

  componentDidMount() {
    const { users, userMeRestore, resetGet } = this.props;
    const { editUserStatus } = users;
    if (editUserStatus?.user) {
      resetGet();
    }
    userMeRestore();
  }
}

export default Settings;

import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import styled from "styled-components";

import { Table, Toggle } from "@madecomfy/webooi";

import DropDown from "Components/DropDown";
import Loading from "Components/Loading";
import { roleText } from "./UserRoles";

const Pill = styled.span`
  display: inline-block;
  background: ${({ theme }) => theme.pill.background};
  color: ${({ theme }) => theme.pill.color};
  text-transform: lowercase;
  font-size: 14px;
  line-height: 20px;
  padding: 2px 8px;
  text-align: center;
  border-radius: 4px;
`;
const WrapperPill = styled.span`
  display: flex;
  gap: 2px;
  flex-wrap: wrap;
`;
const Element = styled.span`
  width: 100%;
  cursor: pointer;
`;
const Text = styled.span`
  padding-left: 8px;
`;
const Container = styled.div`
  span {
    cursor: pointer;
    &:hover {
      background-color: ${({ theme }) => theme.navyLight50};
    }
  }
  .withClickOutside ul li {
    padding: 4px 16px;
    border: none;
    &:hover {
      background: none;
    }
  }
`;

interface IProps extends RouteComponentProps {
  data: any[];
  loading: boolean;
  onPageChange: (...args: any) => any;
  pages?: number;
  pageSize: number;
  userUpdate: Function;
}

const UserList: React.FC<IProps> = (props) => {
  const { data, history, loading, onPageChange, pages, pageSize, userUpdate } =
    props;
  const [index, setIndex] = useState("");
  const columns = [
    {
      title: "Name",
      dataIndex: "fullName",
      render: (fullName: string, rowId: string) => {
        return (
          <Element onClick={() => history?.push(`/users/update/${rowId}`)}>
            {fullName}
          </Element>
        );
      },
      sortable: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      sortable: true,
      render: (email: string, rowId: string) => (
        <Element onClick={() => history?.push(`/users/update/${rowId}`)}>
          {email}
        </Element>
      ),
    },
    {
      title: "Status",
      dataIndex: ["enabled", "canUpdate"],
      render: (status: string[], rowId: string) => (
        <>
          <Toggle
            isEnabled={Boolean(status[1])}
            my={0}
            name={rowId}
            value={Boolean(status[0])}
            sendValue={({ value }: any) => {
              userUpdate(rowId, { enabled: value });
            }}
            size="small"
          />
          <Text>{status[0] ? "Enabled" : "Disabled"}</Text>
        </>
      ),
      sortable: false,
    },
    {
      title: "Roles",
      dataIndex: "roles",
      render: (text: any[], rowId: string) => {
        const size = 3;
        return (
          <WrapperPill>
            {text.slice(0, size).map((item: any, i: number) => {
              return <Pill key={i}>{roleText(item)}</Pill>;
            })}
            {text.length > size && (
              <Container>
                <Pill
                  onMouseOver={() => {
                    setIndex(rowId);
                  }}
                  onMouseLeave={() => {
                    setIndex("");
                  }}
                >
                  +{text.length - size}
                </Pill>
                {index === rowId && (
                  <DropDown
                    align="right"
                    caret={false}
                    isDisplayed={true}
                    items={text.slice(size, text.length).map((role, i) => {
                      return {
                        label: <Pill key={i}>{roleText(role)}</Pill>,
                        link: () => {},
                      };
                    })}
                    onClickOutside={() => setIndex("")}
                    position={{ right: "0" }}
                  />
                )}
              </Container>
            )}
          </WrapperPill>
        );
      },
      sortable: false,
    },
  ];

  return (
    <>
      {loading && <Loading task="usersList" />}
      <Table
        columns={columns}
        data={data}
        dataType="users"
        getPageNumber={(number: number) => onPageChange(number)}
        paginationAuto={false}
        rowsPerPage={pageSize}
        showSearch={false}
        totalCount={pages}
      />
    </>
  );
};

export default withRouter<IProps, any>(UserList);

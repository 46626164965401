import React from "react";

const Users = () => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.0469 5.66176C17.9431 4.39071 16.0714 4.12743 14.6598 5.04465C14.3124 5.27033 13.8479 5.17172 13.6222 4.82438C13.3965 4.47705 13.4951 4.01253 13.8425 3.78685C15.8815 2.46197 18.585 2.84227 20.1794 4.67823C21.7738 6.5142 21.7714 9.24435 20.1739 11.0775C19.2259 12.1654 17.8882 12.7399 16.5315 12.7498C18.2034 12.7584 19.8429 13.3257 21.1752 14.3888C22.9582 15.8116 23.9976 17.9679 24 20.249C24.0004 20.6632 23.665 20.9993 23.2508 20.9997C22.8366 21.0002 22.5004 20.6647 22.5 20.2505C22.4981 18.4252 21.6664 16.6998 20.2396 15.5613C18.8129 14.4229 16.9459 13.9949 15.1657 14.3982C14.7617 14.4897 14.3601 14.2364 14.2685 13.8324C14.177 13.4285 14.4303 13.0268 14.8343 12.9353C15.3723 12.8134 15.9167 12.7522 16.4582 12.7497C15.5527 12.7427 14.6417 12.4839 13.8354 11.9579C13.4884 11.7317 13.3906 11.267 13.6169 10.92C13.8432 10.5731 14.3079 10.4753 14.6548 10.7016C16.0649 11.6212 17.937 11.3612 19.043 10.0921C20.149 8.82292 20.1507 6.93281 19.0469 5.66176Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 2.99972C4.80761 2.99972 2.625 5.18234 2.625 7.87472C2.625 10.5671 4.80761 12.7497 7.5 12.7497C3.35786 12.7497 0 16.1076 0 20.2497C0 20.6639 0.335786 20.9997 0.75 20.9997C1.16421 20.9997 1.5 20.6639 1.5 20.2497C1.5 16.936 4.18629 14.2497 7.5 14.2497C10.8137 14.2497 13.5 16.936 13.5 20.2497C13.5 20.6639 13.8358 20.9997 14.25 20.9997C14.6642 20.9997 15 20.6639 15 20.2497C15 16.1076 11.6421 12.7497 7.5 12.7497C10.1924 12.7497 12.375 10.5671 12.375 7.87472C12.375 5.18234 10.1924 2.99972 7.5 2.99972ZM4.125 7.87472C4.125 6.01076 5.63604 4.49972 7.5 4.49972C9.36396 4.49972 10.875 6.01076 10.875 7.87472C10.875 9.73869 9.36396 11.2497 7.5 11.2497C5.63604 11.2497 4.125 9.73869 4.125 7.87472Z"
    />
  </>
);

export default Users;

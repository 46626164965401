// a very common button.
import React from "react";
import styled from "styled-components";

import {
  Button,
  BUTTON_INLINE,
  BUTTON_LINK,
  BUTTON_SMALL,
  Tooltip,
} from "@madecomfy/webooi";
import { WrapperTooltip } from "Containers/Resources/components/Style";

const Wrapper = styled.div`
  position: relative;
  button {
    position: relative;
    z-index: 9;
  }
`;

interface IProps {
  icon?: any;
  label?: string;
  testId?: string;
  onClick: (...args: any) => any;
}

const ButtonEdit: React.FC<IProps> = ({
  icon = "edit",
  label = "Edit",
  testId,
  onClick,
}) => (
  <Wrapper>
    <Button
      icon={icon}
      label={label}
      my={0}
      onClick={onClick}
      size={BUTTON_SMALL}
      styling={BUTTON_INLINE}
      testId={testId}
      type={BUTTON_LINK}
    />
    <WrapperTooltip>
      <Tooltip
        hideIcon={true}
        position="top"
        text="Edit"
        testId="tooltip"
        timeOut={100}
      />
    </WrapperTooltip>
  </Wrapper>
);

export default ButtonEdit;

import { transformBookingMinimal } from "Actions/bookings/transformBooking";
import { API_URL } from "Config";
import { BOOKINGS_PROPERTY } from "Constants/actionTypes";
import { generateErrorHandler, generateURL, getJSON } from "Utils/Network";
import { bookingRestore } from "./bookingRestore";
import { propertyEventsRestore } from "Actions/properties/propertyEvents";
import { propertyTasksRestore } from "Actions/properties/propertyTasks";

import calcRanges from "Components/Dates/calcRanges";

const handleBookingsError = generateErrorHandler(BOOKINGS_PROPERTY.ERROR);

export const bookingsRestore = ({
  bookingIds = [],
  endDate,
  ignoreEventId,
  loadConfidentials,
  loadRequested,
  propertyId,
  startDate,
}: {
  [key: string]: any;
}) => {
  return async (dispatch: (...args: any) => any) => {
    try {
      const include = ["resources"];
      const statuses = ["BOOKED", "BLOCKED"];
      if (loadConfidentials) {
        include.push("notes");
      }
      if (loadRequested) {
        statuses.push("REQUESTED", "CANCELLED");
      }
      const url = generateURL(
        `${API_URL}v3/properties/${propertyId}/bookings`,
        {
          from: startDate,
          to: endDate,
          recordStatuses: ["ACTIVE", "CANCELLING"],
          statuses,
          include,
        },
      );

      dispatch({ type: BOOKINGS_PROPERTY.RESET }); // reset any errors...
      dispatch({ type: BOOKINGS_PROPERTY.PENDING, status: true });
      const json = await getJSON(url);
      const bookings = transformBookingMinimal(json);

      // load rates only if needed.
      const rates = loadConfidentials
        ? await propertyRatesRestore(propertyId, startDate, endDate)
        : {};

      const loadBookings = Boolean(bookingIds.length);

      // load events only if needed.
      const events = loadConfidentials
        ? await propertyEventsRestore({ propertyId, startDate, endDate })
        : [];

      const tasks = loadConfidentials
        ? await propertyTasksRestore({ propertyId, startDate, endDate })
        : [];

      const calendar = calcRanges({ bookings, events, ignoreEventId });

      dispatch({
        type: BOOKINGS_PROPERTY.SUCCESS,
        bookings,
        calendar,
        tasks,
        pending: loadBookings,
        rates,
      });

      // load individual bookings if needed...
      if (loadBookings) {
        await Promise.all(
          bookingIds.map((id: string) => dispatch(bookingRestore(id, false))),
        );
      }
    } catch (err: any) {
      // eslint-disable-next-line
      console.warn("bookingsRestore err", err);
      dispatch(handleBookingsError(err));
    }
  };
};

const propertyRatesRestore = async (
  propertyId: string,
  startDate: any,
  endDate: any,
) => {
  const url = generateURL(`${API_URL}v3/properties/${propertyId}/rates`, {
    from: startDate,
    to: endDate,
  });
  const json = await getJSON(url);
  const rates = json.data.reduce((acc: any, { attributes }: any) => {
    const { date, airbnbPrice, bookingComPrice, madeComfyPrice, vrboPrice } =
      attributes;
    return {
      ...acc,
      [date]: {
        airbnbPrice,
        bookingComPrice,
        madeComfyPrice,
        vrboPrice,
      },
    };
  }, {});
  return rates;
};

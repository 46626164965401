import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import styled from "styled-components";

import {
  Alert,
  ALERT_WARNING,
  Button,
  BUTTON_CENTER,
  Form,
  TYPE_EMAIL,
  TYPE_TEXT,
  ValidatedCheckBox,
  ValidatedInputText,
  ValidatedToggle,
} from "@madecomfy/webooi";

import PropertySelector from "./PropertySelector";
import CompanySelector from "./CompanySelector";
import UserConfirmModal from "./UserConfirmModal";
import UserDetailsStatic from "./UserDetailsStatic";
import { H5 } from "Components/Typography";
import { CREATE, RESTORE, UPDATE } from "Constants/crud";
import { roleText } from "./UserRoles";
import { userCompanies } from "Actions/users/userCompanies";
import {
  validateContentCreator,
  validateLicensee,
  validateName,
  validateOwner,
  validateProtected,
} from "./validators";

const GridRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: -24px;
  & > div {
    margin-top: -24px;
  }
  @media (max-width: ${({ theme }) => theme.maxMobile}) {
    grid-template-columns: 1fr;
  }
`;
interface IProps extends RouteComponentProps {
  canUpdateUser: boolean;
  editUserRoles: { [key: string]: any };
  editUserStatus: { [key: string]: any };
  onSubmit: (...args: any) => any;
  ownedCompanies?: { [key: string]: any }[];
  ownedProperties?: any[];
  reset?: (...args: any) => any;
  userDetails?: { [key: string]: any };
}

const getDefaults = (
  userDetails = {} as { [key: string]: any },
  ownedProperties = [],
  ownedCompanies = [],
) => {
  const userDefaults = {
    bookingNotificationsEnabled:
      typeof userDetails?.bookingNotificationsEnabled === "boolean"
        ? userDetails?.bookingNotificationsEnabled
        : true,
    bookingChangeNotificationsEnabled:
      typeof userDetails.bookingChangeNotificationsEnabled === "boolean"
        ? userDetails.bookingChangeNotificationsEnabled
        : false,
    bookingCancellationNotificationsEnabled:
      typeof userDetails.bookingCancellationNotificationsEnabled === "boolean"
        ? userDetails.bookingCancellationNotificationsEnabled
        : false,
    ownerStayNotificationsEnabled:
      typeof userDetails.ownerStayNotificationsEnabled === "boolean"
        ? userDetails.ownerStayNotificationsEnabled
        : false,
    ownerStayChangeNotificationsEnabled:
      typeof userDetails.ownerStayChangeNotificationsEnabled === "boolean"
        ? userDetails.ownerStayChangeNotificationsEnabled
        : false,
    ownerStayCancellationNotificationsEnabled:
      typeof userDetails.ownerStayCancellationNotificationsEnabled === "boolean"
        ? userDetails.ownerStayCancellationNotificationsEnabled
        : false,

    email: String(userDetails.email || ""), // we don't want "null" or "undefined"
    enabled: Boolean(
      // default to true for new user
      typeof userDetails.enabled === "boolean" ? userDetails.enabled : true,
    ),
    firstName: String(userDetails.firstName || ""),
    lastName: String(userDetails.lastName || ""),
    mobile: String(userDetails.mobile || ""),
    nps: String(typeof userDetails.nps === "number" ? userDetails.nps : ""), // if 0, honour it, otherwise empty string.
    roles: userDetails.roles || [],
  };

  const formDefaults = {
    properties: [],
    validForm: false,
  };
  return {
    ownedCompanies,
    ownedProperties,
    ...userDefaults,
    ...formDefaults,
  };
};

class UserDetails extends Form<any, any> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      company: null,
      companies: [],
      allCompanies: [],
      modal: false,
      isLicensee: false,
      isOwner: false,
      ...this.handleProps(props as any),
    };
  }
  async componentDidMount() {
    const allCompanies = await userCompanies("licensee");
    this.setState({ allCompanies });
  }
  handleProps = ({
    userDetails,
    ownedProperties,
    ownedCompanies,
  }: {
    ownedCompanies?: any;
    ownedProperties?: any;
    userDetails?: { [key: string]: any };
  }) => {
    return {
      ...this.state,
      ...getDefaults(userDetails, ownedProperties, ownedCompanies),
    };
  };

  confirmationModal = (modal: boolean) => {
    this.setState({ modal });
  };

  checkSubmit = () => {
    const isValid = this.validate();
    if (isValid) {
      this.confirmationModal(true);
    }
  };

  handleSubmit = () => {
    const { canUpdateUser, onSubmit } = this.props;
    const properties = this.state.properties.map(
      (property: { [key: string]: any }) => property.id,
    );
    const companies = this.state.companies.map(
      (property: { [key: string]: any }) => property.id,
    );
    if (!canUpdateUser) {
      onSubmit({
        user: null, // leave user as is, and only update properties
        properties,
        companies,
      });
      return;
    }
    const user = this.getValues();
    user.roles = Object.entries(user.roles)
      .filter(([_, value]) => value)
      .map(([key]) => key);
    user.nps = Number(user.nps);
    if (this.state.isLicensee) {
      user.companyId = this.state.company;
    }
    onSubmit({ user, properties, companies });
  };

  renderModal() {
    const { modal, properties, companies } = this.state;
    if (!modal) return;
    const { editUserStatus, reset } = this.props;
    return (
      <UserConfirmModal
        companies={companies}
        editUserStatus={editUserStatus}
        isLicensee={this.state.isLicensee}
        onCancel={() => this.confirmationModal(false)}
        onConfirm={this.handleSubmit}
        onRetry={() => this.setState({ modal: false }, reset)}
        onSuccess={this.editComplete}
        properties={properties}
        userDetails={this.getValues()}
      />
    );
  }

  editComplete = () => {
    const { history, reset } = this.props;
    reset();
    history.push("/users");
  };

  renderForm(mode: string) {
    const { ...rest } = this.state;
    const {
      bookingNotificationsEnabled,
      bookingChangeNotificationsEnabled,
      bookingCancellationNotificationsEnabled,
      ownerStayNotificationsEnabled,
      ownerStayChangeNotificationsEnabled,
      ownerStayCancellationNotificationsEnabled,
      email,
      enabled,
      firstName,
      lastName,
      mobile,
      nps,
      ownedCompanies,
      ownedProperties,
      roles,
    } = this.state;

    const { editUserRoles, userDetails } = this.props;
    const rolesParams = editUserRoles.reduce(
      // extract `options` and `value` for roles checkbox
      (acc: { [key: string]: any }, { role, canGrant }: any) => {
        const options = acc.options.concat({
          value: role,
          label: roleText(role),
        });
        const value = { ...acc.value, [role]: roles.includes(role) };
        return { options, value };
      },
      { options: [], value: {} },
    );
    const validateRoles = validateProtected(editUserRoles, roles);

    return (
      <div>
        {enabled === false && (
          <Alert message="This account is disabled" type={ALERT_WARNING} />
        )}
        <ValidatedToggle
          {...rest}
          label="Enabled"
          name="enabled"
          testId="enabled"
          value={enabled}
        />
        <ValidatedInputText
          {...rest}
          label="First Name"
          name="firstName"
          type={TYPE_TEXT}
          testId="first-name"
          validation={{
            check: validateName,
            errorClient: "Please enter a first name",
          }}
          value={firstName}
        />
        <ValidatedInputText
          {...rest}
          label="Last Name"
          name="lastName"
          testId="last-name"
          type={TYPE_TEXT}
          validation={{
            check: validateName,
            errorClient: "Please enter a last name",
          }}
          value={lastName}
        />
        <ValidatedInputText
          {...rest}
          label="Email"
          name="email"
          testId="email"
          type={TYPE_EMAIL}
          validation={{
            check: TYPE_EMAIL,
            errorClient: "Please enter a valid email",
          }}
          value={email}
        />
        <ValidatedInputText
          {...rest}
          label="Mobile"
          name="mobile"
          testId="mobile"
          type={TYPE_TEXT}
          validation={{
            check: () => true,
            errorClient: "Not required",
          }}
          value={mobile}
        />
        <GridRow>
          {(this.state.isOwner || this.state.isLicensee) && (
            <div>
              <ValidatedToggle
                {...rest}
                label="Booking Notifications"
                name="bookingNotificationsEnabled"
                testId="booking-notification"
                value={bookingNotificationsEnabled}
              />
              <ValidatedToggle
                {...rest}
                label="Booking Change Notifications"
                name="bookingChangeNotificationsEnabled"
                testId="booking-notification-1"
                value={bookingChangeNotificationsEnabled}
              />
              <ValidatedToggle
                {...rest}
                label="Booking Cancellation Notifications"
                name="bookingCancellationNotificationsEnabled"
                testId="booking-notification-2"
                value={bookingCancellationNotificationsEnabled}
              />
            </div>
          )}
          {this.state.isLicensee && (
            <div>
              <ValidatedToggle
                {...rest}
                label="Owner Stay Notifications"
                name="ownerStayNotificationsEnabled"
                testId="booking-notification-3"
                value={ownerStayNotificationsEnabled}
              />
              <ValidatedToggle
                {...rest}
                label="Owner Stay Change Notifications"
                name="ownerStayChangeNotificationsEnabled"
                testId="booking-notification-4"
                value={ownerStayChangeNotificationsEnabled}
              />
              <ValidatedToggle
                {...rest}
                label="Owner Stay Cancellation Notifications"
                name="ownerStayCancellationNotificationsEnabled"
                testId="booking-notification-5"
                value={ownerStayCancellationNotificationsEnabled}
              />
            </div>
          )}
        </GridRow>
        <ValidatedCheckBox
          {...rest}
          {...rolesParams} // options and value.
          label="Roles"
          name="roles"
          testId="roles"
          validation={{
            check: (newRoles: { [key: string]: any }) => {
              const role = Object.keys(newRoles).filter((k) => newRoles[k]);
              if (role.includes("ROLE_LICENSEE")) {
                this.setState({ isLicensee: true });
              } else {
                this.setState({ isLicensee: false });
              }
              if (role.includes("ROLE_OWNER")) {
                this.setState({ isOwner: true });
              } else {
                this.setState({ isOwner: false });
              }
              if (validateOwner(newRoles)) return false;
              if (validateRoles(newRoles)) return false;
              if (validateLicensee(newRoles)) return false;
              if (validateContentCreator(newRoles)) return false;
              return Object.values(newRoles).some(Boolean);
            },
            errorClient: (newRoles: { [key: string]: any }) => {
              if (validateOwner(newRoles)) {
                return "If a user is an 'owner' they cannot have any other roles assigned!";
              }
              if (validateRoles(newRoles)) {
                return "Sorry, you do not have permission to change that role for that user!";
              }
              if (validateLicensee(newRoles)) {
                return "If a user is a 'licensee' they cannot have any other roles assigned.";
              }
              if (validateContentCreator(newRoles)) {
                return "Config codes and config instructions must also be activated with the content creator permission";
              }
              return "You must check at least one role!";
            },
          }}
        />

        <ValidatedInputText
          {...rest}
          label="Net Promoter Score"
          name="nps"
          testId="nps-id"
          type={TYPE_TEXT}
          validation={{
            check: (v: any) => {
              // this is correct. it can be an empty string if nothing is specified. otherwise, check the number is legit.
              return (
                v === "" ||
                (!isNaN(v as number) &&
                  (v as number) >= -100 &&
                  (v as number) <= 100)
              );
            },
            errorClient:
              "Please enter nothing OR an integer between -100 and 100 inclusive",
          }}
          value={nps}
        />
        {!this.state.isLicensee ? (
          <PropertySelector
            ownedProperties={ownedProperties}
            setProperties={(properties) => {
              this.setState({ properties });
            }}
          />
        ) : (
          <CompanySelector
            userDetails={userDetails}
            ownedCompanies={ownedCompanies}
            setUserCompanies={(companies) => {
              this.setState({ companies });
            }}
            setOwnedCompanies={(ownedCompanies) => {
              this.setState({ ownedCompanies });
            }}
          />
        )}
        <div>
          <Button
            isEnabled={this.getValidation()}
            // @ts-ignore
            label={new Map([
              [RESTORE, "Save"],
              [UPDATE, "Save"],
              [CREATE, "Add User"],
            ]).get(mode)}
            onClick={this.checkSubmit}
            styling={BUTTON_CENTER}
            testId="submit"
          />
        </div>
      </div>
    );
  }

  renderStatic() {
    // staff users can still add properties to a user
    const {
      editUserStatus: { ownedProperties, user },
    } = this.props;
    const isEnabled = this.state.properties.length > 0;
    return (
      <div>
        {this.renderModal()}
        <UserDetailsStatic user={user} />
        <PropertySelector
          ownedProperties={ownedProperties}
          setProperties={(properties) => {
            this.setState({ properties });
          }}
        />
        <Button
          isEnabled={isEnabled}
          label="Add Properties"
          onClick={() => isEnabled && this.confirmationModal(true)}
          styling={BUTTON_CENTER}
          testId="submit"
        />
      </div>
    );
  }

  renderSuccess(mode: string) {
    window.scroll(0, 0);
    return (
      <div>
        <H5>
          {/* @ts-ignore */}
          {new Map([
            [CREATE, "A new user has been created"],
            [UPDATE, "User has been updated"],
          ]).get(mode)}
        </H5>
        <div>
          <Button
            label="All done"
            onClick={this.editComplete}
            testId="user-change-success"
          />
        </div>
      </div>
    );
  }

  // @ts-ignore
  render() {
    const {
      canUpdateUser,
      editUserStatus: {
        mode,
        // pending, <- don't use pending... parent component should handle this
        success,
      },
    } = this.props;

    if (!mode) {
      return null; // editUserStatus.mode - this is before UserAdd or UserEdit has called reset.
    }

    if (success && (mode === CREATE || mode === UPDATE)) {
      return this.renderSuccess(mode);
    }
    if (canUpdateUser) {
      return (
        <div>
          {this.renderForm(mode)}
          {this.renderModal()}
        </div>
      );
    }
    return this.renderStatic();
  }
}

export default withRouter<IProps, any>(UserDetails);
export { getDefaults };

import "isomorphic-fetch";

import { extractHandledError, NetworkError, HandledErrors } from "./errors";

/**
 * makeRequest
 * such a common pattern that an alias is worth it
 * always try and use this approach via the exported shortcuts below first!
 */

const makeRequest = async ({ url, params, method, handledErrors }: { url: string, params?: object, method: string, handledErrors?: HandledErrors }) => {
  const body = typeof params === "object" ? JSON.stringify(params) : params;
  const response = await fetch(url, {
    credentials: "include",
    mode: "cors",
    method,
    body,
  });
  if (handledErrors) {
    await extractHandledError(response, handledErrors);
  }
  if (!response.ok) {
    throw new NetworkError(response);
  }
  return response.json();
};

export const getJSON = (url: string, handledErrors?: HandledErrors) =>
  makeRequest({ url, method: "GET", handledErrors });

export const patchJSON = (url: string, params?: object, handledErrors?: HandledErrors) =>
  makeRequest({ url, params, method: "PATCH", handledErrors });

export const postJSON = (url: string, params?: object, handledErrors?: HandledErrors) =>
  makeRequest({ url, params, method: "POST", handledErrors });

export const deleteJSON = (url: string, params?: object, handledErrors?: HandledErrors) =>
  makeRequest({ url, params, method: "DELETE", handledErrors });

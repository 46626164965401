import React from "react";
// import styled from "styled-components";

import { Form, ValidatedInputText } from "@madecomfy/webooi";

import { ButtonsSaveCancel } from "Components/Buttons";

class EventDetails extends Form<any, any> {
  render() {
    const { initialName, onCancel, onConfirm, onDelete } = this.props;
    const { ...rest } = this.state;
    return (
      <div>
        <ValidatedInputText
          {...rest}
          label="Event details"
          name="eventName"
          multiline
          placeholder="Enter some event details"
          testId="event-details"
          value={initialName || ""}
          validation={{
            check: (value: any) =>
              Boolean(value) && value.length > 0 && value.length <= 250,
            errorClient: (value: any) =>
              value.length === 0
                ? "Enter at least 1 character"
                : "Maximum length is 250 characters",
          }}
        />
        <ButtonsSaveCancel
          onCancel={onCancel}
          onDelete={onDelete}
          onSave={() => {
            if (this.validate()) {
              onConfirm(this.getValues());
            }
          }}
        />
      </div>
    );
  }
}

export default EventDetails;

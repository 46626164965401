import "isomorphic-fetch";

import {
  getJSON,
} from "Utils/Network";
import { API_URL } from "Config";

export const formsRestore = async () => {
  try {
    const json = await getJSON(`${API_URL}v3/forms`);
    const { data } = json;
    const forms = data.map((item: {[key: string]: any}) => {
        const { attributes, id } = item;
        return {
          ...attributes,
          id,
        };
      });
    return forms;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn("forms restore err", err);
  }
};

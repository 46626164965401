const getIconFile = (externalSource: string) => {
  let icon = "";
  switch (externalSource) {
    case "agoda":
      icon = "agoda";
      break;
    case "airbnb":
    case "airbnb-future":
    case "airbnb1":
    case "airnb":
      icon = "airbnb";
      break;
    case "bcom":
    case "bookingcom":
    case "Booking.com":
      icon = "bookingdotcom";
      break;
    case "bookingpal":
      icon = "bookingpal";
      break;
    case "expedia":
      icon = "expedia";
      break;
    case "flipkey":
      icon = "flipkey";
      break;
    case "google":
      icon = "google";
      break;
    case "guesty":
      icon = "guesty";
      break;
    case "homeaway":
      // icon = "homeaway";
      icon = "vrbo";
      break;
    case "marriott":
      icon = "marriott";
      break;
    case "myvr":
    case "myvr-api":
      icon = "myvr";
      break;
    case "stayz":
      icon = "stayz";
      break;
    case "travelmob":
      icon = "travelmob";
      break;
    case "tripadvisor":
      icon = "tripadvisor";
      break;
    case "vrbo":
      icon = "vrbo";
      break;
    case "wheelhouse":
      icon = "wheelhouse";
      break;
    case "zhubaijia":
      icon = "zhubaijia";
      break;
    default:
      icon = "madecomfy";
  }

  return icon;
};

const getIconURL = (externalSource: string) =>
  `/img/portals/${getIconFile(externalSource)}.png`;

export { getIconURL };

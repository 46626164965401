import debounce from "lodash/debounce";
import React, { useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";

import {
  Alert,
  ALERT_ERROR,
  CheckBox,
  InputText,
  TYPE_TEXT,
} from "@madecomfy/webooi";

import closeCross from "./components/assets/closeCross.svg";
import Loading from "Components/Loading";
import NoProperty from "./components/NoProperty";
import PropertyTable from "./components/PropertyTable";
import withClickOutside from "HOC/withClickOutside";
import { Body } from "Components/Typography";
import { Filter } from "./components/Filter";
import { isAuthorized } from "Utils/methods/authentication";
import { ROLE } from "Constants/userRoles";
import { RouteComponentProps } from "react-router";
import { Sort } from "./components/Sort";
import { WithSessionCheckProps } from "HOC/withSessionCheck";

interface IProps extends RouteComponentProps, WithSessionCheckProps {
  autoFocus?: boolean;
  clearCache?: boolean;
  floating?: boolean;
  handleClick?: (...args: any) => any;
  isDisplayed?: boolean; // from withClickOutside
  lazyLoad?: boolean;
  limit?: number;
  onClickOutside?: (...args: any) => any; // from withClickOutside
  onClose?: (...args: any) => any;
  propertiesRestore: (...args: any) => any;
  propertyListSearch?: any; // FIXME: for some reason {[key: string]: any} throws an error
  resetPropertiesRestore: (...args: any) => any;
  showButtons?: boolean;
  user?: any; // FIXME: for some reason {[key: string]: any} throws an error
}

const PropertyListWrapper = styled.div<any>`
  padding: 0;
  ${({ floating, mounted }) => {
    if (floating) {
      // floating is version on dashboard: `/properties/{code}` - invididual property
      return `
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
        height: ${mounted ? 450 : 0}px;
        overflow: hidden;
        position: absolute;
        transition: height 0.25s ease-in-out;
        width: 100%;
        z-index: 10;
        background: #fff;
        border-radius: 4px;
        &:before {
          background-image: linear-gradient(to bottom, rgba(255,255,255,0) 70%, rgba(255,255,255,1) 100%);
          bottom: 0;
          content: '';
          left: 0;
          pointer-events: none;
          position: absolute;
          right: 0;
          top: 0;
        }
      `;
    }
    // otherwise version on `/properties` - properties list
  }};
  div[data-test="loading"] {
    bottom: 100px;
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  @media only screen and (max-width: 500px) {
    display: block;
    width: 100%;
  }
`;

const WrapperSearch = styled.div<any>`
  flex: 1 0 0;
  position: relative;
  @media (min-width: ${({ theme }) => theme.minTablet}) {
    input {
      width: ${({ floating }) => (floating ? "100%" : "445px")};
    }
  }
`;

const CloseCross = styled.img`
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 19px;
  transition: transform 0.15s ease-in-out;
  z-index: 11;
  &:hover {
    transform: scale(1.2);
  }
`;
const Header = styled.p<any>`
  font-family: InterBold;
  font-size: 28px;
  color: #303336;
  margin: 24px 24px 16px 24px;
  display: ${({ lazyLoad }) => (lazyLoad ? "block" : "none")};
`;
const WrapperFilter = styled.div<any>`
  flex: 0 0 170px;
  > div {
    margin: 14px 0 0 12px;
  }
  @media only screen and (max-width: 500px) {
    > div {
      margin: 16px 0 0 0;
    }
    display: flex;
  }
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  gap 24px;
  @media only screen and (max-width: 500px) {
    flex-direction: column;
  }
`;
const Flex = styled.div`
  display: flex;
  gap: 16px;
  @media only screen and (max-width: 500px) {
    width: 100%;
  }
`;
const Count = styled.p`
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #939296;
  padding: 0 0 0 24px;
  margin: 0 0 -16px 0;
`;

const PropertyList: React.FC<IProps> = (props) => {
  const { lazyLoad = true } = props;
  const [filterText, setFilterText] = useState("");
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(props.autoFocus || false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(props?.limit || 25);
  const [resetList, setResetList] = useState(false);
  const [firstInitial, setFirstInitial] = useState(true);
  const [propertyStatus, setPropertyStatus] = useState(
    lazyLoad ? "active" : "onboarding,active,offboarding",
  );
  const [sort, setSort] = useState("");
  const [filter, setFilter] = useState("");
  const loader = useRef(null);

  const filterSearch = (filterText: string) => {
    setResetList(true);
    setFilterText(filterText);
    setPageNumber(1);
    if (filterText.trim() === "" && filterText !== "") return;
    debouncedFetchData(filterText, propertyStatus, pageNumber, sort, filter);
  };

  const fetchData = async (
    filterText?: string,
    propertyStatus?: string,
    pageNum?: number,
    sort?: string,
    filter?: string,
  ) => {
    setLoading(true);
    const isStatusAvailable = filter?.includes("propertyStatuses");
    try {
      await props.propertiesRestore({
        query: filterText,
        pageNumber: pageNum || pageNumber,
        pageSize: pageSize,
        ...(!isStatusAvailable && { propertyStatus }),
        sort,
        filter,
      });
      if (pageNum) setPageNumber(pageNum);
      setLoading(false);
    } catch (error) {
      // eslint-disable-next-line
      setLoading(false);
    }
  };
  const debouncedFetchData = useCallback(
    debounce(
      (
        filterText?: string,
        propertyStatus?: string,
        pageNum?: number,
        sort?: string,
        filter?: string,
      ) => {
        fetchData(filterText, propertyStatus, pageNum, sort, filter);
      },
      600,
      {
        leading: false,
        trailing: true,
      },
    ),
    [
      props?.propertyListSearch?.totalPages,
      props?.limit,
      pageNumber,
      sort,
      filter,
    ],
  );
  const resetProperties = async () => {
    const { resetPropertiesRestore } = props;
    await resetPropertiesRestore();
  };
  const prevFilter = useRef<string | null>(null);
  const prevSort = useRef<string | null>(null);

  useEffect(() => {
    setPageSize(props?.limit || 25);
    const { autoFocus = true, clearCache } = props;
    if (clearCache) {
      resetProperties();
    }
    if (autoFocus === false) return;
    if (firstInitial) setFirstInitial(false);
    setResetList(true);
    let pageNum = pageNumber;
    if (filter !== prevFilter.current || sort !== prevSort.current) {
      pageNum = 1;
    }
    debouncedFetchData(filterText, propertyStatus, pageNum, sort, filter);
    setTimeout(() => {
      setMounted(true);
      const input = document.querySelector(
        "input[data-test='input-text-property-search']",
      ) as any;
      // extra guarded to not invadvertantly cause a browser compatibility error
      input && input.focus && input.focus();
    }, 1);
    prevFilter.current = filter;
    prevSort.current = sort;
  }, [propertyStatus, sort, filter]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "20px",
      threshold: 0.5,
    };

    const handleObserver = (entries: any) => {
      const target = entries[0];
      if (target.isIntersecting && !loading && items?.length >= pageSize) {
        setResetList(false);
        const { propertyListSearch } = props;
        const nextPage = pageNumber + 1;
        if (nextPage > propertyListSearch.totalPages) return;
        debouncedFetchData(filterText, propertyStatus, nextPage, sort, filter);
      }
    };

    const observer = new IntersectionObserver(handleObserver, options);

    if (loader.current) {
      observer.observe(loader.current);
    }

    return () => {
      if (loader.current) {
        observer.unobserve(loader.current);
      }
    };
  }, [loading]);
  useEffect(() => {
    setItems((prevItems) =>
      resetList
        ? props.propertyListSearch?.propertyList
        : ([...prevItems, ...props.propertyListSearch?.propertyList] as any),
    );
  }, [props.propertyListSearch?.propertyList]);
  const {
    propertyListSearch,
    floating = false,
    showButtons = true,
    user: {
      roles,
      propertyStatus: { count },
    },
    //  lazyLoad = true,
  } = props;
  const renderEmpty = () => {
    return <NoProperty />;
  };
  if (
    !isAuthorized([ROLE.STAFF, ROLE.ADMIN], roles) // let staff and admins see all properties
  ) {
    if (count === 0) {
      // user is onboarding, or could be offboarded!
      return renderEmpty();
    }
  }

  const { connectionError, error, pending, propertyList, success, totalCount } =
    propertyListSearch;
  const renderSearch = () => {
    const { floating, onClose } = props;
    return (
      <WrapperSearch floating={floating}>
        <InputText
          my={0}
          name="propertiesRestore"
          placeholder="Search properties"
          sendValue={({ value }: any) => filterSearch(value)}
          testId="property-search"
          type={TYPE_TEXT}
          value={filterText}
        />
        {floating && <CloseCross src={closeCross} onClick={onClose} />}
      </WrapperSearch>
    );
  };
  const renderError = () => {
    const { errorCode, errorMessage } = props.propertyListSearch;
    return (
      <SearchWrapper>
        <Alert
          message={`There was an error ${
            (errorCode || "") + " " + (errorMessage || "")
          }`}
          testId="property-list"
          title="Could not load properties"
          type={ALERT_ERROR}
        />
      </SearchWrapper>
    );
  };
  const renderFilters = () => {
    if (!isAuthorized([ROLE.STAFF, ROLE.ADMIN, ROLE.CONTENT_CREATOR], roles)) {
      return null;
    }
    return (
      <WrapperFilter>
        <CheckBox
          name="searchInactive"
          options={[
            {
              value: "includeInactive",
              label: "Include Inactive",
            },
          ]}
          sendValue={({ value }: any) => {
            setPageNumber(1);
            if (value) {
              setPropertyStatus((prevStatus) => `${prevStatus},inactive`);
              return;
            }
            setPropertyStatus((prevStatus) =>
              prevStatus.replace(",inactive", ""),
            );
          }}
          testId="include-in-results"
        />
      </WrapperFilter>
    );
  };

  return (
    <PropertyListWrapper floating={floating} mounted={mounted}>
      <Header lazyLoad={lazyLoad}>Properties</Header>
      <Wrapper>
        <SearchWrapper>
          {renderSearch()}
          {!lazyLoad && renderFilters()}
        </SearchWrapper>
        {lazyLoad && (
          <Flex>
            <Sort
              isLicensee={isAuthorized([ROLE.LICENSEE], roles)}
              isOwner={isAuthorized([ROLE.OWNER], roles)}
              setSort={setSort}
            />
            <Filter
              isLicensee={isAuthorized([ROLE.LICENSEE], roles)}
              isOwner={isAuthorized([ROLE.OWNER], roles)}
              setFilter={setFilter}
            />
          </Flex>
        )}
      </Wrapper>
      {(error || connectionError) && renderError()}
      {items.length > 0 && (
        <>
          {lazyLoad && (
            <Count>
              {totalCount} {totalCount === 1 ? `result` : "results"}
            </Count>
          )}
          <PropertyTable
            floating={floating}
            handleClick={props?.handleClick}
            height={floating ? 450 : 0}
            isContentCreator={isAuthorized([ROLE.CONTENT_CREATOR], roles)}
            isLicensee={isAuthorized([ROLE.LICENSEE], roles)}
            isOwner={isAuthorized([ROLE.OWNER], roles)}
            isStaff={isAuthorized([ROLE.STAFF], roles)}
            properties={lazyLoad ? items : propertyList}
            showButtons={showButtons}
            showPropertyListPage={lazyLoad}
          />
          {lazyLoad && (
            <div
              ref={loader}
              style={{ height: "10px", background: "transparent" }}
            ></div>
          )}
        </>
      )}
      {pending && <Loading task="restoringPropertyList" />}
      {items && items.length === 0 && !pending && success && (
        <Body center>No results found</Body>
      )}
    </PropertyListWrapper>
  );
};

const PropertyListWithClickOutside = withClickOutside(PropertyList);

export default PropertyList;
export { PropertyListWithClickOutside };

import * as React from "react";
import { type SVGProps } from "react";

const CleaningSprayIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 17"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M15.673.687a.384.384 0 0 1-.132.526l-1.309.785a.384.384 0 1 1-.394-.66l1.309-.783a.384.384 0 0 1 .526.132m-1.967 4.459a.384.384 0 0 1 .527-.13l1.31.792a.384.384 0 0 1-.398.657l-1.309-.792a.384.384 0 0 1-.13-.527m.329-2.02a.384.384 0 0 0 0 .768h1.575a.384.384 0 0 0 0-.768z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.757 1.291a3.5 3.5 0 0 1 1.353-.267h4.718c.212 0 .384.172.384.384V4.56a.384.384 0 0 1-.384.384H7.773c.156.198.351.426.58.653.636.633 1.463 1.198 2.357 1.198a.384.384 0 0 1 0 .767c-1.205 0-2.22-.745-2.899-1.42a8 8 0 0 1-.4-.43v1.82a1.43 1.43 0 0 1 .7 1.232v.686c.751.076 1.492.384 2.07 1.027.674.75 1.08 1.904 1.08 3.54A2.485 2.485 0 0 1 8.779 16.5h-6.3a2.483 2.483 0 0 1-2.484-2.485c0-1.636.41-2.79 1.086-3.54.578-.641 1.318-.95 2.064-1.026v-.686a1.44 1.44 0 0 1 .7-1.232V4.943H1.96a.384.384 0 0 1-.384-.383 3.53 3.53 0 0 1 2.18-3.269m3.238 6.886a.4.4 0 0 1-.187-.067 1 1 0 0 0-.13-.013h-2.1a1 1 0 0 0-.13.013.4.4 0 0 1-.188.067.67.67 0 0 0-.349.586v1.05a.384.384 0 0 1-.383.385c-.698 0-1.373.231-1.878.792-.509.564-.889 1.51-.889 3.025a1.717 1.717 0 0 0 1.717 1.717h6.3a1.716 1.716 0 0 0 1.716-1.717c0-1.515-.377-2.462-.883-3.026-.503-.56-1.178-.791-1.883-.791a.384.384 0 0 1-.384-.384v-1.05a.67.67 0 0 0-.349-.587m-.35-3.617v2.77H4.612V4.56a.384.384 0 0 0-.384-.383H2.369a2.76 2.76 0 0 1 2.74-2.384h4.335v2.384H7.057a.38.38 0 0 0-.413.383"
    />
    <path d="M12.319 2.461a.384.384 0 1 0-.768 0v1.576a.384.384 0 1 0 .768 0z" />
  </svg>
);
export default CleaningSprayIcon;

import React from "react";
import styled from "styled-components";

import { getIconURL } from "./getIcon";

interface IProps {
  animate?: boolean;
  border?: number;
  className?: string;
  externalSource?: string;
  size?: number;
  url?: string;
}

const Badge = styled.div<any>`
  ${({ animate }) => {
    return (
      animate &&
      `
      @keyframes fadein-circle {
        from {
          transform: scale(0);
          opacity: 0;
        }
        to {
          transform: scale(1);
          opacity: 1;
        }
      }
      animation: fadein-circle 0.3s ease-out;
      animation-fill-mode: forwards;
      opacity: 0;
      transform: scale(0);
      `
    );
  }}

  align-items: center;
  background-color: white;
  background-repeat: no-repeat;
  border-radius: 50%;
  box-shadow: 0px 1px 5px rgba(53, 55, 60, 0.1);
  display: flex;
  justify-content: center;
  margin: auto;
  overflow: hidden;
`;

const CircleBadge: React.FC<IProps> = (props) => {
  const {
    animate = true,
    border = 1 / 8, // ratio of `size`
    className,
    externalSource,
    size = 64,
  } = props;
  let { url } = props;
  if (externalSource) {
    url = getIconURL(externalSource);
  }
  if (!url) {
    return null;
  }
  const style = {
    backgroundImage: `url(${url})`,
    backgroundPosition: `${size * border}px ${size * border}px`,
    backgroundSize: `${size * (1 - 2 * border)}px`,
    height: `${size}px`,
    width: `${size}px`,
  };
  return (
    <Badge
      data-test="circle-badge"
      animate={animate}
      className={className}
      style={style}
    />
  );
};

export default CircleBadge;

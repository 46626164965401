import dayjs from "dayjs";
import range from "lodash/range";
import React from "react";

import Day from "./Day";
import { DayCell, MonthRow } from "./RowsAndCells";
import { dayYYYYMMDD } from "./utils";
import { IPropertyTask } from "Containers/EventsCalendar/interface/task";

export const FIRST_DAY_OF_WEEK = 2; // we start on monday

const checkRestricted = (
  dmy: string | number,
  restrictedDates: { [key: string]: any },
) => {
  if (!restrictedDates) return false;
  const { startDate, endDate } = restrictedDates;
  if (startDate && dmy < startDate) return true;
  if (endDate && dmy >= endDate) return true;
  return false;
};

const checkSelected = (
  dmy: string | number,
  selectedDates: { [key: string]: any },
) => {
  if (!selectedDates) return false;
  const { startDate, endDate } = selectedDates;
  if (startDate && endDate) {
    return dmy >= startDate && dmy <= endDate;
  }
  if (startDate) {
    return dmy === startDate;
  }
  if (endDate) {
    return dmy === endDate;
  }
  return false;
};

const Days = ({
  active,
  calendar,
  focus,
  ignoreValidation = false,
  month,
  mouseOver,
  onDayClick,
  onDayHover,
  onIncrement,
  prevMonthDaysCount,
  rates,
  restrictedDates,
  selectedDates,
  showDaysOutsideMonth,
  singleDate,
  timezone,
  today,
  viewingMonth,
  weekIndex,
  year,
  filter,
  pricing,
  userRoles,
  tasks,
}: any) => {
  const rangeStart = FIRST_DAY_OF_WEEK + weekIndex * 7 - prevMonthDaysCount;

  // AVOID USING moment (or dayjs maybe?) in nested loops!!!
  // the nested loop (range + bookings) used to take up to 50ms on a Macbook 2.2 GHz Intel Core i7 16 GB 2400 MHz DDR4
  // on top of that it may be doing it 12x (for each month in a year)...

  // day-generating loop
  return (
    <>
      {range(rangeStart, rangeStart + 7).map((i) => {
        const day = new Date(year, month, i);
        const dmy = dayYYYYMMDD(day);
        const outsideMonth = month !== day.getMonth();

        const taskList = (tasks as IPropertyTask[])?.filter((task) => {
          const scheduledDate = task?.attributes?.scheduledDate;
          return scheduledDate && scheduledDate === dmy;
        });

        const sortedCleaningTasks = taskList?.sort(
          (a: IPropertyTask, b: IPropertyTask) => {
            const scheduledTimeA = `${a.attributes.scheduledDate} ${a.attributes.scheduledTime}`;
            const scheduledTimeB = `${b.attributes.scheduledDate} ${b.attributes.scheduledTime}`;
            const timeA =
              dayjs(scheduledTimeA).valueOf() || Number.MAX_SAFE_INTEGER;
            const timeB =
              dayjs(scheduledTimeB).valueOf() || Number.MAX_SAFE_INTEGER;
            return timeA - timeB;
          },
        );

        // pick appropriate props
        const dayProps = {
          active,
          day,
          dmy,
          focus,
          ignoreValidation,
          isRestricted: checkRestricted(dmy, restrictedDates),
          isSelected: checkSelected(dmy, selectedDates),
          isToday: dmy === today,
          mouseOver,
          onDayClick,
          onDayHover,
          outsideMonth,
          rate: (rates && rates[dmy]) || 0,
          singleDate,
          timezone,
          viewingMonth,
          filter,
          pricing,
          userRoles,
          tasks: sortedCleaningTasks,
        };

        if (showDaysOutsideMonth === false && outsideMonth) {
          return <DayCell key={`day-${i}`} />;
        }

        const eventsMapSingle = calendar?.eventsMap[dmy] || [];

        return (
          <Day
            key={`day-${i}`}
            {...dayProps}
            eventsMapSingle={eventsMapSingle}
            calendar={calendar}
          />
        );
      })}
    </>
  );
};

// establish how many overlapping rows (events/bookings) there are to expand week row height to fit them all in.
const getMaxEvents = ({
  calendar,
  month,
  prevMonthDaysCount,
  weekIndex,
  year,
  tasks,
}: any) => {
  if (calendar.eventsArray.length === 0) {
    return {
      maxEvents: 0,
      maxCleaningTasks: 0,
    };
  }
  const rangeStart = FIRST_DAY_OF_WEEK + weekIndex * 7 - prevMonthDaysCount;
  const rangeEnd = rangeStart + 7;

  const cleanTasksInRange: number[] = [0];
  // find largest yIndex
  const yIndexes = range(rangeStart, rangeEnd).map((i) => {
    // find the largest yIndex + height for each day
    const day = new Date(year, month, i);
    const dmy = dayYYYYMMDD(day);
    const daysEvents = calendar.eventsMap[dmy];
    const dayYIndexes = daysEvents
      ? daysEvents.map(({ yIndex, height }: any) => {
          return yIndex + height;
        })
      : [];
    const tasksInDay = tasks?.filter(
      (task: IPropertyTask) => task?.attributes?.scheduledDate === dmy,
    )?.length;
    if (tasksInDay) cleanTasksInRange.push(tasksInDay);
    const maxEvents = dayYIndexes?.length ? Math.max(...dayYIndexes) : 0;
    // add extra height when have clean task during the day
    return maxEvents;
  });

  const maxEvents = Math.max(...yIndexes);
  // max cleaning tasks on a day of week
  const maxCleaningTasks = Math.max(...cleanTasksInRange);
  // find the largest yIndex for the week
  return {
    maxEvents,
    maxCleaningTasks,
  };
};

const Week = (props: { [key: string]: any }) => {
  const { viewingMonth } = props;
  const { maxEvents, maxCleaningTasks } = getMaxEvents(props);
  return (
    <MonthRow
      numberOfEvents={maxEvents}
      numberOfCleaningTasks={maxCleaningTasks}
      viewingMonth={viewingMonth}
    >
      <Days {...props} />
    </MonthRow>
  );
};

// proptypes are very much like Month.

export default Week;

import styled from "styled-components";

export const FormWrapper = styled.div`
  background: ${({ theme }) => theme.white};
  box-shadow: 0px 1px 5px rgba(53, 55, 60, 0.1);
  border-radius: 4px;
  padding: 40px 40px 50px 40px;
  text-align: left;
`;

export const LogoInv = styled.img`
  margin: 30px 0 30px 0;
  width: 147px;
  display: flex;
`;

export const BodyWrapper = styled.div`
  padding-bottom: 35px;
  margin: 0;
`;

export const LinkWrapper = styled.div`
  a {
    color: ${({ theme }) => theme.white};
    text-decoration: underline;
    float: left;
  }
`;

import React from "react";

const Rocket = () => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.0306 1.03657C23.1918 1.19769 23.2709 1.42333 23.2457 1.6498L23.0057 3.80754C22.7992 5.66955 21.9647 7.40597 20.6396 8.73028L10.8306 18.5373C10.6899 18.6779 10.4991 18.7569 10.3002 18.7569C10.1012 18.7569 9.91047 18.6778 9.76984 18.5371L5.52984 14.2951C5.23707 14.0022 5.23712 13.5274 5.52997 13.2346L15.3369 3.42759C16.6615 2.10289 18.3975 1.26845 20.2594 1.0615L22.4174 0.821495C22.6439 0.796309 22.8695 0.875445 23.0306 1.03657ZM21.6512 2.41595L20.4252 2.5523C18.9018 2.72162 17.4814 3.40436 16.3976 4.48821L7.12083 13.765L10.3005 16.9462L19.579 7.66952C20.6631 6.5861 21.346 5.1656 21.5149 3.64226L21.6512 2.41595Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.28018 10.3454C7.35518 10.0372 6.36262 9.99259 5.41371 10.2167C4.4648 10.4408 3.59705 10.9247 2.90768 11.6142L2.67622 11.8456L5.85772 12.9067L8.38382 10.3799L8.28018 10.3454ZM8.7543 8.92233C7.56505 8.52603 6.28894 8.46873 5.06896 8.75684C3.84896 9.04495 2.73329 9.6671 1.84697 10.5536L1.61552 10.785C1.43464 10.9656 1.30261 11.1899 1.23263 11.4357C1.16261 11.6817 1.1567 11.9416 1.21548 12.1905C1.27425 12.4395 1.39575 12.6692 1.56842 12.8579C1.74095 13.0465 1.95884 13.1878 2.20138 13.2685L5.82301 14.4764C6.09257 14.5663 6.38979 14.4961 6.5907 14.2952L10.3027 10.5822C10.4865 10.3983 10.562 10.1324 10.5022 9.87945C10.4424 9.62647 10.2559 9.42247 10.0093 9.34033L8.7543 8.92233Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1882 13.5701C14.441 13.63 14.6449 13.8165 14.7269 14.0631L15.1428 15.3127C15.5392 16.5018 15.5967 17.7781 15.3088 18.998C15.0209 20.218 14.399 21.3337 13.5127 22.2202L13.2802 22.4517L13.2793 22.4526C13.0988 22.6332 12.8751 22.7647 12.6295 22.8346C12.3835 22.9046 12.1236 22.9105 11.8747 22.8517C11.6257 22.7929 11.396 22.6714 11.2073 22.4988C11.0187 22.3263 10.8774 22.1084 10.7967 21.8659L9.58882 18.2442C9.49884 17.9744 9.56921 17.6769 9.77054 17.476L13.4855 13.769C13.6695 13.5855 13.9353 13.5102 14.1882 13.5701ZM11.1586 18.2099L12.2196 21.391L12.4519 21.1596C13.141 20.4702 13.6251 19.6021 13.849 18.6534C14.0729 17.7045 14.0282 16.712 13.7198 15.7871L13.6866 15.6874L11.1586 18.2099Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.13371 16.2461C6.16065 16.6595 5.84741 17.0164 5.43408 17.0433C5.07109 17.067 4.72917 17.2221 4.47227 17.4796L4.47072 17.4811C4.36184 17.5896 4.16741 17.9081 3.9244 18.4528C3.69699 18.9624 3.46735 19.5748 3.26034 20.1732C3.14533 20.5057 3.03853 20.8303 2.94427 21.1257C3.2394 21.0315 3.56367 20.9248 3.8958 20.8099C4.49407 20.6029 5.10634 20.3732 5.61623 20.1458C6.16081 19.9028 6.48036 19.7082 6.58997 19.5986C6.72924 19.4596 6.84038 19.2939 6.91566 19.1122C6.99093 18.9304 7.02955 18.7356 7.0293 18.5389C7.02876 18.1247 7.36411 17.7884 7.77832 17.7879C8.19253 17.7874 8.52876 18.1227 8.5293 18.5369C8.52981 18.9313 8.45239 19.3218 8.30148 19.6862C8.15063 20.0503 7.92934 20.3811 7.6503 20.6596C7.32087 20.9888 6.75861 21.2787 6.2273 21.5157C5.66113 21.7682 5.00327 22.0139 4.38629 22.2274C3.76702 22.4417 3.1773 22.6275 2.74318 22.7595C2.52582 22.8256 2.34677 22.8785 2.22167 22.9149C2.15911 22.9331 2.11 22.9472 2.07628 22.9568L2.0375 22.9679L2.02443 22.9716L2.0234 22.9719C2.0234 22.9719 2.02325 22.9719 1.8203 22.2499C1.09824 22.0471 1.09828 22.0469 1.09828 22.0469L1.10227 22.0328L1.11331 21.994C1.12295 21.9603 1.13707 21.9111 1.15529 21.8485C1.19171 21.7233 1.24455 21.5442 1.31065 21.3267C1.44268 20.8923 1.62847 20.3023 1.84275 19.6828C2.05624 19.0657 2.30198 18.4077 2.55457 17.8416C2.79138 17.3108 3.0813 16.7485 3.4111 16.4194C3.9255 15.9042 4.60995 15.5938 5.33652 15.5465C5.74985 15.5195 6.10677 15.8328 6.13371 16.2461ZM1.8203 22.2499L1.09828 22.0469C1.02494 22.308 1.09823 22.5885 1.29001 22.7803C1.4818 22.972 1.76231 23.0453 2.0234 22.9719L1.8203 22.2499Z"
    />
  </>
);

export default Rocket;

const ROLE = {
  ADMIN: "ROLE_ADMIN",
  BACKEND: "ROLE_BACKEND",
  CHANNEL_MANAGER: "ROLE_CHANNEL_MANAGER",
  CONFIGURATOR_CODES: "ROLE_CONFIGURATOR_CODES",
  CONFIGURATOR_INSTRUCTIONS: "ROLE_CONFIGURATOR_INSTRUCTIONS",
  CONTENT_CREATOR: "ROLE_CONTENT_CREATOR",
  DEVELOPER: "ROLE_DEVELOPER",
  FINANCE_MANAGER: "ROLE_FINANCE_MANAGER",
  LICENSEE: "ROLE_LICENSEE",
  MARKETING: "ROLE_MARKETING",
  METADATA_MANAGER: "ROLE_METADATA_MANAGER",
  OWNER: "ROLE_OWNER",
  PROPERTY_EDITOR: "ROLE_PROPERTY_EDITOR",
  RESERVATION_ADMIN: "ROLE_RESERVATION_ADMIN",
  RESERVATION_MANAGER: "ROLE_RESERVATION_MANAGER",
  STAFF: "ROLE_STAFF",
  // SUPER_ADMIN: "ROLE_SUPER_ADMIN",
  VERIFIER: "ROLE_VERIFIER",
};
export { ROLE };

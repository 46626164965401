import { VERIFY_TOKEN } from "Constants/actionTypes";

const initialState = {
  success: false,
  error: false,
  connectionError: false,
  pending: false,
};
export function verifyTokenStatus(state = initialState, action: {[key: string]: any}) {
  switch (action.type) {
    case VERIFY_TOKEN.SUCCESS:
      return { ...state, success: true, error: false };
    case VERIFY_TOKEN.PENDING:
      return { ...state, pending: action.status };
    case VERIFY_TOKEN.ERROR:
      return { ...state, error: true, success: false };
    case VERIFY_TOKEN.CONNECTION_FAILURE:
      return { ...state, connectionError: true };
    default:
      return state;
  }
}

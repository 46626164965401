import { FORGOT_PASSWORD } from "Constants/actionTypes";

const initialState = {
  connectionError: false,
  error: false,
  errorCode: "",
  errorMessage: "",
  pending: false,
  success: false,
};
export function forgotPassword(state = initialState, action: {[key: string]: any}) {
  switch (action.type) {
    case FORGOT_PASSWORD.SUCCESS:
      return { ...state, success: true };
    // there is no instance of FORGOT_PASSWORD.PENDING elsewhere in the app
    // case FORGOT_PASSWORD.PENDING:
    //   return { ...state, pending: action.status };
    case FORGOT_PASSWORD.ERROR:
      return {
        ...state,
        error: true,
        errorMessage: action.errorMessage,
        errorCode: action.errorCode,
      };
    case FORGOT_PASSWORD.CONNECTION_FAILURE:
      return {
        ...state,
        connectionError: true,
        errorMessage: action.errorMessage,
        errorCode: action.errorCode,
      };
    default:
      return state;
  }
}

import intersection from "lodash/intersection";

import { ROLE } from "Constants/userRoles";

/**
 * Verify is a component role has the same as user role
 * var compRole = ["ROLE_ADMIN", "ROLE_STAFF", "ROLE_OWNER"]
 * var userRole = ["ROLE_STAFF"]
 * return true
 */
export const isAuthorized = (requiredRole: (string | string[]), userRoles: (string | string[])) => {
  if (typeof userRoles === "string") {
    userRoles = [userRoles];
  }
  if (typeof requiredRole === "string") {
    requiredRole = [requiredRole];
  }

  return intersection(requiredRole, userRoles).length > 0;
};

export const isOwner = (roles: (string | string[])) => {
  return isAuthorized([ROLE.OWNER], roles);
};

export const isStaff = (roles: (string | string[])) => {
  return isAuthorized([ROLE.STAFF], roles);
};

export const isAdmin = (roles: (string | string[])) => {
  return isAuthorized([ROLE.ADMIN], roles);
};

export function isLicensee(roles: (string | string[])) {
  return isAuthorized([ROLE.LICENSEE], roles);
}

import React from "react";
import styled from "styled-components";

import ownerBlockIcon from "Containers/EventsCalendar/assets/owner-block.svg";
import unavailableEventIcon from "Containers/EventsCalendar/assets/unavailable-event.svg";
import availableEventIcon from "Containers/EventsCalendar/assets/available-event.svg";
import noteIcon from "Containers/EventsCalendar/assets/note.svg";
import { AVAILABLE, UNAVAILABLE } from "Constants/propertyEventTypes";
import { BLOCKED } from "Constants/bookingTypes";
import { getIconURL } from "Components/CircleBadge/getIcon";

interface IProps {
  item: {[key: string]: any};
  showNote?: boolean;
}

const Icon = styled.img`
  margin-right: 4px;
  width: 13px;
  height: 13px;
`;

export default ({ item, showNote }: IProps) => {
  const { externalSource, status } = item;
  const source = externalSource && externalSource.source;
  const isAvailable = status === AVAILABLE;
  const isBlocked = status === BLOCKED;
  const isUnavailable = status === UNAVAILABLE;
  if (showNote) {
    return <Icon alt="Note" src={noteIcon} />;
  }
  if (isAvailable) {
    return <Icon alt="Unblocking event" src={availableEventIcon} />;
  }
  if (isUnavailable) {
    return <Icon alt="Blocking event" src={unavailableEventIcon} />;
  }
  if (isBlocked) {
    return <Icon alt="Owner block" src={ownerBlockIcon} />;
  }
  return <Icon alt="Booking Event event" src={getIconURL(source)} />;
};

import { API_URL } from "Config";
import {
  BAD_REQUEST,
  FORBIDDEN,
  generateErrorHandler,
  NetworkError,
} from "Utils/Network";

const handleError = generateErrorHandler("NEWSLETTER_ERROR");

export const newsletterCreate = async (params: {[key: string]: any}) => {
  try {
    const response = await fetch(`${API_URL}v3/newsletters`, {
      credentials: "include",
      method: "POST",
      body: JSON.stringify(params),
    });
    if (response.status === BAD_REQUEST) {
      return response.json();
    }
    if (response.status === FORBIDDEN) {
      return {
        errors: [
          {
            detail: "Sorry, you do not have permission to add a newsletter",
            title: String(FORBIDDEN),
          },
        ],
      };
    }
    if (!response.ok) {
      throw new NetworkError(response);
    }
    const json = await response.json();
    return json.data.attributes;
  } catch (err: any) {
    return handleError(err);
  }
};

import debounce from "lodash/debounce";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { Alert, ALERT_ERROR, InputText, TYPE_TEXT } from "@madecomfy/webooi";

import ComfyContainer from "Components/ComfyContainer";
import { CompanyTable } from "./components/CompanyTable";
import Loading from "Components/Loading";
import { userCompanies } from "Actions/users/userCompanies";

interface IProps {
  initialEmpty?: boolean;
  ownedCompanies?: { [key: string]: any }[];
  handleClick: (...args: any) => any;
}

const Wrapper = styled(ComfyContainer)<any>`
  padding: 0;
`;

const SearchWrapper = styled.div`
  padding: 24px;
  display: flex;
  @media only screen and (max-width: 500px) {
    display: block;
  }
`;

const WrapperSearch = styled.div<any>`
  flex: 1 0 0;
  position: relative;
`;

export const CompanyList: React.FC<IProps> = (props) => {
  const { initialEmpty, ownedCompanies, handleClick } = props;

  const [listData, setListData] = useState<any[]>([]);
  const [listFilter, setListFilter] = useState({
    filterText: "",
    mounted: false,
  });
  const [connectStatus, setConnectStatus] = useState({
    initial: true,
    error: undefined,
    loading: false,
  });

  const getCompanies = async () => {
    try {
      if (!listFilter?.filterText) {
        setListData([]);
        return;
      }
      setConnectStatus({ ...connectStatus, loading: true });
      const companies = (await userCompanies("licensee", {
        ...(!!listFilter?.filterText && { search: listFilter.filterText }),
      })) as any[];
      const availableList = companies?.filter((company) =>
        !ownedCompanies?.some((item) => item?.id === company?.id),
      );
      setListData(availableList);
    } catch (err: any) {
      // eslint-disable-next-line
      console.warn("companies error", err);
      setConnectStatus({ ...connectStatus, error: err });
    } finally {
      setConnectStatus({ ...connectStatus, loading: false });
    }
  };

  const doSearch = useCallback(
    debounce((text: string) => {
      setListFilter({ ...listFilter, filterText: text });
    }, 400),
    [],
  );
  const filterSearch = (filterText: string) => {
    if (connectStatus.initial) {
      setConnectStatus({ ...connectStatus, initial: false });
    }
    doSearch(filterText);
  };

  useEffect(() => {
    if (connectStatus.initial && initialEmpty) return;
    getCompanies();
  }, [JSON.stringify(listFilter)]);

  const renderSearch = () => {
    return (
      <WrapperSearch>
        <InputText
          my={0}
          name="propertiesRestore"
          placeholder="Search"
          sendValue={({ value }: any) => filterSearch(value)}
          testId="property-search"
          type={TYPE_TEXT}
          value={listFilter.filterText}
        />
      </WrapperSearch>
    );
  };

  const renderError = () => {
    const error = connectStatus.error as any;
    return (
      <SearchWrapper>
        <Alert
          message={`There was an error ${
            (error?.errorCode || "") + " " + (error?.errorMessage || "")
          }`}
          testId="company-list"
          title="Could not load properties"
          type={ALERT_ERROR}
        />
      </SearchWrapper>
    );
  };

  const { error, loading } = connectStatus;

  return (
    <Wrapper mounted={listFilter.mounted}>
      <SearchWrapper>
        {loading && <Loading task="restoringCompanyList" />}
        {renderSearch()}
      </SearchWrapper>
      {error && renderError()}
      <CompanyTable
        handleClick={handleClick}
        companies={listData}
      />
    </Wrapper>
  );
};

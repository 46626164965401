import React from "react";

const Bank = () => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9996 -0.000152588C11.573 -0.000152588 11.1553 0.121112 10.7952 0.349357L1.11622 6.10537C0.826009 6.27796 0.686906 6.6232 0.776402 6.94878C0.865897 7.27436 1.16192 7.49999 1.49958 7.49999H22.4996C22.8372 7.49999 23.1333 7.27436 23.2228 6.94878C23.3123 6.6232 23.1731 6.27796 22.8829 6.10537L13.204 0.349357C12.8439 0.121112 12.4262 -0.000152588 11.9996 -0.000152588ZM11.5941 1.61892C11.715 1.54118 11.8558 1.49985 11.9996 1.49985C12.1434 1.49985 12.2841 1.54118 12.4051 1.61892C12.4124 1.62361 12.4198 1.62818 12.4272 1.63262L19.7711 5.99999H4.22804L11.5719 1.63262C11.5794 1.62818 11.5868 1.62361 11.5941 1.61892Z"
    />
    <path d="M3.74951 9.75C3.74951 9.33579 3.41373 9 2.99951 9C2.5853 9 2.24951 9.33579 2.24951 9.75V17.25C2.24951 17.6642 2.5853 18 2.99951 18C3.41373 18 3.74951 17.6642 3.74951 17.25V9.75Z" />
    <path d="M0.749512 20.25C0.749512 19.8358 1.0853 19.5 1.49951 19.5H22.4995C22.9137 19.5 23.2495 19.8358 23.2495 20.25C23.2495 20.6642 22.9137 21 22.4995 21H1.49951C1.0853 21 0.749512 20.6642 0.749512 20.25Z" />
    <path d="M0.749512 23.25C0.749512 22.8358 1.0853 22.5 1.49951 22.5H22.4995C22.9137 22.5 23.2495 22.8358 23.2495 23.25C23.2495 23.6642 22.9137 24 22.4995 24H1.49951C1.0853 24 0.749512 23.6642 0.749512 23.25Z" />
    <path d="M5.99951 9C6.41373 9 6.74951 9.33579 6.74951 9.75V17.25C6.74951 17.6642 6.41373 18 5.99951 18C5.5853 18 5.24951 17.6642 5.24951 17.25V9.75C5.24951 9.33579 5.5853 9 5.99951 9Z" />
    <path d="M11.2495 9.75C11.2495 9.33579 10.9137 9 10.4995 9C10.0853 9 9.74951 9.33579 9.74951 9.75V17.25C9.74951 17.6642 10.0853 18 10.4995 18C10.9137 18 11.2495 17.6642 11.2495 17.25V9.75Z" />
    <path d="M13.4995 9C13.9137 9 14.2495 9.33579 14.2495 9.75V17.25C14.2495 17.6642 13.9137 18 13.4995 18C13.0853 18 12.7495 17.6642 12.7495 17.25V9.75C12.7495 9.33579 13.0853 9 13.4995 9Z" />
    <path d="M18.7495 9.75C18.7495 9.33579 18.4137 9 17.9995 9C17.5853 9 17.2495 9.33579 17.2495 9.75V17.25C17.2495 17.6642 17.5853 18 17.9995 18C18.4137 18 18.7495 17.6642 18.7495 17.25V9.75Z" />
    <path d="M20.9995 9C21.4137 9 21.7495 9.33579 21.7495 9.75V17.25C21.7495 17.6642 21.4137 18 20.9995 18C20.5853 18 20.2495 17.6642 20.2495 17.25V9.75C20.2495 9.33579 20.5853 9 20.9995 9Z" />
  </>
);

export default Bank;

import React from "react";

import {
  Alert,
  ALERT_ERROR,
  Button,
  TYPE_EMAIL,
  Form,
  ValidatedInputText,
  BUTTON_CTA,
} from "@madecomfy/webooi";
import logoInverse from "./assets/logoInverse.svg";
import LinkButton from "./components/LinkButton";
import { H2, H4, Body } from "Components/Typography";
import {
  FormWrapper,
  LogoInv,
  BodyWrapper,
  LinkWrapper,
} from "./components/Elements";

class WelcomeForgotPassword extends Form<any, any> {
  handleSubmit = (event: any) => {
    event && event.preventDefault();
    const { forgotPassword } = this.props;
    const isValid = this.validate();
    if (isValid) {
      const { userEmail } = this.getValues();
      forgotPassword(userEmail);
    }
  };

  renderSuccess() {
    return (
      <div>
        <LogoInv src={logoInverse} />
        <H4 data-test="submitted" white>
          A link to reset your password has been sent
        </H4>
        <LinkWrapper>
          <LinkButton
            testId="back-to-login"
            to="/login"
            label="Back to login"
          />
        </LinkWrapper>
      </div>
    );
  }

  renderForm() {
    const { ...rest } = this.state;
    const { error, errorMessage } = this.props.forgotPasswordStatus;

    return (
      <div>
        <LogoInv src={logoInverse} />
        <H2 white>Forgot password?</H2>
        <BodyWrapper>
          <Body color="#fff">
            Please enter your email and we will send you a link to reset your
            password.
          </Body>
        </BodyWrapper>
        <FormWrapper>
          {error ? <Alert message={errorMessage} type={ALERT_ERROR} /> : null}
          <form onSubmit={this.handleSubmit}>
            <ValidatedInputText
              {...rest}
              label="Email address"
              icon="mail"
              name="userEmail"
              placeholder="Enter your email"
              type={TYPE_EMAIL}
              validation={{
                check: TYPE_EMAIL,
                errorClient: "Enter a valid email",
              }}
            />
          </form>
          <Button
            label="Reset password"
            type={BUTTON_CTA}
            onClick={this.handleSubmit}
          />
          <LinkButton
            testId="back-to-login"
            to="/login"
            label="Remember your password? Back to login"
          />
        </FormWrapper>
      </div>
    );
  }

  render() {
    const { success } = this.props.forgotPasswordStatus;
    if (success) {
      return this.renderSuccess();
    }
    return this.renderForm();
  }
}

export default WelcomeForgotPassword;

/**

# Google Tag Manager Tags

* developers.google.com/tag-manager/devguide
* Connects Google Tag manager, triggers an event
* @param {string} trigger must be an event tag from tag manager, is defined on TagManager
* @param {Object} extra data

*/

declare global {
    // eslint-disable-next-line no-unused-vars
    interface Window {
      dataLayer: any[];
      gtag: (...args: any[]) => void;
    }
  }
  export function tagManager(trigger: string, data: any) {
    // Validate if Tag manager is loaded
    if (typeof window.dataLayer !== "undefined") {
      window.dataLayer.push({ event: trigger, ...data });
    } else {
      // eslint-disable-next-line no-console
      console.log("Tag Manager not loaded", trigger, data);
    }
  }

// google analytics
export const gaEvent = (name: string, params: { [key: string]: any }) => {
    if (typeof window.gtag !== "undefined") {
    window.gtag("event", name, params);
    } else {
    // eslint-disable-next-line no-console
    console.log("GA not loaded");
    }
};

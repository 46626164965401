/*

HOC to provide bookings and events to a consumer

*/

// don't use exact, this component passes many props through.
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { bookingsRestore } from "Actions/bookings/bookingsRestore";
import { dayYYYYMMDD } from "Components/Dates";
import { isAuthorized } from "Utils";
import {
  MAKE_BLOCK,
  MAKE_EVENT,
  MAKE_EVENT_MIN,
} from "Containers/MakeEvent/eventTypes";
import { PE_PREFIX } from "Constants/propertyEventTypes";
import { ROLE } from "Constants/userRoles";

interface IProps {
  booking?: { [key: string]: any };
  bookingsRestore: (...args: any) => any;
  event?: string;
  propertyId: string;
  user: any;
  year: number;
  ignoreEventId?: string;
  month?: number;
  sendValue?: (...args: any) => any;
  showRevisedQuote?: boolean;
  maxGuests?: number;
  isStaffBooking?: boolean;
  isCorporateBooking?: boolean;
  userInfo?: { [key: string]: any };
  onChangeGuestType?: (type: string | null) => void;
  onChangeCorporatePartner?: (id: string | undefined) => void;
}

const withEventRanges = (WrappedComponent: any) => {
  class BookingsAndEvents extends Component<IProps> {
    state = {
      today: dayYYYYMMDD(new Date(Date.now())),
    };

    componentDidMount() {
      this.load();
    }

    load = async () => {
      const {
        bookingsRestore,
        event,
        propertyId,
        user: { roles },
        year,
        // month,
        ignoreEventId,
      } = this.props;

      // ONLY load bookings. the event string can contain many params.
      const bookingIds = event
        ? String(event)
            .split(",")
            .filter(
              (id) =>
                [MAKE_BLOCK, MAKE_EVENT, MAKE_EVENT_MIN].includes(id) ===
                  false && id.startsWith(PE_PREFIX) === false,
            )
        : undefined;

      // establish date ranges for bookings data...

      /*
      const startDate = ymd(dayjs([year]).subtract(1, "months"));
      const endDate = ymd(dayjs([year]).endOf("year").add(1, "months"));

      ^ some users in america and canada (locale might be a red herring) reported the calendar being empty
      this bug could not be replicated! only a phone call with one affected user (chromebook + chrome) could identify this,
      the date range was incorrect and users were only seeing bookings for january.
      example. if year is 2020, we should load from 2019-12-01 -> 2021-01-31, but the above startDate & endDate code was setting the range to 2019-12-01 -> 2020-01-31
      */

      const startDate = `${year - 1}-12-01`;
      const endDate = `${year + 2}-01-31`; // TODO - it is a temporary fix to load the bookings for next 2 years in the datepicker

      // why not this? please do! but you'll need to test thoroughly!
      // const startDate = ymd(dayjs([year, month]).subtract(1, "months"));
      // const endDate = ymd(dayjs([year, month]).add(1, "months"));

      const loadConfidentials = isAuthorized(
        [ROLE.ADMIN, ROLE.STAFF, ROLE.LICENSEE],
        roles,
      ); // only admins see rates and notes
      // MAD-5234: licensee user can access to airbnb price
      const loadRequested = isAuthorized(ROLE.DEVELOPER, roles); // disabling events for non devs.

      await bookingsRestore({
        bookingIds,
        endDate,
        ignoreEventId,
        loadConfidentials,
        loadRequested,
        propertyId,
        startDate,
      });
    };

    async componentDidUpdate(prevProps: IProps) {
      // check to see if date has changed...
      if (
        this.props.year !== prevProps.year ||
        // this.props.month !== prevProps.month || // no need for this since we load data per year
        this.props.event !== prevProps.event
      ) {
        await this.load();
      }
    }

    render() {
      const { today } = this.state;
      return <WrappedComponent {...this.props} today={today} />;
    }
  }

  const mapDispatchToProps = (dispatch: any) => ({
    bookingsRestore: bindActionCreators(bookingsRestore, dispatch),
  });

  const mapStateToProps = (state: any) => ({
    bookingsByCode: state.bookings.bookingsByCode,
    currentProperty: state.currentPropertyStatus.currentProperty,
    user: state.authentication.sessionStatus.user,
  });

  return connect(mapStateToProps, mapDispatchToProps)(BookingsAndEvents);
};

export default withEventRanges;

import React from "react";
import styled, { css } from "styled-components";

import { Tooltip } from "@madecomfy/webooi";

import { AVAILABLE, UNAVAILABLE } from "Constants/propertyEventTypes";
import {
  BLOCKED,
  BOOKED,
  CANCELLED,
  CANCELLING,
  REQUESTED,
  VACANT,
} from "Constants/bookingTypes";

import EventIcon from "./EventIcon";
import { formatDateWithDynamic } from "Utils";

interface StatusItemProps {
  dayOfWeek: number;
  filter?: string;
  item: {
    // required attributes:
    createdBy?: { [key: string]: any };
    eventIndex: number;
    height: number;
    id: string;
    status: string;
    yIndex: number;
    // optional attributes - bookings, property-events and subsequent event ranges make these vary:
    endDate?: string;
    eventDuring?: boolean;
    expiryDate?: string;
    eventEnd?: boolean;
    eventStart?: boolean;
    externalSource?: { [key: string]: any };
    guest?: { [key: string]: any };
    hasEarlyEta?: boolean;
    hasLateEtd?: boolean;
    name?: string;
    note?: string;
    notes?: any[];
    startDate?: string;
    type?: string;
  };
  timezone?: string;
  viewingMonth: boolean;
}

interface StatusProps {
  dayOfWeek: number;
  existing: any[];
  filter?: string;
  viewingMonth: boolean;
  timezone?: string;
}

const StatusWrapper = styled.div<any>`
  border-bottom: 1px;
  border-top: 1px;
  border-style: solid; // this rule must follow the preceeding attributes to work.
  display: flex;
  flex-direction: column;
  margin-left: -1px;
  position: absolute;
  text-align: left;
  width: calc(100% + 2px);

  // render background colour
  ${({ status, hasNote, theme }) => {
    // with `hasNote` == true, linear gradient draws from the bottom, then 19px of status colour, 1 pixel border, 19px of note colour.
    switch (status) {
      case AVAILABLE:
        return css`
          background: ${theme.sand94};
          border-color: ${theme.charcoalLight30};
        `;
      case CANCELLED:
      case CANCELLING:
        return css`
          background: ${hasNote
            ? `linear-gradient(0deg, ${theme.sand94} 19px, ${theme.charcoalLight50} 19px 20px, ${theme.white} 20px);`
            : theme.sand94};
          border-color: ${theme.charcoalLight50};
          opacity: 0.5;
        `;
      case BLOCKED:
        return css`
          background: ${hasNote
            ? `linear-gradient(0deg, ${theme.watermelon92} 19px, ${theme.watermelon} 19px 20px, ${theme.white} 20px);`
            : theme.watermelon92};
          border-color: ${theme.watermelon};
        `;
      case UNAVAILABLE:
        return css`
          background: ${hasNote
            ? `linear-gradient(0deg, ${theme.warnOrange76} 19px, ${theme.alertRed} 19px 20px, ${theme.white} 20px);`
            : theme.warnOrange76};
          border-color: ${theme.alertRed};
        `;
      case BOOKED:
        return css`
          background: ${hasNote
            ? `linear-gradient(0deg, ${theme.lemon75} 19px, ${theme.lemon} 19px 20px, ${theme.white} 20px);`
            : theme.lemon75};
          border-color: ${theme.lemon};
        `;
      case REQUESTED:
        return css`
          background: ${theme.infoBlueLight20};
          border-color: ${theme.infoBlue};
        `;
      case VACANT: // does not render - vacant ranges are not rendered to the DOM
      default:
        // this is an error, should not occur. useful to render vacant ranges if you disable the filter in Status()
        return css`
          top: 0px !important;
          height: 18px !important;
          background: #5b84;
          border-color: #5b8;
        `;
    }
  }};

  // render opacity
  ${({ status }) =>
    status === CANCELLING &&
    css`
      opacity: 0.3;
    `};

  // render size
  ${({ height, theme, viewingMonth, yIndex }) =>
    // small cells cannot handle stacked/overlapping statuses

    // although yIndex may vary, it should mostly be between 1-3...
    // if yIndex was ANY integer or a float, a unique styled-component class would be generated for EVERY unique value.

    // these calculations need to correlate with RowsAndCells > MonthRow yIndex <- see comments there for further details.

    viewingMonth // small cells or big cells
      ? css`
          height: ${height * 21 - 1}px;
          top: ${20 + yIndex * 24}px;
          @media screen and (max-width: ${({ theme }) => theme.maxMobile}) {
            height: 8px;
            top: ${20 + yIndex * 10}px;
            > div {
              display: none; // hide icons and text
            }
          }
        `
      : css`
          height: 8px;
          top: 23px;
        `}

  // render shape
  ${({
    eventStart,
    // eventDuring,
    eventEnd,
    status,
  }) => {
    if (eventStart && eventEnd) {
      return css`
        border-left-width: 1px;
        border-radius: 4px;
        border-right-width: 1px;
        left: 5%;
        margin-left: 0;
        width: 90%;
      `;
    }
    if (eventStart) {
      return css`
        border-left-width: 1px;
        border-right-width: 0;
        border-radius: 4px 0 0 4px;
        margin-right: -1px;
        right: 0;
        width: ${status === BOOKED ? "60%" : "95%"};
      `;
    }
    if (eventEnd) {
      return css`
        border-radius: 0 4px 4px 0;
        border-left-width: 0;
        border-right-width: 1px;
        width: ${status === BOOKED ? "30%" : "95%"};
      `;
    }
    // this is eventDuring:
    return css`
      border-left-width: 0;
      border-right-width: 0;
      width: calc(100% + 2px);
    `;
  }};
`;

const CommonText = styled.div`
  align-items: center;
  font-family: Inter;
  font-size: 12px;
  margin-left: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: 11;
  :hover {
    overflow: inherit;
    span {
      background: rgba(255, 255, 255, 0.8);
    }
  }
`;

const Text = styled(CommonText)`
  color: ${({ theme }) => theme.charcoal};
`;

const Note = styled(CommonText)`
  color: ${({ theme }) => theme.charcoalLight50};
  margin-bottom: 2px;
`;
const Label = styled.span<any>`
  display: ${({ eventEnd, hasLateEtd }) =>
    eventEnd && hasLateEtd ? "inline-flex" : "initial"};
`;

const CheckInIcon = styled.div`
  display: inline-block;
  vertical-align: text-top;
  margin-right: 4px;
  height: 16px;
  div[data-test="tooltip-checkIn"] {
    width: 125px;
    padding: 8px;
    z-index: 99;
  }
`;
const CheckOutIcon = styled.div<any>`
  position: absolute;
  right: 4px;
  top: ${({ hasNote }) => (hasNote ? "22px" : "2px")};
  div[data-test="tooltip-checkOut"] {
    width: 125px;
    padding: 8px;
    z-index: 99;
  }
`;

const ExpiryIcon = styled.div<any>`
  display: inline-block;
  vertical-align: text-top;
  margin-left: 4px;
  margin-right: 8px;
  width: 12px;
  height: 12px;
  & > div {
    margin: 0;
  }
  div[data-test="tooltip-expiry"] {
    width: auto;
    left: -20px;
  }
  svg {
    width: 12px!important;
    height: 12px!important;
    fill: ${({ theme }) => theme.charcoal};
  }
`;

const StatusItem: React.FC<StatusItemProps> = ({
  dayOfWeek,
  item,
  timezone,
  viewingMonth,
  filter,
}) => {
  const {
    createdBy,
    eventStart,
    eventEnd,
    hasEarlyEta,
    hasLateEtd,
    note,
    status,
    expiryDate,
  } = item;
  const showText = viewingMonth && (eventStart || dayOfWeek === 1);
  const showCheckIn = viewingMonth && eventStart;
  const showCheckOut = viewingMonth && eventEnd;
  const hasNote = Boolean(note);
  const showNote = Boolean(showText && hasNote);
  const isBlocked = status === BLOCKED;
  const isBooked = status === BOOKED;
  const isRequested = status === REQUESTED;
  const isUnavailable = status === UNAVAILABLE;
  const label = isBlocked
    ? `Blocked by ${createdBy?.type}`
    : item.name || item.guest?.name || "";

  const className =
    isBlocked || isBooked || isUnavailable || isRequested
      ? {
          // className: "month-and-year-view" - no classname is needed.
        }
      : { className: "month-view-only" }; // used to hide any events that are not seen in year view. see MonthRow
  if (filter && !filter.includes(status)) return null; // shows only the status included in the filter which can be bookingTypes & propertyEventTypes.
  return (
    <StatusWrapper
      hasNote={hasNote}
      viewingMonth={viewingMonth}
      {...item}
      {...className}
    >
      {showNote && (
        <Note>
          <EventIcon item={item} showNote />
          <span>{note}</span>
        </Note>
      )}
      {showText && (
        <>
          <Text>
            {hasEarlyEta && showCheckIn && (
              <CheckInIcon>
                <Tooltip
                  icon="checkIn"
                  position="bottom"
                  text="Early Check-in"
                  testId="tooltip-checkIn"
                />
              </CheckInIcon>
            )}
            <EventIcon item={item} />
            {isUnavailable && !!expiryDate && (
              <ExpiryIcon>
                <Tooltip
                  icon="clock"
                  position="bottom"
                  text={`Expires ${formatDateWithDynamic(expiryDate, "Do MMM YY h:mm a", timezone)}`}
                  testId="tooltip-expiry"
                />
              </ExpiryIcon>
            )}
            <Label eventEnd={eventEnd} hasLateEtd={hasLateEtd}>
              {label}
            </Label>
          </Text>
        </>
      )}
      {hasLateEtd && showCheckOut && (
        <CheckOutIcon hasNote={hasNote}>
          <Tooltip
            icon="checkOut"
            position="bottom"
            text="Late Check-out"
            testId="tooltip-checkOut"
          />
        </CheckOutIcon>
      )}
    </StatusWrapper>
  );
};

const Status = (props: StatusProps) => {
  const { dayOfWeek, existing, viewingMonth, filter, timezone } = props;
  return (
    <>
      {existing.map((item, index) => {
        return (
          <StatusItem
            dayOfWeek={dayOfWeek}
            item={item}
            key={index}
            viewingMonth={viewingMonth}
            filter={filter}
            timezone={timezone}
          />
        );
      })}
    </>
  );
};

export default Status;

import { generateURL, getJSON } from "Utils/Network";
import { API_URL } from "Config";
import { get, keyBy } from "lodash";

import { IPropertyTaskRes } from "Containers/EventsCalendar/interface/task";

export const propertyTasksRestore = async ({
  propertyId,
  startDate,
  endDate,
}: {
  [key: string]: any;
}) => {
  try {
  const url = generateURL(`${API_URL}v3/property-tasks`, {
    externalPlatform: "breezeway",
    propertyId,
    from: startDate,
    to: endDate,
    include: "comments",
  });
  const json = await getJSON(url);
  const data = json.data as IPropertyTaskRes[];
  const commentsMap = keyBy(json?.included, "id");
  const tasks = data?.map((item: any) => {
    const commentId = get(item, "relationships.comments.data.id");
    const comments = get(
      commentsMap,
      [commentId, "attributes", "comments"],
      [],
    );
    return {
      attributes: item.attributes,
      id: item.id,
      type: item.type,
      comments,
    };
  });
  return tasks;
} catch (err) {
  // eslint-disable-next-line no-console
  console.warn("task err", err);
}
};

export const propertyTaskRestore = async ({
  taskId,
}: {
  [key: string]: any;
}) => {
  try {
  const url = generateURL(`${API_URL}v3/property-tasks/${taskId}`, {
    include: "attachments,comments,resources",
  });
  const json = await getJSON(url);
  const task = transformBooking(json);
  return task;
} catch (err) {
  // eslint-disable-next-line no-console
  console.warn("task err", err);
}
  };

  const getAttachments = ({ included, relationships }: {[key: string]: any}) => {
    if (!relationships["attachments"]) {
      return {};
    }
    const attachments = included.find((item: {[key: string]: any}) => {
      return (
        item.id === relationships["attachments"].data?.id &&
        item.type === "task-attachments"
      );
    });
    return { ...attachments?.attributes };
  };
  const getComments = ({ included, relationships }: {[key: string]: any}) => {
    if (!relationships["comments"]) {
      return {};
    }
    const comments = included.find((item: {[key: string]: any}) => {
      return (
        item.id === relationships["comments"].data?.id &&
        item.type === "task-comments"
      );
    });
    return { ...comments?.attributes };
  };
  const getResources = ({ included, relationships }: {[key: string]: any}) => {
    if (!relationships["resources"]) {
      return {};
    }
    const resources = included.find((item: {[key: string]: any}) => {
      return (
        item.id === relationships["resources"].data?.id &&
        item.type === "external-resources"
      );
    });
    return { ...resources?.attributes };
  };
const transformBooking = (task: any) => {
  // extract all the fields {
  const {
    data: {
      attributes: {
        breezewayId,
        name,
        description,
        scheduledDate,
        scheduledTime,
        startedAt,
        finishedAt,
        isPaused,
        reportUrl,
        department,
        priority,
        tags,
        statusCode,
        statusStage,
        createdAt,
        deletedAt,
        assignments,
        reporterType,
        location,
        repairDuringStay,
        totalTime,
        createdBy,
        displayStatus,
      },
      relationships,
    },
    included,
  } = task;

  const comments = getComments({ included, relationships });
  const attachments = getAttachments({ included, relationships });
  const resources = getResources({ included, relationships });

  const clean = {
    breezewayId,
    name,
    description,
    scheduledDate,
    scheduledTime,
    startedAt,
    finishedAt,
    isPaused,
    reportUrl,
    department,
    priority,
    tags,
    statusCode,
    statusStage,
    createdAt,
    deletedAt,
    assignments,
    reporterType,
    location,
    repairDuringStay,
    totalTime,
    createdBy,
    displayStatus,
    ...comments,
    ...attachments,
    ...resources,
  };

  return clean;
};

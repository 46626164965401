import styled from "styled-components";
import React, { useEffect, useState } from "react";

import { ContainerWrapper } from "Components/ComfyContainer";
import { H3, H4 } from "Components/Typography";

const Content = styled.div`
  border-bottom: 0.5px solid ${({ theme }) => theme.white};
  pre {
    white-space: break-spaces;
  }
`;
const Latest = styled.span`
  border: 1px solid ${({ theme }) => theme.successGreen};
  border-radius: 16px;
  padding: 2px 8px;
  margin-left: 16px;
  font-size: 12px;
  color: ${({ theme }) => theme.successGreen};
`;
const Wrapper = styled(ContainerWrapper)`
  background-color: ${({ theme }) => theme.darkPetrol};
  color: ${({ theme }) => theme.white};
  h4 {
    color: ${({ theme }) => theme.turquoise};
  }
`;

const Releases = () => {
  const [release, setRelease] = useState<any>(null);

  const getRelease = () => {
    fetch(`/release.json`)
      .then((resp) => resp.json())
      .then((data) => {
        setRelease(data);
      });
  };
  useEffect(() => {
    getRelease();
  }, []);
  if (!release) return null;
  return (
    <Wrapper>
      <H3 color="theme.lemon">Release history</H3>
      {release.map((item: any, i: any) => {
        const content = item.body.split("**")[0].replace(/by @.*\/[0-9]+/g, "");
        return (
          <Content key={i}>
            <p>
              Release version: <b>{item.tag_name}</b>
              {i === 0 && <Latest>Latest</Latest>}
            </p>
            <H4>{item.name}</H4>
            <div>
              <pre>{content}</pre>
            </div>
          </Content>
        );
      })}
    </Wrapper>
  );
};

export default Releases;

import { AnyAction, combineReducers } from "redux";

import { SESSION } from "Constants/actionTypes";

// bookings
import { bookingsByCode } from "./bookings/bookingsByCode";
import { currentBooking } from "./bookings/currentBooking";

// authentication
import { sessionStatus } from "./authentication/sessionStatus";
import { forgotPassword } from "./authentication/forgotPassword";
import { createPasswordStatus } from "./authentication/createPasswordStatus";
import { verifyTokenStatus } from "./authentication/verifyTokenStatus";

// channels
import { channelData } from "./channels/channelData";
import { channelAccountData } from "./channels/channelAccountData";
import { accountListingData } from "./channels/accountListingData";

// events
import { searchEvents } from "./events/searchEvents";
import { searchReservations } from "./reservations/searchReservations";

// properties
import { propertyBlocks } from "./properties/propertyBlocks";
import { currentPropertyStatus } from "./properties/currentProperty";
import { propertyListSearch } from "./properties/propertyListSearch";
import { propertyPerformance } from "./properties/propertyPerformance";
import { propertyRevenueGraphs } from "./properties/propertyRevenueGraphs";

// user managment
import { editUserPermissions } from "./users/editUserPermissions";
import { editUserStatus } from "./users/editUserStatus";
import { userHistory } from "./users/userHistory";

// resources
import { newsletter } from "./resources/newsletter";

// statements
import { statements } from "./statements/statements";

const appReducer = combineReducers({
  authentication: combineReducers({
    sessionStatus,
    forgotPassword,
    createPasswordStatus,
    verifyTokenStatus,
  }),
  bookings: combineReducers({
    bookingsByCode,
    currentBooking,
  }),
  channelData,
  channelAccountData,
  accountListingData,
  currentPropertyStatus,
  searchEvents,
  searchReservations,
  properties: combineReducers({
    propertyBlocks,
    performance: propertyPerformance,
    propertyListSearch,
    revenueGraphs: propertyRevenueGraphs,
  }),
  users: combineReducers({
    editUserPermissions,
    editUserStatus,
    userHistory,
  }),
  newsletter,
  statements,
});

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === SESSION.RESET) {
    // reset app back to initial state
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;

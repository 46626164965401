import React, { useState } from "react";
import styled from "styled-components";

import {
  BUTTON_DANGER,
  BUTTON_SECONDARY,
  BUTTON_PRIMARY,
  InputText,
  TYPE_TEXT,
} from "@madecomfy/webooi";
import { Body } from "Components/Typography";

import Popup from "Components/Popup";
import Generic from "Components/Popup/GenericWrapper";
import {
  newsletterCreate,
  newsletterDelete,
  newsletterUpdate,
} from "Actions/resources";

const Wrapper = styled.div``;
interface IPropsAdd {
  handleClose: (...args: any) => any;
  newsletterRestore: (...args: any) => any;
}

export const AddNewsLetter: React.FC<IPropsAdd> = ({
  handleClose,
  newsletterRestore,
}: any) => {
  const [loading, setLoading] = useState(false);
  const [heroImageUrl, setHeroImageUrl] = useState("");
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");

  const handleSave = async () => {
    if (!title || !url) {
      return;
    }
    setLoading(true);
    const params = {
      title,
      heroImageUrl,
      url,
    };
    const resp = await newsletterCreate(params);
    if (resp?.url) {
      setLoading(false);
      await newsletterRestore();
      handleClose();
    }
  };

  return (
    <Popup
      body={
        <Generic
          body={
            <Wrapper>
              <InputText
                my={0}
                name="title"
                placeholder="Enter the title"
                sendValue={({ value }: any) => setTitle(value)}
                testId="title"
                type={TYPE_TEXT}
                label="Title"
                value={title}
                instructions="Max 23 characters e.g. November 2024"
              />
              <InputText
                my={24}
                name="newsletter-url"
                placeholder="Enter the newsletter URL"
                sendValue={({ value }: any) => setUrl(value)}
                testId="newsletter-url"
                type={TYPE_TEXT}
                label="Newsletter URL"
                value={url}
              />
              <InputText
                my={0}
                name="image"
                placeholder="Enter the image URL"
                sendValue={({ value }: any) => setHeroImageUrl(value)}
                testId="image"
                type={TYPE_TEXT}
                label="Hero image URL"
                value={heroImageUrl}
                instructions="Recommended image size: 600x260px"
              />
            </Wrapper>
          }
          buttons={[
            { label: "Cancel", onClick: handleClose, type: BUTTON_SECONDARY },
            {
              label: "Save",
              onClick: handleSave,
              type: BUTTON_PRIMARY,
              isEnabled: Boolean(title && url),
            },
          ]}
          loading={loading}
          title="Add a new newsletter"
        />
      }
      handleClose={handleClose}
      width={450}
    />
  );
};

interface IPropsUpdate {
  data: { [key: string]: any };
  handleClose: (...args: any) => any;
  newsletterRestore: (...args: any) => any;
}

export const EditNewsLetter: React.FC<IPropsUpdate> = ({
  data,
  handleClose,
  newsletterRestore,
}: any) => {
  const [loading, setLoading] = useState(false);
  const [heroImageUrl, setHeroImageUrl] = useState(data?.heroImageUrl);
  const [title, setTitle] = useState(data?.title);
  const [url, setUrl] = useState(data?.url);

  const handleSave = async () => {
    if (!title || !url) {
      return;
    }
    setLoading(true);
    const params = {
      title,
      heroImageUrl,
      url,
    };
    const resp = await newsletterUpdate(data?.id, params);
    if (resp?.url) {
      setLoading(false);
      await newsletterRestore();
      handleClose();
    }
  };

  return (
    <Popup
      body={
        <Generic
          body={
            <Wrapper>
              <InputText
                my={0}
                name="title"
                placeholder="Enter the title"
                sendValue={({ value }: any) => setTitle(value)}
                testId="title"
                type={TYPE_TEXT}
                label="Title"
                value={title}
                instructions="Max 23 characters e.g. November 2024"
              />
              <InputText
                my={24}
                name="newsletter-url"
                placeholder="Enter the newsletter URL"
                sendValue={({ value }: any) => setUrl(value)}
                testId="newsletter-url"
                type={TYPE_TEXT}
                label="Newsletter URL"
                value={url}
              />
              <InputText
                my={0}
                name="image"
                placeholder="Enter the image URL"
                sendValue={({ value }: any) => setHeroImageUrl(value)}
                testId="image"
                type={TYPE_TEXT}
                label="Hero image URL"
                value={heroImageUrl}
                instructions="Recommended image size: 600x260px"
              />
            </Wrapper>
          }
          buttons={[
            { label: "Cancel", onClick: handleClose, type: BUTTON_SECONDARY },
            {
              label: "Save",
              onClick: handleSave,
              type: BUTTON_PRIMARY,
              isEnabled: Boolean(title && url),
            },
          ]}
          loading={loading}
          title="Edit newsletter"
        />
      }
      handleClose={handleClose}
      width={450}
    />
  );
};

interface IPropsDelete {
  handleClose: (...args: any) => any;
  id: string;
  newsletterRestore: (...args: any) => any;
}

export const DeleteNewsLetter: React.FC<IPropsDelete> = ({
  handleClose,
  id,
  newsletterRestore,
}: any) => {
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);
    const resp = await newsletterDelete(id);
    if (resp) {
      setLoading(false);
      newsletterRestore();
      handleClose();
    }
  };

  return (
    <Popup
      body={
        <Generic
          body={<Body>Are you sure you want to delete this newsletter?</Body>}
          buttons={[
            { label: "Cancel", onClick: handleClose, type: BUTTON_SECONDARY },
            {
              label: "Delete",
              onClick: handleDelete,
              type: BUTTON_DANGER,
            },
          ]}
          loading={loading}
          title="Delete newsletter"
        />
      }
      handleClose={handleClose}
      width={450}
    />
  );
};

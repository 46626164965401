import { STATEMENTS_DATA } from "Constants/actionTypes";

export function statements(
  state = {
    error: false,
    errorCode: 0,
    errorMessage: "",
    statements: [],
    pending: false,
    success: false,
    totalCount: 0,
    totalPages: 0,
  },
  action: {[key: string]: any},
) {
  switch (action.type) {
    case STATEMENTS_DATA.SUCCESS:
      return {
        ...state,
        pending: false,
        success: true,
        statements: action.response,
        totalCount: action.totalCount,
        totalPages: action.totalPages,
      };
    case STATEMENTS_DATA.PENDING:
      return {
        ...state,
        pending: action.status,
        totalCount: 0,
        totalPages: 0,
      };
    case STATEMENTS_DATA.ERROR:
      return {
        ...state,
        error: true,
        errorMessage: action.errorMessage,
        errorCode: action.errorCode,
        pending: false,
      };
    default:
      return state;
  }
}

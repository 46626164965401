/**
 * generateURL
 * serialise url request including parameters
 * to omit parameters ensure they are `undefined`
 * this method is currently fit for purpose, however...
 * internally could use `p = new URLSearchParams()` and then forEach entry `p.set(k, v))`
 */

export const generateURL = (path: string, parameters: object) => {
  if (!parameters) {
    throw new Error("pointless use of Network.generateURL");
  }

  // everything gets cast to string due to string concatenation...
  // which means arrays will be CSV

  const serialised = Object.entries(parameters)
    .filter(([k, v]) => typeof v !== "undefined")
    .map(([k, v]) => `${k}=${v}`)
    .join("&");

  if (serialised.length === 0) {
    // all params have been filtered out due to undefined
    return path;
  }

  return `${path}?${serialised}`;
};

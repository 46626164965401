import dayjs from "dayjs";
import React, { useState } from "react";
import styled from "styled-components";
import * as H from "history";

import { Alert, ALERT_ERROR, BUTTON_PRIMARY, Select } from "@madecomfy/webooi";

import ComfyContainer from "Components/ComfyContainer";
import PropertyHeader from "Containers/Booking/components/PropertyHeader";
import { AVAILABLE, UNAVAILABLE } from "Constants/propertyEventTypes";
import { blankCalendar, DatePicker } from "Components/Dates";
import { H4 } from "Components/Typography";

import EventForm from "./EventForm";
import { ROLE } from "Constants/userRoles";
import { isAuthorized, yyyymmdd } from "Utils";
import { ExpirySelect } from "./components/ExpirySelect";

const DateWrapper = styled.div`
  max-width: 784px;
`;

const checkDateString = (str: string) => /^\d\d\d\d-\d\d-\d\d$/.test(str);

interface IProps {
  history: H.History;
  endDate: string;
  onConfirm: (...args: any) => any;
  propertyId: string;
  startDate: string;
  status: string;
  expiry?: string;
  title: string;
  // only exist in editing state
  name?: string;
  onDelete?: (...args: any) => any;
  user?: { [key: string]: any };
}
const EventEditor: React.FC<IProps> = (props) => {
  const [dates, setDates] = useState({
    startDate: props.startDate,
    endDate: props.endDate,
  });
  const [errorsAPI, setErrorsAPI] = useState([]);
  const [eventStatus, setEventStatus] = useState(props.status);
  const [expiry, setExpiry] = useState(props?.expiry);

  const isReservationManager = isAuthorized(props.user?.roles, [
    ROLE.RESERVATION_MANAGER,
  ]);
  const isLicensee = isAuthorized(props.user?.roles, [ROLE.LICENSEE]);

  const {
    endDate,
    history,
    name = "",
    onConfirm,
    onDelete,
    propertyId,
    startDate,
    status,
    title,
  } = props;
  const errorsParams = [] as any;
  if (!checkDateString(startDate)) {
    errorsParams.push("Invalid start date");
  }
  if (!checkDateString(endDate)) {
    errorsParams.push("Invalid end date");
  }
  if (status !== UNAVAILABLE && status !== AVAILABLE) {
    errorsParams.push("Invalid status");
  }

  const goToProperty = () => {
    const year = startDate.substring(0, 4);
    const month = Number(startDate.substring(5, 7));
    history?.push(`/properties/${propertyId}?year=${year}&month=${month}`);
  };

  const errors = [].concat(errorsAPI).concat(errorsParams);

  const methods: {
    onCancel?: (...args: any) => any;
    onConfirm?: (...args: any) => any;
    onDelete?: (...args: any) => any;
  } = {
    onCancel: goToProperty,
    onConfirm: async ({ eventName }: any) => {
      try {
        const params = {
          start: dates.startDate,
          end: dates.endDate,
          blockDates: eventStatus === UNAVAILABLE,
          name: eventName,
          status: eventStatus,
          ...(expiry && {
            expiryAt: yyyymmdd(dayjs().add(Number(expiry), "day"), "GMT"),
          }),
        };
        const result = await onConfirm(params);

        if (!result) {
          setErrorsAPI(["No response"] as any);
          return;
        }
        if (result.errors) {
          setErrorsAPI(result.errors.map(({ detail }: any) => detail));
          return;
        }
        goToProperty();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.warn("err", err);
      }
    },
  };

  if (onDelete) {
    // EventCreate has no delete - no entity exists yet...
    methods.onDelete = async () => {
      const ok = window.confirm(
        "Are you sure you want to delete this property event?",
      );
      if (!ok) return;
      await onDelete();
      goToProperty();
    };
  }

  return (
    <ComfyContainer>
      <H4>{title}</H4>
      {errors.length > 0 && (
        <Alert
          title="Something is wrong"
          message={
            errors.length === 1 ? (
              errors[0]
            ) : (
              <ul>
                {errors.map((err, i) => (
                  <li key={i}>{err}</li>
                ))}
              </ul>
            )
          }
          buttons={[
            { callback: goToProperty, label: "Back", type: BUTTON_PRIMARY },
          ]}
          type={ALERT_ERROR}
        />
      )}
      <PropertyHeader propertyId={propertyId} />
      <DateWrapper>
        <DatePicker
          calendar={blankCalendar}
          ignoreValidation={true}
          initialDates={dates}
          initialFocus={false}
          isEnabled
          label="Dates"
          month={new Date().getMonth() + 1}
          year={new Date().getFullYear()}
          sendValue={(dates) =>
            setDates({
              startDate: dates.startDate,
              endDate: dates.endDate,
            })
          }
        />
      </DateWrapper>
      {/*
       <DatePickerStatic {...{ startDate, endDate }} label="Dates" />
      <TimePicker
        initialTimes={{ startTime: "12:30", endTime: "" }}
        label="Times"
        sendValue={(v) => {
          console.log("got", v);
        }}
        isEnabled
      />
      <TimePickerStatic
        {...{ startTime: "12:30 pm", endTime: "02:30 pm" }}
        label="Times"
      />
      */}
      <Select
        label="Status"
        name="status"
        options={[
          { value: AVAILABLE, label: "Available" },
          { value: UNAVAILABLE, label: "Blocking" },
        ]}
        value={status as any}
        sendValue={({ value }: any) => setEventStatus(value)}
      />
      {eventStatus === UNAVAILABLE && (isLicensee || isReservationManager) && (
        <>
          <ExpirySelect
            value={expiry}
            testId="expiry-time"
            onChange={({ value }: any) => setExpiry(value)}
          />
          {/* <ExpiryWrapper>
            <Select
              label="Expiry"
              instructions="The event will be automatically deleted after this time"
              name="expiry"
              options={[
                { value: "", label: "Never" },
                { value: "1", label: "1 day" },
                { value: "2", label: "2 days" },
                { value: "3", label: "3 days" },
              ]}
              value={expiry as any}
              testId="expiry-time"
              my={0}
              sendValue={({ value }: any) => setExpiry(value)}
            />
          </ExpiryWrapper> */}
        </>
      )}
      <EventForm initialName={name} {...methods} />
    </ComfyContainer>
  );
};

export default EventEditor;

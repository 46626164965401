import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Statements from "./Statements";
import { statementsRestore } from "Actions/statements/statementsRestore";

const mapStateToProps = (state: any) => ({
  statements: state.statements,
  user: state.authentication.sessionStatus.user,
});

const mapDispatchToProps = (dispatch: any) => ({
  statementsRestore: bindActionCreators(statementsRestore, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Statements);

import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";

import { isAuthorized } from "Utils/methods/authentication";

const Wrapper = styled.ul`
  display: flex;
  gap: 24px;
  padding: 0;
  border-bottom: 2px solid #cdcdcd;
  padding-bottom: 8px;
  margin: 0;
`;
const TabItem = styled.li<{ isActive: boolean }>`
  color: ${({ theme }) => theme.text.default};
  font-size: 18px;
  list-style-type: none;
  line-height: 25px;
  cursor: pointer;
  ${({ isActive }) =>
    isActive &&
    css`
      border-bottom: 4px solid ${({ theme }) => theme.border.accentYellow};
      margin-bottom: -8px;
      font-weight: 600;
    `}
`;

interface IProps {
  active?: string;
  setActive?: Function;
  userRoles: string[];
  tab: {
    component: any;
    id: string;
    label: string;
    requiredRoles: string[];
  }[];
}
const Tab: React.FC<IProps> = ({ active, setActive, tab, userRoles }) => {
  const allowedTabs = tab.filter(({ requiredRoles }: any) => {
    return isAuthorized(requiredRoles, userRoles);
  });
  const [activeTab, setActiveTab] = useState(active || allowedTabs[0].id);
  useEffect(() => {
    if (active) {
      setActiveTab(active);
    }
  }, [active]);
  const handleTabClick = (id: string) => {
    setActive && setActive(id);
    setActiveTab(id);
  };
  const Missing = {
    component: () => <div>error</div>,
  };
  const activeComponent =
    allowedTabs.find((tabItem) => tabItem.id === activeTab) || Missing;
  const { component: ChildComponent } = activeComponent;

  return (
    <div>
      <Wrapper>
        {allowedTabs.map(({ id, label }: { id: string; label: string }) => (
          <TabItem
            isActive={activeTab === id}
            key={id}
            onClick={() => handleTabClick(id)}
          >
            {label}
          </TabItem>
        ))}
      </Wrapper>
      <ChildComponent />
    </div>
  );
};

export default Tab;

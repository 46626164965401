import React from "react";

const OpenTask = () => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7556 4.7364C14.3513 4.33209 13.6958 4.33209 13.2915 4.7364L7.0136 11.0144L6.76973 12.7224L8.47769 12.4786L14.7556 6.20054C15.1599 5.79623 15.1599 5.14071 14.7556 4.7364ZM12.626 4.07089C13.3978 3.29902 14.6493 3.29902 15.4211 4.07089C16.193 4.84275 16.193 6.09419 15.4211 6.86605L9.03228 13.255C8.96027 13.327 8.86685 13.3737 8.76604 13.3881L6.28171 13.7428C6.13509 13.7638 5.98717 13.7145 5.88244 13.6097C5.77771 13.505 5.7284 13.3571 5.74933 13.2105L6.10404 10.7261C6.11844 10.6253 6.16514 10.5319 6.23715 10.4598L12.626 4.07089Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.01166 1.4214C3.01166 0.901605 3.43304 0.480225 3.95284 0.480225H8.03117C8.55097 0.480225 8.97235 0.901604 8.97235 1.4214V2.48807C8.97235 3.00786 8.55097 3.42924 8.03117 3.42924H3.95284C3.43304 3.42924 3.01166 3.00787 3.01166 2.48807V1.4214ZM3.95284 1.4214V2.48807L8.03117 2.48807V1.4214L3.95284 1.4214Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.03133 1.95474C8.03133 1.69484 8.24202 1.48415 8.50192 1.48415H10.5097C11.3241 1.48415 11.9842 2.14432 11.9842 2.95866C11.9842 3.21856 11.7735 3.42925 11.5136 3.42925C11.2537 3.42925 11.043 3.21856 11.043 2.95866C11.043 2.6641 10.8043 2.42532 10.5097 2.42532H8.50192C8.24202 2.42532 8.03133 2.21463 8.03133 1.95474Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.47449 2.42532C1.17996 2.42532 0.941177 2.6641 0.941177 2.95866V15.0057C0.941177 15.3003 1.17996 15.539 1.47449 15.539H10.5096C10.8041 15.539 11.0429 15.3003 11.0429 15.0057V12.9979C11.0429 12.738 11.2536 12.5273 11.5135 12.5273C11.7734 12.5273 11.9841 12.738 11.9841 12.9979V15.0057C11.9841 15.8201 11.324 16.4802 10.5096 16.4802H1.47449C0.660144 16.4802 0 15.8201 0 15.0057V2.95866C0 2.14432 0.660144 1.48415 1.47449 1.48415H3.4823C3.74219 1.48415 3.95288 1.69484 3.95288 1.95474C3.95288 2.21463 3.74219 2.42532 3.4823 2.42532H1.47449Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.01166 5.97042C3.01166 5.71052 3.22235 5.49983 3.48225 5.49983H8.50176C8.76166 5.49983 8.97235 5.71052 8.97235 5.97042C8.97235 6.23032 8.76166 6.44101 8.50176 6.44101H3.48225C3.22235 6.44101 3.01166 6.23032 3.01166 5.97042Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.01166 8.98218C3.01166 8.72229 3.22235 8.5116 3.48225 8.5116H5.49006C5.74995 8.5116 5.96064 8.72229 5.96064 8.98218C5.96064 9.24208 5.74995 9.45277 5.49006 9.45277H3.48225C3.22235 9.45277 3.01166 9.24208 3.01166 8.98218Z"
    />
  </>
);

export default OpenTask;

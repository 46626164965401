import { NEWSLETTER_DATA } from "Constants/actionTypes";

export function newsletter(
  state = {
    error: false,
    errorCode: 0,
    errorMessage: "",
    newsletters: [],
    pending: false,
    success: false,
  },
  action: {[key: string]: any},
) {
  switch (action.type) {
    case NEWSLETTER_DATA.SUCCESS:
      return {
        ...state,
        pending: false,
        success: true,
        newsletters: action.response,
      };
    case NEWSLETTER_DATA.PENDING:
      return {
        ...state,
        pending: action.status,
      };
    case NEWSLETTER_DATA.ERROR:
      return {
        ...state,
        error: true,
        errorMessage: action.errorMessage,
        errorCode: action.errorCode,
        pending: false,
      };
    default:
      return state;
  }
}

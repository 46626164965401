import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import {
  propertiesRestore,
  resetPropertiesRestore,
} from "Actions/properties/propertiesRestore";

import PropertyList, { PropertyListWithClickOutside } from "./PropertyList";

const mapStateToProps = (state: {[key: string]: any}) => ({
  propertyListSearch: state.properties.propertyListSearch,
  user: state.authentication.sessionStatus.user,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  propertiesRestore: bindActionCreators(propertiesRestore, dispatch),
  resetPropertiesRestore: bindActionCreators(resetPropertiesRestore, dispatch),
});

const PropertyListWithClickOutsideConnected = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PropertyListWithClickOutside as any),
);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PropertyList as any),
);
export {
  PropertyListWithClickOutsideConnected as PropertyListWithClickOutside,
};

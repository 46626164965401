import { getOwnerProperty } from "Utils/methods/redirects";

interface IProps {
  location: {[key: string]: any};
  propertyCountStatus: {[key: string]: any};
  sessionStatus: {[key: string]: any};
}

const getRedirect = ({ location, propertyCountStatus, sessionStatus }: IProps) => {
  const { user, valid } = sessionStatus;
  if (valid) {
    return getOwnerProperty(user);
  }
  const { pathname, search } = location;
  const redirect = encodeURIComponent(pathname + search);
  if (!redirect || ["%2F", "%2Findex.html"].includes(redirect)) {
    return "/login";
  }
  // only add post login (pl) redirect if it's truthy or not either root or index
  return `/login?pl=${redirect}`;
};

export default getRedirect;

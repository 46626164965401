import { EDIT_USER } from "Constants/actionTypes";

const initialState = {
  error: false,
  errorCode: 0,
  errorMessage: "",
  mode: null,
  ownedCompanies: [],
  ownedProperties: [],
  pending: false,
  success: false,
  user: null,
};

export function editUserStatus(state = initialState, action: {[key: string]: any}) {
  switch (action.type) {
    case EDIT_USER.SUCCESS:
      const nextState = {
        ...state,
        mode: action.mode,
        pending: false,
        success: true,
      };
      const { ownedCompanies, ownedProperties, user } = action;
      if (ownedCompanies) nextState.ownedCompanies = ownedCompanies;
      if (ownedProperties) nextState.ownedProperties = ownedProperties;
      if (user) nextState.user = user;
      return nextState;

    case EDIT_USER.ERROR:
      return {
        ...state,
        mode: action.mode,
        error: true,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        pending: false,
      };

    case EDIT_USER.PENDING:
      return {
        ...state,
        mode: action.mode,
        pending: action.status,
        success: false,
      };

    case EDIT_USER.RESET:
      if (action.mode) {
        return {
          ...initialState,
          mode: action.mode,
        };
      }
      return initialState;

    default:
      return state;
  }
}

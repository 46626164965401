import { API_URL } from "Config";
import { generateURL, getJSON } from "Utils/Network";

export const userCompanies = async (partnerType?: string | null, filter?: any) => {
  try {
  const url = generateURL(`${API_URL}v3/companies`, {
    ...(partnerType && { partnerType }),
    ...(filter && filter),
  });
  const json = await getJSON(url);
  const result = json.data.map((item: { [key: string]: any }) => {
    const { attributes, id } = item;
    return {
      companyName: attributes?.companyName,
      partnerType: attributes?.partnerType,
      id,
    };
  });
  return result;
} catch (err) {
  // eslint-disable-next-line
  console.warn("companies error", err);
}
};

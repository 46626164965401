import { SESSION } from "Constants/actionTypes";

const initialState = {
  error: false,
  errorCode: 0,
  errorMessage: "",
  pending: false,
  user: null,
  valid: false,
};

export function sessionStatus(state = initialState, action: {[key: string]: any}) {
  switch (action.type) {
    case SESSION.ERROR:
      return {
        ...state,
        error: true,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        pending: false,
        valid: false,
      };
    case SESSION.SUCCESS:
      return { ...state, pending: false, valid: true, user: action.user };
    case SESSION.PENDING:
      return { ...state, pending: action.status };
    // case SESSION.RESET:
    // no need: whole app is reset
    default:
      return state;
  }
}

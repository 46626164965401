import React from "react";

const Channel = () => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M8.25 3.74805C8.25 1.67698 9.92893 -0.00195312 12 -0.00195312C14.0711 -0.00195312 15.75 1.67698 15.75 3.74805C15.75 5.56227 14.4617 7.07558 12.75 7.42303V11.998H13.6899C17.3426 11.8548 20.459 14.5224 20.9418 18.0782C22.2652 18.3906 23.25 19.5793 23.25 20.998C23.25 22.6549 21.9069 23.998 20.25 23.998C18.5931 23.998 17.25 22.6549 17.25 20.998C17.25 19.6256 18.1716 18.4684 19.4298 18.1116C18.9748 15.3948 16.5574 13.3805 13.7361 13.4974C13.7257 13.4978 13.7154 13.498 13.705 13.498H12.75V18.0926C14.0439 18.4256 15 19.6002 15 20.998C15 22.6549 13.6569 23.998 12 23.998C10.3431 23.998 9 22.6549 9 20.998C9 19.6002 9.95608 18.4256 11.25 18.0926V13.498H10.295L10.2771 13.4978L10.2639 13.4974C7.44262 13.3805 5.02521 15.3948 4.57021 18.1116C5.82836 18.4684 6.75 19.6256 6.75 20.998C6.75 22.6549 5.40685 23.998 3.75 23.998C2.09315 23.998 0.75 22.6549 0.75 20.998C0.75 19.5793 1.73485 18.3906 3.05819 18.0782C3.54105 14.5224 6.65743 11.8548 10.3101 11.998H11.25V7.42303C9.53832 7.07558 8.25 5.56227 8.25 3.74805ZM10.5 20.998C10.5 20.1696 11.1716 19.498 12 19.498C12.8284 19.498 13.5 20.1696 13.5 20.998C13.5 21.8265 12.8284 22.498 12 22.498C11.1716 22.498 10.5 21.8265 10.5 20.998ZM3.74671 19.4981C2.9198 19.4998 2.25 20.1707 2.25 20.998C2.25 21.8265 2.92157 22.498 3.75 22.498C4.57843 22.498 5.25 21.8265 5.25 20.998C5.25 20.171 4.58062 19.5002 3.75407 19.4981L3.74671 19.4981ZM14.25 3.74805C14.25 4.99069 13.2426 5.99805 12 5.99805C10.7574 5.99805 9.75 4.99069 9.75 3.74805C9.75 2.50541 10.7574 1.49805 12 1.49805C13.2426 1.49805 14.25 2.50541 14.25 3.74805ZM18.75 20.998C18.75 20.1696 19.4216 19.498 20.25 19.498C21.0784 19.498 21.75 20.1696 21.75 20.998C21.75 21.8265 21.0784 22.498 20.25 22.498C19.4216 22.498 18.75 21.8265 18.75 20.998Z"
  />
);

export default Channel;

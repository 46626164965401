import { CHANNEL_DATA } from "Constants/actionTypes";

const initialState = {
  error: false,
  errorCode: 0,
  errorMessage: "",
  pending: false,
  success: false,
  channels: [],
  accounts: [],
};
export function channelData(state = initialState, action: {[key: string]: any}) {
  switch (action.type) {
    case CHANNEL_DATA.SUCCESS:
      const { accounts, channels, mode } = action;

      return {
        ...state,
        accounts: accounts,
        channels: channels,
        mode,
        pending: false,
        success: true,
      };
    case CHANNEL_DATA.PENDING:
      return { ...state, pending: action.status };
    case CHANNEL_DATA.ERROR:
      return {
        ...state,
        error: true,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        pending: false,
      };
    case CHANNEL_DATA.RESET:
      return initialState;
    default:
      return state;
  }
}

import React from "react";
import { RouteComponentProps } from "react-router";
import styled from "styled-components";

import {
  Alert,
  ALERT_WARNING,
  Button,
  BUTTON_CENTER,
  Form,
  TYPE_EMAIL,
  TYPE_TEXT,
  ValidatedInputText,
  ValidatedToggle,
} from "@madecomfy/webooi";

import { Body, H5 } from "Components/Typography";
import { UPDATE } from "Constants/crud";
import { validateName } from "./validators";

const GridRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  margin-bottom: -24px;
  & > div {
    margin-top: 16px;
  }
  @media (max-width: ${({ theme }) => theme.maxMobile}) {
    grid-template-columns: 1fr;
  }
`;
const WrapButton = styled.div`
  button {
    margin-right: 0;
  }
`;
interface IProps extends RouteComponentProps {
  canUpdateUser: boolean;
  editUserRoles: { [key: string]: any };
  editUserStatus: { [key: string]: any };
  onSubmit: (...args: any) => any;
  reset?: (...args: any) => any;
  userDetails?: { [key: string]: any };
}

const getDefaults = (userDetails = {} as { [key: string]: any }) => {
  const userDefaults = {
    bookingNotificationsEnabled:
      typeof userDetails?.bookingNotificationsEnabled === "boolean"
        ? userDetails?.bookingNotificationsEnabled
        : true,
    bookingChangeNotificationsEnabled:
      typeof userDetails?.bookingChangeNotificationsEnabled === "boolean"
        ? userDetails.bookingChangeNotificationsEnabled
        : false,
    bookingCancellationNotificationsEnabled:
      typeof userDetails?.bookingCancellationNotificationsEnabled === "boolean"
        ? userDetails.bookingCancellationNotificationsEnabled
        : false,
    ownerStayNotificationsEnabled:
      typeof userDetails?.ownerStayNotificationsEnabled === "boolean"
        ? userDetails.ownerStayNotificationsEnabled
        : false,
    ownerStayChangeNotificationsEnabled:
      typeof userDetails?.ownerStayChangeNotificationsEnabled === "boolean"
        ? userDetails.ownerStayChangeNotificationsEnabled
        : false,
    ownerStayCancellationNotificationsEnabled:
      typeof userDetails?.ownerStayCancellationNotificationsEnabled ===
      "boolean"
        ? userDetails.ownerStayCancellationNotificationsEnabled
        : false,
    email: String(userDetails?.email || ""), // we don't want "null" or "undefined"
    firstName: String(userDetails?.firstName || ""),
    lastName: String(userDetails?.lastName || ""),
    mobile: String(userDetails?.mobile || ""),
  };

  return {
    ...userDefaults,
  };
};

class EditSettings extends Form<any, any> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      company: null,
      companies: [],
      modal: false,
      isLicensee: false,
      isOwner: false,
      ...this.handleProps(props as any),
    };
  }
  async componentDidMount() {
    //
  }
  handleProps = ({
    userDetails,
  }: {
    ownedCompanies?: { [key: string]: any }[];
    ownedProperties?: any;
    userDetails?: { [key: string]: any };
  }) => {
    return {
      ...this.state,
      ...getDefaults(userDetails),
    };
  };

  confirmationModal = (modal: boolean) => {
    this.setState({ modal });
  };

  checkSubmit = () => {
    const isValid = this.validate();
    if (isValid) {
      // this.confirmationModal(true);
      this.handleSubmit();
    }
  };

  handleSubmit = () => {
    const { canUpdateUser, onSubmit } = this.props;
    const properties = this.state.properties?.map(
      (property: { [key: string]: any }) => property.code,
    );
    if (!canUpdateUser) {
      onSubmit({
        user: null, // leave user as is, and only update properties
        properties,
      });
      return;
    }
    const user = this.getValues();
    onSubmit({ user, properties });
  };

  editComplete = () => {
    const { reset } = this.props;
    reset();
    window.location.reload();
  };

  renderForm(mode: string) {
    const { ...rest } = this.state;
    const {
      bookingNotificationsEnabled,
      bookingChangeNotificationsEnabled,
      bookingCancellationNotificationsEnabled,
      ownerStayNotificationsEnabled,
      ownerStayChangeNotificationsEnabled,
      ownerStayCancellationNotificationsEnabled,
      email,
      enabled,
      firstName,
      lastName,
      mobile,
    } = this.state;

    const userRoles = this.props.userDetails?.roles;
    const isLicensee = userRoles?.includes("ROLE_LICENSEE");
    const isOwner = userRoles?.includes("ROLE_OWNER");

    return (
      <div>
        {enabled === false && (
          <Alert message="Your account is disabled" type={ALERT_WARNING} />
        )}
        <H5>User details</H5>
        <ValidatedInputText
          {...rest}
          label="First name"
          name="firstName"
          type={TYPE_TEXT}
          testId="first-name"
          validation={{
            check: validateName,
            errorClient: "Please enter a first name",
          }}
          value={firstName}
        />
        <ValidatedInputText
          {...rest}
          label="Last name"
          name="lastName"
          testId="last-name"
          type={TYPE_TEXT}
          validation={{
            check: validateName,
            errorClient: "Please enter a last name",
          }}
          value={lastName}
        />
        <ValidatedInputText
          {...rest}
          label="Email"
          name="email"
          testId="email"
          type={TYPE_EMAIL}
          validation={{
            check: TYPE_EMAIL,
            errorClient: "Please enter a valid email",
          }}
          value={email}
          // @ts-ignore
          isEnabled={!isOwner && !isLicensee}
        />
        <ValidatedInputText
          {...rest}
          label="Mobile"
          name="mobile"
          testId="mobile"
          type={TYPE_TEXT}
          validation={{
            check: () => true,
            errorClient: "Not required",
          }}
          value={mobile}
        />
        {(isOwner || isLicensee) && <H5>Notifications</H5>}
        <GridRow>
          {(isOwner || isLicensee) && (
            <div>
              <Body bold>Bookings</Body>
              <ValidatedToggle
                {...rest}
                label="Created"
                name="bookingNotificationsEnabled"
                testId="booking-notification"
                value={bookingNotificationsEnabled}
              />
              <ValidatedToggle
                {...rest}
                label="Changed"
                name="bookingChangeNotificationsEnabled"
                testId="booking-notification-1"
                value={bookingChangeNotificationsEnabled}
              />
              <ValidatedToggle
                {...rest}
                label="Cancelled"
                name="bookingCancellationNotificationsEnabled"
                testId="booking-notification-2"
                value={bookingCancellationNotificationsEnabled}
              />
            </div>
          )}
          {isLicensee && (
            <div>
              <Body bold>Owner stays</Body>
              <ValidatedToggle
                {...rest}
                label="Created"
                name="ownerStayNotificationsEnabled"
                testId="booking-notification-3"
                value={ownerStayNotificationsEnabled}
              />
              <ValidatedToggle
                {...rest}
                label="Changed"
                name="ownerStayChangeNotificationsEnabled"
                testId="booking-notification-4"
                value={ownerStayChangeNotificationsEnabled}
              />
              <ValidatedToggle
                {...rest}
                label="Cancelled"
                name="ownerStayCancellationNotificationsEnabled"
                testId="booking-notification-5"
                value={ownerStayCancellationNotificationsEnabled}
              />
            </div>
          )}
        </GridRow>
        <WrapButton>
          <Button
            isEnabled={this.getValidation()}
            // @ts-ignore
            label="Save"
            onClick={this.checkSubmit}
            styling={BUTTON_CENTER}
            testId="submit"
          />
        </WrapButton>
      </div>
    );
  }

  renderSuccess(mode: string) {
    window.scroll(0, 0);
    return (
      <div>
        <H5>Your info has been updated</H5>
        <div>
          <Button
            label="All done"
            onClick={this.editComplete}
            testId="user-change-success"
          />
        </div>
      </div>
    );
  }

  // @ts-ignore
  render() {
    const {
      canUpdateUser,
      editUserStatus: {
        mode,
        // pending, <- don't use pending... parent component should handle this
        success,
      },
    } = this.props;

    if (!mode) {
      return null; // editUserStatus.mode - this is before UserAdd or UserEdit has called reset.
    }

    if (success && mode === UPDATE) {
      return this.renderSuccess(mode);
    }
    if (canUpdateUser) {
      return <div>{this.renderForm(mode)}</div>;
    }
    return <></>;
  }
}

export default EditSettings;
export { getDefaults };

import { CALENDAR_BLOCK, DISMISS_ERRORS } from "Constants/actionTypes";

const initialState = {
  error: false,
  errorCode: 0,
  errorDetails: null,
  errorMessage: "",
  mode: null,
  pending: false,
  success: false,
};
export function propertyBlocks(state = initialState, action: {[key: string]: any}) {
  switch (action.type) {
    case CALENDAR_BLOCK.SUCCESS:
      return {
        ...state,
        mode: action.mode,
        pending: false,
        success: true,
      };
    case CALENDAR_BLOCK.PENDING:
      return { ...state, pending: action.status };
    case CALENDAR_BLOCK.ERROR:
      return {
        ...state,
        error: true,
        errorCode: action.errorCode,
        errorDetails: action.errorDetails,
        errorMessage: action.errorMessage,
        pending: false,
      };
    case DISMISS_ERRORS:
      return {
        ...state,
        error: false,
        errorCode: 0,
        errorDetails: null,
        errorMessage: "",
      };
    case CALENDAR_BLOCK.RESET:
      return initialState;
    default:
      return state;
  }
}

import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { debounce } from "lodash";

import { Body, H4 } from "Components/Typography";

import Down from "../assets/chevron-down.svg";
import Folder from "../assets/folder.svg";
import Loading from "Components/Loading";
import Right from "../assets/chevron-right.svg";

import { documentsRestore } from "Actions/resources";
import { formatDateWithFullYear } from "Utils";
import { FileItem } from "./types";
import { parseData } from "./parse";
import { InputText, TYPE_TEXT } from "@madecomfy/webooi";
import { ButtonCopy } from "../components/ButtonCopy";
import { ButtonDownload } from "../components/ButtonDownload";
import { ButtonExternal } from "../components/ButtonExternal";

const Wrapper = styled.div`
  padding-top: 32px;
`;
const Container = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.background.white};
  border-radius: 8px;
  color: ${({ theme }) => theme.text.default};
  margin-top: 24px;
  padding: 16px;
  > div > div {
    &:last-child {
      border-bottom: none;
      border-radius: 0 0 8px 8px;
    }
  }
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: 4fr 2fr 2fr 2fr minmax(112px, 1fr);
  padding: 0 16px 16px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.border.default};
  font-family: InterMedium;
`;

const Row = styled.div<{ level: number; isOpen: boolean }>`
  display: grid;
  grid-template-columns: 4fr 2fr 2fr 2fr minmax(112px, 1fr);
  padding: 8px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.border.default};
  align-items: center;
  cursor: pointer;
  min-height: 49px;
  background-color: ${({ isOpen, theme }) =>
    isOpen ? theme.background.subtle3 : theme.background.white};

  & > div {
    &:first-child {
      padding-left: ${(props) => props.level * 24}px;
    }
  }
  div[data-test="file-actions"] {
    display: none;
  }
  &:hover {
    background: #f2fbfc;
    div[data-test="file-actions"] {
      display: flex;
    }
  }
`;

const Cell = styled.div``;
const CellSubtle = styled.div`
  color: ${({ theme }) => theme.text.subtle};
`;

const AccordionContainer = styled.div`
  width: 100%;
  font-size: 16px;
`;

const FolderImg = styled.img`
  margin: 0 8px;
`;
const NoDocuments = styled(Body)`
  padding: 24px 24px 16px 24px;
`;
const Type = styled.span`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  background: ${({ theme }) => theme.background.accentGreenSubtle};
  border-radius: 24px;
  text-transform: uppercase;
  font-family: "InterMedium";
  font-size: 12px;
  width: fit-content;
`;

const InputWrapper = styled.div`
  position: relative;
  width: 350px;
  input {
    padding-right: 48px;
  }
  img {
    position: absolute;
    top: 12px;
    right: 12px;
    @media (min-width: ${({ theme }) => theme.minTablet}) {
      top: 35px;
    }
  }
  @media (max-width: ${({ theme }) => theme.maxDesktop}) {
    width: auto;
  }
`;

const ButtonsWrap = styled.div`
  align-items: center;
  gap: 8px;
`;
const ButtonWrap = styled.div`
  div[data-test="copy-button"] {
    position: relative;
    width: fit-content;
  }
  div[data-test="copy-box"] {
    position: relative;
    background-color: transparent;
    border-radius: 4px;
    &:hover {
      background: ${({ theme }) => theme.background.whiteHover};
    }
  }
  span[data-test="copied-popup"] {
    @media (min-width: ${({ theme }) => theme.maxTablet}) {
      white-space: nowrap;
    }
    bottom: 34px;
    left: 50%;
    transform: translate(-50%);
  }
  div[data-test="external-button"] {
    background-color: transparent;
    &:hover {
      background: ${({ theme }) => theme.background.whiteHover};
    }
  }
`;

interface IProps {
  isAllView?: boolean;
}

const NestedAccordion: React.FC<{
  items: FileItem[];
  level?: number;
  isSearching?: boolean;
}> = ({ items, level = 0, isSearching }) => {
  const [openSections, setOpenSections] = useState<{ [key: number]: boolean }>(
    {},
  );

  const toggleSection = (index: number) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  useEffect(() => {
    if (items?.length > 0) {
      const newOpenOptions = items.reduce<{ [key: number]: boolean }>(
        (acc, _, index) => {
          acc[index] = Boolean(isSearching);
          return acc;
        },
        {},
      );
      setOpenSections(newOpenOptions);
    }
  }, [isSearching]);

  return (
    <AccordionContainer>
      {items.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <Row
              isOpen={(openSections[index] && item?.isFolder) || false}
              level={level}
              onClick={() => {
                item.children && toggleSection(index);
                !item?.isFolder && !!item?.url && window.open(item.url);
              }}
            >
              <Cell>
                {item.children && item.children.length > 0 ? (
                  <>
                    {openSections[index] ? (
                      <img src={Down} />
                    ) : (
                      <img src={Right} />
                    )}
                    <FolderImg src={Folder} />
                  </>
                ) : null}{" "}
                {item?.isFolder
                  ? item?.resourceName
                  : item?.resourceName.slice(
                      0,
                      item?.resourceName.lastIndexOf("."),
                    )}
              </Cell>
              <Cell>
                {item?.resourceType ? (
                  <Type>
                    {item?.resourceName.slice(
                      item?.resourceName.lastIndexOf(".") + 1,
                    )}
                  </Type>
                ) : (
                  ""
                )}
              </Cell>
              <CellSubtle>
                {item.lastModified
                  ? formatDateWithFullYear(item.lastModified)
                  : ""}
              </CellSubtle>
              <CellSubtle>{item.fileSize}</CellSubtle>
              <Cell>
                {!item?.isFolder && !!item?.url && (
                  <ButtonsWrap data-test="file-actions">
                    <ButtonWrap>
                      <ButtonCopy url={item.url} />
                    </ButtonWrap>
                    <ButtonDownload url={item?.url} name={item?.resourceName} />
                    {item?.resourceType === "pdf" && (
                      <ButtonWrap>
                        <ButtonExternal url={item.url} />
                      </ButtonWrap>
                    )}
                  </ButtonsWrap>
                )}
              </Cell>
            </Row>
            {openSections[index] &&
              item.children &&
              item.children.length > 0 && (
                <NestedAccordion
                  isSearching={isSearching}
                  items={item.children}
                  level={level + 1}
                />
              )}
          </React.Fragment>
        );
      })}
    </AccordionContainer>
  );
};

const Documents: React.FC<IProps> = (props) => {
  const { isAllView } = props;
  const [data, setData] = useState<FileItem | null>(null);
  const [keywordSearch, setKeywordSearch] = useState<string>("");
  const [searchValue, setSearchValue] = useState<FileItem | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await documentsRestore();
      const parsedData = parseData(response);
      setData(parsedData);
      setSearchValue(parsedData);
    };

    fetchData();
  }, []);

  const searchFiles = (folders: FileItem[], search: string) => {
    const filterFoldersWithFiles = (node: FileItem, searchKeyword: string) => {
      const isMatchedKeyword = node?.resourceName
        ?.toLowerCase()
        .includes(searchKeyword.toLowerCase());
      if (!node.isFolder && isMatchedKeyword) {
        return node; // If the node is a file and matches the search keyword, keep this node
      }

      if (node.isFolder) {
        // Recursively filter the children
        const filteredChildren = node?.children
          ?.map((child) => filterFoldersWithFiles(child, searchKeyword))
          ?.filter(Boolean) as any[];

        if (filteredChildren?.length > 0) {
          return {
            ...node,
            children: filteredChildren,
          };
        }
      }

      return null; // Remove folder has no children after filtering
    };

    return folders
      .map((folder) => filterFoldersWithFiles(folder, search))
      .filter(Boolean);
  };

  const handleSearch = useCallback(
    debounce((text: string) => {
      setKeywordSearch(text);
      const fileItems = data?.children;
      if (fileItems) {
        const newItems = searchFiles(fileItems, text) as FileItem[];
        setSearchValue({ ...data, children: newItems });
      }
    }, 200),
    [data],
  );

  if (!searchValue) return <Loading task="documents" />;
  return (
    <Wrapper>
      <H4>Documents</H4>
      {!isAllView && (
        <InputWrapper>
          <InputText
            name="searchTerm"
            placeholder="Search for documents"
            testId="documents-search"
            type={TYPE_TEXT}
            sendValue={({ value }: any) => handleSearch(value)}
          />
        </InputWrapper>
      )}
      <Container>
        <Header>
          <Cell>Resource name</Cell>
          <Cell>Resource type</Cell>
          <Cell>Last modified</Cell>
          <Cell>File size</Cell>
        </Header>
        {searchValue?.children && searchValue?.children?.length > 0 ? (
          <NestedAccordion
            isSearching={!!keywordSearch}
            items={searchValue.children || []}
          />
        ) : (
          <NoDocuments center>
            {keywordSearch
              ? "There are no documents matching your search"
              : "There are no documents available yet"}
          </NoDocuments>
        )}
      </Container>
    </Wrapper>
  );
};

export default Documents;

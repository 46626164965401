import { API_URL } from "Config";
import { BAD_REQUEST, FORBIDDEN, generateErrorHandler, NetworkError, UNPROCESSABLE_ENTITY } from "Utils/Network";

const handleError = generateErrorHandler("NEWSLETTER_DELETE_ERROR");

export const newsletterDelete = async (newsletterUUID: string) => {
    try {
      const response = await fetch(`${API_URL}v3/newsletters/${newsletterUUID}`, {
        credentials: "include",
        method: "DELETE",
      });
      if (response.status === BAD_REQUEST) {
        return response.json();
      }
      if (response.status === UNPROCESSABLE_ENTITY) {
        return {
            errors: [
              {
                detail: "Sorry, there is an error",
                title: String(UNPROCESSABLE_ENTITY),
              },
            ],
          };
      }
      if (response.status === FORBIDDEN) {
        return {
          errors: [
            {
              detail: "Sorry, you do not have permission to delete",
              title: String(FORBIDDEN),
            },
          ],
        };
      }
      if (!response.ok) {
        throw new NetworkError(response);
      }
      return true;
    } catch (err: any) {
      return handleError(err);
    }
  };

import {
  BAD_REQUEST,
  FORBIDDEN,
  generateURL,
  getJSON,
  NetworkError,
} from "Utils/Network";
import { API_URL } from "Config";

export const propertyEventsRestore = async ({
  propertyId,
  startDate,
  endDate,
}: {[key: string]: any}) => {
  const url = generateURL(`${API_URL}v3/properties/${propertyId}/events`, {
    from: startDate,
    to: endDate,
  });
  const json = await getJSON(url);
  return json.data;
};

export const propertyEventCreate = async (propertyId: string, params: {[key: string]: any}) => {
  const response = await fetch(`${API_URL}v3/properties/${propertyId}/events`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(params),
  });
  if (response.status === BAD_REQUEST) {
    return response.json();
  }
  if (response.status === FORBIDDEN) {
    return {
      errors: [
        {
          detail:
            "Sorry, you do not have permission to create a property event",
          title: String(FORBIDDEN),
        },
      ],
    };
  }
  if (!response.ok) {
    throw new NetworkError(response);
  }
  const json = await response.json();
  return json;
};

export const propertyEventUpdate = async (eventId: string | number, params: {[key: string]: any}) => {
  const response = await fetch(`${API_URL}v3/property-events/${eventId}`, {
    credentials: "include",
    method: "PATCH",
    body: JSON.stringify(params),
  });
  if (response.status === BAD_REQUEST) {
    return response.json();
  }
  if (response.status === FORBIDDEN) {
    return {
      errors: [
        {
          detail:
            "Sorry, you do not have permission to update a property event",
          title: String(FORBIDDEN),
        },
      ],
    };
  }
  if (!response.ok) {
    throw new NetworkError(response);
  }
  const json = await response.json();
  return json;
};

export const propertyEventDelete = async (eventId: string | number) => {
  const response = await fetch(`${API_URL}v3/property-events/${eventId}`, {
    credentials: "include",
    method: "DELETE",
  });
  if (response.status === BAD_REQUEST) {
    return response.json();
  }
  if (response.status === FORBIDDEN) {
    return {
      errors: [
        {
          detail:
            "Sorry, you do not have permission to delete a property event",
          title: String(FORBIDDEN),
        },
      ],
    };
  }
  if (!response.ok) {
    throw new NetworkError(response);
  }
  return true;
};

import { EVENT_SEARCH } from "Constants/actionTypes";
const initialState = {
  error: false,
  errorCode: 0,
  errorMessage: "",
  meta: {},
  pending: false,
  success: false,
  results: [],
};
export function searchEvents(state = initialState, action: {[key: string]: any}) {
  switch (action.type) {
    case EVENT_SEARCH.SUCCESS:
      return {
        ...state,
        pending: false,
        meta: action.meta, // TODO temp?
        results: action.results,
        success: true,
      };
    case EVENT_SEARCH.PENDING:
      return { ...state, pending: action.status, results: [] };
    case EVENT_SEARCH.ERROR:
      return {
        ...state,
        error: true,
        errorMessage: action.errorMessage,
        errorCode: action.errorCode,
        pending: false,
      };
    case EVENT_SEARCH.RESET:
      return initialState;
    default:
      return state;
  }
}

import React from "react";
import styled from "styled-components";

import { Button } from "@madecomfy/webooi";

import ComfyContainer from "Components/ComfyContainer";
import { H2, Body } from "Components/Typography";

const WrapperText = styled(Body)`
  margin: 30px auto;
  max-width: 400px;
`;

const WrapperButton = styled.div`
  margin: 0 auto;
  max-width: 200px;
`;

const NoProperty = () => {
  return (
    <ComfyContainer>
      <H2 center>Onboarding in progress!</H2>
      <WrapperText>
        We are working to get your property onboarded as soon as possible.
      </WrapperText>
      <WrapperText>Get ready for the bookings to start coming in!</WrapperText>
      <WrapperText>
        In the meantime, take a look at our FAQ for useful articles about our
        services.
      </WrapperText>
      <WrapperText>
        If you have any questions, please contact the onboarding team at{" "}
        <a href="mailto:support@madecomfy.com.au">support@madecomfy.com.au</a>{" "}
        or call us on <a href="tel:02-8520-3106">02 8520 3106</a>.
      </WrapperText>
      <WrapperButton>
        <Button
          onClick={() =>
            window.open(
              "https://helpcentre.madecomfy.com.au/s/topic/0TO5g000000TmxZGAS/madecomfy-dashboard",
            )
          }
          label="Go to articles"
        />
      </WrapperButton>
    </ComfyContainer>
  );
};

export default NoProperty;

import All from "./All";
import Documents from "./Documents";
import Forms from "./Forms";
import Newsletter from "./Newsletter";
import { ROLE } from "Constants/userRoles";

const { ADMIN, STAFF, LICENSEE, MARKETING } = ROLE;

export default [
  {
    id: "all",
    label: "All",
    component: All,
    requiredRoles: [ADMIN, STAFF, LICENSEE, MARKETING],
  },
  {
    id: "documents",
    label: "Documents",
    component: Documents,
    requiredRoles: [ADMIN, STAFF, LICENSEE, MARKETING],
  },
  {
    id: "forms",
    label: "Forms",
    component: Forms,
    requiredRoles: [LICENSEE],
  },
  {
    id: "newsletters",
    label: "Newsletters",
    component: Newsletter,
    requiredRoles: [ADMIN, STAFF, LICENSEE, MARKETING],
  },
];

import React, { Component } from "react";
import styled from "styled-components";

import BreadCrumb from "Components/BreadCrumb/BreadCrumb";
import CircleBadge from "Components/CircleBadge";
import ChannelAccountCreate from "./ChannelAccountCreate";
import StatusPill from "Components/StatusPill";
import { Body, H4 } from "Components/Typography";

const Account = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.sand91};
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  padding: 16px 24px;
`;
const AccountBadge = styled.div`
  align-items: flex-end;
  display: flex;
  margin-bottom: 16px;
`;
const AccountsHeader = styled.div`
  align-items: baseline;
  display: flex;
  h4 {
    margin-right: 32px;
  }
`;
const AccountsWrapper = styled.div``;

const ChannelBadge = styled(CircleBadge)`
  filter: drop-shadow(0px 1px 5px rgba(53, 55, 60, 0.1));
  margin: 0 16px 0 0;
`;
const LineSpacer = styled.div`
  border: 0.5px solid ${({ theme }) => theme.charcoalLight10};
  margin: 32px 0;
`;
const Wrapper = styled.div`
  margin: 24px;
`;

interface IProps {
  channelId: string;
  channelData: any;
}
class ChannelAccounts extends Component<IProps, any> {
  render() {
    const { channelId, channelData } = this.props;
    const { accounts, channels } = channelData;
    const currentChannelAccounts = accounts.find(
      ({ id }: any) => id === channelId,
    );
    const currentChannel = channels.find(({ id }: any) => id === channelId);
    if (!currentChannelAccounts) return null;
    return (
      <Wrapper>
        <BreadCrumb
          items={[{ to: "/channels", label: "Channels" }]}
          staticText={currentChannel.name}
        />
        <AccountBadge>
          <ChannelBadge externalSource={currentChannel.id} size={40} />
          <H4>{currentChannel.name}</H4>
        </AccountBadge>
        <ChannelAccountCreate externalLink={currentChannel.setupUrl} />
        <LineSpacer />
        <AccountsWrapper>
          <AccountsHeader>
            <H4>Accounts</H4>
          </AccountsHeader>
          {currentChannelAccounts.accountsData &&
            currentChannelAccounts.accountsData.map(
              ({ email, name, status }: any, i: number) => {
                return (
                  <Account data-test="channel-account" key={i}>
                    <div>
                      <Body>{name}</Body>
                      <Body color="theme.charcoalLight50">{email}</Body>
                    </div>
                    <StatusPill status={status} />
                  </Account>
                );
              },
            )}
        </AccountsWrapper>
      </Wrapper>
    );
  }
}

export default ChannelAccounts;

import get from "lodash/get";

import { API_URL } from "Config";
import { BAD_REQUEST, getJSON,
  generateErrorHandler,
  INTERNAL_SERVER_ERROR,
  UNPROCESSABLE_ENTITY } from "Utils/Network";

  const handleError = generateErrorHandler("PROPERTIES_COUNT_ERROR");

export const propertiesCountRestore = async () => {
  try {
  let json = await getJSON(`${API_URL}v3/search?page[size]=1&filters[type]=property&filters[query]=&version=2`, {
    errorCodes: [BAD_REQUEST, UNPROCESSABLE_ENTITY, INTERNAL_SERVER_ERROR],
  });
  const count = get(json, "meta.totalCount");
  if (count !== 1) {
    return { count, first: null };
  }
  // if total is 1 we need to get its Id so we can redirect to it.
  const first = get(json, "data[0].id", null);
  return { count, first };
} catch (err) {
  // eslint-disable-next-line no-console
  console.warn("properties err", err);
  return handleError(err);
}
};

import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const WrapperBreadCrumb = styled.div<{ showLabel: boolean }>`
  cursor: default;
  display: flex;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.text.subtle};
  margin-bottom: 24px;
  a {
    color: ${({ showLabel, theme }) =>
      showLabel ? theme.text.subtle : theme.text.default};
    text-transform: capitalize;
    cursor: ${({ showLabel }) => (showLabel ? "default" : "pointer")};
    ${({ showLabel }) =>
      !showLabel &&
      css`
        &:after {
          color: ${({ theme }) => theme.text.subtle};
          content: "/";
          padding: 0 4px;
        }
      `}
  }
  span {
    text-transform: capitalize;
  }
`;

interface IProps {
  items: any[];
  staticText: string;
  setActive?: Function;
}
const BreadCrumb: React.FC<IProps> = ({ items, staticText, setActive }) => {
  const showLabel = items.some((item) => item?.within === staticText);
  return (
    <WrapperBreadCrumb showLabel={showLabel}>
      {items.map(({ to, label, staticLabel, within }, i) => (
        <div key={i}>
          {staticLabel && <span>{staticLabel}</span>}
          {to ? (
            <Link key={to} to={to}>
              {label}
            </Link>
          ) : within && setActive ? (
            <a onClick={() => setActive(within)}>{label}</a>
          ) : (
            <span>{label}</span>
          )}
        </div>
      ))}
      {!showLabel && <span>{staticText}</span>}
    </WrapperBreadCrumb>
  );
};

export default BreadCrumb;

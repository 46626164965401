import { connect } from "react-redux";
import Header from "./Header";

const mapStateToProps = (state: any) => ({
  user: state.authentication.sessionStatus.user,
});

const HeaderConnected = connect(mapStateToProps)(Header);

export default HeaderConnected;

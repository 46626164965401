import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import { logout } from "Actions/authentication/logout";
import { connect } from "react-redux";

class Logout extends Component<any, any> {
  state = {
    redirect: false,
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(logout()).then(() => {
      this.setState({
        redirect: true,
      });
    });
  }

  render() {
    return this.state.redirect ? (
      <Redirect to="/login" />
    ) : (
      <div hidden>Logout loading...</div>
    );
  }
}

export default connect()(Logout);

import { API_URL } from "Config";
import { CHANNEL_DATA } from "Constants/actionTypes";
import { generateErrorHandler, getJSON } from "Utils/Network";

const handleError = generateErrorHandler(CHANNEL_DATA.ERROR);

export const channelsRestore = () => {
  return async (dispatch: (...args: any) => any) => {
    try {
      dispatch({ type: CHANNEL_DATA.PENDING, status: true });
      const json = await getJSON(`${API_URL}v3/channels?include=accounts`);
      const { data, included } = json;
      const channels = data.map((item: {[key: string]: any}) => {
        const { attributes, id } = item;
        return {
          ...attributes,
          id,
        };
      });
      const accounts = data.map((item: {[key: string]: any}) => {
        const { id, relationships } = item;
        const accountsData = relationships.accounts.data;
        if (accountsData.length) {
          const accountDetails = accountsData.map((account: {[key: string]: any}) => {
            const includedData = included.find(({ id }: {[key: string]: any}) => id === account.id);
            return { code: includedData.id, ...includedData.attributes };
          });
          return {
            accountsData: accountDetails,
            id,
          };
        }
        return { accountsData: [], id };
      });
      dispatch({ type: CHANNEL_DATA.SUCCESS, accounts, channels });
    } catch (err: any) {
      // eslint-disable-next-line
      console.warn("channelsRestore err", err);
      dispatch(handleError(err));
    }
  };
};

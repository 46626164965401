import { CURRENT_PROPERTY } from "Constants/actionTypes";
import { CREATE, UPDATE } from "Constants/crud";

const initialState = {
  currentProperty: null,
  error: false,
  errorCode: 0,
  errorDetails: "",
  errorMessage: "",
  errorTitle: "",
  meta: "",
  mode: null,
  pending: false,
  success: false,
};
export function currentPropertyStatus(state = initialState, action: {[key: string]: any}) {
  const {
    errorMessage,
    errorTitle,
    errorCode,
    errorDetails,
    meta,
    mode,
    status,
    type,
  } = action;
  switch (type) {
    case CURRENT_PROPERTY.SUCCESS:
      let currentProperty;
      if (mode === UPDATE) {
        // if update, mix the new with the old.
        currentProperty = {
          ...state.currentProperty as any, //  old data
          ...action.currentProperty, // new data
        };
      } else if (mode === CREATE) {
        // this is currently only used for updating property channels
        // if create, add/update the object in the array by finding its key
        currentProperty = { ...state.currentProperty as any };
        const key = Object.keys(action.currentProperty)[0];
        const index = currentProperty[key].findIndex(
          ({ id }: { id: string | number }) => id === action.currentProperty[key].id,
        );
        if (index === -1) {
          currentProperty[key].unshift(action.currentProperty[key]); // adds an object to the start of the existing array if the id does not exist
        } else {
          currentProperty[key][index] = action.currentProperty[key]; // updates the object in the existing array by finding its id
        }
      } else {
        currentProperty = action.currentProperty; // otherwise it is a RESTORE, replace it with the response
      }
      return { ...state, currentProperty, mode, pending: false, success: true };
    case CURRENT_PROPERTY.PENDING:
      return { ...state, mode, pending: status };
    case CURRENT_PROPERTY.ERROR:
      return {
        ...state,
        error: true,
        errorMessage,
        errorTitle,
        errorCode,
        errorDetails,
        meta,
        mode,
        pending: false,
      };
    case CURRENT_PROPERTY.RESET:
      return initialState;
    default:
      return state;
  }
}

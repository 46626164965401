import { DocumentTypes, ContentTypes, FileItem } from "./types";
import {
    formatBytes,
  } from "Actions/properties/propertyPhotos";

export const parseData = (data: DocumentTypes): FileItem => {
  const parseContents = (contents: ContentTypes[]): FileItem[] => {
    return contents.map((content) => ({
      resourceName: content.name,
      resourceType: content.fileType ?? "",
      lastModified: content.modifiedAt ?? "",
      fileSize: content.fileSize ? formatBytes(content.fileSize, 0) : "-",
      children: content.isFolder && content.contents ? parseContents(content.contents) : [],
      isFolder: content?.isFolder,
      url: content?.url,
      modifiedAt: content?.modifiedAt,
    }));
  };

  const root = data?.attributes;
  return {
    resourceName: root.name,
    resourceType: "",
    lastModified: "",
    fileSize: "",
    children: parseContents(root.contents),
  };
};

/*

High Order Component to add click outside functionality.

Wrap a component in this to detect if subsequent clicks occur within the container element.

This works with components:
- that mount/unmount on demand needed - if so always use property `isDisplayed` set as `true`
- that are always mounted - if so use property `isDisplayed` set as `false`/`true` as needed

*/

import React, { useEffect, useRef } from "react";

interface IProps {
  isDisplayed: boolean;
  onClickOutside: (event: Event) => void;
}

const withClickOutside = function <I extends object> (WrappedComponent: React.ComponentType<I>) {
  const ClickOutside = (props: IProps & I) => {
    const { isDisplayed, onClickOutside } = props;
    let element: any;
    const elementRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
      element = elementRef.current;
    }, [isDisplayed]);
    useEffect(() => {
      document.addEventListener("click", handleClickOutside);
      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }, [isDisplayed]);

    const handleClickOutside = (e: Event) => {
      if (!isDisplayed) {
        // WrappedComponent is not open
        return;
      }
      if (!element?.contains) {
        // if element.contains is not a function, return so we don't accidentally close a panel.
        // all panels that are closable *should* also contain a close button!
        return;
      }
      if (element.contains(e.target)) {
        // click is within dropown hit area
        return;
      }
      // user has clicked outside WrappedComponent, let's close it!
      e.stopPropagation();
      onClickOutside(e);
    };

    // this HOC needs a div to detect if the click outside event occurs...
    // the className is added for clarity within the DOM
    return (
      <div ref={elementRef} className="withClickOutside">
        <WrappedComponent {...props} />
      </div>
    );
  };

  return ClickOutside;
};

export default withClickOutside;

import debounce from "lodash/debounce";
import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { Button, BUTTON_INLINE, InputText, TYPE_TEXT } from "@madecomfy/webooi";

import { ContainerWrapper } from "Components/ComfyContainer";
import { H4 } from "Components/Typography";
import UserList from "./components/UserList";
import search from "./assets/search.svg";

const InputWrapper = styled.div`
  position: relative;
  width: 350px;
  input {
    padding-right: 48px;
  }
  img {
    position: absolute;
    top: 12px;
    right: 12px;
    @media (min-width: ${({ theme }) => theme.minTablet}) {
      top: 35px;
    }
  }
  @media (max-width: ${({ theme }) => theme.maxDesktop}) {
    width: auto;
  }
`;
const Wrapper = styled.div`
  @media (min-width: ${({ theme }) => theme.minTablet}) {
    display: flex;
    justify-content: space-between;
  }
`;
const WrapperList = styled.div`
  overflow-x: auto;
`;

interface IProps {
  usersRestore: (...args: any) => any;
  userHistory: {
    connectionError: boolean;
    error: boolean;
    errorMessage: string;
    logs: any[];
    pending: boolean;
    success: boolean;
    totalCount?: number;
  };
  userUpdate: Function;
}

const pageSizeOptions = [10, 50, 100];

class UserSearch extends Component<IProps, { [key: string]: any }> {
  fetchSearchDebounced: any;

  constructor(props: IProps) {
    super(props);
    this.state = {
      pageIndex: 1,
      pageSize: pageSizeOptions[0],
      searchTerm: "",
    };
    this.fetchSearchDebounced = debounce(this.fetchSearch, 400);
  }

  componentDidMount() {
    this.fetchPage(1);
  }

  fetchPage(pageIndex: number) {
    const { usersRestore } = this.props;
    const { pageSize, searchTerm } = this.state;
    usersRestore(pageIndex, pageSize, searchTerm);
  }

  searchUser = (searchTerm: string) => {
    this.setState({ searchTerm }, () => {
      this.fetchSearchDebounced(searchTerm);
    });
  };

  fetchSearch() {
    this.fetchPage(1);
  }

  render() {
    const {
      connectionError,
      error,
      errorMessage,
      logs: data,
      pending,
      success,
      totalCount,
    } = this.props.userHistory;
    const { pageSize } = this.state;
    return (
      <ContainerWrapper>
        <H4>User Search</H4>
        <Wrapper>
          <InputWrapper>
            <InputText
              name="searchTerm"
              placeholder="e.g. email address, name"
              testId="user-search"
              type={TYPE_TEXT}
              sendValue={({ value }: any) => this.searchUser(value)}
            />
            <img src={search} />
          </InputWrapper>
          <Link to="/users/create">
            <Button icon="plus" label="Add User" styling={BUTTON_INLINE} />
          </Link>
        </Wrapper>
        <WrapperList data-test="table-list">
          {error && errorMessage}
          {connectionError && errorMessage}
          {success && (
            <UserList
              data={data}
              loading={pending}
              onPageChange={(pageIndex: number) => {
                this.setState({ pageIndex }, () => {
                  this.fetchPage(pageIndex);
                });
              }}
              // onPageSizeChange={(pageSize: number, pageIndex: number) => {
              //   this.setState({ pageSize }, () => {
              //     this.fetchPage(pageIndex);
              //   });
              // }}
              // onSearch={(text) => this.searchUser(text)}
              pages={totalCount}
              pageSize={pageSize}
              //  pageSizeOptions={pageSizeOptions}
              userUpdate={this.props.userUpdate}
            />
          )}
        </WrapperList>
      </ContainerWrapper>
    );
  }
}

export default UserSearch;

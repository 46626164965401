// /src/Utils/date has a dayjs version of this function: ymd
export const dayYYYYMMDD = (date: string | Date) => {
  if (typeof date === "string") {
    return `${date.substring(0, 4)}-${date.substring(5, 7)}-${date.substring(8, 10)}`;
  }
  return [
    date.getFullYear(),
    String(date.getMonth() + 1).padStart(2, "0"),
    String(date.getDate()).padStart(2, "0"),
  ].join("-");
};

export const dayDDMMYYYY = (date: string | Date) => {
  if (typeof date === "string") {
    return `${date.substring(8, 10)}/${date.substring(5, 7)}/${date.substring(0, 4)}`;
  }
  return [
    String(date.getDate()).padStart(2, "0"),
    String(date.getMonth() + 1).padStart(2, "0"),
    date.getFullYear(),
  ].join("/");
};

export const monthLabel = (monthIndex: number) => {
  return [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][monthIndex];
};

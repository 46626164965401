/*

High Order Component to extract query string into an object.

Also contains an extension of react-router's Route component named `RouteQuery`.

Wrap a component `withRouter` and then with this HOC.

requires first argument to be a function that returns an array of params to extract.
each `param` must be an object of `{key, defaultValue, type}`.

- `key` is a string.
- `defaultValue` is optional but must be either a value of the correct type or a function that returns a value of the correct type.
- `type` will cast the value to that type. the following types are supported: "string", "number", "boolean".

eg.

```
const Wrappee = ({day, month, year}) => {
  return <div>{year}-{month}-{day}</div>
}

const Example = withRouter(
  withURLQuery(() => [
    { key: "day", defaultValue: 0, type: "number" },
    { key: "month", type: "number" },
    { key: "year", defaultValue: () => new Date().getFullYear(), type: "number" },
    { key: "bookingId", type: "string" },
  ])(Wrappee),
);
```
*/

import React from "react";
import { Route as RouteOriginal } from "react-router-dom";

const cast = (type: any, value: string) => {
  switch (type) {
    case "number":
      const n = Number(value);
      if (Number.isNaN(n)) {
        return undefined;
      }
      return n;
    case "boolean":
      return Boolean(value);
    // it will always be a string by default.
    // case "string": return String(value);
    default:
      // throw new Error("unsupported type", type, value);
      throw new Error("unsupported type", type);
  }
};

const withURLQuery = (fnParamsArray: (...args: any) => any) => (WrappedComponent: any) => {
  const queryWrapper = (props: { location: {[key: string]: any} }) => {
    const { location } = props;
    const values = new URLSearchParams(location.search);
    const params = fnParamsArray().reduce((acc: any, param: {[key: string]: any}) => {
      const { key, defaultValue, type } = param;
      const value = values.get(key);
      const typeOf = typeof value;
      // console.log(typeOf);

      if (value !== "undefined" && value !== null && typeOf !== type) {
        const typedValue = cast(type, value);
        if (typeof typedValue !== "undefined") {
          // console.log("casting to", type, key, value);
          return { ...acc, [key]: typedValue };
        } else {
          // attempted to cast, but still no good.
          // console.log("could not cast to", type, key, value);
        }
      }

      if (typeOf === type) {
        // console.log("leaving as is", key, value);
        return { ...acc, [key]: value };
      }

      // no value found, assign a default value.
      if (typeof defaultValue === "function") {
        // console.log("calling defaultValue()", key, value);
        return { ...acc, [key]: defaultValue() };
      }

      if (typeof defaultValue !== "undefined") {
        // console.log("returning defaultValue", key, value);
        return { ...acc, [key]: defaultValue };
      }

      // console.log("omitting", key, value);
      // param not found, omit it.
      return acc;
    }, {});

    return <WrappedComponent {...props} {...params} />;
  };

  return queryWrapper;
};

export default withURLQuery;

// add a RouteQuery (instead of Route) to a router and you will receive search (`?param=value`) as an object (`{param: "value"}`).

// No types here!

export const RouteQuery = (props: any) => {
  const values = new URLSearchParams(props.location.search);
  const query = {} as any;
  for (const [key, value] of values.entries() as any) {
    query[key] = value;
  }
  props.computedMatch.query = query;
  return <RouteOriginal {...props} />;
};

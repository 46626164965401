export const standardKey = {
  check: (value: any) =>
    Boolean(value) &&
    value.length > 0 &&
    value !== "name" &&
    value === value.toLowerCase() &&
    value === value.replace(/\s/g, ""),
  errorClient:
    "Enter at least 1 character, lowercase and no spaces. Cannot be 'name'",
};

export const notEmpty = {
  check: (value: any) => Boolean(value) && value.length > 0,
  errorClient: "Enter at least 1 character",
};

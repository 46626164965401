import { getJSON } from "Utils/Network";
import { API_URL } from "Config";

export const propertyMerchantAccounts = async () => {
  const json = await getJSON(`${API_URL}v3/merchant-accounts`);
  const { data } = json;
  return data.map((item: {[key: string]: any}) => {
    const { attributes, id } = item;
    return {
      merchantAccountId: id,
      merchantName: attributes.name,
    };
  });
};

export const merchantAccountsBalances = async () => {
  const json = await getJSON(`${API_URL}v3/merchant-accounts/balances`);
  const { data } = json;
  return data.map((item: {[key: string]: any}) => {
    const { attributes, id } = item;
    return {...attributes, id};
  });
};

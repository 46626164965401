import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";

import ChannelsList from "./ChannelsList";
import ChannelAccounts from "./ChannelAccounts";

interface IProps {
  channelsRestore: (...args: any) => any;
  channelData: any;
  history: any;
  match: any;
}
class Channels extends Component<IProps, any> {
  async componentDidMount() {
    const { channelsRestore } = this.props;
    await channelsRestore();
  }
  render() {
    const { channelData, history, match } = this.props;
    const { channels } = channelData;
    const { channelId } = match.params;
    return (
      <Router {...this.props}>
        <Switch>
          <Route
            exact
            path="/channels/:channelId"
            render={() => (
              <ChannelAccounts
                channelData={channelData}
                channelId={channelId}
              />
            )}
          />
          <Route
            exact
            path="/channels"
            render={() => (
              <ChannelsList channels={channels} history={history} />
            )}
          />
        </Switch>
      </Router>
    );
  }
}

export default Channels;

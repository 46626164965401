import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Body, H4 } from "Components/Typography";

import { ButtonCopy } from "./components/ButtonCopy";
import { ButtonExternal } from "./components/ButtonExternal";
import { formsRestore } from "Actions/resources";

const Wrapper = styled.div`
  padding-top: 32px;
  h4 {
    margin-bottom: 24px;
  }
`;
const Container = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 16px;
  align-self: stretch;
  flex-wrap: wrap;
`;
const Card = styled.div`
  background: ${({ theme }) => theme.background.white};
  padding: 16px;
  border-radius: 8px;
  box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.08);
  min-width: 200px;
  cursor: pointer;
  p {
    font-family: InterSemiBold;
    margin: 0;
    padding-bottom: 16px;
  }
  &:hover {
    box-shadow: 1px 1px 24px 0px rgba(0, 0, 0, 0.08);
  }
`;
const Buttons = styled.div`
  display: flex;
  gap: 8px;
`;
interface DataItem {
  id: string;
  title: string;
  url: string;
}

const Forms: React.FC = () => {
  const [data, setData] = useState<DataItem[]>([]);

  useEffect(() => {
    const asyncLoadAvailable = async () => {
      const response = await formsRestore();
      setData(response);
    };
    asyncLoadAvailable();
  }, []);
  return (
    <Wrapper>
      <H4>Forms</H4>
      {data && data.length > 0 ? (
        <Container>
          {data.map(({ title, url }, i) => (
            <Card
              key={i}
              data-test="form-card"
              onClick={() => window.open(url, "_blank")}
            >
              <p>{title}</p>
              <Buttons>
                <ButtonCopy url={url} />
                <ButtonExternal url={url} />
              </Buttons>
            </Card>
          ))}
        </Container>
      ) : (
        <Body>There are no forms available yet</Body>
      )}
    </Wrapper>
  );
};

export default Forms;

import React, { Component } from "react";
import styled, { css } from "styled-components";

import Status from "./Status";
import { DayCell } from "./RowsAndCells";
import { getVacancyFromCalendar, START_DATE, END_DATE } from "./DatePicker";
import { CANCELLED, CANCELLING } from "Constants/bookingTypes";
import { getIconURL } from "Components/CircleBadge/getIcon";
import { isLicensee, isStaff } from "Utils";
import { CleaningTask } from "./CleaningTasks/CleaningTask";
import { IPropertyTask } from "Containers/EventsCalendar/interface/task";

interface IProps {
  active?: { [key: string]: any };
  bookingStatus?: any[];
  calendar: { [key: string]: any };
  day: { [key: string]: any };
  dmy: string;
  eventsMapSingle: any[];
  focus?: string;
  ignoreValidation: boolean;
  isRestricted: boolean;
  isSelected: boolean;
  isToday: boolean;
  mouseOver?: string;
  onDayClick: (...args: any) => any;
  onDayHover: (...args: any) => any;
  outsideMonth?: boolean;
  rate: number;
  singleDate?: boolean;
  timezone?: string;
  viewingMonth: boolean;
  filter?: string;
  pricing?: string;
  userRoles?: string[];
  tasks?: IPropertyTask[];
}

const WrapperRate = styled.div<any>`
  bottom: 4px;
  pointer-events: none;
  position: absolute;
  right: 4px;
  display: flex;
  align-items: flex-end;
  ${({ viewingMonth, theme }) =>
    viewingMonth
      ? css`
          font-size: 14px;
          line-height: 14px;
          bottom: 10px;
          right: 14px;
          font-weight: 400;
        `
      : css`
          font-size: 10px;
          line-height: 12px;
          color: ${theme.charcoalLight50};
        `};
  @media screen and (max-width: ${({ theme }) => theme.maxMobile}) {
    ${({ viewingMonth }) =>
      viewingMonth &&
      css`
        font-size: 10px;
        right: 4px;
        bottom: 4px;
        img {
          display: none;
        }
      `}
    ${({ theme }) => css`
      color: ${theme.charcoalLight50};
    `}
  }
`;

const WrapperDigit = styled.span<any>`
  margin: ${({ viewingMonth }: any) => (viewingMonth ? "2px 14px" : "2px 4px")};
  display: inline-block;
  pointer-events: none;
  color: ${({ theme }) => theme.charcoal15};
  font-size: 14px;
  @media screen and (max-width: ${({ theme }) => theme.maxMobile}) {
    margin: 2px 4px;
  }
`;

const Icon = styled.img`
  width: 14px;
  height: 14px;
  margin-right: 6px;
`;

const CleaningTasks = styled.div`
  left: 4px;
  right: 4px;
  bottom: 4px;
  position: absolute;
  width: calc(100% - 8px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 2px;
`;

const Digit = ({ dmy, viewingMonth }: any) => (
  <WrapperDigit viewingMonth={viewingMonth}>
    {Number(dmy.substring(8, 10))}
  </WrapperDigit>
);

const DayRate = ({ rate, viewingMonth, pricingIconUrl }: any) =>
  rate ? (
    <WrapperRate viewingMonth={viewingMonth}>
      {viewingMonth && <Icon alt="logo-icon" src={pricingIconUrl} />}
      <div>{`$${Math.round(rate / 100)}`}</div>
    </WrapperRate>
  ) : null;

class Day extends Component<IProps> {
  // if this function is needed it will need to be much more detailed then the following attempt:
  /*
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.active !== this.props.active) return true;
    if (nextProps.mouseOver !== this.props.mouseOver) return true;
    return nextProps.dmy !== this.props.dmy;
  }
  */

  hasActiveBookings = (existing: string | any[]) => {
    const activeBookings = [];

    for (let i = 0; i < existing.length; i++) {
      if (
        existing[i].status !== CANCELLED &&
        existing[i].status !== CANCELLING
      ) {
        activeBookings.push(existing[i]);
      }
    }

    return activeBookings.length;
  };

  render() {
    const {
      active,
      calendar,
      day,
      dmy,
      eventsMapSingle,
      focus,
      ignoreValidation,
      isRestricted,
      isSelected,
      isToday,
      mouseOver,
      onDayClick,
      onDayHover,
      outsideMonth,
      rate: rateNumber,
      singleDate,
      timezone,
      viewingMonth,
      filter,
      pricing,
      userRoles,
      tasks,
    } = this.props;
    const tdProps = {
      isRestricted,
      isSelected,
      isToday,
      outsideMonth,
    } as any;

    const { eventsArray } = calendar;

    const existing = eventsMapSingle.map((event, i) => {
      // merge the event data (booking specifics etc) with the rendering data (calendar blob positioning etc)
      return { ...eventsArray[event.eventIndex], ...event };
    });

    const vacant = getVacancyFromCalendar(calendar, dmy);

    const allDayEvents = { existing, vacant };

    // const rate = existing.length === 0 && rateNumber; // only show if there is no booking
    const rate = !this.hasActiveBookings(existing) && rateNumber; // only show if there are no "active" bookings

    const statusProps = {
      existing,
      filter,
      dayOfWeek: day.getDay(),
      viewingMonth,
      timezone,
    };

    const pricingIconUrl = (pricing?: string) => {
      if (userRoles && isLicensee(userRoles)) {
        return getIconURL("airbnb");
      }
      if (userRoles && isStaff(userRoles)) {
        switch (pricing) {
          case "madeComfyPrice":
            return getIconURL("madecomfy");
          case "bookingComPrice":
            return getIconURL("bcom");
          case "vrboPrice":
            return getIconURL("vrbo");
          default:
            return getIconURL("airbnb");
        }
      }
      return null;
    };

    // outside available range
    if (isRestricted) {
      return (
        <DayCell
          data-test="restricted-day-cell"
          {...tdProps}
          onClick={(event: Event) =>
            onDayClick({ error: "That date is restricted" })
          }
        >
          <Digit dmy={dmy} viewingMonth={viewingMonth} />
          <Status {...statusProps} />
          <DayRate
            rate={rate}
            viewingMonth={viewingMonth}
            pricingIconUrl={pricingIconUrl(pricing)}
          />
        </DayCell>
      );
    }

    // not an interactive datepicker, but may have some event handlers
    if (!active) {
      if (onDayClick) {
        tdProps.onClick = (event: Event) => {
          onDayClick(dmy, allDayEvents);
        };
      }
      return (
        <DayCell data-test="day-cell" {...tdProps}>
          <Digit dmy={dmy} viewingMonth={viewingMonth} />
          <Status {...statusProps} />
          <DayRate
            rate={rate}
            viewingMonth={viewingMonth}
            pricingIconUrl={pricingIconUrl(pricing)}
          />
          <CleaningTasks>
            {tasks?.map((task) => (
              <CleaningTask task={task} />
            ))}
          </CleaningTasks>
        </DayCell>
      );
    }

    if (onDayClick) {
      tdProps.onClick = (event: Event) => {
        const ok = { error: null, dmy, range: vacant };

        if (ignoreValidation || singleDate) {
          onDayClick(ok);
          return;
        }

        if (!vacant) {
          // no valid range here, bail immediately
          onDayClick({ error: "That date is not available" });
          return;
        }

        if (!focus) {
          onDayClick({ error: "Select start or end date first" });
          return;
        }

        if (focus === START_DATE && vacant.endDate === dmy) {
          // trying to start at the end of a valid range: 0 days available
          onDayClick({ error: "Invalid start date" });
          return;
        }

        if (focus === END_DATE && vacant.startDate === dmy) {
          // trying to end at the start of the available range...
          onDayClick({ error: "Invalid end date" });
          return;
        }

        if (focus === START_DATE && active.endDate && dmy >= active.endDate) {
          onDayClick(ok);
          //  onDayClick({ error: "Start day after end date" });
          return;
        }

        if (focus === END_DATE && active.startDate && dmy <= active.startDate) {
          onDayClick({ error: "End day before start date" });
          return;
        }

        // can't do XOR in Javascript yet.
        // but if a startDate or endDate is selected but not both and they are picking within a different range
        if (
          (active.startDate && !active.endDate && focus === START_DATE) ||
          (!active.startDate && active.endDate && focus === END_DATE)
        ) {
          onDayClick(ok);
          return;
        }

        if (active.range.startDate || active.range.endDate) {
          // ^ these two vars always come in pairs, they'll always be true together.
          if (vacant.eventIndex === active.range.eventIndex) {
            // user is clicking within the same range, all good.
            onDayClick(ok);
            return;
          }
          // Let the API handle the blocking clash event
          /* onDayClick({ error: "There is a clash with a blocking event" });
          return; */
        }

        onDayClick(ok);
      };

      if (onDayHover) {
        tdProps.onMouseOver = () => {
          if (ignoreValidation || singleDate) {
            onDayHover(dmy);
            return;
          }
          if (!active.range) {
            // this is an error.
            // eslint-disable-next-line no-console
            console.log(active);
            return;
          }
          if (dmy >= active.range.startDate && dmy <= active.range.endDate) {
            onDayHover(dmy);
          }
        };
        tdProps.onMouseOut = () => {
          onDayHover(null);
        };
      }
    }

    // render a standard day
    return (
      <DayCell
        data-test="standard-day-cell"
        {...tdProps}
        {...getDayCellProps({ active, dmy, focus, mouseOver, singleDate })}
      >
        <Digit dmy={dmy} viewingMonth={viewingMonth} />
        <Status {...statusProps} />
        <DayRate
          rate={rate}
          viewingMonth={viewingMonth}
          pricingIconUrl={pricingIconUrl(pricing)}
        />
      </DayCell>
    );
  }
}

const getDayCellProps = ({
  active,
  dmy,
  focus,
  mouseOver,
  singleDate,
}: any) => {
  const { startDate, endDate } = active;

  const isStart = startDate && startDate === dmy;
  const afterStart = startDate && dmy > startDate;

  const isEnd = endDate && endDate === dmy;
  const beforeEnd = endDate && dmy < endDate;

  if (!mouseOver) {
    if (isStart) {
      return { pendingStart: true };
    }
    if (isEnd) {
      return { pendingEnd: true };
    }
    if (afterStart && beforeEnd) {
      return { pendingDuring: true };
    }
    return;
  }

  if (singleDate) {
    if (dmy === mouseOver) {
      return { pendingStart: true };
    }
    return;
  }

  if (focus === START_DATE) {
    if (endDate && mouseOver >= endDate) {
      // invalid selection
      return;
    }
    if (dmy === mouseOver) {
      return { pendingStart: true };
    }
    if (beforeEnd && dmy >= mouseOver) {
      return { pendingDuring: true };
    }
    if (isEnd) {
      return { pendingEnd: true };
    }
  }

  if (focus === END_DATE) {
    if (startDate && mouseOver <= startDate) {
      // invalid selection
      return;
    }
    if (dmy === mouseOver) {
      return { pendingEnd: true };
    }
    if (afterStart && dmy <= mouseOver) {
      return { pendingDuring: true };
    }
    if (isStart) {
      return { pendingStart: true };
    }
  }
};

export default React.memo(Day);

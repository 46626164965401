// the name of this is dubious...
import { USER_HISTORY } from "Constants/actionTypes";

export function userHistory(
  state = {
    error: false,
    errorCode: 0,
    errorMessage: "",
    logs: [],
    pending: false,
    success: false,
    totalCount: 0,
    totalPages: 0,
  },
  action: {[key: string]: any},
) {
  switch (action.type) {
    case USER_HISTORY.SUCCESS:
      return {
        ...state,
        pending: false,
        success: true,
        logs: action.response,
        totalCount: action.totalCount,
        totalPages: action.totalPages,
      };
    case USER_HISTORY.PENDING:
      return {
        ...state,
        pending: action.status,
        totalCount: 0,
        totalPages: 0,
      };
    case USER_HISTORY.ERROR:
      return {
        ...state,
        error: true,
        errorMessage: action.errorMessage,
        errorCode: action.errorCode,
        pending: false,
      };
    default:
      return state;
  }
}

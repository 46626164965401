import React, { Component } from "react";
import styled from "styled-components";

import chevron from "./assets/chevron-down.svg";
import cross from "./assets/cross.svg";
import smallCross from "./assets/small-cross.svg";
import tick from "./assets/tick.svg";
import { Body } from "Components/Typography";

interface IProps {
  existingItems: any[];
  handleTypeAhead: (...args: any) => any;
  placeholder: string;
  searchResult: any[];
  sendValue: (...args: any) => any;
  testId?: string;
  reset?: boolean;
  setResetVal?: any;
}

const Wrapper = styled.ul<any>`
  list-style: none;
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.charcoalLight50};
  border-radius: 4px;
  cursor: text;
  position: relative;
  line-height: 24px;
  padding: 8px 32px 8px 8px;
  margin-top: 4px;
  ${({ setFocus }) =>
    setFocus && `box-shadow: 0px 0px 6px rgba(52, 101, 127, 0.7);`}
`;
const SelectedItem = styled.li`
  display: inline-flex;
  background-color: ${({ theme }) => theme.background.brandHover};
  padding: 4px 8px;
  border-radius: 4px;
  margin: 0px 8px 2px 0;
  color: ${({ theme }) => theme.text.default};
  font-size: 12px;
  font-weight: 600;
`;
const InputWrapper = styled.div<any>`
  display: inline-flex;
  background: none;
  margin: 0;
  width: ${({ setFocus }) => (setFocus ? "auto" : "0")};
`;
const Remove = styled.img`
  cursor: pointer;
  pointer-events: auto !important;
  width: 12px;
  height: 12px;
  margin: 6px 0 0 4px;
`;
const Input = styled.input<any>`
  border: none;
  font-size: 16px;
  line-height: 24px;
  background: transparent;
  &:focus {
    outline: none;
    border: none;
  }
  width: auto;
`;
const SelectContainer = styled.div`
  position: absolute;
  z-index: 1;
  background: ${({ theme }) => theme.white};
  top: 100%;
  left: 0;
  max-height: 400px;
  overflow: auto;
  font-size: 12px;
  width: 100%;
  box-shadow: 0px 20px 40px rgba(53, 55, 60, 0.1);
  border-radius: 4px;
  margin-top: 2px;
  padding: 8px 0;
`;
const List = styled(Body)<any>`
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin: 0 8px;
  border-radius: 4px;
  ${({ isHighlighted, selected, theme }) =>
    (isHighlighted || selected) &&
    `background-color: ${theme.background.brandHover}`};
`;
const Arrow = styled.img`
  position: absolute;
  right: 8px;
  top: 34%;
  cursor: pointer;
`;

class MultiSelect extends Component<IProps, any> {
  textInput: React.RefObject<any>;

  constructor(props: IProps) {
    super(props);
    this.state = {
      currentIndex: -1,
      inputValue: "",
      showList: false,
      selectedItems: this.props.existingItems,
      setFocus: false,
    };
    this.textInput = React.createRef();
  }

  handleSendValue = () => {
    this.props.sendValue(this.state.selectedItems);
  };

  handleEvent = (event: any) => {
    const value = event.target.value;
    this.setState({ inputValue: value, showList: true });
    this.props.handleTypeAhead(value);
  };

  addSelectedItem = (index: number) => {
    const { selectedItems } = this.state;
    const value = this.props.searchResult[index];
    // remove the item from the selected list if it is selected again
    if (selectedItems.some((item: any) => item.id === value.id)) {
      this.setState(
        (prevState: any) => ({
          selectedItems: prevState.selectedItems.filter(
            (item: any) => item.id !== value.id,
          ),
          showList: false,
          inputValue: "",
        }),
        this.handleSendValue,
      );
      return;
    }
    this.setState(
      {
        inputValue: "",
        selectedItems: [...selectedItems, value],
        showList: false,
        setFocus: false,
      },
      this.handleSendValue,
    );
  };

  focus = () => {
    this.textInput.current.focus();
    this.setState({
      setFocus: true,
    });
  };
  removeSelectedItem = (item: { [key: string]: any }, e: any) => {
    e.stopPropagation();

    const { selectedItems } = this.state;
    const newItems = selectedItems.filter(
      (val: { [key: string]: any }) => val.id !== item.id,
    );
    this.setState(
      {
        selectedItems: newItems,
      },
      this.handleSendValue,
    );
    this.setState({
      showList: false,
      setFocus: false,
    });
    e.stopPropagation();
  };
  handleKeyDown = (event: any) => {
    const { currentIndex } = this.state;
    const keyCode = event.which;
    switch (keyCode) {
      case 40: // down arrow
        this.highlightList(currentIndex + 1);
        break;
      case 38: // up arrow
        this.highlightList(currentIndex - 1);
        break;
      case 13: // enter
        this.addSelectedItem(currentIndex);
        break;
      default:
      // user has pressed a key we are not interested in.
    }
  };

  highlightList = (currentIndex: number) => {
    const { searchResult } = this.props;
    if (currentIndex >= searchResult.length) return;
    if (currentIndex < 0) return;
    this.setState({ currentIndex });
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    const { reset, setResetVal, existingItems } = this.props;
    if (prevProps.reset !== reset) {
      this.setState({ selectedItems: [] }, this.handleSendValue);
      setResetVal(false);
    }
    if (prevProps.existingItems !== existingItems) {
      this.setState({ selectedItems: existingItems });
    }
  }
  render() {
    const { currentIndex, inputValue, selectedItems, setFocus, showList } =
      this.state;
    const { placeholder, searchResult, testId } = this.props;

    return (
      <Wrapper
        onBlur={() => {
          this.setState({
            showList: false,
            setFocus: false,
          });
        }}
        onClick={this.focus}
        setFocus={setFocus}
        data-test="select-wrapper"
      >
        {selectedItems.length
          ? selectedItems.map((item: { [key: string]: any }, i: number) => {
              if (i < 3) {
                // Render the first three selected items normally
                return (
                  <SelectedItem key={i} data-test="selected-item">
                    <>
                      <>{item?.name}</>
                      <Remove
                        src={cross}
                        alt="remove icon"
                        onClick={(e) => this.removeSelectedItem(item, e)}
                      />
                    </>
                  </SelectedItem>
                );
              } else if (i === 3) {
                // Render the count of remaining items after the third item
                return (
                  <SelectedItem key={i} data-test="selected-item">
                    <>
                      <>+{selectedItems.length - 3}...</>
                    </>
                  </SelectedItem>
                );
              } else {
                // Render nothing for items beyond the third and the count item
                return null;
              }
            })
          : null}
        <InputWrapper setFocus={setFocus}>
          <Input
            data-test={testId}
            onChange={this.handleEvent}
            onFocus={this.handleEvent}
            onKeyDown={this.handleKeyDown}
            placeholder={selectedItems.length ? "" : placeholder}
            ref={this.textInput}
            type="text"
            value={inputValue}
            setWidth={selectedItems.length}
          />
          {selectedItems.length > 0 ? (
            <Arrow
              src={smallCross}
              alt="cross"
              onClick={() =>
                this.setState({ selectedItems: [] }, this.handleSendValue)
              }
            />
          ) : (
            <Arrow src={chevron} alt="arrow-down" />
          )}
          {showList && (
            <SelectContainer id="select-container">
              {searchResult.length > 0 ? (
                searchResult.map(({ name, id }, i) => {
                  return (
                    <List
                      key={`item-${i}`}
                      onMouseDown={() => {
                        this.addSelectedItem(currentIndex);
                        this.setState({ currentIndex: -1 });
                      }}
                      onMouseOver={() => this.setState({ currentIndex: i })}
                      onMouseOut={() => this.setState({ currentIndex: -1 })}
                      isHighlighted={currentIndex === i}
                      selected={selectedItems.some(
                        (item: any) => item.id === id,
                      )}
                    >
                      {name}
                      {selectedItems.some((item: any) => item.id === id) && (
                        <img alt="selected" src={tick} />
                      )}
                    </List>
                  );
                })
              ) : (
                <List>No items found.</List>
              )}
            </SelectContainer>
          )}
        </InputWrapper>
      </Wrapper>
    );
  }
}

export default MultiSelect;

import React, { Component } from "react";
import { Redirect, Switch, BrowserRouter } from "react-router-dom";

import { logException } from "./ThirdParty";
// import { setABTests } from "Utils/methods/abTests";

import withSessionCheck from "HOC/withSessionCheck";

import AppRoute from "Containers/AppRoute";
import Balances from "Containers/Balances";
import Channels from "Containers/Channels";
import Events from "Containers/Events";
import { KnowledgeCentre as KnowledgeCentreNaked } from "Containers/KnowledgeCentre";
import Logout from "Containers/Logout/Logout";
import PageErrorNaked from "Containers/PageError";
import PropertyListNaked from "Containers/PropertyList";
import Releases from "Containers/Releases";
import Resources from "Containers/Resources";
import RootNaked from "Containers/Root";
import Settings from "Containers/Settings";
import Statements from "Containers/Statements";
import UserManager from "Containers/UserManager";
import Welcome from "Containers/Welcome";
import {
  Booking as BookingNaked,
  DesignSystem,
  Experiments as ExperimentsNaked,
  Reservations,
  PropertyView as PropertyViewNaked,
} from "./Lazy";

const Booking = withSessionCheck(BookingNaked);
const Balance = withSessionCheck(Balances);
const Experiments = withSessionCheck(ExperimentsNaked);
const KnowledgeCentre = withSessionCheck(KnowledgeCentreNaked);
const PageError404 = withSessionCheck(PageErrorNaked({ code: 404 }));
const PageError500 = withSessionCheck(PageErrorNaked({ code: 500 }));
const PropertyList = withSessionCheck(PropertyListNaked);
const PropertyView = withSessionCheck(PropertyViewNaked);
const Release = withSessionCheck(Releases);
const Statement = withSessionCheck(Statements);
const Resource = withSessionCheck(Resources);
const Root = withSessionCheck(RootNaked);

/**
 * Router will create the first layer of routes
 * All base routes should be defined here
 * sub routes can be defined in children
 * routes with header and footer should be wrapped in `AppRoute` component
 * routes with authorisation requirements should be wrapped in `withSessionCheck` high order component
 */

class Router extends Component {
  state = { error: null };

  // no active ab tests
  /*
  constructor(props) {
    super(props);
    setABTests(document.location.search);
  }
  */

  componentDidCatch(error: any, errorInfo: any) {
    // eslint-disable-next-line
    console.warn("Router error", error);
    this.setState({ error });
    logException(error, { extra: errorInfo });
  }

  render() {
    const { error }: any = this.state;
    if (error) {
      return (
        <BrowserRouter>
          <AppRoute
            name="Error"
            component={PageErrorNaked({ code: "", message: error.toString() })}
          />
        </BrowserRouter>
      );
    }

    return (
      <BrowserRouter>
        <Switch>
          <Redirect from="/search" to="/properties" />
          <Redirect from="/property/*" to="/properties/*" />
          <Redirect from="/booking/*" to="/reservations/*" />
          <AppRoute exact path="/logout" name="Logout" component={Logout} />
          <AppRoute
            component={Welcome}
            exact
            name="Create Password"
            path="/login/:action/:newPasswordToken"
            showNavigation={false}
          />
          <AppRoute
            component={Welcome}
            exact
            name="Sign in"
            path="/(forgot|login)"
            showNavigation={false}
          />
          <AppRoute
            component={Booking}
            exact
            name="Reservations"
            path="/reservations/:bookingId/:tab?"
          />
          <AppRoute
            component={DesignSystem}
            exact
            name="Design System"
            path="/design-system"
          />
          <AppRoute
            component={Experiments}
            name="Experiments"
            path="/experiments/:experimentId?"
          />
          <AppRoute
            component={Events}
            name="Events"
            path="/events/:propertyId?/:action?/:eventId?"
          />
          <AppRoute
            component={PropertyList}
            exact
            name="Search"
            path="/properties"
          />
          <AppRoute
            component={PropertyView}
            name="Property"
            path="/properties/:propertyId/:tab?/:section?"
          />
          <AppRoute
            component={UserManager}
            name="Users"
            path="/users/:action(create|update)?/:code?"
          />
          <AppRoute
            component={Channels}
            name="Channels"
            path="/channels/:channelId?/:action?/:accountId?"
          />
          <AppRoute
            component={Release}
            exact
            name="Releases"
            path="/releases"
          />
          <AppRoute
            component={Statement}
            exact
            name="Statements"
            path="/statements"
          />
          <AppRoute
            component={Balance}
            exact
            name="Balances"
            path="/balances"
          />
          <AppRoute
            component={KnowledgeCentre}
            exact
            name="Knowledge Centre"
            path="/knowledgecentre"
          />
          <AppRoute
            component={Resource}
            exact
            name="Resources"
            path="/resources"
          />
          <AppRoute component={Settings} name="Settings" path="/settings" />
          <AppRoute
            component={Reservations}
            name="Reservations"
            path="/reservation-all"
          />
          <AppRoute
            name="500 Error"
            path="/error"
            exact
            component={PageError500}
          />
          <AppRoute exact path="/(index.html)?" name="" component={Root} />
          {/* 404 must be last */}
          <AppRoute name="404 Error" component={PageError404} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default Router;

import { EDIT_USER_PERMISSIONS } from "Constants/actionTypes";

const initialState = {
  error: false,
  errorCode: 0,
  errorMessage: "",
  pending: false,
  roles: [],
  success: false,
};

export function editUserPermissions(state = initialState, action: {[key: string]: any}) {
  switch (action.type) {
    case EDIT_USER_PERMISSIONS.SUCCESS:
      return {
        ...state,
        pending: false,
        roles: action.roles,
        success: true,
      };

    case EDIT_USER_PERMISSIONS.ERROR:
      return {
        ...state,
        error: true,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        pending: false,
      };

    case EDIT_USER_PERMISSIONS.PENDING:
      return {
        ...state,
        pending: action.status,
        success: false,
      };

    case EDIT_USER_PERMISSIONS.RESET:
      return initialState;

    default:
      return state;
  }
}

import React from "react";
import styled from "styled-components";

import { Alert, ALERT_ERROR } from "@madecomfy/webooi";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
  height: 70vh;
  width: 80%;
  > div {
    width: 100%;
  }
`;

const PageError =
  ({
    code,
    message = "The page you are looking for is unavailable",
  }: {
    code: any;
    message?: string;
  }) =>
  () => {
    // try and catch the special case where a chunk is missing...
    if (
      typeof message === "string" &&
      /Loading chunk (\d+) failed/.test(message)
    ) {
      return (
        <Wrapper>
          <Alert
            buttons={[
              {
                callback: () => window.location.reload(),
                label: "Reload",
                testId: "test-id-force-reload",
                type: "primary",
              },
            ]}
            message="It looks like a new version of our website is available"
            testId="page-error"
            title="Sorry, something is missing"
            type={ALERT_ERROR}
          />
        </Wrapper>
      );
    }
    return (
      <Wrapper>
        <Alert
          message={message}
          testId="page-error"
          title={String(code)}
          type={ALERT_ERROR}
        />
      </Wrapper>
    );
  };

export default PageError;

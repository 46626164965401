export const AUD = "AUD";
export const NZD = "NZD";

export const formatCurrency = (value: number, digits: number = 0, currency: string = AUD) => {
  const abs = value < 0 ? -value : value; // Math.abs removes decimal points!
  const sign = value < 0 ? "-" : "";
  const fixed = abs.toFixed(2);
  const round = Math.pow(10, digits);
  const prefix = shortenCurrency(currency === "" ? currency = AUD : currency);

  let rounded = Math.round(abs * round) / round;
  if (Number(fixed) !== Number(rounded)) {
    // check if we should in fact honour showing cents
    rounded = Number(fixed);
    digits = Math.max(digits, 2);
  }

  return `${sign}${prefix}${rounded.toFixed(digits).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,",
  )}`;
  // do not use Intl!!!! see https://github.com/madecomfy/host/commit/d56040ce3f16fcec8c5bda8ee01ade5dcf5ec1f3
  /*
  return new Intl.NumberFormat("en-AU", {
    style: "currency",
    currency: "AUD",
    minimumFractionDigits: digits,
  }).format(value);
  */
};

const shortenCurrency = (currency: string) => {
  switch (currency) {
    case AUD:
      return "A$";
    case NZD:
      return "N$";
    default:
      return currency;
  }
};

import "isomorphic-fetch";

import { VERIFY_TOKEN } from "Constants/actionTypes";
import Config from "Config";

export function verifyToken(token: number | string) {
  return (dispatch: (...args: any) => any) => {
    dispatch({ type: VERIFY_TOKEN.PENDING, status: true });
    return fetch(
      `${Config.API_URL}v1/public/verify/${token}/?checkExpiration=true`,
      {
        credentials: "include",
        method: "GET",
        mode: "cors",
      },
    )
      .then((response) => {
        if (response.status === 200) {
          dispatch({ type: VERIFY_TOKEN.SUCCESS });
        } else {
          dispatch({
            type: VERIFY_TOKEN.ERROR,
          });
        }
      })
      .then(() => {
        dispatch({ type: VERIFY_TOKEN.PENDING, status: false });
      })
      .catch((response) => {
        dispatch({ type: VERIFY_TOKEN.PENDING, status: false });
        dispatch({
          type: VERIFY_TOKEN.CONNECTION_FAILURE,
        });
      });
  };
}

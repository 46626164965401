export const googlePlaceDataByType = (data: any) => {
  const addressComponents =
    data && data.address_components ? data.address_components : [];
  const placeDataByType: any = {};
  addressComponents.forEach((c: any) =>
    c.types.forEach((t: any) => (placeDataByType[t] = c.long_name)),
  );
  return placeDataByType;
};

const googlePlaceDataWithLatLng = (data: any): any => {
  if (!data || !data.geometry) return null;
  return Object.assign({}, googlePlaceDataByType(data), {
    lat: data.geometry.location.lat(),
    lng: data.geometry.location.lng(),
  });
};

export const parsedGooglePlacesAddress = (googlePlaceData: any = {}) => {
  let addressComponents = googlePlaceDataWithLatLng(googlePlaceData);
  if (!addressComponents) return;
  return {
    city: addressComponents.locality || "",
    country: addressComponents.country || "",
    full: googlePlaceData.formatted_address || "",
    latitude: addressComponents.lat || "",
    longitude: addressComponents.lng || "",
    line1:
      `${addressComponents.street_number} ${addressComponents.route}` || "",
    postcode: addressComponents.postal_code || "",
    state: addressComponents.administrative_area_level_1 || "",
    unit: addressComponents.subpremise || "",
  };
};

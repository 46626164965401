import React from "react";
import styled from "styled-components";

import externalLink from "./assets/external-link.svg";

interface IProps {
  label: string;
}

const IconExternalImage = styled.img`
  margin-left: 4px;
`;

const IconExternal: React.FC<IProps> = ({ label }) => (
  <IconExternalImage src={externalLink} alt={`Open ${label} in new window`} />
);

export default IconExternal;

import styled from "styled-components";
import theme, { getColourFromString } from "Constants/theme";

interface TextProps {
  as?: string;
  center?: boolean;
  children: any[] | number | {[key: string]: any} | string;
  "data-test"?: string;
}

interface BodyProps extends TextProps {
  bold?: boolean;
  semi?: boolean;
  medium?: boolean;
  color?: string;
  white?: boolean;
}

interface HeaderProps extends TextProps {
  color?: string;
  white?: boolean;
}

// TODO rename all use of color to colour
const setColor = ({ color, white }: any) => {
  if (color) {
    return getColourFromString(color);
  }
  if (white) {
    return theme.text.inverse;
  }
  return theme.text.default;
};

const setWeight = ({ bold, semi, medium }: any) => {
  if (bold) {
    return "700";
  }
  if (semi) {
    return "600";
  }
  if (medium) {
    return "500";
  }
  return "400";
};

const setCenter = ({ center }: any) => (center ? "center" : "left");

const H1 = styled.h1<HeaderProps>`
  color: ${setColor};
  font-family: InterBold;
  font-size: 34px;
  line-height: 44px;
  text-align: ${setCenter};

  @media (max-width: ${({ theme }) => theme.maxMobile}) {
    font-size: 24px;
    line-height: 32px;
  }
`;

const H2 = styled.h2<HeaderProps>`
  color: ${setColor};
  font-family: InterBold;
  font-size: 24px;
  line-height: 40px;
  text-align: ${setCenter};

  @media (max-width: ${({ theme }) => theme.maxMobile}) {
    font-size: 24px;
    line-height: 32px;
  }
`;

const H3 = styled.h3<HeaderProps>`
  color: ${setColor};
  font-family: InterBold;
  font-size: 20px;
  line-height: 32px;
  text-align: ${setCenter};

  @media (max-width: ${({ theme }) => theme.maxMobile}) {
    font-size: 18px;
    line-height: 24px;
  }
`;

const H4 = styled.h4<HeaderProps>`
  color: ${setColor};
  font-family: InterSemiBold;
  font-size: 18px;
  line-height: 24px;
  text-align: ${setCenter};

  @media (max-width: ${({ theme }) => theme.maxMobile}) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const H5 = styled.h5<HeaderProps>`
  color: ${setColor};
  font-family: InterMedium;
  font-size: 16px;
  line-height: 24px;
  text-align: ${setCenter};
  margin: 0; //is this right ?
`;

const H6 = styled.h6<HeaderProps>`
  color: ${setColor};
  font-family: InterMedium;
  font-size: 14px;
  line-height: 20px;
  text-align: ${setCenter};
`;

const Body = styled.p<BodyProps>`
  color: ${setColor};
  // font-family: Inter; <-- inherited from body
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  font-weight: ${setWeight};
  text-align: ${setCenter};
`;

const ParagraphSmall = styled.p<BodyProps>`
  color: ${setColor};
  font-family: Inter;
  font-size: 14px;
  line-height: 20px;
  font-weight: ${setWeight};
  text-align: ${setCenter};
`;

const ParagraphLarge = styled.p<any>`
  color: ${setColor};
  font-family: Inter;
  font-size: 20px;
  line-height: 28px;
  font-weight: ${setWeight};
  text-align: ${setCenter};

  @media only screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const FinePrint = styled.p<BodyProps>`
  color: ${setColor};
  font-family: Inter;
  font-size: 12px;
  line-height: 18px;
  font-weight: ${setWeight};
  text-align: ${setCenter};
`;

export {
  // Display1,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Body,
  FinePrint,
  ParagraphLarge,
  ParagraphSmall,
};

import React, { useState } from "react";
import styled from "styled-components";

import { Tooltip } from "@madecomfy/webooi";

import { Body } from "Components/Typography";

export const Specifications = styled.div`
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  margin-top: 8px;
`;

export const Top = styled.div`
  display: flex;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`;

export const Bottom = styled.div`
  display: flex;
`;

export const Column = styled.div`
  flex: 1;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  &:last-of-type {
    border-right: none;
  }
  ${({ onClick }) => onClick && `cursor: pointer;`};
`;

const Text = styled(Body)<any>`
  color: ${({ theme }) => theme.white};
  line-height: 23px;
  @media screen and (max-width: ${({ theme }) => theme.maxMobile}) {
    display: ${({ isMobile }) => (isMobile ? "flex" : "none")};
  }
`;

const Label = styled(Body)`
  color: ${({ theme }) => theme.white};
  font-size: 14px;
  line-height: 22px;
`;

// displays icon + text inline:
const WrapperIconText = styled.div`
  display: flex;
  margin: 12px;
  position relative;
`;
const WrapperTooltip = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
> div{
  position absolute;
  left: 0;
}
div[data-test="tooltip"]{
  width: max-content;
  padding: 8px;
}
`;

// displays text on two lines:
const WrapperText = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 12px;
`;

const Dot = styled.span<any>`
  background: ${({ dot }) => dot};
  border-radius: 5px;
  display: inline-block;
  height: 10px;
  margin-right: 5px;
  width: 10px;
`;

const Icon = styled.img`
  float: left;
  margin-right: 5px;
`;

export const IconText = ({
  alt,
  src,
  text,
  isMobile = true,
}: {
  alt: any;
  src: any;
  text?: string;
  isMobile?: boolean;
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  let timeoutId: any;
  const handleMouseEnter = () => {
    // Set a delay of 300 milliseconds before showing the tooltip
    timeoutId = setTimeout(() => {
      setShowTooltip(true);
    }, 300);
  };

  const handleMouseLeave = () => {
    // Clear the timeout if the user leaves before the delay expires
    clearTimeout(timeoutId);
    setShowTooltip(false);
  };
  return (
    <WrapperIconText>
      {typeof src === "string" ? <Icon src={src} alt={alt} /> : src}
      <WrapperTooltip
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {showTooltip && (
          <Tooltip
            hideIcon={true}
            position="bottom"
            text={alt}
            testId="tooltip"
            timeOut={100}
          />
        )}
      </WrapperTooltip>
      {text && <Text isMobile={isMobile}>{text}</Text>}
    </WrapperIconText>
  );
};

export const LabelText = ({
  dot,
  label,
  text,
}: {
  dot?: any;
  label: string;
  text: string | React.ReactNode;
}) => {
  // this component renders as a <p> by default (majority of cases).
  // if `text` is not `number` or `string`, all good, but if it is a `StatusPill` or other React element, wrap as a div.
  const asDiv = typeof text === "object" ? { as: "div" } : {};
  return (
    <WrapperText>
      <Label>{label}</Label>
      {/* @ts-ignore */}
      <Text {...asDiv} data-test="label-text">
        {dot && <Dot dot={dot} />}
        {text}
      </Text>
    </WrapperText>
  );
};

export const HeaderText = ({
  label,
  text,
}: {
  label: string;
  text: string;
}) => {
  return (
    <WrapperText>
      {label && (
        <Body bold color="white">
          {label}
        </Body>
      )}
      <Text data-test="header-text">{text}</Text>
    </WrapperText>
  );
};

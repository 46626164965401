import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import withClickOutside from "HOC/withClickOutside";

import IconExternal from "Components/IconExternal";

import DropDownProps from "./dropDownPropTypes";

interface IProps extends DropDownProps {
  isDisplayed: boolean;
  onClickOutside: (...args: any) => any;
}

// this was bastardised from https://www.bootply.com/QD8FO89DcY
const DropDownFloating = styled.ul<any>`
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  background-color: ${({ theme }) => theme.background.white};
  border-radius: 4px;
  display: ${({ isDisplayed }) => (isDisplayed ? "block" : "none")};
  list-style: none;
  margin: 0;
  min-width: 245px;
  padding: 16px 0;
  position: absolute;
  z-index: 1000;
  border: 1px solid ${({ theme }) => theme.border.default};
  box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.08);
  color: ${({ theme }) => theme.text.default};

  text-align: ${({ align }) => {
    // 'left' or 'right'
    return align;
  }};
`;

// because we use either `li` or `Link` depending on whether the link is external...
const DropDownCommon = css`
  color: ${({ theme }) => theme.text.default};
  cursor: pointer;
  display: flex;
  font-family: Inter;
  font-size: 16px;
  line-height: 22px;
  margin: 0 16px;
  padding: 8px;
  white-space: nowrap;
  a {
    text-decoration: none;
  }
  &:hover {
    background-color: ${({ theme }) => theme.background.whiteHover};
  }
`;

const DropDownListItem = styled.li<any>`
  ${DropDownCommon}
  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      opacity: 0.6;
      cursor: not-allowed;
    `}
  img {
    margin-left: 4px;
  }
`;

const DropDownLink = styled(Link)`
  ${DropDownCommon}
  display: block;
  text-decoration: none;
  }
`;

const Caret = styled.span<any>`
  position: absolute;
  top: -18px;

  ${({ align }) => {
    if (align === "left") {
      return `left: 20%;`;
    }
    // align is "right"
    return `right: 20%;`;
  }};

  &:before,
  &:after {
    content: "";
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-width: 7px 8px;
    border-style: solid;
    border-color: transparent;
    z-index: 1001;
  }
  &:before {
    bottom: -18px;
    right: -9px;
    border-bottom-color: ${({ theme }) => theme.sand96};
  }
  &:after {
    bottom: -19px;
    right: -9px;
    border-bottom-color: ${({ theme }) => theme.white};
  }
`;

const DropDown: React.FC<IProps> = (props) => {
  const {
    align,
    caret = true,
    isDisplayed,
    items,
    position,
    testId,
    minWidth,
  } = props;

  // `position` -> expecting object which represents css positioning including units, eg. { left: "20px", top: "0" }

  const wrapperProps = {
    align,
    isDisplayed,
    style: { ...position, minWidth },
    "data-test": testId || undefined,
  };

  return (
    <DropDownFloating {...wrapperProps}>
      {caret && <Caret align={align} />}
      {items.map(({ label, link, isEnabled = true, newWindow }, index) => {
        const key =
          typeof label === "string" ? `dropdown-${label}` : `dropdown-${index}`;
        const itemProps = {
          "data-test": testId ? `${testId}-${index}` : undefined,
        };

        // handle a callback
        if (typeof link === "function") {
          return (
            <DropDownListItem
              $isDisabled={!isEnabled}
              key={key}
              onClick={link}
              {...itemProps}
            >
              {label}
              {newWindow && <IconExternal label={label} />}
            </DropDownListItem>
          );
        }

        // handle a new window
        if (newWindow) {
          return (
            <DropDownListItem
              key={key}
              onClick={() => window.open(link)}
              {...itemProps}
            >
              {label}
              <IconExternal label={label} />
            </DropDownListItem>
          );
        }

        // handle a route change
        return (
          <DropDownLink key={key} to={link} {...itemProps}>
            {label}
          </DropDownLink>
        );
      })}
    </DropDownFloating>
  );
};

export default withClickOutside<IProps>(DropDown);

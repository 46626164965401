/**
 * Wrapper for most of the application
 * - supplies the navigation if required
 * - updates <head><title>
 */

import get from "lodash/get";
import React, { PureComponent } from "react";
import styled, { css } from "styled-components";
import { Route } from "react-router-dom";

import { titleCase } from "Utils";
import Header from "./components/Header/index";
import Navigation from "./components";
import ProductionBanner from "Components/ProductionBanner";

interface IProps {
  component?: any;
  computedMatch?: { [key: string]: any };
  exact?: boolean | undefined;
  name: string;
  path?: string;
  showNavigation?: boolean;
}
const AppBody = styled.div<any>`
  position: relative;
  width: 100%;
  ${({ showNavigation }) =>
    showNavigation
      ? css`
          @media screen and (min-width: ${({ theme }) => theme.minTablet}) {
            margin-left: 92px;
          }
        `
      : css`
          width: 100%; // welcome screen needs this
        `}
`;

class AppRoute extends PureComponent<IProps> {
  /*
  // global debugger see store.js for reference.
  state = {
    debug: [],
  };
  componentDidMount() {
    setInterval(() => {
      // console.log("interval calling", global.msg);
      this.setState({ debug: [].concat(global.msg) });
    }, 100);
    global.debugLog("app booted");
  }
  */

  render() {
    const { computedMatch, name, showNavigation = true } = this.props;
    const action = titleCase(
      get(computedMatch, "params.action", ""), // extract action if exists
    );
    const title = [name, action, "MadeComfy Dashboard"]
      .filter(Boolean)
      .join(" | ");
    document.title = title;

    return (
      <>
        <ProductionBanner />
        <div className="app">
          {showNavigation && <Navigation />}
          <AppBody id="app-body" showNavigation={showNavigation}>
            {showNavigation && <Header />}
            <Route {...this.props} />
          </AppBody>
          {/*
          <div
            style={{
              minHeight: 50,
              background: "#faa",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            Debug:
            {this.state.debug.join(", ")}
          </div>
          */}
        </div>
      </>
    );
  }
}

export default AppRoute;

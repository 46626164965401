import React from "react";

const FAQ = () => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1925 6.24531C13.9356 5.13035 12.8828 4.38577 11.746 4.51496C10.6091 4.64415 9.75022 5.60597 9.75 6.75014C9.74992 7.16436 9.41407 7.50008 8.99986 7.5C8.58564 7.49992 8.24992 7.16407 8.25 6.74986C8.25036 4.84289 9.68182 3.23987 11.5766 3.02455C13.4714 2.80924 15.226 4.0502 15.6542 5.90846C16.0825 7.76672 15.0479 9.65045 13.25 10.2861C12.9502 10.3921 12.7498 10.6756 12.75 10.9936V11.25C12.75 11.6642 12.4142 12 12 12C11.5858 12 11.25 11.6642 11.25 11.25V10.9944C11.2497 10.0406 11.8507 9.18983 12.75 8.87189C13.8287 8.4905 14.4495 7.36026 14.1925 6.24531Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 13.5C11.3787 13.5 10.875 14.0037 10.875 14.625C10.875 15.2463 11.3787 15.75 12 15.75C12.6213 15.75 13.125 15.2463 13.125 14.625C13.125 14.0037 12.6213 13.5 12 13.5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 2.25C0 1.00736 1.00736 0 2.25 0H21.75C22.9926 0 24 1.00736 24 2.25V17.25C24 18.4926 22.9926 19.5 21.75 19.5H11.5L5.7 23.85C5.47274 24.0204 5.16868 24.0479 4.91459 23.9208C4.6605 23.7938 4.5 23.5341 4.5 23.25V19.5H2.25C1.00736 19.5 0 18.4926 0 17.25V2.25ZM2.25 1.5C1.83579 1.5 1.5 1.83579 1.5 2.25V17.25C1.5 17.6642 1.83579 18 2.25 18H5.25C5.66421 18 6 18.3358 6 18.75V21.75L10.8 18.15C10.9298 18.0526 11.0877 18 11.25 18H21.75C22.1642 18 22.5 17.6642 22.5 17.25V2.25C22.5 1.83579 22.1642 1.5 21.75 1.5H2.25Z"
    />
  </>
);

export default FAQ;

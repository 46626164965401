import { connect } from "react-redux";
import ProductionBanner from "./ProductionBanner";

const mapStateToProps = (state: any) => ({
  user: state.authentication.sessionStatus.user,
});

const ProductionBannerConnected = connect(mapStateToProps)(ProductionBanner);

export default ProductionBannerConnected;

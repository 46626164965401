import React, { Fragment } from "react";
import styled from "styled-components";

import { Alert, ALERT_ERROR, Button, BUTTON_INLINE } from "@madecomfy/webooi";

import Loading from "Components/Loading";
import { H5, Body, FinePrint } from "Components/Typography";

interface IProps {
  alert?: string;
  body?: React.ReactNode;
  buttons?: any[];
  finePrint?: { [key: string]: any };
  loading: boolean;
  title: string;
}

const Wrapper = styled.div`
  //  padding: 20px 24px;
  padding: 24px;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  // float: right;
  // margin-top: -20px;
  background: #f4f4f5;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 24px;
  gap: 16px;
  margin: 0 -24px;
  width: calc(100% + 48px);
  margin-bottom: -24px;
  margin-top: 32px;
  border-radius: 0 0 4px 4px;
  border-top: 1px solid #e9e9ea;
  @media (max-width: ${({ theme }) => theme.maxMobile}) {
    position: fixed;
    bottom: 24px;
    width: 100%;
  }
`;
const Title = styled(H5)`
  font-family: InterBold;
  font-size: 16px;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.charcoal};
  border-bottom: 1px solid #e9e9ea;
  margin-left: -24px;
  margin-right: -24px;
  padding-left: 24px;
  padding-bottom: 24px;
`;
const WrapperLoading = styled.div`
  padding: 42px 0;
  div[data-test="loading"] {
    top: 27px;
  }
`;

/*

this helper is designed for rendering minimal popups.

it will be tempting to keep building on this to end up with a single wrapper that fits all scenario. but no.

the restrictions being imposed are:
- the order of elements cannot be modified
- loading is the only condition that creates a branch

reasonable ideas:
- array of elements? sounds bad.
- adding an alertType? seems ok.
*/

const GenericWrapper: React.FC<IProps> = ({
  // read above before adding more arguments.
  alert,
  body,
  buttons,
  finePrint,
  loading,
  title,
}) => {
  // read above before adding any extra conditions or logic.
  return (
    <Wrapper>
      {title && <Title>{title}</Title>}
      {loading ? (
        <WrapperLoading>
          <Loading task="updatingGenericWrapper" />
        </WrapperLoading>
      ) : (
        <Fragment>
          {body && (typeof body === "string" ? <Body>{body}</Body> : body)}

          {alert && <Alert message={alert} type={ALERT_ERROR} />}

          {finePrint && (
            <div
              style={{ display: "none" }} // cancellation policies pending...
            >
              <FinePrint bold color="theme.navy">
                {finePrint.label}
              </FinePrint>
            </div>
          )}

          {buttons && buttons.length && (
            <ButtonWrapper>
              {buttons.map((button: any, index: React.Key) => {
                return (
                  <Button
                    key={index}
                    styling={BUTTON_INLINE}
                    {...button}
                    my={0}
                  />
                );
              })}
            </ButtonWrapper>
          )}
        </Fragment>
      )}
    </Wrapper>
  );
};

export default GenericWrapper;

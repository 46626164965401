import React from "react";
import styled from "styled-components";

import Documents from "../Documents";
import Forms from "../Forms";
import Newsletter from "../Newsletter";
import { isAuthorized } from "Utils";
import { ROLE } from "Constants/userRoles";

const Wrapper = styled.div``;

interface IProps {
  user: { [key: string]: any };
}
const All: React.FC<IProps> = ({ user }) => {
  const isLicensee = isAuthorized(user.roles, [ROLE.LICENSEE]);

  return (
    <Wrapper>
      <Documents isAllView />
      {isLicensee && <Forms />}
      <Newsletter showNew={false} />
    </Wrapper>
  );
};

export default All;

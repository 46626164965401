import React, { useState } from "react";
import styled, { css } from "styled-components";

import CleaningSprayIcon from "../assets/CleaningSprayIcon";
import messageIcon from "../assets/message.svg";
import { FinePrint, ParagraphSmall } from "Components/Typography";
import { Tooltip } from "@madecomfy/webooi";
import {
  cleaningPriorityContent,
  cleaningStatusContent,
} from "./getCleaningStatus";
import { CleaningTaskPopup } from "./CleaningTaskPopup";
import { IPropertyTask } from "Containers/EventsCalendar/interface/task";

const CleaningTaskWrapper = styled.div<{
  $color?: string;
}>`
  height: 25px;
  position: relative;
  width: 100%;
  background: ${({ theme }) => theme.neutral100};
  display: flex;
  align-items: center;
  border-radius: 2px;
  border-block: inherit;
  border-left: 2px solid ${({ $color }) => $color};
  padding: 4px;
  & > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  z-index: 30;
`;

const SVGIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 4px;
  svg {
    flex: none;
  }
`;

const CleanIcon = styled.img<{
  $size?: number;
  $color?: string;
}>`
  margin-right: 4px;
  width: 12px;
  height: 12px;
  ${({ $size }) =>
    $size &&
    css`
      width: ${$size}px;
      height: ${$size}px;
    `};
`;

const CleaningTooltipWrapper = styled.span`
  position: absolute;
  top: 0px;
  left: 8px;
  width: 100%;
  z-index: 9;
  svg path {
    fill: #4a4f54;
  }
  div[data-test="tooltip-info"] {
    padding: 8px;
    width: 153px;
    background: rgb(48, 51, 54, 0.8);
    left: -13px;
    &::after {
      border-top-color: rgb(48, 51, 54, 0.8);
      margin-top: 0;
    }
  }
`;

const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  p[data-test="task-name"],
  p[data-test="task-cleaner"] {
    margin-top: 0;
    margin-bottom: 4px;
  }
`;

const TaskSubInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const TaskStatus = styled.div<{
  $backgroundColor?: string;
}>`
  margin-top: 8px;
  margin-bottom: 8px;
  align-items: center;
  display: flex;
  padding: 4px 8px;
  width: fit-content;
  border-radius: 4px;
  ${({ $backgroundColor }) =>
    $backgroundColor &&
    css`
      background-color: ${$backgroundColor};
    `};
  p[data-test="task-status-text"] {
    margin: 0;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  p {
    margin: 0;
  }
  &[data-test="task-priority-mes"] {
    justify-content: space-between;
    width: 100%;
  }
`;

interface IProps {
  task: IPropertyTask;
}

export const Status = ({ status }: any) => {
  return (
    <TaskStatus $backgroundColor={status.color}>
      {status?.icon && (
        <CleanIcon $size={16} src={status?.icon} alt={status?.label} />
      )}
      <ParagraphSmall data-test="task-status-text" white>
        {status?.label}
      </ParagraphSmall>
    </TaskStatus>
  );
};

export const Priority = ({ priority }: any) => {
  return (
    <Row>
      {priority?.priorityIcon && (
        <CleanIcon
          $size={12}
          src={priority?.priorityIcon}
          alt={priority?.priorityLabel}
        />
      )}
      <ParagraphSmall data-test="task-status-text" white>
        {priority?.priorityLabel}
      </ParagraphSmall>
    </Row>
  );
};

export const CleaningTaskTooltip: React.FC<IProps> = (props) => {
  const { task } = props;
  const taskInfo = task?.attributes;
  const displayStatus = taskInfo?.displayStatus;
  const taskPriority = taskInfo?.priority;
  const comments = task?.comments;
  const status = cleaningStatusContent.get(displayStatus);
  const priority = cleaningPriorityContent.get(taskPriority);
  return (
    <TooltipContent data-test="clean-tooltip-content">
      <ParagraphSmall bold white data-test="task-name">
        {taskInfo?.name}
      </ParagraphSmall>
      <ParagraphSmall data-test="task-cleaner" white>
        {taskInfo?.assignments?.[0]?.name}
      </ParagraphSmall>
      <TaskSubInfo>
        {status && <Status status={status} />}
        <Row data-test="task-priority-mes">
          {priority && <Priority priority={priority} />}
          <Row>
            <CleanIcon $size={12} src={messageIcon} />
            <ParagraphSmall data-test="task-message-num" white>
              {Number(comments?.length)}
            </ParagraphSmall>
          </Row>
        </Row>
      </TaskSubInfo>
    </TooltipContent>
  );
};

export const CleaningTask: React.FC<IProps> = (props) => {
  const { task } = props;
  const taskInfo = task?.attributes;
  const displayStatus = taskInfo?.displayStatus;
  const status = cleaningStatusContent.get(displayStatus);
  const [showPopup, setShowPopup] = useState(false);

  return (
    <>
      <CleaningTaskWrapper
        data-test="cleaning-task"
        $color={status?.color}
        onClick={(e) => {
          e.stopPropagation();
          setShowPopup(true);
        }}
      >
        <SVGIcon>
          <CleaningSprayIcon fill={status?.color} />
        </SVGIcon>
        <FinePrint>{taskInfo?.name}</FinePrint>
        <CleaningTooltipWrapper>
          <Tooltip
            hideIcon={true}
            position="bottom"
            testId="tooltip-info"
            text={<CleaningTaskTooltip task={task} />}
            timeOut={0}
          />
        </CleaningTooltipWrapper>
      </CleaningTaskWrapper>
      {showPopup && (
        <CleaningTaskPopup
          taskId={task?.id}
          handleClose={(e) => {
            e.stopPropagation();
            setShowPopup(false);
          }}
        />
      )}
    </>
  );
};

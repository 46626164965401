import React from "react";

const Resources = () => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.30078 2.75195C2.30078 2.06159 2.86043 1.50195 3.55078 1.50195H12.4539V6.84296C12.4539 7.4531 12.7039 8.03359 13.1413 8.45798C13.5779 8.88161 14.1654 9.11572 14.7733 9.11572H20.4999V11.5925C20.4999 12.0067 20.8357 12.3425 21.2499 12.3425C21.6641 12.3425 21.9999 12.0067 21.9999 11.5925V8.60456C21.9999 7.86341 21.7007 7.15364 21.1702 6.6361L15.1709 0.783489C14.6573 0.282416 13.9682 0.00194836 13.2506 0.00194836H3.55078C2.032 0.00194836 0.800781 1.23317 0.800781 2.75195V21.2481C0.800781 22.7668 2.032 23.9981 3.55078 23.9981H11.7233C12.1375 23.9981 12.4733 23.6623 12.4733 23.2481C12.4733 22.8338 12.1375 22.4981 11.7233 22.4981H3.55078C2.86043 22.4981 2.30078 21.9384 2.30078 21.2481V2.75195ZM13.9539 1.71857V6.84296C13.9539 7.04053 14.0346 7.23471 14.1858 7.38145C14.3378 7.52896 14.5488 7.61572 14.7733 7.61572H20.0263L14.1235 1.85719C14.0708 1.80577 14.014 1.75946 13.9539 1.71857Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.4141 16.6622C14.9999 16.6622 14.6641 16.998 14.6641 17.4122C14.6641 17.8264 14.9999 18.1622 15.4141 18.1622H23.707C24.1212 18.1622 24.457 17.8264 24.457 17.4122C24.457 16.998 24.1212 16.6622 23.707 16.6622H15.4141Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6641 14.4941C14.6641 14.0799 14.9999 13.7441 15.4141 13.7441H23.707C24.1212 13.7441 24.457 14.0799 24.457 14.4941C24.457 14.9083 24.1212 15.2441 23.707 15.2441H15.4141C14.9999 15.2441 14.6641 14.9083 14.6641 14.4941Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.4141 19.5801C14.9999 19.5801 14.6641 19.9159 14.6641 20.3301C14.6641 20.7443 14.9999 21.0801 15.4141 21.0801H23.707C24.1212 21.0801 24.457 20.7443 24.457 20.3301C24.457 19.9159 24.1212 19.5801 23.707 19.5801H15.4141Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6641 23.248C14.6641 22.8338 14.9999 22.498 15.4141 22.498H23.707C24.1212 22.498 24.457 22.8338 24.457 23.248C24.457 23.6623 24.1212 23.998 23.707 23.998H15.4141C14.9999 23.998 14.6641 23.6623 14.6641 23.248Z"
    />
  </>
);

export default Resources;

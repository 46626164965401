import React from "react";
import styled from "styled-components";

import { Tooltip } from "@madecomfy/webooi";

import External from "../assets/external.svg";
import { WrapperTooltip } from "./Style";

const Wrapper = styled.div`
  background: ${({ theme }) => theme.background.subtle3};
  padding: 8px;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  display: flex;
  cursor: pointer;
  position: relative;
  &:hover {
    background: ${({ theme }) => theme.background.subtle3Hover};
  }
`;
const Image = styled.img`
  width: 16px;
  height: 16px;
`;

interface IProps {
  url: string;
}
export const ButtonExternal: React.FC<IProps> = ({ url }) => {
  return (
    <Wrapper data-test="external-button" onClick={() => window.open(url)}>
      <Image src={External} alt="external" />
      <WrapperTooltip>
        <Tooltip
          hideIcon={true}
          position="top"
          text="Open"
          testId="tooltip"
          timeOut={100}
        />
      </WrapperTooltip>
    </Wrapper>
  );
};

import range from "lodash/range";
import React from "react";

import Month from "./Month";

import { YEAR_EARLIEST, YEAR_LATEST } from "./DatePicker";

interface IProps {
  calendar?: { [key: string]: any };
  tasks?: { [key: string]: any };
  month?: number;
  onDayClick?: (...agrs: any) => any;
  rates?: { [key: string]: any };
  selectedDates?: {
    startDate?: string;
    endDate: string;
  };
  today?: string;
  year: number;
  filter?: { [key: string]: any };
  pricing?: string;
  userRoles?: string[];
  timezone?: string;
}

const Year: React.FC<IProps> = (props: IProps) => {
  const { month, year } = props;
  if (year < parseFloat(YEAR_EARLIEST) || year > parseFloat(YEAR_LATEST)) {
    return (
      <div>
        Sorry, you can only view {YEAR_EARLIEST} to {YEAR_LATEST}
      </div>
    );
  }
  const viewingMonth = typeof month === "number";
  if (viewingMonth) {
    // if currently viewing a specific month, just return that single month
    return (
      <Month
        {...(props as any)}
        viewingMonth={true}
        showDaysOutsideMonth={true}
      />
    );
  }

  return (
    <>
      {range(0, 12).map((monthIndex) => {
        return (
          <Month
            {...(props as any)}
            month={monthIndex}
            key={`month-${monthIndex}`}
            viewingMonth={false}
            showDaysOutsideMonth={false}
          />
        );
      })}
    </>
  );
};

export default Year;

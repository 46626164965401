import { getJSON, generateErrorHandler } from "Utils/Network";
import { API_URL } from "Config";
import { STATEMENTS_DATA } from "Constants/actionTypes";

const handleError = generateErrorHandler(STATEMENTS_DATA.ERROR);

export function statementsRestore(params: { [key: string]: any }) {
  return async (dispatch: (...args: any) => any) => {
    dispatch({ type: STATEMENTS_DATA.PENDING, status: true });
    try {
      const { query, month, year, sort, number } = params;
      const sp = ["page[size]=25&include=property,owners"];
      if (query) sp.push(`search=${query}`);
      if (month) sp.push(`month=${month}`);
      if (year) sp.push(`year=${year}`);
      if (number) sp.push(`page[number]=${number}`);
      if (sort) sp.push(`sort=${sort}`);

      const url = `${API_URL}v3/statements?${sp.join("&")}`;
      const json = await getJSON(url);
      const { data, meta, included } = json;
      const statements = data?.map((statement: { [key: string]: any }) => {
        const { attributes, relationships, id, type } = statement;

        // get property data
        const propertyId = relationships?.property?.data?.id;
        const propertyData = included?.find(
          (includeItem: any) =>
            includeItem?.type === "property" && includeItem?.id === propertyId,
        );

        // Get owner data
        const ownerIds =
          relationships?.owners?.data?.map(
            (owner: { [key: string]: any }) => owner.id,
          ) || [];
        const ownerData = ownerIds
          .map((ownerId: string) =>
            included?.find(
              (includeItem: { [key: string]: any }) =>
                includeItem?.type === "user" && includeItem?.id === ownerId,
            ),
          )
          // filter out undefined and null
          .filter(Boolean);

        return {
          ...attributes,
          ownerData: ownerData.map(
            (owner: { [key: string]: any }) => owner?.attributes,
          ),
          propertyData: propertyData?.attributes,
          id,
          type,
        };
      });
      dispatch({
        type: STATEMENTS_DATA.SUCCESS,
        response: statements,
        totalPages: meta?.totalPages,
        totalCount: meta?.totalCount,
      });
    } catch (err: any) {
      // eslint-disable-next-line
      console.warn("statementsRestore err", err);
      dispatch(handleError(err));
    }
  };
}

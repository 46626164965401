// wrapper to use React.portals
// this causes any children (typically Popup) to be rendered in a different part of the tree.
// use case was so ancestor styles were eliminated.

import { PureComponent } from "react";
import ReactDOM from "react-dom";

const modalRoot = document.getElementById("modal-root");

class PopupPortal extends PureComponent<any> {
  el: HTMLDivElement;

  constructor(props: any) {
    super(props);
    this.el = document.createElement("div");
  }

  componentDidMount() {
    modalRoot?.appendChild(this.el);
  }

  componentWillUnmount() {
    modalRoot?.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

export default PopupPortal;

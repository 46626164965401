import { API_URL } from "Config";
import { CURRENT_PROPERTY } from "Constants/actionTypes";
import {
  generateErrorHandler,
  generateURL,
  getJSON,
  patchJSON,
} from "Utils/Network";
import { UPDATE as mode } from "Constants/crud";

const handlePhotoError = generateErrorHandler("PHOTO_ERROR");

export const getSignedUrl = async (code: string) => {
  try {
    const json = await getJSON(`${API_URL}v3/properties/${code}/photo-url`);
    const { publicUrl, signedUrl } = json.data.attributes;
    return { publicUrl, signedUrl };
  } catch (err: any) {
    // eslint-disable-next-line
    console.warn("getSignedUrl error", err);
    return handlePhotoError(err);
  }
};

export const photoCreate = async (url: any, file: any) => {
  try {
    const response = await fetch(url, { method: "PUT", body: file });
    return response.ok;
  } catch (err: any) {
    // eslint-disable-next-line
    console.warn("photoCreate error", err);
    return handlePhotoError(err);
  }
};

const handleError = generateErrorHandler(CURRENT_PROPERTY.ERROR);

export function propertyPhotosUpdate(code: string, photos: {[key: string]: any}[]) {
  return async (dispatch: (...args: any) => any) => {
    try {
      dispatch({ mode, type: CURRENT_PROPERTY.PENDING, status: true });
      const json = await patchJSON(`${API_URL}v3/properties/${code}/photos`, {
        photos,
      });
      const currentProperty = json.data.attributes;
      dispatch({ mode, type: CURRENT_PROPERTY.SUCCESS, currentProperty });
    } catch (err: any) {
      // eslint-disable-next-line
      console.warn("propertyPhotosUpdate error", err);
      dispatch(handleError(err));
    }
  };
}

// this is not currently used
export const photoTagsRestore = async (search: string) => {
  const url = generateURL(`${API_URL}v3/photo-tags`, { search });
  const json = await getJSON(url);
  return json.data.attributes.tags;
};

export const getInstructionsSignedUrl = async (code: string) => {
  try {
    const json = await getJSON(`${API_URL}v3/properties/${code}/instructions/photo-url`);
    const { s3Key, signedUrl } = json.data.attributes;
    return { s3Key, signedUrl };
  } catch (err: any) {
    // eslint-disable-next-line
    console.warn("getInstructionsSignedUrl error", err);
    return handlePhotoError(err);
  }
};

export const getManualsSignedUrl = async (code: string, type: string = "image") => {
  const uploadUrl = type === "image" ? `${API_URL}v3/properties/${code}/manuals/attachment-url` : `${API_URL}v3/properties/${code}/manuals/attachment-url?type=pdf`;

  try {
    const json = await getJSON(uploadUrl);
    const { s3Key, signedUrl } = json.data.attributes;
    return { s3Key, signedUrl };
  } catch (err) {
    // eslint-disable-next-line
    console.warn("getManualsSignedUrl error", err);
    return handlePhotoError(err);
  }
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const generateUid = () => {
  return Math.random().toString(16).slice(2);
};

const loadScript = (id: string, url: string) =>
  new Promise<void>((resolve, reject) => {
    const scriptId = `madecomfy-${id}`;
    const existingScript = document.getElementById(scriptId);
    if (existingScript) {
      resolve();
    }
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = url;
      script.id = scriptId;
      script.onload = () => {
        resolve();
      };
      script.onerror = reject;
      document.body.appendChild(script);
    }
  });

export default loadScript;

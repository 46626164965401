import React, { useState } from "react";
import styled from "styled-components";

import Avatar from "../assets/avatar.svg";
import Logout from "../assets/logout.svg";
import Settings from "../assets/settings.svg";
import DropDown from "Components/DropDown";

import { titleCase } from "Utils";

const Wrapper = styled.div`
  display: none;
  @media screen and (min-width: ${({ theme }) => theme.minTablet}) {
    background: ${({ theme }) => theme.white};
    padding: 24px 0;
    display: flex;
  }
`;
const Container = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 32px;
  margin-right: 32px;
`;
const User = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  p {
    margin: 0;
    font-family: InterBold;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.text.default};
  }
  img {
    width: 32px;
    height: 32px;
  }
`;
const SettingsIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
  padding: 8px;
  box-sizing: content-box;
  :hover {
    background: ${({ theme }) => theme.background.subtle3};
    border-radius: 4px;
  }
`;
const Text = styled.div`
  display: flex;
  gap: 16px;
`;

interface IProps {
  user?: any;
}

const Header: React.FC<IProps> = ({ user }) => {
  if (!user) return null;
  const [isMenuVisible, setMenuVisible] = useState(false);
  const toggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };
  return (
    <Wrapper id="header">
      <Container>
        <User>
          <img alt="avatar" src={Avatar} />
          <div>
            <p>{titleCase(user?.name)}</p>
          </div>
        </User>
        <SettingsIcon alt="settings" src={Settings} onClick={toggleMenu} />
      </Container>
      {isMenuVisible && (
        <DropDown
          align="left"
          caret={false}
          isDisplayed={true}
          items={[
            {
              label: (
                <Text>
                  <img src={Settings} alt="" />
                  Settings
                </Text>
              ),
              link: "/settings",
            },
            {
              label: (
                <Text>
                  <img src={Logout} alt="" />
                  Logout
                </Text>
              ),
              link: "/logout",
            },
          ]}
          position={{ right: "32px", top: "90px" }}
          onClickOutside={() => setMenuVisible(false)}
          minWidth="165px"
        />
      )}
    </Wrapper>
  );
};

export default Header;

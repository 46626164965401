import React from "react";
import styled from "styled-components";

import { Alert, ALERT_WARNING, InputTitleLabel } from "@madecomfy/webooi";

import { Badge } from "./UserRoles";
import { formatDateWithTime } from "Utils";

interface IProps {
  user: {
    email: string;
    enabled: boolean;
    firstName: string;
    lastLoginAt: string | Date;
    lastName: string;
    mobile: string;
    nps: string;
    roles: any[];
  };
}

// could just use InputText disabled...
const Item = styled.div`
  margin: 15px 0;
`;

const Text = styled.div`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.charcoalLight50};
  color: ${({ theme }) => theme.charcoal};
  font-size: 16px;
  margin-top: 4px;
  min-height: 48px;
  padding: 12px 16px;
  white-space: normal;
`;

const StaticText = ({ label, value }: any) => {
  return (
    <Item>
      <InputTitleLabel>{label}</InputTitleLabel>
      <Text data-test="static-text">{value}</Text>
    </Item>
  );
};

const StaticUserDetails = ({
  user: {
    email,
    enabled,
    firstName,
    lastLoginAt,
    lastName,
    mobile,
    nps,
    roles,
  },
}: IProps) => {
  return (
    <div>
      {enabled === false && (
        <Alert message="This account is disabled" type={ALERT_WARNING} />
      )}
      <StaticText label="First Name" value={firstName} />
      <StaticText label="Last Name" value={lastName} />
      <StaticText label="Email" value={email} />
      <StaticText label="Mobile" value={mobile} />
      <StaticText
        label="Roles"
        value={roles.map((role: any) => (
          <Badge key={role} role={role} />
        ))}
      />
      <StaticText label="Net Promoter Score" value={nps} />
      <StaticText label="Last login" value={formatDateWithTime(lastLoginAt)} />
    </div>
  );
};

export default StaticUserDetails;

import { getJSON, generateErrorHandler } from "Utils/Network";
import { API_URL } from "Config";
import { NEWSLETTER_DATA } from "Constants/actionTypes";

const handleError = generateErrorHandler(NEWSLETTER_DATA.ERROR);

export function newsletterRestore() {
  return async (dispatch: (...args: any) => any) => {
    dispatch({ type: NEWSLETTER_DATA.PENDING, status: true });
    try {
      const url = `${API_URL}v3/newsletters`;
      const json = await getJSON(url);
      const { data } = json;
      const newsletters = data.map((item: {[key: string]: any}) => {
        const { attributes, id } = item;
        return {
          ...attributes,
          id,
        };
      });
      dispatch({
        type: NEWSLETTER_DATA.SUCCESS,
        response: newsletters,
      });
    } catch (err: any) {
      // eslint-disable-next-line
      console.warn("newslettersRestore err", err);
      dispatch(handleError(err));
    }
  };
}

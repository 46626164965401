import React, { Component } from "react";

import { Alert, ALERT_ERROR, BUTTON_PRIMARY } from "@madecomfy/webooi";

import UserDetails from "./components/UserDetails";
import ComfyContainer from "Components/ComfyContainer";
import Loading from "Components/Loading";
import { H4 } from "Components/Typography";

interface IProps {
  // code is required, but when navigating away there is a split second where this errors
  code: string;
  editUserRoles: { [key: string]: any };
  editUserStatus: { [key: string]: any };
  reset: (...args: any) => any;
  userRestore: (...args: any) => any;
  userUpdate: (...args: any) => any;
}

class UserEdit extends Component<IProps> {
  render() {
    const { editUserRoles, editUserStatus, code, reset, userRestore } =
      this.props;
    const {
      connectionError,
      error,
      errorMessage,
      user,
      ownedCompanies,
      ownedProperties,
    } = editUserStatus;
    if (error || connectionError) {
      // eslint-disable-next-line
      console.warn("UserEdit error:", error, connectionError);
      return (
        <ComfyContainer>
          <H4>Editing user - failed</H4>
          <Alert
            buttons={[
              {
                callback: () => {
                  reset();
                  userRestore(code);
                },
                label: "Try again",
                type: BUTTON_PRIMARY,
              },
            ]}
            message={errorMessage || `There was an error loading user: ${code}`}
            testId="user-edit"
            title="Oops"
            type={ALERT_ERROR}
          />
        </ComfyContainer>
      );
    }

    if (!user || editUserRoles.pending || editUserStatus.pending) {
      return <Loading task="updatingUserEdit" />;
    }

    const canUpdateUser = user.canUpdate;

    return (
      <ComfyContainer>
        <H4>{canUpdateUser ? "Editing user" : "User info"}</H4>
        {!canUpdateUser && (
          <div>
            For any user admin changes, please add a request in tech_support
            slack channel.
          </div>
        )}
        <UserDetails
          canUpdateUser={canUpdateUser}
          editUserRoles={editUserRoles}
          editUserStatus={editUserStatus}
          userDetails={user}
          ownedCompanies={ownedCompanies}
          ownedProperties={ownedProperties}
          onSubmit={this.handleSubmit.bind(this)}
          reset={reset}
        />
      </ComfyContainer>
    );
  }

  // cannot spy on arrow function
  handleSubmit(updatedDetails: { [key: string]: any }) {
    const { user: updatedUser, properties, companies } = updatedDetails;
    const { code, userUpdate } = this.props;
    userUpdate(code, updatedUser, properties, companies);
  }

  componentDidMount() {
    const { code, editUserStatus, userRestore, reset } = this.props;
    if (editUserStatus.user) {
      reset();
    }
    userRestore(code);
  }
}

export default UserEdit;

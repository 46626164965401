import React, { Component, Fragment } from "react";
import styled from "styled-components";

import DesktopMenu, { LineSpacer } from "./DesktopMenu";
import MenuToggler from "./MenuToggler";
import MobileMenu from "./MobileMenu";

const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.2);
  height: 100%;
  opacity: 0.7;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 9;
  @media (min-width: ${({ theme }) => theme.minTablet}) {
    display: none;
  }
`;

interface IProps {
  menuItems: any[];
  user: any;
  isMenuOpen: any;
  menuOpen: boolean;
}
class MenuBlock extends Component<IProps> {
  state = {
    isUserOpen: false,
    isMobileOpen: false,
  };

  toggleMobileMenu = () => {
    this.setState({
      isMobileOpen: !this.state.isMobileOpen,
    });
  };

  closeMobileMenu = () => {
    this.setState({ isMobileOpen: false });
  };

  toggleMenu = () => {
    const { isUserOpen } = this.state;
    this.setState({ isUserOpen: !isUserOpen });
  };

  componentDidUpdate(prevProps: any, prevState: { isMobileOpen: boolean }) {
    const { isMobileOpen } = this.state;
    if (prevState.isMobileOpen !== isMobileOpen) {
      document!.querySelector("body")!.style.overflow = isMobileOpen
        ? "hidden"
        : "visible";
    }
  }

  render() {
    const { isMobileOpen } = this.state;
    const { menuItems, isMenuOpen, menuOpen } = this.props;

    return (
      <Fragment>
        <LineSpacer />
        {/* @ts-ignore */}
        <DesktopMenu
          // @ts-ignore
          items={menuItems}
          isMenuOpen={isMenuOpen}
          menuOpen={menuOpen}
        />
        {isMobileOpen && (
          <Overlay id="test-id-mobile-overlay" onClick={this.closeMobileMenu} />
        )}
        <MenuToggler open={isMobileOpen} onClick={this.toggleMobileMenu} />
        <MobileMenu
          // @ts-ignore
          visible={isMobileOpen}
          items={menuItems}
          handleClose={this.closeMobileMenu}
        />
      </Fragment>
    );
  }
}

export default MenuBlock;

import styled from "styled-components";

export const DoubleCol = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 0;
`;

export const BorderedBlock = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.sand94};
  margin: 0 16px;
  :last-of-type {
    //  border-bottom: none;
  }
`;

export const Italic = styled.div<{ isEstimated: boolean }>`
  font-style: ${({ isEstimated }) => (isEstimated ? "italic" : "normal")};
`;

import { API_URL } from "Config";
import { generateErrorHandler, generateURL, getJSON } from "Utils/Network";
import { USER_HISTORY } from "Constants/actionTypes";
import get from "lodash/get";

const handleError = generateErrorHandler(USER_HISTORY.ERROR);

export function usersRestore(pageNumber: number, pageSize: number, search: string | undefined) {
  return async (dispatch: (...args: any) => any) => {
    dispatch({ type: USER_HISTORY.PENDING, status: true });
    try {
      const url = generateURL(`${API_URL}v3/users`, {
        "page[number]": pageNumber,
        "page[size]": pageSize,
        search: search || undefined,
      });
      const json = await getJSON(url);
      dispatch({
        type: USER_HISTORY.SUCCESS,
        response: get(json, "data", []).map((user: {[key: string]: any}) => {
          return { ...user.attributes, id: user.id };
        }),
        totalPages: get(json, "meta.totalPages", 0),
        totalCount: get(json, "meta.totalCount", 0),
      });
    } catch (err: any) {
      // eslint-disable-next-line
      console.warn("usersRestore err", err);
      dispatch(handleError(err));
    }
  };
}

export const ownersRestore = async (pageNumber: number, pageSize: number, search: string | undefined, filter: string) => {
  try {
  const url = generateURL(`${API_URL}v3/users`, {
    "page[number]": pageNumber,
    "page[size]": pageSize,
    search: search || undefined,
    "filters[role]": filter,
  });
  const json = await getJSON(url);
  const result = json.data.map((item: { [key: string]: any }) => {
    const { attributes, id } = item;
    return {
      name: attributes?.fullName,
      id,
    };
  });
  return result;
} catch (err) {
  // eslint-disable-next-line no-console
  console.warn("ownersRestore err", err);
}
};

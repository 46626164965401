import { BOOKING, BOOKING_QUOTES, DISMISS_ERRORS } from "Constants/actionTypes";

const initialState = {
  booking: null,
  error: false,
  errorCode: 0,
  errorDetail: "",
  errorMessage: "",
  errorTitle: "",
  mode: null,
  pending: false,
  success: false,
};

export function currentBooking(state = initialState, action: {[key: string]: any}) {
  switch (action.type) {
    case BOOKING.SUCCESS:
      const { booking, mode } = action;
      const nextState = {
        error: false,
        errorCode: 0,
        errorDetail: "",
        errorMessage: "",
        errorTitle: "",
        pending: false,
        success: true,
        booking,
        mode,
      };
      // also, notice BOOKING.RESET is not fired anywhere... hmmm?
      return { ...state, ...nextState };
    case BOOKING_QUOTES.SUCCESS:
      const currentBooking = { ...state.booking as any };
      const { bookingId, quotes } = action;
      if (currentBooking?.id !== bookingId) {
        return state;
      }
      return {
        ...state,
        booking: {
          ...currentBooking,
          quotes,
        },
      };
    case BOOKING.PENDING:
      return { ...state, mode: action.mode, pending: action.status };
    case BOOKING.ERROR:
      return {
        ...state,
        error: true,
        errorCode: action.errorCode,
        errorDetail: action.errorDetails,
        errorMessage: action.errorMessage,
        errorTitle: action.errorTitle,
        pending: false,
        success: false,
      };
    case DISMISS_ERRORS:
      return {
        ...state,
        error: false,
        errorCode: 0,
        errorDetail: "",
        errorMessage: "",
        errorTitle: "",
      };
    case BOOKING.RESET:
      return initialState;
    default:
      return state;
  }
}

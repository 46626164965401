import styled from "styled-components";
import React from "react";

import ComfyContainer from "Components/ComfyContainer";

const Wrapper = styled(ComfyContainer)`
  height: 90vh;
  iframe{
  border: 0;
  }
`;

export const KnowledgeCentre = () => {
  return (
    <Wrapper>
      <iframe
        allowFullScreen
        src="https://helpcentre.madecomfy.com.au/s/mc-pro"
        width="100%"
        height="100%"
      ></iframe>
    </Wrapper>
  );
};

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { newsletterRestore } from "Actions/resources";

import Newsletter from "./Newsletter";

const mapStateToProps = (state: any) => ({
  user: state.authentication.sessionStatus.user,
  newsletters: state.newsletter.newsletters,
});

const mapDispatchToProps = (dispatch: any) => ({
  newsletterRestore: bindActionCreators(newsletterRestore, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Newsletter);

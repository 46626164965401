import styled, { css } from "styled-components";

export const MonthName = styled.div<any>`
  display: flex;
  font-family: Inter;
  justify-content: space-between;
  ${({ viewingMonth }) =>
    viewingMonth &&
    css`
      display: none;
    `}
  h5 {
    width: 100%;
  }
`;

export const MonthTable = styled.table<any>`
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
`;

export const THead = styled.thead<any>`
  tr {
    height: ${({ viewingMonth }) => (viewingMonth ? "38px" : "36px")};
    td {
      line-height: 32px;
      text-align: center;
      vertical-align: middle;
      ${({ viewingMonth }) =>
        viewingMonth &&
        css`
          font-weight: 700;
          text-align: right;
          padding-right: 12px;
          font-size: 16px;
        `}
      @media screen and (max-width: ${({ theme }) => theme.maxMobile}) {
        font-weight: 400;
        text-align: center;
        font-size: 14px;
        padding: 0;
      }
    }
  }
`;

export const DayCell = styled.td<any>`
  padding: 0;
  position: relative;
  text-align: right;
  transition: background 0.1s linear;
  width: calc(100% / 7);
  @media screen and (max-width: ${({ theme }) => theme.maxMobile}) {
    ${({ outsideMonth }) =>
      outsideMonth &&
      css`
        visibility: hidden;
      `}
  }
  ${({ isRestricted }) => isRestricted && `opacity: 0.3 !important;`}

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      background: ${theme.background.brandHover};
      box-shadow: inset 0px 0px 0px 1px ${theme.turquoise};
    `}

  ${({ isToday, theme }) =>
    isToday &&
    css`
      font-weight: bold;
      color: ${theme.turquoise};
      box-shadow: inset 0px 0px 0px 1px ${theme.turquoise80};
    `}

  ${({ pendingStart, pendingDuring, pendingEnd, theme }) => {
    if (pendingStart || pendingEnd) {
      return css`
        background: ${theme.turquoise};
      `;
    }
    if (pendingDuring) {
      return css`
        background: ${theme.turquoise80};
      `;
    }
  }}

  ${({ outsideMonth }) =>
    outsideMonth &&
    css`
      opacity: 0.6;
    `}
`;

export const TBody = styled.tbody`
  border: 1px solid ${({ theme }) => theme.charcoalLight10};
  border-width: 1px 0 0 1px;
  overflow: hidden;
  ${DayCell} {
    border: 1px solid ${({ theme }) => theme.charcoalLight10};
    border-width: 0 1px 1px 0;
    :hover {
      cursor: pointer;
      // font-weight: bold; // affects all the status pill text too...
      box-shadow: inset 0px 0px 0px 2px ${({ theme }) => theme.turquoise};
    }
  }

  ${css`
    ${Array(7)
      .fill(0)
      // render `td` cells with left most on top. so inner contents can flow over on to neighbours to the right...
      .map((_, i) => `td:nth-child(${i + 1}) {z-index: ${7 - i};}`)
      .join("\n")}
  `}
`;

export const MonthRow = styled.tr<any>`
  ${({ numberOfEvents, numberOfCleaningTasks, viewingMonth }) =>
    // the following calculations need to correlate with Status > StatusPill's yIndex
    // we only need to expand the row if there are any more than 2 coinciding events, otherwise they fit easily within the base height of each table row.
    // the formula is: base y offset to start rendering event rows + the height of each event row * number of event rows
    viewingMonth
      ? css`
          ${
            // desktop view, event rows are slightly larger, but so is the table row
            numberOfEvents + numberOfCleaningTasks > 2
              ? `height: ${
                  34 + 24 * numberOfEvents + numberOfCleaningTasks * 27 // rowHeight = originalHeight + (eventTaskHeight + gap) * numberOfEvents + (cleaningTaskHeight + gap) * numberOfCleaningTasks
                }px;`
              : `height: 100px;`
          }
          @media screen and (max-width: ${({ theme }) => theme.maxMobile}) {
            ${
              // mobile view, event rows are smaller
              numberOfEvents > 2
                ? `height: ${30 + 10 * numberOfEvents}px;`
                : `height: 48px;`
            }
          }
        `
      : css`
          height: 48px;
          .month-view-only {
            display: none;
          }
        `}
`;

// Common pattern for expanding contracting elements.
// Currently is fixed size. TODO nope!!!
import React from "react";
import styled, { css } from "styled-components";

import { getColourFromString } from "Constants/theme";
import { ParagraphSmall } from "Components/Typography";

interface IProps {
  colour?: string;
  text?: {
    expanded: string;
    collapsed: string;
  };
  isExpanded: boolean;
  handleExpand?: (...args: any) => any;
  size?: number;
  testId?: string;
  filled?: boolean;
}

const SVG = styled.svg<any>`
  cursor: pointer;
  ${({ marginLeft }) =>
    marginLeft &&
    css`
      margin-left: 8px;
    `};
  transform: rotate(${({ isExpanded }) => (isExpanded ? 180 : 0)}deg);
  transition: transform 0.15s ease-in-out;
`;

const ExpandWithText = styled(ParagraphSmall)`
  color: ${({ theme }) => theme.navy};
  cursor: pointer;
  text-decoration: underline;
`;

const Arrow = ({
  colour,
  isExpanded,
  marginLeft,
  onClick,
  size,
  title,
  filled = false,
}: {
  [key: string]: any;
}) => {
  return (
    <SVG
      width={size}
      height={size}
      viewBox="0 0 11 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      isExpanded={isExpanded}
      marginLeft={marginLeft}
      onClick={onClick}
    >
      {/* {title && <title>{title}</title>} TODO AND? */}
      {filled ? (
        <path
          d="M6.20841 6.99439C6.08919 7.12727 5.88102 7.12727 5.76181 6.99439L0.800394 1.46433C0.627094 1.27117 0.764187 0.963993 1.0237 0.963993L10.9465 0.963994C11.206 0.963994 11.3431 1.27117 11.1698 1.46433L6.20841 6.99439Z"
          fill={getColourFromString(colour) as any}
        />
      ) : (
        <path
          d="M10.5815 0.880022C10.5815 0.795201 10.5391 0.699776 10.4754 0.63616L9.94531 0.106027C9.8817 0.0424108 9.78627 0 9.70145 0C9.61663 0 9.52121 0.0424108 9.45759 0.106027L5.29074 4.27288L1.12388 0.106027C1.06027 0.0424108 0.964844 0 0.880022 0C0.784598 0 0.699777 0.0424108 0.636161 0.106027L0.106027 0.63616C0.0424107 0.699776 0 0.795201 0 0.880022C0 0.964844 0.0424107 1.06027 0.106027 1.12388L5.04688 6.06473C5.11049 6.12835 5.20592 6.17076 5.29074 6.17076C5.37556 6.17076 5.47098 6.12835 5.5346 6.06473L10.4754 1.12388C10.5391 1.06027 10.5815 0.964844 10.5815 0.880022Z"
          fill={getColourFromString(colour) as any}
        />
      )}
    </SVG>
  );
};

const ExpandChevron: React.FC<IProps> = (props) => {
  const {
    colour = "#303336",
    handleExpand,
    isExpanded,
    size = 12,
    testId,
    text,
    filled = false,
  } = props;
  const p = {} as { [key: string]: any };
  if (testId) {
    p["data-test"] = testId;
  }
  if (handleExpand) {
    p.onClick = handleExpand;
  }

  const arrowProps = {
    colour,
    title: "arrow",
    isExpanded,
    marginLeft: Boolean(text),
    size,
    filled,
  };

  if (text) {
    return (
      // @ts-ignore
      <ExpandWithText {...p}>
        {isExpanded ? text.expanded : text.collapsed}
        <Arrow {...arrowProps} />
      </ExpandWithText>
    );
  }
  return <Arrow {...p} {...arrowProps} />;
};

export default ExpandChevron;

// any modifications to this file should also be copied to fitzgerald!

// Base colours

const COLOUR_BASE_RED100 = "#fceae7";
const COLOUR_BASE_RED200 = "#F8D4CF";
const COLOUR_BASE_RED500 = "#de2910";

const COLOUR_BASE_BLUE100 = "#dee5f5";
const COLOUR_BASE_BLUE200 = "#c8d4ed";
const COLOUR_BASE_BLUE500 = "#1c4eac";
const COLOUR_BASE_BLUE600 = "#163e8a";

const COLOUR_BASE_PINK500 = "#f44c7f";

const COLOUR_BASE_ORANGE100 = "#fef5ed";
const COLOUR_BASE_ORANGE200 = "#fdebdb";
const COLOUR_BASE_ORANGE400 = "#f6b170";
const COLOUR_BASE_ORANGE500 = "#f49d4c";

const COLOUR_BASE_GREEN100 = "#eff8f3";
const COLOUR_BASE_GREEN200 = "#def1e7";
const COLOUR_BASE_GREEN300 = "#cee9db";
const COLOUR_BASE_GREEN500 = "#5ab786";
const COLOUR_BASE_GREEN600 = "#48926b";

const COLOUR_BASE_YELLOW500 = "#ffcf01";

const COLOUR_BASE_TEAL0 = "#d2f1f6";
const COLOUR_BASE_TEAL100 = "#f2fbfc";
const COLOUR_BASE_TEAL300 = "#68b8c4";
const COLOUR_BASE_TEAL500 = "#2192a3";
const COLOUR_BASE_TEAL600 = "#1a7582";
const COLOUR_BASE_TEAL700 = "#145862";

const COLOUR_BASE_NAVY500 = "#30637f";

const COLOUR_BASE_NEUTRAL0 = "#ffffff";
const COLOUR_BASE_NEUTRAL50 = "#f4f4f5";
const COLOUR_BASE_NEUTRAL100 = "#e9e9ea";
const COLOUR_BASE_NEUTRAL200 = "#d4d3d5";
const COLOUR_BASE_NEUTRAL300 = "#b6b5b7";
const COLOUR_BASE_NEUTRAL500 = "#939296";
const COLOUR_BASE_NEUTRAL900 = "#303336";
// const COLOUR_BASE_NEUTRAL1000 = "#1d1d1e";

const COLOUR_BASE_TRANSPARENT = "transparent";

// -------- old colours

const COLOUR_BLUE_40 = "#30779C";

const COLOUR_CHARCOAL_LIGHT_50 = "#939296";
const COLOUR_CHARCOAL_LIGHT_30 = "#b6b5b7";
const COLOUR_CHARCOAL_LIGHT_10 = "#e0dfdf";

const COLOUR_DARK_PETROL = "#003b4d";
const COLOUR_DARK_PETROL_LIGHT_50 = "#7f9da6";
const COLOUR_DARK_PETROL_LIGHT_20 = "#ccd7db";

const COLOUR_NAVY = "#34657f";
const COLOUR_NAVY_LIGHT_20 = "#d6e0e5";
const COLOUR_NAVY_LIGHT_50 = "#99b2bf";

const COLOUR_LEMON_LIGHT_50 = "#ffe780";

const COLOUR_SAND = "#d6d1ca";
const COLOUR_SAND_LIGHT_50 = "#EAE7E4";
const COLOUR_SAND_LIGHT_30 = "#F2F1EF";
const COLOUR_SAND_LIGHT_20 = "#F6F5F4";

const COLOUR_TURQUOISE = "#64ccc9";
const COLOUR_TURQUOISE_LIGHT_20 = "#E0F5F4";

const COLOUR_DARK_TRANSPARENT = "#303336CC";

const SHADOW_GREY = "0px 0px 6px rgba(74, 79, 84, 0.7)";
const SHADOW_RED = "0px 0px 6px rgba(222, 41, 16, 0.7)";
const SHADOW_NAVY = "0px 0px 6px rgba(52, 101, 127, 0.7)";
const SHADOW_NONE = "none";

const FONT_INTER = "'Inter', sans-serif;";

const FONT_SIZE_LARGE = "18px";
const FONT_SIZE_MEDIUM = "16px";
const FONT_SIZE_SMALL = "14px";

const BORDER_RADIUS_SM = "4px";
const BORDER_RADIUS_MD = "8px";

const colours = {
  // new
  // Backgrounds
  background: {
    white: COLOUR_BASE_NEUTRAL0,
    whiteHover: COLOUR_BASE_NEUTRAL50,
    brand: COLOUR_BASE_TEAL500,
    brandHover: COLOUR_BASE_TEAL0,
    neutral: COLOUR_BASE_NEUTRAL500,
    subtle: COLOUR_BASE_NEUTRAL200,
    subtleHover: COLOUR_BASE_NEUTRAL300,
    subtle2: COLOUR_BASE_NEUTRAL100,
    subtle2Hover: COLOUR_BASE_NEUTRAL200,
    subtle3: COLOUR_BASE_NEUTRAL50,
    subtle3Hover: COLOUR_BASE_NEUTRAL100,
    disabled: COLOUR_BASE_NEUTRAL100,
    accentYellow: COLOUR_BASE_YELLOW500,
    accentMagenta: COLOUR_BASE_PINK500,
    accentNavy: COLOUR_BASE_NAVY500,
    accentTeal: COLOUR_BASE_TEAL300,
    accentTealBold: COLOUR_BASE_TEAL700,
    accentGreenSubtle: COLOUR_BASE_GREEN100,
    alert: COLOUR_BASE_RED100,
    alertHover: COLOUR_BASE_RED200,
    alertBold: COLOUR_BASE_RED500,
    info: COLOUR_BASE_BLUE100,
    infoHover: COLOUR_BASE_BLUE200,
    infoBold: COLOUR_BASE_BLUE500,
    infoBoldHover: COLOUR_BASE_BLUE600,
    lightTeal: COLOUR_BASE_TEAL100,
    warning: COLOUR_BASE_ORANGE100,
    warningHover: COLOUR_BASE_ORANGE200,
    warningBold: COLOUR_BASE_ORANGE500,
    warningBoldHover: COLOUR_BASE_ORANGE400,
    success: COLOUR_BASE_GREEN200,
    successHover: COLOUR_BASE_GREEN300,
    successBold: COLOUR_BASE_GREEN500,
    successBoldHover: COLOUR_BASE_GREEN600,
    transparent: COLOUR_BASE_TRANSPARENT,
  },
  // Borders
  border: {
    default: COLOUR_BASE_NEUTRAL100,
    brand: COLOUR_BASE_TEAL500,
    alert: COLOUR_BASE_RED500,
    transparent: COLOUR_BASE_TRANSPARENT,
    white: COLOUR_BASE_NEUTRAL0,
    accentYellow: COLOUR_BASE_YELLOW500,
    slateGray: COLOUR_BASE_NEUTRAL500,
  },
  // Icons
  icon: {
    default: COLOUR_BASE_NEUTRAL900,
    inverse: COLOUR_BASE_NEUTRAL0,
    brand: COLOUR_BASE_TEAL500,
    disabled: COLOUR_BASE_NEUTRAL300,
    subtle: COLOUR_BASE_NEUTRAL500,
    subtle2: COLOUR_BASE_NEUTRAL100,
    inactive: COLOUR_BASE_NEUTRAL500,
    accentYellow: COLOUR_BASE_YELLOW500,
    accentGreen: COLOUR_BASE_GREEN500,
    accentOrange: COLOUR_BASE_ORANGE500,
    info: COLOUR_BASE_BLUE500,
    alert: COLOUR_BASE_RED500,
    warning: COLOUR_BASE_ORANGE500,
    success: COLOUR_BASE_GREEN500,
  },
  // Text
  text: {
    default: COLOUR_BASE_NEUTRAL900,
    inverse: COLOUR_BASE_NEUTRAL0,
    brand: COLOUR_BASE_TEAL500,
    inactive: COLOUR_BASE_NEUTRAL500,
    subtle: COLOUR_BASE_NEUTRAL500,
    link: COLOUR_BASE_TEAL500,
    linkHover: COLOUR_BASE_TEAL600,
    disabled: COLOUR_BASE_NEUTRAL300,
    info: COLOUR_BASE_BLUE500,
    alert: COLOUR_BASE_RED500,
    alertHover: COLOUR_BASE_RED200,
    warning: COLOUR_BASE_ORANGE500,
    success: COLOUR_BASE_GREEN500,
  },

  // these colours need to be replaced as semantic variables across the files
  // primary colours
  turquoise: COLOUR_BASE_TEAL300,
  turquoise48: "#3DB8B4",
  turquoise80: COLOUR_BASE_TEAL0,

  navy: COLOUR_BASE_NAVY500,
  navyDark10: "#2e5a72",
  navyLight50: COLOUR_NAVY_LIGHT_50,
  navyLight20: "#d6e0e5",

  white: COLOUR_BASE_NEUTRAL0,

  blue40: COLOUR_BLUE_40,

  neutral100: COLOUR_BASE_NEUTRAL100,
  neutral500: COLOUR_BASE_NEUTRAL500,

  // secondary colours
  darkPetrol: COLOUR_DARK_PETROL,
  darkPetrol14: "#003545",
  darkPetrol94: "#E0F8FF",
  darkTransparent: COLOUR_DARK_TRANSPARENT,

  charcoal: COLOUR_BASE_NEUTRAL900,
  charcoalLight50: COLOUR_CHARCOAL_LIGHT_50,
  charcoalLight30: COLOUR_CHARCOAL_LIGHT_30,
  charcoalLight20: "#cbc9cb",
  charcoalLight10: COLOUR_CHARCOAL_LIGHT_10,
  charcoal15: "#25282A",

  sand: COLOUR_SAND,
  sand91: COLOUR_SAND_LIGHT_50,
  sand94: COLOUR_SAND_LIGHT_30,
  sand96: COLOUR_SAND_LIGHT_20,
  sand98: "#FAFAF9",

  // accent colours
  lemon: COLOUR_BASE_YELLOW500,
  lemon45: "#e5ba00",
  lemon75: COLOUR_LEMON_LIGHT_50,
  lemon90: "#FFF6CC",
  lemon95: "#FFFAE5",

  watermelon: COLOUR_BASE_PINK500,
  watermelon81: "#F9A5BE",
  watermelon92: "#FDDAE5",

  // alert colours
  successGreen: COLOUR_BASE_GREEN500,
  warnOrange: COLOUR_BASE_ORANGE500,
  warnOrange91: "#FCE7D4",
  warnOrange76: "#F8C08C",
  alertRed: COLOUR_BASE_RED500,
  infoBlue: COLOUR_BASE_BLUE500,
  infoBlueLight20: "#94cfff",

  transparent: "transparent",
};

const alert = {
  borderRadius: BORDER_RADIUS_SM,
  color: colours.text.default,
  fontFamily: FONT_INTER,
  fontSize: FONT_SIZE_MEDIUM,
  title: {
    fontFamily: FONT_INTER,
    fontSize: FONT_SIZE_LARGE,
  },
};

const button = {
  // common
  borderRadius: "4px",
  fontFamily: FONT_INTER,
  fontSize: FONT_SIZE_MEDIUM,
  focus: SHADOW_NONE,
  text: COLOUR_BASE_NEUTRAL900,
  // variants
  normal: {
    cta: {
      background: colours.background.accentYellow,
      backgroundHover: COLOUR_LEMON_LIGHT_50,
      border: colours.border.transparent,
      borderHover: colours.border.transparent,
      text: COLOUR_DARK_PETROL,
      textHover: COLOUR_DARK_PETROL,
    },
    primary: {
      background: colours.background.brand,
      backgroundHover: colours.background.brandHover,
      border: colours.border.transparent,
      borderHover: colours.border.transparent,
      text: colours.text.inverse,
      textHover: colours.text.brand,
    },
    secondary: {
      background: colours.background.transparent,
      backgroundHover: colours.background.whiteHover,
      border: colours.border.transparent,
      borderHover: colours.border.transparent,
      text: colours.text.default,
      textHover: colours.text.default,
    },
    disabled: {
      background: colours.background.disabled,
      backgroundHover: colours.background.disabled,
      border: colours.border.transparent,
      borderHover: colours.border.transparent,
      text: colours.text.disabled,
      textHover: colours.text.disabled,
    },
    link: {
      background: colours.background.transparent,
      backgroundHover: colours.background.transparent,
      border: colours.border.transparent,
      borderHover: colours.border.transparent,
      text: colours.text.brand,
      textHover: colours.text.linkHover,
    },
    danger: {
      background: colours.background.alertBold,
      backgroundHover: colours.background.alertHover,
      border: colours.border.transparent,
      borderHover: colours.border.transparent,
      text: colours.text.inverse,
      textHover: colours.text.alert,
    },
    secondaryAlt: {
      background: colours.background.subtle3,
      backgroundHover: colours.background.subtle3Hover,
      border: colours.border.transparent,
      borderHover: colours.border.transparent,
      disabledBackground: colours.background.subtle3,
      text: colours.text.default,
      textHover: colours.text.default,
    },
  },
  ghost: {
    cta: {
      background: colours.background.accentYellow,
      backgroundHover: COLOUR_LEMON_LIGHT_50,
      border: colours.border.transparent,
      borderHover: colours.border.transparent,
      text: COLOUR_DARK_PETROL,
      textHover: COLOUR_DARK_PETROL,
    },
    primary: {
      background: colours.background.brand,
      backgroundHover: colours.background.brandHover,
      border: colours.border.transparent,
      borderHover: colours.border.transparent,
      text: colours.text.inverse,
      textHover: colours.text.brand,
    },
    secondary: {
      background: colours.background.white,
      backgroundHover: colours.background.brandHover,
      border: colours.border.brand,
      borderHover: colours.border.transparent,
      text: colours.text.brand,
      textHover: colours.text.brand,
    },
    disabled: {
      background: colours.background.disabled,
      backgroundHover: colours.background.disabled,
      border: colours.border.transparent,
      borderHover: colours.border.transparent,
      text: colours.text.disabled,
      textHover: colours.text.disabled,
    },
    link: {
      background: colours.background.transparent,
      backgroundHover: colours.background.transparent,
      border: colours.border.transparent,
      borderHover: colours.border.transparent,
      text: colours.text.brand,
      textHover: colours.text.linkHover,
    },
    danger: {
      background: colours.background.alertBold,
      backgroundHover: colours.background.alertHover,
      border: colours.border.transparent,
      borderHover: colours.border.transparent,
      text: colours.text.inverse,
      textHover: colours.text.alert,
    },
  },
};

const buttonSet = {
  background: colours.background.white,
  border: COLOUR_CHARCOAL_LIGHT_50,
  borderRadius: BORDER_RADIUS_MD,
  text: colours.text.default,
  fontFamily: FONT_INTER,
  fontSize: FONT_SIZE_MEDIUM,
  hover: {
    background: colours.background.brandHover,
    text: colours.text.default,
  },
  selected: {
    background: colours.background.brand,
    text: colours.text.inverse,
  },
  focus: {
    shadow: SHADOW_NAVY,
  },
};

const checkBox = {
  background: colours.background.white,
  border: COLOUR_CHARCOAL_LIGHT_50,
  borderRadius: BORDER_RADIUS_SM,
  shadow: SHADOW_NONE,
  checked: {
    background: COLOUR_BASE_TEAL500,
    border: COLOUR_BASE_TEAL500,
    foreground: colours.background.white,
  },
  hover: {
    background: COLOUR_BASE_TEAL300,
    border: COLOUR_BASE_TEAL500,
    shadow: SHADOW_NONE,
  },
  focus: {
    shadow: SHADOW_NONE,
  },
  alert: {
    border: colours.border.alert,
  },
};

const errorMessage = {
  text: colours.text.alert,
  fontFamily: FONT_INTER,
  fontSize: FONT_SIZE_SMALL,
};

const inputText = {
  background: colours.background.white,
  border: COLOUR_CHARCOAL_LIGHT_50,
  borderRadius: BORDER_RADIUS_SM,
  text: colours.text.default,
  fontFamily: FONT_INTER,
  fontSize: FONT_SIZE_MEDIUM,
  placeholder: COLOUR_CHARCOAL_LIGHT_50,
  disabled: {
    background: COLOUR_CHARCOAL_LIGHT_10,
  },
  alert: {
    border: colours.border.alert,
    shadow: SHADOW_RED,
  },
  focus: {
    border: COLOUR_NAVY,
    shadow: SHADOW_NAVY,
  },
};

const inputTitleLabel = {
  text: colours.text.default,
  fontFamily: FONT_INTER,
  fontSize: FONT_SIZE_MEDIUM,
};

const instructionsLabel = {
  text: COLOUR_CHARCOAL_LIGHT_50,
  fontFamily: FONT_INTER,
  fontSize: FONT_SIZE_SMALL,
};

const numberIncrementor = {
  background: colours.background.white,
  border: COLOUR_CHARCOAL_LIGHT_50,
  borderRadius: BORDER_RADIUS_SM,
  fontFamily: FONT_INTER,
  fontSize: FONT_SIZE_MEDIUM,
  placeholder: COLOUR_CHARCOAL_LIGHT_50,
  text: colours.text.default,
  alert: {
    border: colours.border.alert,
    shadow: SHADOW_RED,
  },
  focus: {
    border: COLOUR_NAVY,
    shadow: SHADOW_NAVY,
  },
  button: {
    background: colours.background.white,
    border: COLOUR_CHARCOAL_LIGHT_50,
    disabled: {
      background: COLOUR_CHARCOAL_LIGHT_10,
    },
    hover: {
      background: COLOUR_DARK_PETROL_LIGHT_20,
      foreground: COLOUR_DARK_PETROL_LIGHT_50,
    },
  },
};

const pill = {
  background: COLOUR_NAVY_LIGHT_20,
  color: COLOUR_NAVY,
};

const progressBar = {
  background: COLOUR_SAND_LIGHT_50,
  foreground: COLOUR_TURQUOISE,
};

const select = {
  background: colours.background.white,
  border: COLOUR_CHARCOAL_LIGHT_50,
  borderRadius: BORDER_RADIUS_SM,
  fontFamily: FONT_INTER,
  fontSize: FONT_SIZE_MEDIUM,
  shadow: SHADOW_NAVY,
  text: colours.text.default,
  alert: {
    border: colours.border.alert,
    shadow: SHADOW_RED,
  },
  disabled: {
    background: COLOUR_CHARCOAL_LIGHT_10,
  },
};

const toggle = {
  background: COLOUR_CHARCOAL_LIGHT_10,
  foreground: colours.background.white,
  shadow: SHADOW_GREY,
  checked: {
    background: COLOUR_TURQUOISE,
  },
};

const toolTip = {
  background: colours.text.default,
  borderRadius: BORDER_RADIUS_SM,
  fontFamily: FONT_INTER,
  text: colours.text.inverse,
};

const selectSearchInput = {
  background: colours.background.white,
  border: COLOUR_CHARCOAL_LIGHT_50,
  borderRadius: BORDER_RADIUS_SM,
  fontFamily: FONT_INTER,
  fontSize: FONT_SIZE_MEDIUM,
  placeholder: COLOUR_CHARCOAL_LIGHT_50,
  text: colours.text.default,
  alert: {
    border: colours.border.alert,
    shadow: SHADOW_RED,
  },
  focus: {
    border: COLOUR_NAVY,
    shadow: SHADOW_NAVY,
  },
  button: {
    background: colours.background.white,
    border: COLOUR_CHARCOAL_LIGHT_50,
    disabled: {
      background: COLOUR_CHARCOAL_LIGHT_10,
      color: COLOUR_SAND_LIGHT_30,
    },
    hover: {
      background: COLOUR_DARK_PETROL_LIGHT_20,
      foreground: COLOUR_DARK_PETROL_LIGHT_50,
    },
    enabled: {
      background: COLOUR_NAVY_LIGHT_20,
    },
  },
  dropdownItem: {
    hover: COLOUR_CHARCOAL_LIGHT_10,
    focus: COLOUR_NAVY_LIGHT_20,
  },
  line: {
    border: COLOUR_CHARCOAL_LIGHT_10,
  },
  svg: {
    color: COLOUR_CHARCOAL_LIGHT_30,
  },
};

const multiChoice = {
  instructions: COLOUR_CHARCOAL_LIGHT_50,
  option: {
    background: COLOUR_CHARCOAL_LIGHT_10,
    border: COLOUR_CHARCOAL_LIGHT_10,
    active: {
      background: COLOUR_TURQUOISE_LIGHT_20,
      border: COLOUR_NAVY,
    },
    alert: {
      border: colours.border.alert,
    },
  },

  optionTick: {
    background: colours.background.white,
    border: COLOUR_CHARCOAL_LIGHT_50,
    active: {
      background: COLOUR_NAVY,
      border: COLOUR_NAVY,
    },
  },
};

const breakPoints = {
  maxMobile: "599px",
  minTablet: "600px",
  maxTablet: "991px",
  minDesktop: "992px",
  maxDesktop: "1023px",
  minLargeDesktop: "1024px",
};

const borderRadius = {
  radiusSM: BORDER_RADIUS_SM,
  radiusMD: BORDER_RADIUS_MD,
};

const spacing = {
  spacingNone: "0px",
  spacingXXS: "4px",
  spacingXS: "8px",
  spacingSM: "16px",
  spacingMD: "24px",
  spacingLG: "32px",
  spacingXL: "48px",
  spacingXXL: "64px",
  spacingXXXL: "128px",
};

const all = {
  alert,
  button,
  buttonSet,
  borderRadius,
  checkBox,
  errorMessage,
  inputText,
  inputTitleLabel,
  instructionsLabel,
  multiChoice,
  numberIncrementor,
  pill,
  progressBar,
  radioOption: checkBox, // identical, but note even if this was customised, the option/checkbox's always render the same due to `OptionRollover` function
  select,
  selectSearchInput,
  spacing,
  toggle,
  toolTip,
  ...breakPoints,
  ...colours,
};

export default all;

export const getColourFromString = (colour: string) => {
  if (colour.startsWith("theme.")) {
    const themeColour = all[colour.replace("theme.", "") as keyof typeof all];
    if (!themeColour) {
      throw new Error(
        `theme.colour specified with invalid colour: '${colour}'`,
      );
    }
    return themeColour;
  }
  return colour;
};

export const set = (theme: any) => {
  // set theme here
  if (theme) {
    // eslint-disable-next-line no-console
    console.log("set is a noop");
    return;
  }

  const recurse = (tree: { [key: string]: any }) => {
    for (var k in tree) {
      if (typeof tree[k] === "string") {
        tree[k] =
          "#" +
          Array(6)
            .fill("")
            .map(() => Math.floor(Math.random() * 16).toString(16))
            .join("");
      } else {
        recurse(tree[k]);
      }
    }
  };

  recurse(all);
};

import React from "react";
import styled from "styled-components";

import { Button, BUTTON_INLINE, BUTTON_SMALL } from "@madecomfy/webooi";

import { H4 } from "Components/Typography";

const Wrapper = styled.div`
  background: ${({ theme }) => theme.white};
  border-radius: 4px;
  padding: 24px;
`;
const Info = styled.ul`
  margin-bottom: 24px;
  padding-left: 16px;
`;

interface IProps {
  externalLink: string;
}
const ChannelAccountCreate: React.FC<IProps> = ({ ...props }) => {
  const { externalLink } = props;
  return (
    <div>
      <Wrapper>
        <H4>Add a new account</H4>
        <Info>
          <li>
            Clicking on the button below will direct you to Guesty to connect a
            new channel account
          </li>
          <li>
            Contact the onboarding/tech team if you don’t have an active Guesty
            account
          </li>
          <li>
            Guesty will provide a new email address for the channel account
          </li>
          <li>
            It will take approx. 5 minutes for the account to appear on this
            page
          </li>
        </Info>
        <Button
          icon="externalLink"
          label="Add new account"
          my={0}
          onClick={() => {
            window.open(externalLink);
          }}
          size={BUTTON_SMALL}
          styling={BUTTON_INLINE}
        />
      </Wrapper>
    </div>
  );
};

export default ChannelAccountCreate;

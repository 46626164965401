import React from "react";
import Config from "Config";
import styled from "styled-components";
import { ROLE } from "Constants/userRoles";

const Banner = styled.div`
  background: orangered;
  color: white;
  text-align: center;
  font-size: large;
  padding: 6px 2px;
  height: 40px;
  position: sticky;
  top: 0px;
  z-index: 100;
}
`;

const isProd = Config.ENV === "production" as string;

interface IProps {
  user?: { roles: any[] };
}

export const showProductionBanner = ({ user }: IProps) => isProd && user && user?.roles?.includes(ROLE.DEVELOPER);

const ProductionBanner = ({ user }: IProps) => {
  if (showProductionBanner({ user })) {
    return <Banner>You are currently on <strong>PRODUCTION</strong>!</Banner>;
  }

  return <></>;
};

export default ProductionBanner;

import React, { useState } from "react";
import styled from "styled-components";

import BreadCrumb from "Components/BreadCrumb/BreadCrumb";
import RESOURCES_TABS from "./ResourcesTab";
import Tab from "Components/Tab";
import { isAuthorized } from "Utils";
import { ROLE } from "Constants/userRoles";

const Wrapper = styled.div`
  padding: 24px;
`;
const Header = styled.p`
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  color: ${({ theme }) => theme.text.default};
  margin-top: 24px;
  margin-bottom: 40px;
`;

interface IProps {
  user: { [key: string]: any };
}
const Resources: React.FC<IProps> = ({ user }: { [key: string]: any }) => {
  const [active, setActive] = useState("all");
  const isLicensee = isAuthorized(user.roles, [ROLE.LICENSEE]);
  return (
    <Wrapper>
      <BreadCrumb
        items={[
          {
            label: "Resources",
            staticLabel: `${isLicensee ? "MadeComfy Pro / " : "MadeComfy / "}`,
            within: "all",
          },
        ]}
        staticText={active}
        setActive={setActive}
      />
      <Header>Resources</Header>
      <Tab
        active={active}
        setActive={setActive}
        tab={RESOURCES_TABS}
        userRoles={user?.roles}
      />
    </Wrapper>
  );
};

export default Resources;

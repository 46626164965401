import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { userMeRestore, resetGet } from "Actions/users/userRestore";
import { userRolesRestore } from "Actions/users/userRolesRestore";
import { userMeUpdate } from "Actions/users/userUpdate";

import Settings from "./Settings";

import withSessionCheck from "HOC/withSessionCheck";

const mapStateToProps = (state: {[key: string]: any}) => ({
  users: state.users,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  resetGet: bindActionCreators(resetGet, dispatch),
  userMeRestore: bindActionCreators(userMeRestore, dispatch),
  userMeUpdate: bindActionCreators(userMeUpdate, dispatch),
  userRolesRestore: bindActionCreators(userRolesRestore, dispatch),
});

const SettingsConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Settings);

export default withSessionCheck(SettingsConnected);

export { SettingsConnected };

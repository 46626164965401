import React from "react";
import styled from "styled-components";

import { Tooltip } from "@madecomfy/webooi";

import Download from "../assets/download.svg";
import { downloadFileFromUrlWithCredentials } from "Utils/methods/download";
import { WrapperTooltip } from "./Style";

const Wrapper = styled.div`
  padding: 8px;
  border-radius: 4px;
  width: 32px;
  cursor: pointer;
  height: 32px;
  display: flex;
  position: relative;
  background-color: transparent;
  border-radius: 4px;
  &:hover {
    background: ${({ theme }) => theme.background.whiteHover};
  }
`;
const Image = styled.img`
  width: 16px;
  height: 16px;
`;

interface IProps {
  url?: string;
  name?: string;
}
export const ButtonDownload: React.FC<IProps> = (props) => {
  const { url, name } = props;

  const handleDownload = async (url?: string, name?: string, e?: any) => {
    e.stopPropagation();
    if (!url) return;
    downloadFileFromUrlWithCredentials(url, name);
  };

  return (
    <div data-test="download-button">
      <Wrapper
        data-test="download-box"
        onClick={(e) => handleDownload(url, name, e)}
      >
        <Image src={Download} alt="download" />
        <WrapperTooltip>
          <Tooltip
            hideIcon={true}
            position="top"
            text="Download"
            testId="tooltip"
            timeOut={100}
          />
        </WrapperTooltip>
      </Wrapper>
    </div>
  );
};

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import "./Polyfills";
import Router from "./Router";
import store from "./store";
import ThemeWrapper from "./ThemeWrapper";

const appRouter = (
  <Provider store={store}>
    <ThemeWrapper>
      <Router />
    </ThemeWrapper>
  </Provider>
);

ReactDOM.render(appRouter, document.getElementById("root"));

import React, { memo, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import dayjs, { type Dayjs } from "dayjs";

import { Body, ParagraphSmall } from "Components/Typography";
import withClickOutside from "HOC/withClickOutside";
import { ButtonClear } from "./PickerElements";
import calendarIcon from "./assets/calendar.svg";
import chevronLeftIcon from "./assets/small-chevron-left.svg";
import chevronRightIcon from "./assets/small-chevron-right.svg";
import themes from "Constants/theme";

export const WrapperInput = styled.div<any>`
  width: 200px;
  align-items: center;
  background: ${({ theme }) => theme.white};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.charcoalLight50};
  display: flex;
  height: 40px;
  padding: 0 8px;
  transition: border 0.15s linear, box-shadow 0.3s linear;
  cursor: pointer;
  svg {
    margin: 8px 8px 8px 0;
  }
  img[data-test="clear-icon"] {
    margin-left: auto;
  }
  ${({ $isActive }) =>
    $isActive &&
    css`
      box-shadow: 0px 0px 6px rgba(52, 101, 127, 0.7);
    `};
`;

const MonthPickerWrapper = styled.div`
  display: inline-block;
  position: relative;
`;

const MonthPickerDropdown = styled.div`
  position: absolute;
  top: calc(100% + 4px);
  right: 0;
  background: ${({ theme }) => theme.white};
  box-shadow: 4px 4px 24px 0px #00000014;
  border-radius: 8px;
  z-index: 10;
`;

const MonthGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 8px;
`;

const MonthCell = styled.div<{
  $isSelected?: boolean;
  $isDisable?: boolean;
}>`
  width: 80px;
  height: 40px;
  padding: 8px;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.background.whiteHover};
  }
  ${({ $isSelected }) =>
    $isSelected &&
    css`
      background-color: ${({ theme }) => theme.turquoise80};
    `}
  ${({ $isDisable }) =>
    $isDisable &&
    css`
      cursor: not-allowed;
      border-radius: 0;
      background-color: ${({ theme }) => theme.background.disabled}!important;
    `}
`;

const YearSelector = styled.div`
  height: 56px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: ${({ theme }) => theme.white};
  border-bottom: 1px solid ${({ theme }) => theme.border.default};
`;

const YearButton = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.background.whiteHover};
  }
`;

const IconWrap = styled.img`
  width: 24px;
  height: 24px;
`;

const CalendarIconWrap = styled.img`
  width: 16px;
  height: 16px;
  margin: 0 8px 0;
`;

interface IProps {
  yearValue?: number;
  monthValue?: number;
  placeholder?: string;
  minTime?: Dayjs | string | Date;
  maxTime?: Dayjs | string | Date;
  sendValue: (month: number | null, year: number, val: Dayjs) => void;
  onChangeYear?: (year: number) => void;
  onClear?: () => void;
}

interface IDropdownProps {
  selectedMonth: number | null;
  selectedYear: number | null;
  currentYear: number;
  minTime?: Dayjs | string | Date;
  maxTime?: Dayjs | string | Date;
  handleChangeYear: (year: number) => void;
  handleSelectMonth: (month: number, year: number) => void;
}

const PickerDropdown = withClickOutside(
  ({
    selectedMonth,
    selectedYear,
    currentYear,
    minTime,
    maxTime,
    handleChangeYear,
    handleSelectMonth,
  }: IDropdownProps) => {
    const isMonthDisabled = (month: number, year: number) => {
      const date = dayjs()
        .year(year)
        .month(month - 1);
      if (minTime && date.isBefore(dayjs(minTime), "month")) return true;
      if (maxTime && date.isAfter(dayjs(maxTime), "month")) return true;
      return false;
    };
    return (
      <MonthPickerDropdown>
        <YearSelector data-test="year-selector">
          <YearButton
            data-test="prev-year-btn"
            onClick={() => handleChangeYear(currentYear - 1)}
          >
            <IconWrap alt="prev-year" src={chevronLeftIcon} />
          </YearButton>
          <ParagraphSmall>{currentYear}</ParagraphSmall>
          <YearButton
            data-test="next-year-btn"
            onClick={() => handleChangeYear(currentYear + 1)}
          >
            <IconWrap alt="next-year" src={chevronRightIcon} />
          </YearButton>
        </YearSelector>
        <MonthGrid>
          {Array.from({ length: 12 }).map((_, index) => {
            const isSelected =
              selectedMonth === index + 1 && selectedYear === currentYear;
            const isDisabled = isMonthDisabled(index + 1, currentYear);
            return (
              <MonthCell
                key={`cell-${index}`}
                data-test={`month-cell-${index}`}
                onClick={() => {
                  if (isDisabled) return;
                  handleSelectMonth(index + 1, currentYear);
                }}
                $isSelected={isSelected}
                $isDisable={isDisabled}
              >
                {dayjs().month(index).format("MMM")}
              </MonthCell>
            );
          })}
        </MonthGrid>
      </MonthPickerDropdown>
    );
  },
);

export const MonthPicker: React.FC<IProps> = memo((props) => {
  const {
    yearValue,
    monthValue,
    placeholder = "All months",
    minTime,
    maxTime,
    sendValue,
    onClear,
    onChangeYear,
  } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedMonth, setSelectedMonth] = useState<number | null>(
    monthValue ?? null,
  );
  const [selectedYear, setSelectedYear] = useState<number | null>(
    yearValue ?? null,
  );
  const [currentYear, setCurrentYear] = useState<number>(
    yearValue ?? dayjs().year(),
  );

  const toggleDropdown = (): void => {
    setIsOpen((prev) => !prev);
  };

  const handleSelectMonth = (month: number, year: number): void => {
    sendValue(
      month,
      year,
      dayjs()
        .year(year)
        .month(month - 1)
        .startOf("month"),
    );
    setIsOpen(false);
    setSelectedMonth(month);
    setSelectedYear(year);
  };

  const handleChangeYear = (year: number): void => {
    setCurrentYear(year);
    onChangeYear?.(year);
  };

  useEffect(() => {
    if (yearValue) {
      setSelectedYear(yearValue);
    }
  }, [yearValue]);

  useEffect(() => {
    if (selectedMonth) {
      setSelectedMonth(selectedMonth);
    }
  }, [selectedMonth]);

  return (
    <MonthPickerWrapper>
      <WrapperInput
        data-test="month-picker-input"
        onClick={toggleDropdown}
        $isActive={isOpen}
      >
        <CalendarIconWrap alt="calendar-icon" src={calendarIcon} />
        <Body
          color={
            selectedMonth && selectedYear
              ? themes.charcoal
              : themes.charcoalLight50
          }
        >
          {selectedMonth && selectedYear
            ? dayjs()
                .year(selectedYear)
                .month(selectedMonth - 1)
                .format("MMM YYYY")
            : placeholder}
        </Body>
        <ButtonClear
          handleClick={(event) => {
            event.stopPropagation();
            setSelectedMonth(null);
            onClear?.();
          }}
          showClear={!!selectedMonth}
        />
      </WrapperInput>
      {isOpen && (
        <PickerDropdown
          isDisplayed={isOpen}
          onClickOutside={() => {
            if (!selectedMonth) setCurrentYear(yearValue ?? dayjs().year());
            setIsOpen(false);
          }}
          selectedMonth={selectedMonth}
          selectedYear={selectedYear}
          currentYear={currentYear}
          minTime={minTime}
          maxTime={maxTime}
          handleSelectMonth={handleSelectMonth}
          handleChangeYear={handleChangeYear}
        />
      )}
    </MonthPickerWrapper>
  );
});

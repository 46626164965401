import { PROPERTY_REVENUE } from "Constants/actionTypes";

const initialState = {
  data: [],
  error: false,
  errorCode: 0,
  errorMessage: "",
  pending: false,
  success: false,
};
export function propertyRevenueGraphs(state = initialState, action: {[key: string]: any}) {
  switch (action.type) {
    case PROPERTY_REVENUE.SUCCESS:
      return {
        ...state,
        success: true,
        pending: false,
        data: action.data,
      };
    case PROPERTY_REVENUE.PENDING:
      return { ...state, pending: action.status };
    case PROPERTY_REVENUE.ERROR:
      return {
        ...state,
        error: true,
        errorMessage: action.errorMessage,
        errorCode: action.errorCode,
        pending: false,
      };
    case PROPERTY_REVENUE.RESET:
      return initialState;
    default:
      return state;
  }
}

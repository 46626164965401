export const validateName = (name: string) => name && String(name).length > 0;

export const validateOwner = (newRoles: {[key: string]: any}) => {
  // warn if user is an owner and has other access levels
  if (newRoles.ROLE_OWNER) {
    const newRolesCount = Object.values(newRoles).filter(Boolean).length;
    return newRolesCount > 1;
  }
  return false;
};

export const validateLicensee = (newRoles: {[key: string]: any}) => {
  // warn if user is licensee and has other access levels
  if (newRoles.ROLE_LICENSEE) {
    const newRolesCount = Object.values(newRoles).filter(Boolean).length;
    return newRolesCount > 1;
  }
  return false;
};
export const validateContentCreator = (newRoles: {[key: string]: any}) => {
  // warn if user is content creator, config code and instructions should also be selected
  if (newRoles.ROLE_CONTENT_CREATOR) {
    const checkConfig = newRoles.ROLE_CONFIGURATOR_CODES & newRoles.ROLE_CONFIGURATOR_INSTRUCTIONS;
    return !checkConfig;
  }
  return false;
};
// note this function returns a function to get `allowedRoles` & `existingRoles` in scope
export const validateProtected =
  (allowedRoles: any[], existingRoles: string | string[]) => (newRoles: {[key: string]: any}) => {
    // warn the current user if they try and grant the user they are editing
    // roles beyond their own permission limit
    const protectedRoles = allowedRoles.filter(
      ({ canGrant }) => canGrant === false,
    );

    return Object.entries(newRoles).some(([roleKey, newRoleValue]) => {
      const protectedRole = protectedRoles.find(({ role }) => {
        return role === roleKey;
      });
      if (protectedRole) {
        const oldRoleValue = existingRoles.includes(roleKey);
        return oldRoleValue !== newRoleValue;
      }
      return false;
    });
  };

import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledLink = styled.div`
  margin-top: 15px;
  text-align: center;
  a {
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 16px;
    text-align: center;
    color: ${({ theme }) => theme.charcoalLight50};
  }
`;

interface IProps {
  testId?: string;
  label: string;
  to: string;
}
class LinkButton extends Component<IProps> {
  render() {
    const { testId, to, label } = this.props;
    return (
      <StyledLink>
        <Link to={to} data-test={testId}>
          {label}
        </Link>
      </StyledLink>
    );
  }
}

export default LinkButton;

import React from "react";
import styled from "styled-components";

import { ROLE } from "Constants/userRoles";
import theme from "Constants/theme";

const badgeColour = new Map([
  [ROLE.ADMIN, theme.alertRed],
  [ROLE.BACKEND, "#d065bc"],
  [ROLE.DEVELOPER, theme.alertRed],
  [ROLE.OWNER, "#63c2de"],
  [ROLE.STAFF, "#d065bc"],
  // legacy do not use
  ["ROLE_API", "black"],
  ["ROLE_API_BACKEND", "black"],
  ["ROLE_SUPER_ADMIN", "black"],
]);

const BadgeSpan = styled.span<any>`
  background-color: ${({ role }) => badgeColour.get(role) || "#7e9898"};
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  margin: 0 4px 2px 0;
  padding: 2px 8px;
  white-space: nowrap;
  display: inline-block;
`;

export const Badge = ({ role }: { role: string }) => (
  <BadgeSpan role={role}>{roleText(role)}</BadgeSpan>
);

export const roleText = (role: string) => {
  return role
    .toLowerCase()
    .replace("role_", "")
    .replace("_", " ")
    .replace("configurator", "config");
};

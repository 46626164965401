import React, { Component } from "react";

import { Alert, ALERT_ERROR, BUTTON_PRIMARY } from "@madecomfy/webooi";

import ComfyContainer from "Components/ComfyContainer";
import Loading from "Components/Loading";
import UserDetails from "./components/UserDetails";
import { H4 } from "Components/Typography";

interface IProps {
  editUserRoles: { [key: string]: any };
  editUserStatus: { [key: string]: any };
  reset: (...args: any) => any;
  userCreate: (...args: any) => any;
}

class UserAdd extends Component<IProps> {
  render() {
    const { editUserRoles, editUserStatus, reset, userCreate } = this.props;

    const { connectionError, error, errorMessage } = editUserStatus;
    if (error || connectionError) {
      // eslint-disable-next-line
      console.warn("UserAdd error:", error, connectionError);
      return (
        <ComfyContainer>
          <H4>Adding user - failed</H4>
          <Alert
            buttons={[
              { callback: reset, label: "Try again", type: BUTTON_PRIMARY },
            ]}
            message={errorMessage || "There was an error creating user"}
            testId="user-add"
            title="Oops"
            type={ALERT_ERROR}
          />
        </ComfyContainer>
      );
    }

    if (editUserRoles.pending || editUserStatus.pending) {
      return <Loading task="editingUserRoledOrStatus" />;
    }

    return (
      <ComfyContainer>
        <H4>Create a new user</H4>
        <UserDetails
          canUpdateUser
          editUserRoles={editUserRoles}
          editUserStatus={editUserStatus}
          reset={reset}
          onSubmit={({
            user,
            properties,
            companies,
          }: {
            user: { [key: string]: any };
            properties: { [key: string]: any };
            companies: { [key: string]: any };
          }) => {
            userCreate(user, properties, companies);
          }}
        />
      </ComfyContainer>
    );
  }

  componentDidMount() {
    this.props.reset();
  }
}

export default UserAdd;

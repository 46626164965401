import { API_URL } from "Config";
import { generateErrorHandler, getJSON } from "Utils/Network";
import { EDIT_USER_PERMISSIONS } from "Constants/actionTypes";

const handleError = generateErrorHandler(EDIT_USER_PERMISSIONS.ERROR);

export function userRolesRestore() {
  return async (dispatch: (...args: any) => any) => {
    dispatch({ type: EDIT_USER_PERMISSIONS.PENDING, status: true });
    try {
      const json = await getJSON(`${API_URL}v3/roles`);
      dispatch({
        type: EDIT_USER_PERMISSIONS.SUCCESS,
        roles: json.data.attributes.roles,
      });
    } catch (err: any) {
      // eslint-disable-next-line
      console.warn("userRolesRestore err", err);
      dispatch(handleError(err));
    }
  };
}

import React from "react";

const Statement = () => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.215542 0.785216C0.215542 0.426231 0.506557 0.135216 0.865542 0.135216H13.462C13.6344 0.135216 13.7997 0.203676 13.9216 0.325541L18.8518 5.25453C18.9737 5.37644 19.0422 5.54179 19.0422 5.71421V6.25224C19.0422 6.25514 19.0423 6.25804 19.0423 6.26095C19.0423 6.26386 19.0422 6.26677 19.0422 6.26967V17.2148C19.0422 17.5738 18.7512 17.8648 18.3922 17.8648C18.0332 17.8648 17.7422 17.5738 17.7422 17.2148V6.91095H12.9153C12.5564 6.91095 12.2653 6.61994 12.2653 6.26095V1.43522H1.51554V17.2148C1.51554 17.5738 1.22453 17.8648 0.865542 17.8648C0.506557 17.8648 0.215542 17.5738 0.215542 17.2148V0.785216ZM13.5653 1.80763V5.61095H17.3696L13.5653 1.80763Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.95487 5.48028C6.95487 5.1213 6.66386 4.83028 6.30487 4.83028C5.94589 4.83028 5.65487 5.1213 5.65487 5.48028V5.98045C5.17657 6.07084 4.73721 6.25995 4.37848 6.52946C3.86191 6.91754 3.48056 7.5034 3.48056 8.20551C3.48056 8.90601 3.85861 9.49209 4.37536 9.88115C4.84088 10.2316 5.44273 10.4458 6.09108 10.4846L5.48691 12.3015C5.23965 12.185 5.02409 12.0104 4.85859 11.7927C4.64133 11.5069 4.23354 11.4514 3.94776 11.6686C3.66198 11.8859 3.60644 12.2937 3.82369 12.5794C4.11568 12.9635 4.49605 13.2716 4.93258 13.4774C5.16331 13.5862 5.40608 13.6647 5.65487 13.7117V14.2C5.65487 14.559 5.94589 14.85 6.30487 14.85C6.66386 14.85 6.95487 14.559 6.95487 14.2V13.7021C7.43558 13.612 7.87675 13.4229 8.23677 13.1528C8.75439 12.7645 9.1353 12.1784 9.1353 11.4765C9.1353 10.7745 8.75439 10.1885 8.23678 9.80016C7.77029 9.4502 7.16756 9.23614 6.51914 9.19737L7.12348 7.37995C7.3707 7.49636 7.58619 7.67074 7.75183 7.88841C7.96923 8.17408 8.37705 8.22944 8.66272 8.01204C8.9484 7.79465 9.00375 7.38683 8.78636 7.10116C8.49421 6.71725 8.11387 6.40938 7.67725 6.2038C7.44652 6.09516 7.2037 6.01679 6.95487 5.96992V5.48028ZM6.51914 9.19737L7.12348 7.37995C6.87616 7.26351 6.60441 7.20847 6.33133 7.2195C6.32259 7.21985 6.31385 7.22003 6.3051 7.22003C5.83438 7.22003 5.43078 7.36488 5.15932 7.56882C4.88791 7.77273 4.78056 8.00461 4.78056 8.20551C4.78056 8.40802 4.88777 8.63968 5.15729 8.8426C5.42719 9.04581 5.83034 9.19099 6.3051 9.19099C6.37691 9.19099 6.4483 9.19313 6.51914 9.19737ZM6.09108 10.4846L5.48691 12.3015C5.72848 12.4154 5.99349 12.4707 6.26048 12.4631C6.27515 12.4621 6.28995 12.4616 6.30487 12.4616L6.32366 12.4619C6.79053 12.4582 7.18853 12.314 7.45663 12.1129C7.72812 11.9092 7.8353 11.6775 7.8353 11.4765C7.8353 11.2754 7.72812 11.0437 7.45663 10.8401C7.18498 10.6363 6.77997 10.491 6.3051 10.491C6.23329 10.491 6.1619 10.4888 6.09108 10.4846Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1695 10.6432C11.1695 10.2843 11.4605 9.99325 11.8195 9.99325H15.6538C16.0128 9.99325 16.3038 10.2843 16.3038 10.6432C16.3038 11.0022 16.0128 11.2932 15.6538 11.2932H11.8195C11.4605 11.2932 11.1695 11.0022 11.1695 10.6432Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8195 13.8264C11.4605 13.8264 11.1695 14.1174 11.1695 14.4764C11.1695 14.8354 11.4605 15.1264 11.8195 15.1264H15.6538C16.0128 15.1264 16.3038 14.8354 16.3038 14.4764C16.3038 14.1174 16.0128 13.8264 15.6538 13.8264H11.8195Z"
    />
  </>
);

export default Statement;

import React from "react";
import { Router, Route, Switch } from "react-router-dom";

import withSessionCheck from "HOC/withSessionCheck";
import { RouteQuery } from "HOC/withURLQuery";

import EventCreate from "./EventCreate";

const EventsRouter = (props: any) => {
  return (
    <Router {...props}>
      <Switch>
        <Route
          exact
          path="/events/:propertyId?"
          render={({ match }) => {
            const { propertyId } = match.params;
            return (
              <div>
                Please search for events using the{" "}
                {propertyId ? (
                  <a href={`/properties/${propertyId}}`}>property calendar</a>
                ) : (
                  <a href="/properties">properties page</a>
                )}
              </div>
            );
          }}
        />
        <RouteQuery
          exact
          path="/events/:propertyId/create"
          component={EventCreate}
        />
      </Switch>
    </Router>
  );
};

export default withSessionCheck(EventsRouter);

import React from "react";
import * as H from "history";

import { propertyEventCreate } from "Actions/properties/propertyEvents";
import withEventRanges from "Containers/EventsCalendar/withEventRanges";

import EventEditor from "./EventEditor";

interface IProps {
  history: H.History;
  match: {
    params: {
      propertyId: string;
    },
    query: {
      startDate: string;
      endDate: string;
      status: string;
      expiry?: string;
    },
  };
  [key: string]: any;
}

const EventCreate = (props: any) => {
  const {
    history,
    match: {
      params: { propertyId },
      query: { startDate, endDate, status, expiry },
    },
    ...rest
  } = props as IProps;

  return (
    <EventEditor
      {...{
        endDate,
        expiry,
        history,
        propertyId,
        startDate,
        status,
        title: "Create an Event",
      }}
      onConfirm={async (params: any) => propertyEventCreate(propertyId, params)}
      {...rest}
    />
  );
};

export default withEventRanges(EventCreate);

import React from "react";
import styled, { css } from "styled-components";

const Wrapper = styled.div<any>`
  width: ${({ open }) => (open ? "82%" : "16px")};
  transition: all 0.3s ease-in-out;
  z-index: 9;
  background-color: ${({ theme }) => theme.white};
  position: absolute;
  top: 14px;
  ${({ open }) =>
    open &&
    css`
      display: none;
      top: -16px;
      height: 51px;
    `}
`;
const Lines = styled.div`
  background-color: ${({ theme }) => theme.white};
  cursor: pointer;
  margin: 0;
  position: relative;
  width: 24px;
  height: 24px;
  margin-left: auto;
  @media (min-width: ${({ theme }) => theme.minTablet}) {
    display: none;
  }
`;

const Line = styled.div<any>`
  background: ${({ theme }) => theme.charcoal};
  border-radius: 20px;
  height: ${({ open }) => (open ? "1.5px" : "2.5px")};
  position: absolute;
  width: ${({ open }) => (open ? "20px" : "24px")};
  transition: all 0.2s ease-in-out;
  transform-origin: 50% 50%;
  left: 0;
  right: 0;
  margin: auto;
`;

const LineOne = styled(Line)`
  top: ${({ open }) => (open ? "10px" : "8px")};
  transform: translate(0, ${({ open }) => (open ? 12 : 0)}px)
    rotate(${({ open }) => (open ? 45 : 0)}deg);
`;

const LineTwo = styled(Line)`
  top: 16px;
  transform: rotate(${({ open }) => (open ? 90 : 0)}deg);
  opacity: ${({ open }) => (open ? 0 : 1)};
  width: 24px;
`;

const LineThree = styled(Line)`
  top: ${({ open }) => (open ? "26px" : "24px")};
  transform: translate(0px, ${({ open }) => (open ? -4 : 0)}px)
    rotate(${({ open }) => (open ? 135 : 0)}deg);
`;

interface IProps {
  onClick: (...args: any) => any;
  open: boolean;
}
const MenuToggler: React.FC<IProps> = ({ onClick, open }) => {
  return (
    <Wrapper open={open}>
      <Lines onClick={onClick} id="test-id-menu-toggler">
        <LineOne open={open} />
        <LineTwo open={open} />
        <LineThree open={open} />
      </Lines>
    </Wrapper>
  );
};

export default MenuToggler;

import styled, { css } from "styled-components";

const animationDelays = () =>
  Array(10) // only supports up to 10 containers...
    .fill("")
    .map(
      (_, i) => css`
        :nth-child(${i}) {
          animation-delay: ${i * 150}ms;
          // background: hsl(${(i * 50) % 360}deg, 50%, 50%);
        }
      `,
    );

// use this if you want to animate a container in on page change etc... eg, you don't want the ComfyContainer aesthetics - like bg and padding etc.
export const AnimatedContainer = styled.div`
  animation: zoom-animation 0.4s forwards ease-in-out;
  opacity: 0;
  transform: scale(0.97);
  transform-origin: top center;
  ${animationDelays()};

  @keyframes zoom-animation {
    0% {
      opacity: 0;
      transform: scale(0.97);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

const ComfyContainer = styled(AnimatedContainer)`
  background-color: ${({ theme }) => theme.white};
  border-radius: 4px;
  box-shadow: 10px 10px 30px rgba(53, 55, 60, 0.1);
  margin-bottom: 24px;
  min-height: 180px;
  // don't do this. yes it's handy to have a hard perimeter, but some elements should extend over the edges of a container (tooltips/dropdowns)
  // overflow: hidden;

  padding: 24px;
  position: relative;
  h4 {
    margin-bottom: 20px;
  }
`;
export const ContainerWrapper = styled(ComfyContainer)`
  margin: 24px;
`;
export default ComfyContainer;

/**
 * Root component sitting at `/` of application
 * There should be no content at this location, this route is for deciding where to redirect to
 * this is so:
 * - users can type in my.madecomfy.com.au, hit enter, and it should load correctly.
 * - at anytime (eg after logging in) we can simply redirect to / and it will work out a suitable landing page.
 * To achieve this, it must be wrapped with `withSessionCheck`
 */
import React, { PureComponent } from "react";

class Root extends PureComponent {
  render() {
    return <div hidden>Root Loading...</div>;
  }
}

export default Root;

import React from "react";

const Experiment = () => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4757 15.75C16.4757 15.3358 16.1399 15 15.7257 15C15.3115 15 14.9757 15.3358 14.9757 15.75V16.5H14.2256C13.8114 16.5 13.4756 16.8358 13.4756 17.25C13.4756 17.6642 13.8114 18 14.2256 18H14.9757V18.75C14.9757 19.1642 15.3115 19.5 15.7257 19.5C16.1399 19.5 16.4757 19.1642 16.4757 18.75V18H17.2256C17.6398 18 17.9756 17.6642 17.9756 17.25C17.9756 16.8358 17.6398 16.5 17.2256 16.5H16.4757V15.75Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.72575 1.5H7.47561V8.02768L0.859778 18.2062C0.110833 19.3584 0.0520894 20.828 0.706677 22.0363C1.36127 23.2446 2.62432 23.998 3.99853 24H19.9477C21.3228 23.9999 22.5875 23.2471 23.2433 22.0385C23.8992 20.8298 23.8409 19.3592 23.0914 18.2062L19.2942 12.3642C19.2851 12.3491 19.2755 12.3343 19.2654 12.3199L16.4756 8.02768V6.76448C16.4757 6.75966 16.4757 6.75483 16.4757 6.74999C16.4757 6.74514 16.4757 6.74031 16.4756 6.73549V3.76449L16.4757 3.74999L16.4756 3.7355V1.5H17.2258C17.64 1.5 17.9758 1.16421 17.9758 0.75C17.9758 0.335786 17.64 0 17.2258 0H6.72575C6.31154 0 5.97575 0.335786 5.97575 0.75C5.97575 1.16421 6.31154 1.5 6.72575 1.5ZM14.9756 5.99999V4.49999H12.7257C12.3115 4.49999 11.9757 4.16421 11.9757 3.74999C11.9757 3.33578 12.3115 2.99999 12.7257 2.99999H14.9756V1.5H8.97561V8.25C8.97561 8.39509 8.93352 8.53707 8.85444 8.65873L6.68271 12H17.2685L15.0968 8.65873C15.0177 8.53707 14.9756 8.39509 14.9756 8.25V7.49999H12.7257C12.3115 7.49999 11.9757 7.1642 11.9757 6.74999C11.9757 6.33577 12.3115 5.99999 12.7257 5.99999H14.9756ZM5.70775 13.5L2.11744 19.0237C1.66808 19.715 1.63282 20.5968 2.02557 21.3218C2.41817 22.0464 3.17556 22.4985 3.99971 22.5H19.9475C20.7726 22.4999 21.5314 22.0482 21.9249 21.3231C22.3184 20.5979 22.2834 19.7155 21.8338 19.0237L18.2435 13.5H5.70775Z"
    />
  </>
);

export default Experiment;

import React from "react";
import styled from "styled-components";

import {
  Button,
  BUTTON_INLINE,
  BUTTON_DANGER,
  BUTTON_SMALL,
  BUTTON_SECONDARY,
} from "@madecomfy/webooi";

interface IProps {
  asyncAction?: "cancelling" | "deleting" | "saving";
  onCancel: (...args: any) => any;
  onDelete?: (...args: any) => any; // certainly optional...
  onSave: (...args: any) => any;
  textSave?: string; // often it is `Next`, `Continue` or `Confirm`
}

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  button[data-test="button-delete"] {
    // always put delete on far left.
    margin-right: auto;
  }
`;

const ButtonsSaveCancel: React.FC<IProps> = ({
  asyncAction,
  onCancel,
  onDelete,
  onSave,
  textSave = "Save",
}) => {
  return (
    <Wrapper>
      {onDelete && (
        <Button
          isEnabled={asyncAction !== "deleting"}
          isLoading={asyncAction === "deleting"}
          label="Delete"
          my={0}
          onClick={onDelete}
          size={BUTTON_SMALL}
          styling={BUTTON_INLINE}
          testId="delete"
          type={BUTTON_DANGER}
        />
      )}
      <Button
        isEnabled={asyncAction !== "cancelling"}
        isLoading={asyncAction === "cancelling"}
        label="Cancel"
        my={0}
        onClick={onCancel}
        size={BUTTON_SMALL}
        styling={BUTTON_INLINE}
        testId="cancel"
        type={BUTTON_SECONDARY}
      />
      <Button
        isEnabled={asyncAction !== "saving"}
        isLoading={asyncAction === "saving"}
        label={textSave}
        my={0}
        onClick={onSave}
        size={BUTTON_SMALL}
        styling={BUTTON_INLINE}
        testId="save"
      />
    </Wrapper>
  );
};

export default ButtonsSaveCancel;

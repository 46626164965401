import React, { useState } from "react";
import styled from "styled-components";

import { Tooltip } from "@madecomfy/webooi";

import Copy from "../assets/copy.svg";
import Tick from "../assets/tick.svg";
import { WrapperTooltip } from "./Style";

const Container = styled.div`
  position: relative;
`;
const Wrapper = styled.div`
  background: ${({ theme }) => theme.background.subtle3};
  padding: 8px;
  border-radius: 4px;
  width: 32px;
  cursor: pointer;
  height: 32px;
  display: flex;
  position: relative;
  z-index: 9;
  &:hover {
    background: ${({ theme }) => theme.background.subtle3Hover};
  }
`;
const Image = styled.img`
  width: 16px;
  height: 16px;
`;
const Copied = styled.span`
  position: absolute;
  bottom: 32px;
  box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.08);
  background: ${({ theme }) => theme.background.white};
  color: ${({ theme }) => theme.text.default};
  padding: 8px;
  border-radius: 4px;
  width: max-content;
  img {
    margin-right: 8px;
  }
`;

interface IProps {
  url: string;
}
export const ButtonCopy: React.FC<IProps> = ({ url }) => {
  const [copied, setCopied] = useState(false);
  const copyToClipboard = async (e: any) => {
    try {
      e.stopPropagation();
      await navigator.clipboard.writeText(url);
      setCopied(true);
      setTimeout(() => setCopied(false), 800); // Reset copied state after 2 seconds
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Failed to copy: ", error);
    }
  };
  return (
    <Container data-test="copy-button">
      <Wrapper data-test="copy-box" onClick={copyToClipboard}>
        <Image src={Copy} alt="copy" />
        <WrapperTooltip>
          <Tooltip
            hideIcon={true}
            position="top"
            text="Copy link"
            testId="tooltip"
            timeOut={100}
          />
        </WrapperTooltip>
      </Wrapper>
      {copied && (
        <Copied data-test="copied-popup">
          <img src={Tick} alt="tick" />
          Copied to clipboard
        </Copied>
      )}
    </Container>
  );
};

import React from "react";
import ReactLoading from "react-loading";

import theme from "Constants/theme";

// wrapper for ReactLoading so it looks consistent across the whole app, centred, etc.
// if you experience an issue rendering it:
// 1) put position relative on the parent container (not this one, the caller)
// 2) set a min height of 100px or so on the parent.

// inner animating spinner defaults to 64px x 64px;
// padding is 8px, so;
// so overall size if size = 64 is 64 + 8 * 2 = 80px;

interface IProps {
  size?: number;
  task: string;
}

const Loading: React.FC<IProps> = ({ size = 64, task }) => {
  // console.info("Loading... task:", task);
  const radius = (size + 8 * 2) / 2;
  return (
    <div
      aria-busy="true"
      data-test="loading"
      style={{
        background: "rgba(255, 255, 255, 0.8)",
        borderRadius: `${radius}px`,
        boxShadow: "0px 0px 3px rgba(53, 55, 60, 0.5)",
        left: "50%",
        marginLeft: `-${radius}px`,
        padding: "8px",
        position: "absolute",
        zIndex: 1000,
      }}
    >
      <ReactLoading
        type="spin"
        color={theme.turquoise}
        delay={0}
        width={size}
        height={size}
      />
    </div>
  );
};

export default Loading;

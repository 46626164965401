import "isomorphic-fetch";

import Config from "Config";
import { BAD_REQUEST, NOT_FOUND, OK } from "Utils/Network";
import { FORGOT_PASSWORD } from "Constants/actionTypes";

export function forgotPassword(email: string) {
  return (dispatch: (...args: any) => any) => {
    return fetch(Config.API_URL + "v1/public/forgot/" + email + "/", {
      credentials: "include",
      mode: "cors",
      method: "GET",
    })
      .then((response) => {
        if (response.status === OK) {
          dispatch({ type: FORGOT_PASSWORD.SUCCESS });
          return response;
        }
        if (response.status === NOT_FOUND) {
          dispatch({
            type: FORGOT_PASSWORD.ERROR,
            errorMessage: "Invalid user",
          });
          return response;
        }
        if (response.status === BAD_REQUEST) {
          dispatch({
            type: FORGOT_PASSWORD.ERROR,
            errorMessage: "Reset email already requested",
          });
          return response;
        }
        dispatch({
          type: FORGOT_PASSWORD.ERROR,
          errorMessage: "A network error occurred",
        });
      })
      .catch(() => {
        dispatch({
          type: FORGOT_PASSWORD.CONNECTION_FAILURE,
        });
      });
  };
}

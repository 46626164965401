import range from "lodash/range";
import React from "react";
import styled from "styled-components";

import Week, { FIRST_DAY_OF_WEEK } from "./Week";
import { monthLabel } from "./utils";
import { H5 } from "Components/Typography";

// if this component is moved into webooi, these need sorting:
import calendarArrow from "Containers/EventsCalendar/assets/calendar-arrow.svg";

import {
  DayCell,
  MonthName,
  MonthRow,
  MonthTable,
  TBody,
  THead,
} from "./RowsAndCells";

interface ButtonNavProps {
  direction: number;
  onClick: (...args: any) => any;
  testId: string;
}

interface MonthProps {
  active?: { [key: string]: any };
  calendar: { [key: string]: any };
  focus?: string;
  ignoreValidation?: boolean;
  month: number;
  mouseOver?: string;
  onDayClick?: (...args: any) => any;
  onDayHover?: (...args: any) => any;
  onNavigate?: (...args: any) => any;
  rates?: { [key: string]: any };
  restrictedDates?: {
    startDate: string;
    endDate: string;
  };
  selectedDates?: {
    startDate: string;
    endDate: string;
  };
  showDaysOutsideMonth: boolean;
  singleDate?: boolean;
  timezone?: string;
  today: string;
  viewingMonth: boolean;
  year: number;
  filter?: string;
  userRoles?: string[];
}

const ArrowButton = styled.button<any>`
  width: 24px;
  height: 24px;
  background: none;
  border: none;
  outline: none;
  img {
    transition: transform 0.1s ease-out;
    transform: ${({ direction }) => `scaleX(${direction})`};
  }
  &:hover {
    img {
      transform: ${({ direction }) => `scaleX(${direction * 1.1}) scaleY(1.1)`};
    }
  }
`;
const TextLabelMonth = styled(H5)`
  margin: auto;
  color: ${({ theme }) => theme.charcoal15};
  font-size: 16px;
  font-family: InterSemiBold;
`;

const ButtonNav = ({ direction, onClick, testId }: ButtonNavProps) => {
  return (
    <ArrowButton onClick={onClick} direction={direction} data-test={testId}>
      <img alt="arrow" src={calendarArrow} />
    </ArrowButton>
  );
};

const Month: React.FC<MonthProps> = (props: MonthProps) => {
  // Looked into using React's context
  // although we do have to pass props from Year > Month > Week > Day, some are unique to each level of the tree, and some are shared.
  // eg. active, onDayClick, mouseOver are the same for every level, but day, dmy, rate are unique.
  // decision is to pass them all manually in one bundle (props) rather than creating provider/consumer and extract them from two (props/context)

  const { onNavigate, ...rest } = props;
  const { month, viewingMonth, year } = props;

  // this array used to be generated with a function, but hardcoded makes more sense for the moment.
  const sevenDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map(
    (day) => {
      return <DayCell key={`month-${month}-weekday-${day}`}>{day}</DayCell>;
    },
  );

  const monthStart = new Date(year, month, 1);

  // number of days to insert before the first of the month to correctly align the weekdays
  let prevMonthDaysCount = monthStart.getDay();
  while (prevMonthDaysCount < FIRST_DAY_OF_WEEK - 1) {
    prevMonthDaysCount += 7;
  }

  return (
    <div>
      <MonthName viewingMonth={viewingMonth}>
        {onNavigate && (
          <ButtonNav
            direction={-1}
            onClick={onNavigate(-1)}
            testId="button-month-previous"
          />
        )}
        <TextLabelMonth center>
          {monthLabel(month)} {year}
        </TextLabelMonth>
        {onNavigate && (
          <ButtonNav
            direction={+1}
            onClick={onNavigate(+1)}
            testId="button-month-next"
          />
        )}
      </MonthName>
      <MonthTable viewingMonth={viewingMonth}>
        <THead viewingMonth={viewingMonth}>
          <MonthRow>{sevenDays}</MonthRow>
        </THead>
        <TBody>
          {range(0, 6).map((weekIndex) => {
            const p = {
              weekIndex,
              prevMonthDaysCount,
              ...rest,
            };
            return <Week key={weekIndex} {...p} />;
          })}
        </TBody>
      </MonthTable>
    </div>
  );
};

export default Month;

import {
  captureEvent,
  captureException,
  configureScope,
  withScope,
} from "@sentry/browser";

import { ENV } from "Config";

import { isStaff, loadScript } from "Utils";

declare var __insp: any;

export function logException(event: any, context: any) {
  try {
    withScope((scope) => {
      scope.setExtra("debug", false);
      scope.setExtra("context", context);
      captureException(event);
    });
  } catch (err) {
    // only likely to happen in dev mode...
    // eslint-disable-next-line
    console.warn("ThirdParty.logException failed", err);
  }
}

export function logEvent(event: any, context: any) {
  try {
    withScope((scope) => {
      scope.setExtra("debug", false);
      scope.setExtra("context", context);
      captureEvent(event);
    });
  } catch (err) {
    // only likely to happen in dev mode...
    // eslint-disable-next-line
    console.warn("ThirdParty.logEvent failed", err);
  }
}

export function setUser(user = {} as {[key: string]: any}) {
  const { code, email, firstName, roles } = user;

  // inspectlet is setup in GTM
  // identify user to inspectlet
  if (email && typeof __insp !== "undefined") {
    // prettier-ignore
    // eslint-disable-next-line
    __insp.push && __insp.push(["identify", email]);
  }

  // jira collector functionality
  if ((ENV === "production" as string) && isStaff(roles)) {
    loadScript(
      "jira-collector",
      "https://madecomfy.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-le44p9/b/24/a44af77267a987a660377e5c46e0fb64/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=2d423bb4",
    );
  }

  if (!firstName || !roles || !code) {
    return null;
  }
  const cleansed = {
    firstName,
    roles: roles.join(","),
    code,
  };

  configureScope((scope) => {
    scope.setUser(cleansed);
  });
}

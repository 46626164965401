import { API_URL } from "Config";
import { BOOKING } from "Constants/actionTypes";
import { generateErrorHandler, generateURL, getJSON } from "Utils/Network";
import { RESTORE as mode } from "Constants/crud";

import include from "./includesForBooking";
import { transformBooking } from "./transformBooking";

const handleBookingError = generateErrorHandler(BOOKING.ERROR);

export const fetchBooking = async (bookingId: string, withQuotes: boolean = true) => {
  const url = generateURL(`${API_URL}v3/bookings/${bookingId}`, { include: include(withQuotes) });
  const json = await getJSON(url);
  const booking = transformBooking(json, withQuotes);
  return booking;
};

export const bookingRestore = (bookingId: string, withQuotes: boolean = true) => {
  return async (dispatch: (...args: any) => any) => {
    try {
      dispatch({ mode, type: BOOKING.PENDING, status: true });
      const booking = await fetchBooking(bookingId, withQuotes);
      dispatch({ mode, type: BOOKING.SUCCESS, booking });
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.warn("bookingRestore err");
      // eslint-disable-next-line no-console
      console.warn(err);
      if (err.status === 401) {
        // don't log 401s... booking is not ready yet.
        dispatch(handleBookingError(err, false));
        return;
      }
      dispatch(handleBookingError(err));
    }
  };
};

// Wrapper for DropDown with additional functionality: adds a standard button to open the DropDown.

import React, { useState } from "react";
import styled, { css } from "styled-components";

import ExpandChevron from "Components/ExpandChevron";

import DropDown from "./DropDown";
import DropDownProps from "./dropDownPropTypes";

interface IProps extends DropDownProps {
  label: string;
  iconColor?: string;
  iconFilled?: boolean;
  items: {
    label: string;
    link: (...args: any) => any; // this is more restricted than standard DropDown
    mobileOnly?: boolean;
  }[];
}

const Wrapper = styled.div`
  position: relative;
  padding: 0 2px;
  @media screen and (min-width: ${({ theme }) => theme.maxMobile}) {
    padding: 0 8px;
  }
  ul {
    border: none;
    padding: 0;
    li {
      border-bottom: none;
    }
    span::after,
    span::before {
      display: none;
    }
    @media screen and (max-width: ${({ theme }) => theme.maxMobile}) {
      &[data-test="calendar-controls-year-month"] {
        right: 0;
        left: auto !important;
      }
    }
  }
`;

const Activate = styled.button<any>`
  border: none;
  background: none;
  color: ${({ theme }) => theme.charcoal};
  cursor: pointer;
  font-family: InterMedium;
  font-size: 16px;
  outline: none;
  svg {
    margin-left: 8px;
  }
  ${({ isActive }) =>
    !isActive &&
    css`
      :hover {
        svg {
          transform: scale(1.1);
        }
      }
    `}
`;

const EnabledDropDown: React.FC<IProps> = (props) => {
  const {
    iconColor = "theme.charcoal",
    iconFilled = true,
    items,
    label,
    ...rest
  } = props;
  const [isActive, setActive] = useState(false);
  const wrapperProps = {
    "data-test": props.testId || undefined,
  };
  return (
    <Wrapper {...wrapperProps}>
      <Activate isActive={isActive} onClick={() => setActive(true)}>
        {label}
        <ExpandChevron
          isExpanded={isActive}
          colour={iconColor}
          filled={iconFilled}
        />
      </Activate>
      {isActive && (
        <DropDown
          {...rest}
          // @ts-ignore
          items={items.map((option) => {
            return {
              ...option,
              link: () => {
                // enhance the link function with close.
                // for this reason this component can only accept links as functions.
                setActive(false);
                option.link();
              },
            };
          })}
          isDisplayed
          onClickOutside={() => setActive(false)}
        />
      )}
    </Wrapper>
  );
};

export default EnabledDropDown;

import { createStore, applyMiddleware, compose } from "redux";
import ReduxThunk from "redux-thunk";
import { routerMiddleware } from "react-router-redux";
import { createBrowserHistory } from "history";
import { createLogger } from "redux-logger";
import * as Sentry from "@sentry/browser";

import createSentryMiddleware from "redux-sentry-middleware";

import { ENV, REDUX_LOGGING, SENTRY_DSN } from "Config";
import rootReducer from "./reducers";

// global debugger if e2e tests are hard to debug...
// just call `global.debugLog("your message")` or `global.debugLog({yourObject})`
// see AppRoute for debug renderer
/*
global.msg = [];
global.debugLog = (m) => {
  const record = typeof m === "string" ? m : JSON.stringify(m, null, " ");
  console.log("debugLog", record);
  global.msg.push(record);
};
*/

Sentry.init({
  dsn: SENTRY_DSN,
  enabled: Boolean(SENTRY_DSN),
  environment: ENV,
  release: process.env.CIRCLE_TAG || "unversioned",
  whitelistUrls: [/madecomfy\.com\.au/, /my\.madecomfy\.com\.au/],
});

const history = createBrowserHistory();
const historyMiddleware = routerMiddleware(history);

const logger = REDUX_LOGGING
  ? createLogger({
      collapsed: (getState, action, logEntry) => !logEntry?.error,
    })
  : ({ getState, dispatch }: any) => (next: (...args: any) => any) => (action: any) => {
      return next(action);
      // add your custom console log here if needed! eg.
      // const nextAction = next(action);
      // console.log(nextAction);
      // return nextAction;
    };

const enhancer = compose(
  applyMiddleware(
    ReduxThunk,
    historyMiddleware,
    logger,
    createSentryMiddleware(Sentry),
  ),
);

const store = createStore(rootReducer, {}, enhancer);

export default store;

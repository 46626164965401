import React from "react";
import styled from "styled-components";

import { BUTTON_SECONDARY, BUTTON_DANGER } from "@madecomfy/webooi";

import Generic from "Components/Popup/GenericWrapper";
import Popup from "Components/Popup";
import { Body } from "Components/Typography";

interface IProps {
  id?: string | number | null;
  title: string;
  loading?: boolean;
  content?: string;
  handleClose: (...agrs: any) => any;
  handleDelete: (...agrs: any) => any;
}

const Content = styled(Body)`
  margin-bottom: 32px;
`;

export const DeletePopup: React.FC<IProps> = (props) => {
  const {
    id,
    title,
    loading = false,
    content = "",
    handleClose,
    handleDelete,
  } = props;
  if (!id) return <></>;
  return (
    <Popup
      body={
        <Generic
          body={<Content>{content}</Content>}
          buttons={[
            { label: "Cancel", onClick: handleClose, type: BUTTON_SECONDARY },
            {
              label: "Delete",
              onClick: () => {
                handleDelete(id);
              },
              type: BUTTON_DANGER,
            },
          ]}
          title={title}
          loading={loading}
        />
      }
      handleClose={handleClose}
      //  height={160}
      width={450}
    />
  );
};

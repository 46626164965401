import React, { Component } from "react";
import styled from "styled-components";

import CircleBadge from "Components/CircleBadge";
import { Body, H4 } from "Components/Typography";

const Channel = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.sand91};
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  margin-top: 8px;
  padding: 16px 24px;
  &:hover {
    background: ${({ theme }) => theme.navyLight20};
  }
`;
const ChannelBadge = styled(CircleBadge)`
  filter: drop-shadow(0px 1px 5px rgba(53, 55, 60, 0.1));
  margin: 0 16px 0 0;
`;
const Wrapper = styled.div`
  margin: 24px;
`;

interface IProps {
  channels: any[];
  history: any;
}
class ChannelsList extends Component<IProps, any> {
  render() {
    const { channels, history } = this.props;
    return (
      <Wrapper>
        <H4>Channels</H4>
        {channels.map((item, i) => {
          return (
            <Channel
              data-test="channel"
              key={i}
              onClick={() => history.push(`/channels/${item.id}`)}
            >
              <ChannelBadge externalSource={item.id} size={40} />
              <Body>{item.name}</Body>
            </Channel>
          );
        })}
      </Wrapper>
    );
  }
}

export default ChannelsList;

import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { debounce } from "lodash";
import dayjs from "dayjs";

import { InputText, Table, Tooltip, TYPE_TEXT } from "@madecomfy/webooi";

import BreadCrumb from "Components/BreadCrumb/BreadCrumb";
import { Body } from "Components/Typography";
import { downloadFileFromUrlWithCredentials } from "Utils/methods/download";
import downloadIcon from "./assets/download.svg";
import { formatMonthYear } from "Utils/methods/date";
import { isAuthorized } from "Utils";
import Loading from "Components/Loading";
import { MonthPicker } from "Components/Dates/MonthPicker";
import { ROLE } from "Constants/userRoles";

const Container = styled.div`
  position: relative;
`;

const Element = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  p {
    margin: 0;
    line-height: 24px;
    cursor: pointer;
  }
`;

const Text = styled.p`
  margin: 0;
  line-height: 24px;
  cursor: pointer;
`;

const WrapperTooltip = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: -webkit-fill-available;
  div[data-test="owner-tooltip"],
  div[data-test="download-tooltip"],
  div[data-test="external-tooltip"] {
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    white-space: nowrap;
    bottom: 30px;
    text-align: center;
    background-color: ${({ theme }) => theme?.darkTransparent};
    :after {
      display: none;
    }
    @media screen and (max-width: ${({ theme }) => theme.maxMobile}) {
      white-space: normal;
    }
  }
`;

const TooltipContent = styled.div`
  max-height: 300px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.background.neutral};
    border-radius: 10px;
  }
`;

const TooltipText = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.text.inverse};
  text-align: left;
`;

const FlexWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;
  margin-right: 50px;
`;

const IconAction = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  opacity: 0;
  &:hover {
    background: ${({ theme }) => theme.background.whiteHover};
  }
`;

const IconWrap = styled.img`
  width: 16px;
  height: 16px;
  color: ${({ theme }) => theme.text.inactive};
`;

const Wrapper = styled.div`
  padding: 24px;
`;

const Header = styled.p`
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  color: ${({ theme }) => theme.text.default};
  margin-top: 24px;
  margin-bottom: 40px;
`;
const ToolbarWrapper = styled.div`
  display: flex;
  gap: 24px;
`;
const InputWrapper = styled.div`
  position: relative;
  width: calc(100% - 200px);
  input {
    height: 40px;
    padding: 0 8px;
  }
`;
const TableWrapper = styled.div`
  overflow: visible;
  margin-top: 24px;
  padding: 16px;
  background-color: ${({ theme }) => theme.background.white};
  border-radius: 8px;
  table {
    tbody{
      tr {
      &:hover {
        background: ${({ theme }) => theme.background.lightTeal};
        div[data-test="download-btn"],
        div[data-test="external-link-btn"] {
          opacity: 1;
        }
      }
    }
    }
    td {
      min-width: auto;
      padding: 8px 16px;
      height: 48px;
    }
    th {
      padding: 0 16px 16px;
    }
  }
  > div {
    p {
      font-size: 16px;
    }
  }
`;

const NoDataWrapper = styled.div`
  margin-top: 16px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface IProps {
  statements: { [key: string]: any };
  statementsRestore: Function;
  user: { [key: string]: any };
}

const Statements: React.FC<IProps> = (props) => {
  const { statements, statementsRestore, user } = props;
  const { pending } = statements;
  const [searchTerm, setSearchTerm] = useState("");
  const [month, setMonth] = useState<number | null>(null);
  const [year, setYear] = useState<number | null>(null);
  const [tableSort, setTableSort] = useState<{
    column: string;
    order: "asc" | "desc";
  }>({
    column: "day",
    order: "asc",
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [reset, setReset] = useState(false);
  const [items, setItems] = useState<any>([]);
  const { roles, propertyStatus } = user;
  const isOwner = isAuthorized(roles, [ROLE.OWNER]);
  const isLicensee = isAuthorized(roles, [ROLE.LICENSEE]);
  const loader = useRef(null);

  // handle sort
  const statementsSort = new Map([
    ["day", "date"],
    ["propertyData.address.full", "property"],
    ["ownerData", "owner"],
  ]);

  const getSortVal = (columnItem: string, order: "asc" | "desc") => {
    const sortValue = statementsSort.get(columnItem);
    return sortValue ? `${order === "desc" ? "-" : ""}${sortValue}` : "";
  };

  const fetchData = async (
    pageNumber: number,
    val?: string,
    month?: number,
    year?: number,
    sort?: string,
  ) => {
    const params = {
      query: val,
      month,
      year,
      number: pageNumber,
      sort,
    };
    setLoading(true);
    try {
      await statementsRestore(params);
      setLoading(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const debouncedFetchData = useCallback(
    debounce((num, searchTerm, month, year, sort) => {
      fetchData(num, searchTerm, month, year, sort);
    }, 400),
    [],
  );

  const handleDownload = async (url?: string, name?: string) => {
    if (!url) return;
    downloadFileFromUrlWithCredentials(url, name);
  };

  const handleRowClick = (row: any) => {
    if (row?.downloadUrl) {
      window.open(row?.downloadUrl, "_blank");
    }
  };

  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];
      if (target.isIntersecting && !loading && items.length >= 25) {
        setReset(false);
        setIsLoadingMore(true);
        const nextPage = pageNumber + 1;
        setPageNumber(nextPage);
        if (nextPage > statements.totalPages) {
          setIsLoadingMore(false);
          return;
        }

        debouncedFetchData(
          nextPage,
          searchTerm,
          month,
          year,
          getSortVal(tableSort.column, tableSort.order),
        );
      }
    },
    [loading],
  );

  useEffect(() => {
    setReset(true);
    setPageNumber(1);
    debouncedFetchData(
      1,
      searchTerm,
      month,
      year,
      getSortVal(tableSort.column, tableSort.order),
    );
  }, [searchTerm, month, year, tableSort.column, tableSort.order]);

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      threshold: 0.5,
    });

    if (loader.current) {
      observer.observe(loader.current);
    }

    return () => {
      if (loader.current) {
        observer.unobserve(loader.current);
      }
    };
  }, [handleObserver]);

  useEffect(() => {
    setItems((prevItems: any) =>
      reset
        ? statements?.statements
        : ([...prevItems, ...statements?.statements] as any),
    );
    setIsLoadingMore(false);
  }, [statements?.statements]);

  const generateOwnerTooltipContent = (ownerData: any[]) => {
    if (ownerData.length <= 1) return null;
    const otherOwners = ownerData.slice(1);
    return (
      <TooltipContent>
        {otherOwners.map((owner) => (
          <TooltipText key={owner?.id}>{owner?.fullName}</TooltipText>
        ))}
      </TooltipContent>
    );
  };

  const columns = [
    {
      title: "Month",
      dataIndex: "day",
      render: (date: string, rowId: string) => {
        return (
          <Container>
            <Text>{formatMonthYear(date)}</Text>
          </Container>
        );
      },
      sortable: true,
    },
    {
      title: "Property",
      dataIndex: "propertyData.address.full",
      render: (propertyAddress: string, rowId: string) => {
        return (
          <Container>
            <Element>
              <p>{propertyAddress}</p>
            </Element>
          </Container>
        );
      },
      sortable: !isOwner,
    },
    // hide this column when user's role is owner
    ...(!isOwner
      ? [
          {
            title: "Owner",
            dataIndex: "ownerData",
            render: (ownerData: any[], rowId: string) => {
              return (
                <Container>
                  <Text>
                    {ownerData[0]?.fullName}
                    {ownerData.length > 1 && (
                      <>
                        {` +${ownerData.length - 1}`}
                        <WrapperTooltip>
                          <Tooltip
                            hideIcon={true}
                            position="bottom"
                            text={generateOwnerTooltipContent(ownerData)}
                            testId="owner-tooltip"
                            timeOut={100}
                          />
                        </WrapperTooltip>
                      </>
                    )}
                  </Text>
                </Container>
              );
            },
            sortable: true,
          },
        ]
      : []),
    {
      title: "",
      dataIndex: "downloadUrl",
      render: (url: string, rowId: string) => {
        return (
          <FlexWrap>
            <IconAction
              data-test="download-btn"
              onClick={() => handleDownload(url)}
            >
              <IconWrap alt="download" src={downloadIcon} />
              <WrapperTooltip>
                <Tooltip
                  hideIcon={true}
                  position="bottom"
                  text={<TooltipText>Download</TooltipText>}
                  testId="download-tooltip"
                  timeOut={100}
                />
              </WrapperTooltip>
            </IconAction>
          </FlexWrap>
        );
      },
    },
  ];

  return (
    <Wrapper>
      <BreadCrumb
        items={[
          {
            label: "Statements",
            staticLabel: isLicensee ? "MadeComfy Pro / " : "MadeComfy / ",
          },
        ]}
        staticText={""}
      />
      <Header>Statements</Header>
      <ToolbarWrapper>
        <InputWrapper>
         {/* if owner has only 1 property, does not show input search */}
          {(propertyStatus?.count > 1 || !isOwner) && (
            <InputText
              name="searchTerm"
              placeholder={
                isOwner ? "Search properties" : "Search properties or owners"
              }
              testId="user-search"
              type={TYPE_TEXT}
              my={0}
              sendValue={({ value }: any) => {
                setSearchTerm(value?.trim());
              }}
            />
          )}
        </InputWrapper>
        <MonthPicker
          sendValue={(month, year) => {
            setMonth(month);
            setYear(year);
          }}
          maxTime={dayjs().startOf("month")}
          onClear={() => {
            setMonth(null);
            setYear(null);
          }}
        />
      </ToolbarWrapper>
      {pending && <Loading task="statements" />}
      <TableWrapper>
        <Table
          columns={columns}
          data={items}
          pagination={false}
          showSearch={false}
          sortBy={tableSort.column}
          sortOrder={tableSort.order}
          rowsPerPage={1e6}
          onTableSortBy={(columnItem, order) => {
            setTableSort({ ...tableSort, column: columnItem, order });
          }}
          sortLocal={false}
          handleRowClick={handleRowClick}
        />
        {items?.length === 0 && (
          <NoDataWrapper>
          <Body>{(searchTerm || month) ? "There are no statements matching your search" : "There are no statements available yet"}</Body>
          </NoDataWrapper>
        )}
      </TableWrapper>
      <div
        ref={loader}
        style={{ height: "10px", background: "transparent" }}
      ></div>
      {isLoadingMore && <Loading task="load more statements" />}
    </Wrapper>
  );
};

export default Statements;

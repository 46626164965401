// a handy wrapper for DropDown adding three dots icon to open links.
import React, { useState } from "react";
import styled from "styled-components";

import DropDown from "Components/DropDown";

interface IProps {
  align?: "left" | "right";
  links?: {
    label: string | React.ReactNode;
    link: (...args: any) => any | string;
    newWindow?: boolean;
    isEnabled?: boolean;
  }[];
}

const WrapperMoreInfo = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.radiusSM};
  flex: 0 0 10px;
  padding: 8px;
  position: relative;
  cursor: pointer;
  text-align: center;
  width: 40px;
  height: 40px;
  ${({ onClick }) => onClick && `cursor: pointer;`}
  svg {
    transition: transform 0.1s ease-out;
  }
  :hover {
    background-color: ${({ theme }) => theme.background.whiteHover};
  }
`;

const MoreInfoDots = ({ links = [], align = "right" }: IProps) => {
  const [isDisplayed, setDisplayed] = useState(false);
  if (isDisplayed) {
    if (links.length === 0) {
      // this component should not be used if there are no links.
      return <>&quot;Could not load links!&quot;</>;
    }
    return (
      <WrapperMoreInfo data-test="more-info">
         <svg
        // alt="More info" <-- alt attr doesn't exist on svg elements
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M14.5 4.48022C14.5 5.58479 13.6046 6.48022 12.5 6.48022C11.3954 6.48022 10.5 5.58479 10.5 4.48022C10.5 3.37566 11.3954 2.48022 12.5 2.48022C13.6046 2.48022 14.5 3.37566 14.5 4.48022Z" fill="#303336"/>
        <path d="M14.5 12.4802C14.5 13.5848 13.6046 14.4802 12.5 14.4802C11.3954 14.4802 10.5 13.5848 10.5 12.4802C10.5 11.3757 11.3954 10.4802 12.5 10.4802C13.6046 10.4802 14.5 11.3757 14.5 12.4802Z" fill="#303336"/>
        <path d="M12.5 22.4802C13.6046 22.4802 14.5 21.5848 14.5 20.4802C14.5 19.3757 13.6046 18.4802 12.5 18.4802C11.3954 18.4802 10.5 19.3757 10.5 20.4802C10.5 21.5848 11.3954 22.4802 12.5 22.4802Z" fill="#303336"/>
      </svg>
        <DropDown
          align={align}
          caret={false}
          isDisplayed={true}
          items={links.map((item) => {
            if (typeof item.link === "function") {
              return {
                ...item,
                link: () => {
                  item.link();
                  setDisplayed(false); // auto close if it's a function
                },
              };
            }
            return item;
          })}
          onClickOutside={() => setDisplayed(false)}
          position={{ right: "0" }}
        />
      </WrapperMoreInfo>
    );
  }

  return (
    <WrapperMoreInfo data-test="more-info" onClick={() => setDisplayed(true)}>
      <svg
        // alt="More info" <-- alt attr doesn't exist on svg elements
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M14.5 4.48022C14.5 5.58479 13.6046 6.48022 12.5 6.48022C11.3954 6.48022 10.5 5.58479 10.5 4.48022C10.5 3.37566 11.3954 2.48022 12.5 2.48022C13.6046 2.48022 14.5 3.37566 14.5 4.48022Z" fill="#303336"/>
        <path d="M14.5 12.4802C14.5 13.5848 13.6046 14.4802 12.5 14.4802C11.3954 14.4802 10.5 13.5848 10.5 12.4802C10.5 11.3757 11.3954 10.4802 12.5 10.4802C13.6046 10.4802 14.5 11.3757 14.5 12.4802Z" fill="#303336"/>
        <path d="M12.5 22.4802C13.6046 22.4802 14.5 21.5848 14.5 20.4802C14.5 19.3757 13.6046 18.4802 12.5 18.4802C11.3954 18.4802 10.5 19.3757 10.5 20.4802C10.5 21.5848 11.3954 22.4802 12.5 22.4802Z" fill="#303336"/>
      </svg>
    </WrapperMoreInfo>
  );
};

export default MoreInfoDots;

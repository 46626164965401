import React from "react";

const Suitcase = () => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M8.57171 0.106934C7.91813 0.106934 7.29134 0.366565 6.82919 0.8287L6.82918 0.828716C6.36706 1.29085 6.10742 1.91764 6.10742 2.57122V5.24979H2.57171C1.21072 5.24979 0.107422 6.3531 0.107422 7.71408V21.4284C0.107422 22.7894 1.21073 23.8927 2.57171 23.8927H21.4289C22.7899 23.8927 23.8931 22.7894 23.8931 21.4284V7.71408C23.8931 6.35311 22.7899 5.24979 21.4289 5.24979H17.8931V2.57122C17.8931 1.91764 17.6335 1.29085 17.1714 0.828716L17.1714 0.8287C16.7092 0.366565 16.0824 0.106934 15.4289 0.106934H8.57171ZM16.3931 5.24979V2.57122C16.3931 2.31547 16.2915 2.0702 16.1107 1.88936C15.9299 1.70852 15.6846 1.60693 15.4289 1.60693H8.57171C8.31597 1.60693 8.07071 1.70852 7.88986 1.88936L7.88984 1.88937C7.70901 2.07022 7.60742 2.31548 7.60742 2.57122V5.24979H16.3931ZM1.60742 7.71408C1.60742 7.18153 2.03915 6.74979 2.57171 6.74979H21.4289C21.9614 6.74979 22.3931 7.18152 22.3931 7.71408V21.4284C22.3931 21.961 21.9614 22.3927 21.4289 22.3927H2.57171C2.03914 22.3927 1.60742 21.9609 1.60742 21.4284V7.71408Z"
  />
);

export default Suitcase;

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { propertyRestore } from "Actions/properties/propertyRestore";

import PropertyHeader from "./PropertyHeader";

const mapStateToProps = (state: any) => ({
  currentPropertyStatus: state.currentPropertyStatus,
});

const mapDispatchToProps = (dispatch: any) => ({
  propertyRestore: bindActionCreators(propertyRestore, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyHeader);

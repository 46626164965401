import React, { useEffect, useState } from "react";
import styled from "styled-components";
import dayjs from "dayjs";

import {
  Button,
  BUTTON_INLINE,
  BUTTON_LINK,
  BUTTON_SMALL,
  Tooltip,
} from "@madecomfy/webooi";

import {
  Body,
  FinePrint,
  ParagraphLarge,
  ParagraphSmall,
} from "Components/Typography";

import {
  cleaningPriorityContent,
  cleaningStatusContent,
} from "./getCleaningStatus";
import { formatDateWithDay, formatTime, formatDateWithDynamic } from "Utils";
import { propertyTaskRestore } from "Actions/properties/propertyTasks";
import { Status, Priority } from "./CleaningTask";
import Generic from "Components/Popup/GenericWrapper";
import Popup from "Components/Popup";

import assignee from "../assets/person.svg";
import attachment from "../assets/attachment.svg";
import clock from "../assets/clock.svg";
import Expandable from "Components/Expandable/Expandable";
import left from "../assets/left-align.svg";
import message from "../assets/message-default.svg";
import pencil from "../assets/pencil.svg";

interface IProps {
  taskId?: any;
  handleClose: (...agrs: any) => any;
}

const Wrapper = styled.div`
  button[data-test="button-breezeway"] {
    color: ${({ theme }) => theme.text.default};
    font-family: InterMedium;
    svg {
      fill: #303336;
    }
  }
`;
const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -8px;
  div:first-of-type {
    margin: 0;
    margin-right: 40px;
  }
`;
const Name = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin: 24px 0;
  flex-wrap: wrap;
  p[data-test="name"] {
    width: 100%;
  }
  p {
    margin: 0 8px 0 0;
  }
  p[data-test="task-status-text"] {
    color: ${({ theme }) => theme.text.default};
  }
`;
const Column = styled.div<any>`
  display: flex;
  gap: 24px;
  align-items: baseline;
  padding-bottom: ${({ pB }) => (pB ? "16px" : "0")};
  position: relative;
  img {
    width: 16px;
    height: 16px;
  }
`;

const Line = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.border.default};
  margin: 24px -24px 24px -24px;
`;
const Comment = styled.div`
  margin-top: 16px;
  p[data-test="commentedAt"] {
    color: ${({ theme }) => theme.text.subtle};
    margin: 0;
  }
`;
const ColumnComment = styled(Column)`
  background: #f4f4f5;
  margin: -24px -24px -24px -24px;
  padding: 24px 24px 24px 24px;
  button {
    display: flex;
    flex-direction: row-reverse;
    gap: 8px;
    height: 24px;
  }
  div[data-test="comments-tooltip"] {
    top: 24px;
    left: 24px;
  }
`;
const Image = styled.img`
  width: 48px !important;
  height: 48px !important;
`;
const ImageContainer = styled.div`
  display: flex;
  position: relative;
  flex-flow: wrap;
  gap: 8px;
`;
const WrapperTooltip = styled.div<any>`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  cursor: pointer;

  div[data-test="tooltip"] {
    width: auto;
    svg {
      display: none;
    }
  }
`;
const Dot = styled.span`
  height: 4px;
  width: 4px;
  background: #939296;
  border-radius: 50%;
`;
const Time = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;
const Content = styled(Body)`
  word-wrap: break-word;
  max-width: 575px;
`;

export const CleaningTaskPopup: React.FC<IProps> = ({
  taskId,
  handleClose,
}) => {
  const [loading, setLoading] = useState(false);
  const [task, setTask] = useState<any>(null);

  useEffect(() => {
    setLoading(true);
    getTasks();
  }, []);

  const getTasks = async () => {
    const resp = await propertyTaskRestore({ taskId });
    setTask(resp);
    if (resp) {
      setLoading(false);
    }
  };
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Popup
        body={
          <Generic
            body={
              <Wrapper>
                <Flex>
                  <Button
                    testId="breezeway"
                    icon="externalLink"
                    label="View On Breezeway"
                    my={0}
                    onClick={() => window.open(task?.breezewayUrl)}
                    size={BUTTON_SMALL}
                    styling={BUTTON_INLINE}
                    type={BUTTON_LINK}
                  />
                  {task?.displayStatus && (
                    <Status
                      status={cleaningStatusContent.get(task?.displayStatus)}
                    />
                  )}
                </Flex>
                <Line />
                <Name>
                  <ParagraphLarge data-test="name" semi>
                    {task?.name}
                  </ParagraphLarge>
                  {task?.scheduledDate && (
                    <ParagraphSmall>
                      {formatDateWithDay(task?.scheduledDate)}
                    </ParagraphSmall>
                  )}
                  {task?.scheduledTime && (
                    <ParagraphSmall>
                      {dayjs(`1970-01-01T${task?.scheduledTime}`).format(
                        "hh:mm A",
                      )}
                    </ParagraphSmall>
                  )}
                  {task?.priority && (
                    <Priority
                      priority={cleaningPriorityContent.get(task?.priority)}
                    />
                  )}
                </Name>
                <Line />
                <div>
                  {task?.assignments.length > 0 && (
                    <Column pB>
                      <img src={assignee} alt="person" />
                      <WrapperTooltip>
                        <Tooltip
                          hideIcon={true}
                          position="bottom"
                          text="Assignee"
                          testId="tooltip"
                          timeOut={100}
                        />
                      </WrapperTooltip>
                      <div>
                        {task.assignments.map(
                          ({ name }: any, index: number) => (
                            <Body as="span" key={index}>
                              {index > 0 && ", "}
                              {name}
                            </Body>
                          ),
                        )}
                      </div>
                    </Column>
                  )}
                  {task?.description && (
                    <Column>
                      <img src={left} alt="left" />
                      <WrapperTooltip>
                        <Tooltip
                          hideIcon={true}
                          position="bottom"
                          text="Description"
                          testId="tooltip"
                          timeOut={100}
                        />
                      </WrapperTooltip>
                      <Expandable
                        html={task?.description.replace(/\n/g, "<br />")}
                        expandText="Show All"
                      />
                    </Column>
                  )}
                  <Column pB>
                    <img src={clock} alt="clock" />
                    <WrapperTooltip>
                      <Tooltip
                        hideIcon={true}
                        position="bottom"
                        text="Duration"
                        testId="tooltip"
                        timeOut={100}
                      />
                    </WrapperTooltip>
                    <Time>
                      {task?.startedAt && task?.finishedAt ? (
                        <>
                          {task?.startedAt && (
                            <Body>{formatTime(task?.startedAt)}</Body>
                          )}
                          {task?.startedAt && task?.finishedAt && (
                            <Body>-</Body>
                          )}
                          {task?.finishedAt && (
                            <Body>{formatTime(task?.finishedAt)}</Body>
                          )}
                        </>
                      ) : (
                        <Body>-</Body>
                      )}
                      {task?.totalTime && (
                        <>
                          <Dot></Dot>
                          <Body
                            color={
                              task?.displayStatus === "in_progress"
                                ? "#1C4EAC"
                                : "#303336"
                            }
                          >
                            {task?.totalTime}
                          </Body>
                        </>
                      )}
                    </Time>
                  </Column>
                  <Line />
                  {task?.attachments.length > 0 ? (
                    <>
                      <Column>
                        <img src={attachment} alt="attachment" />
                        <WrapperTooltip>
                          <Tooltip
                            hideIcon={true}
                            position="bottom"
                            text="Attachments"
                            testId="tooltip"
                            timeOut={100}
                          />
                        </WrapperTooltip>
                        <ImageContainer>
                          {task?.attachments.map(
                            ({ url }: any, index: number) => (
                              <ImageContainer key={index}>
                                <a href={url} target="_blank" rel="noreferrer">
                                  <Image src={url} />
                                </a>
                              </ImageContainer>
                            ),
                          )}
                        </ImageContainer>
                      </Column>
                    </>
                  ) : (
                    <Column>
                      <img src={attachment} alt="attachment" />
                      <Body>No images</Body>
                    </Column>
                  )}
                  <Line />
                  {task?.comments.length > 0 && (
                    <>
                      <ColumnComment>
                        <img src={message} alt="message" />
                        <WrapperTooltip data-test="comments-tooltip">
                          <Tooltip
                            hideIcon={true}
                            position="bottom"
                            text="Comments"
                            testId="tooltip"
                            timeOut={100}
                          />
                        </WrapperTooltip>
                        <div>
                          <Button
                            testId="breezeway-comment"
                            icon="externalLink"
                            label="Reply on Breezeway"
                            my={0}
                            onClick={() => window.open(task?.breezewayUrl)}
                            size={BUTTON_SMALL}
                            styling={BUTTON_INLINE}
                            type={BUTTON_LINK}
                          />
                          {task.comments.map(
                            ({ comment, commentedAt }: any, index: number) => {
                              const linkRegex = /(https?:\/\/[^\s]+)/g;

                              const formatComment = (text: string) => {
                                const parts = text.split(linkRegex);
                                return parts.map((part, i) =>
                                  linkRegex.test(part) ? (
                                    <a
                                      key={i}
                                      href={part}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {part}
                                    </a>
                                  ) : (
                                    <span key={i}>{part}</span>
                                  ),
                                );
                              };

                              return (
                                <Comment key={index}>
                                  <FinePrint data-test="commentedAt">
                                    {formatDateWithDynamic(
                                      commentedAt,
                                      "Do MMM YY h:mm A",
                                    )}
                                  </FinePrint>
                                  <Content>{formatComment(comment)}</Content>
                                </Comment>
                              );
                            },
                          )}
                        </div>
                      </ColumnComment>
                      <Line />
                    </>
                  )}
                  <Column>
                    <img src={pencil} alt="pencil" />
                    <WrapperTooltip>
                      <Tooltip
                        hideIcon={true}
                        position="bottom"
                        text="Created"
                        testId="tooltip"
                        timeOut={100}
                      />
                    </WrapperTooltip>
                    {task?.createdBy && <Body bold>{task?.createdBy}</Body>}
                    {task?.createdAt && (
                      <Body>
                        {formatDateWithDynamic(
                          task?.createdAt,
                          "Do MMM YY h:mm A",
                        )}
                      </Body>
                    )}
                  </Column>
                </div>
              </Wrapper>
            }
            title=""
            loading={loading}
          />
        }
        handleClose={handleClose}
        width={700}
      />
    </div>
  );
};

import React, { Component, Fragment } from "react";
import styled from "styled-components";

import { Button } from "@madecomfy/webooi";

import ButtonsSaveCancel from "Components/Buttons/ButtonsSaveCancel";
import { H5 } from "Components/Typography";
import Loading from "Components/Loading";
import Popup from "Components/Popup";
import { CREATE, UPDATE } from "Constants/crud";
import { Badge } from "./UserRoles";

const ModalBody = styled.div`
  padding: 24px;
  table {
    tr {
      th {
        padding: 4px;
        background: ${({ theme }) => theme.sand94};
      }
      td {
        padding: 8px 4px;
      }
    }
  }
`;

const Row = styled.div`
  display: grid;
  grid-column-gap: 8px;
  margin: 0 0 8px 0;
  padding: 12px 20px;
  word-break: break-word;
  label {
    display: none;
  }
  @media (max-width: ${({ theme }) => theme.maxMobile}) {
    display: block;
    label {
      font-weight: bold;
      display: inline-block;
      width: 100px;
    }
  }
`;

const Header = styled(Row)`
  background: ${({ theme }) => theme.sand94};
  font-size: 16px;
  font-weight: bold;
  @media (max-width: ${({ theme }) => theme.maxMobile}) {
    display: none;
  }
`;

const checkEmpty = (content: any) => content || "-";
interface IProps {
  companies?: any[];
  editUserStatus: { [key: string]: any };
  isLicensee: boolean;
  onCancel: (...args: any) => any;
  onConfirm: (...args: any) => any;
  onRetry: (...args: any) => any;
  onSuccess: (...args: any) => any;
  properties: any[];
  userDetails: { [key: string]: any };
}
class UserConfirmModal extends Component<IProps> {
  modalButtons() {
    const { editUserStatus, onCancel, onConfirm, onRetry, onSuccess } =
      this.props;
    const { mode, pending, errorMessage, error, success } = editUserStatus;
    if (pending) {
      return <Loading task="editingUserStatus" />;
    } else if (error) {
      return <Button label={`${errorMessage} - Try Again`} onClick={onRetry} />;
    } else if (success && (mode === CREATE || mode === UPDATE)) {
      return (
        <Button
          label="User saved"
          onClick={onSuccess}
          testId="user-change-success"
        />
      );
    } else {
      return (
        <ButtonsSaveCancel
          onCancel={onCancel}
          onSave={onConfirm}
          textSave="Confirm"
        />
      );
    }
  }

  render() {
    const {
      companies,
      isLicensee,
      userDetails,
      onCancel,
      properties,
    } = this.props;

    const updatingUser =
      userDetails?.email && userDetails?.firstName && userDetails?.lastName;

    const columnsUser = { gridTemplateColumns: "2fr 2fr 2fr 2fr 2fr" };
    const columnsProperty = { gridTemplateColumns: "1fr 1fr 1fr" };
    const columnsCompany = { gridTemplateColumns: "1fr 1fr" };

    const body = (
      <ModalBody>
        {updatingUser ? (
          <Fragment>
            <H5>Confirm new user details</H5>
            <Header style={columnsUser}>
              <div>Name</div>
              <div>Email</div>
              <div>Mobile</div>
              <div>Roles</div>
              <div>Booking Notification</div>
            </Header>
            <Row style={columnsUser}>
              <div>
                <label>Name:</label>
                {checkEmpty(userDetails?.firstName)}
                {checkEmpty(userDetails?.lastName)}
              </div>
              <div>
                <label>Email:</label>
                {checkEmpty(userDetails?.email)}
              </div>
              <div>
                <label>Mobile:</label>
                {checkEmpty(userDetails?.mobile)}
              </div>
              <div>
                <label>User Role:</label>
                {
                  // convert object to array to string
                  Object.entries(userDetails?.roles)
                    .filter(([_, value]) => value)
                    .map(([role]) => (
                      <Badge key={`badge${role}`} role={role} />
                    ))
                }
              </div>
              <div>
                <label>Booking Notification:</label>
                {userDetails?.bookingNotificationsEnabled ? "On" : "Off"}
              </div>
            </Row>
          </Fragment>
        ) : (
          <H5>Confirm new user properties</H5>
        )}
        {isLicensee ? (
          companies?.length ? (
            <>
              <Header style={columnsCompany}>
                <div>Company Name</div>
                <div>Partner Type</div>
              </Header>
              {companies?.map((company, index) => (
                <Row
                  key={`user-alert-company-prop${index}`}
                  data-test="company-item"
                  style={columnsCompany}
                >
                  <div>{company.companyName}</div>
                  <div>{company.partnerType}</div>
                </Row>
              ))}
            </>
          ) : (
            <>
              <Header style={columnsCompany}>
                <div>Company Name</div>
                <div>Partner Type</div>
              </Header>
              <Row style={{ gridTemplateColumns: "1fr" }}>
                <div>
                  <label>Company:</label>None
                </div>
              </Row>
            </>
          )
        ) : properties.length > 0 ? (
          <>
            <Header style={columnsProperty}>
              <div>Property Name</div>
              <div>Address</div>
              <div>City</div>
            </Header>
            {properties.map(({ name, address: { line1, city } }, index) => (
              <Row
                data-test="property-item"
                key={`user-alert-prop${index}`}
                style={columnsProperty}
              >
                <label>Property {index + 1}</label>
                <div>{name}</div>
                <div>{line1}</div>
                <div>{city}</div>
              </Row>
            ))}
          </>
        ) : (
          <>
            <Header style={columnsProperty}>
              <div>Property Name</div>
              <div>Address</div>
              <div>City</div>
            </Header>
            <Row style={{ gridTemplateColumns: "1fr" }}>
              <div>
                <label>Properties:</label>None
              </div>
            </Row>
          </>
        )}
      </ModalBody>
    );

    return (
      <Popup
        body={body}
        footer={this.modalButtons()}
        handleClose={onCancel}
        height={600}
        width={800}
      />
    );
  }
}

export default UserConfirmModal;

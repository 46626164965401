import React from "react";
import styled from "styled-components";

import { Select } from "@madecomfy/webooi";
import { ParagraphSmall } from "Components/Typography";

interface IProps {
  value?: any;
  testId?: string;
  error?: string;
  onChange?: ({ value }: any) => void;
}

const ExpiryWrapper = styled.div`
  margin-top: 24px;
`;

const Error = styled(ParagraphSmall)`
  color: ${({ theme }) => theme.alertRed};
  margin: -24px 0 0 2px;
`;

export const ExpirySelect: React.FC<IProps> = (props) => {
  const { value, testId, error, onChange } = props;
  return (
    <ExpiryWrapper>
      <Select
        label="Expiry"
        instructions="The event will be automatically deleted after this time"
        name="expiry"
        options={[
          { value: "", label: "Never" },
          { value: "1", label: "1 day" },
          { value: "2", label: "2 days" },
          { value: "3", label: "3 days" },
        ]}
        value={value}
        testId={testId}
        my={0}
        sendValue={onChange}
      />
      {!!error && <Error>{error}</Error>}
    </ExpiryWrapper>
  );
};

import active from "./assets/active.svg";
import inactive from "./assets/inactive.svg";
import offboarding from "./assets/offboarding.svg";
import onboarding from "./assets/onboarding.svg";
import theme from "Constants/theme";

export const getStatus = new Map([
    ["active", active],
    ["inactive", inactive],
    ["offboarding", offboarding],
    ["onboarding", onboarding],
]);
export const getCleanStatus = new Map([
    ["blocked", "Blocked"],
    ["dirty", "Clean required"],
    ["in_progress", "Clean required"],
    ["occupied", "Occupied"],
    ["ready", "Guest ready"],
]);
export const getKeyStatus = new Map([
    ["Unknown", "Unknown"],
    ["In Use", "In use"],
    ["Key in place", "In place"],
    ["Cleaner confirmed key is in place", "In place"],
    ["Waiting for drop off", "Drop off"],
    ["In Store", "In store"],
]);
export const getKeyStatusColor = new Map([
    ["Unknown", theme.text.alert],
    ["In Use", theme.text.info],
    ["Key in place", theme.text.success],
    ["Cleaner confirmed key is in place", theme.text.warning],
    ["Waiting for drop off", theme.text.warning],
    ["In Store", theme.text.success],
]);

import React, { PureComponent } from "react";
import { Link } from "react-router-dom";

import {
  Alert,
  ALERT_ERROR,
  Button,
  BUTTON_CTA,
  InputText,
  TYPE_PASSWORD,
} from "@madecomfy/webooi";

import LinkButton from "./components/LinkButton";
import logoInverse from "./assets/logoInverse.svg";
import { H4, H2, Body } from "Components/Typography";
import { INTERNAL_SERVER_ERROR, NOT_FOUND, UNAUTHORIZED } from "Utils/Network";
import {
  FormWrapper,
  LogoInv,
  BodyWrapper,
  LinkWrapper,
} from "./components/Elements";

const errorMessages = {
  [UNAUTHORIZED]: "Invalid email address or password. Please try again.",
  [NOT_FOUND]:
    "The reset link appears to be invalid. Please reset your password or use the chat to talk to a customer experience manager.",
  [INTERNAL_SERVER_ERROR]: "There was an error connecting to the server.",
};

const ACTION_CHANGE = "change";

// this does not use webooi's form intentionally since the password confirmation & submitted states are a little complex
// it also handles lastpass auto filling a generated password into both fields.
interface IProps {
  createPassword: (...args: any) => any;
  createPasswordStatus: { [key: string]: any };
  match: { [key: string]: any };
  verifyTokenStatus: { [key: string]: any };
  verifyToken: (...args: any) => any;
}
class WelcomeCreatePassword extends PureComponent<IProps> {
  state = {
    hasTypedPassword: false,
    hasTypedPasswordConfirm: false,
    password: "",
    passwordConfirm: "",
    submitted: false,
  };

  componentDidMount() {
    const { newPasswordToken } = this.props.match.params;
    const { verifyToken } = this.props;
    verifyToken(newPasswordToken);
  }

  handleSubmit = (event: any) => {
    event && event.preventDefault();
    this.setState({ submitted: true });
    const { password, passwordConfirm } = this.state;
    const isValid = password === passwordConfirm && password.length >= 8;
    if (isValid) {
      const { action: type, newPasswordToken: token } = this.props.match.params;
      const payload = { password, passwordConfirm, token, type };
      this.props.createPassword(payload);
    }
  };

  renderSuccess() {
    return (
      <div>
        <LogoInv src={logoInverse} />
        <H4 white>
          Your password was successfully updated. You can now continue to login
          to your account.
        </H4>
        <Link to="/login">
          <Button label={"Login"} type={BUTTON_CTA} />
        </Link>
      </div>
    );
  }

  renderVerifyTokenError() {
    return (
      <div>
        <LogoInv src={logoInverse} />
        <BodyWrapper>
          <Body color="#fff">
            Sorry, this link has expired. Please try resetting your password or
            contact us for more information
          </Body>
        </BodyWrapper>
        <LinkWrapper>
          <LinkButton to="/login" label="Back to login" />
        </LinkWrapper>
      </div>
    );
  }

  getBodyText(action: string) {
    if (action === ACTION_CHANGE) {
      return "Please enter a new password";
    }
    return "Please create a password to get started";
  }

  getButtonText(action: string) {
    if (action === ACTION_CHANGE) {
      return "Update password";
    }
    return "Create password";
  }

  renderPasswordForm() {
    const {
      hasTypedPassword,
      hasTypedPasswordConfirm,
      password,
      passwordConfirm,
      submitted,
    } = this.state;
    const {
      createPasswordStatus: { errorCode, pending },
      match: {
        params: { action },
      },
    } = this.props;
    return (
      <div>
        <LogoInv src={logoInverse} />
        <H2 white>Welcome to MadeComfy</H2>
        <BodyWrapper>
          <Body color="#fff">{this.getBodyText(action)}</Body>
        </BodyWrapper>
        <FormWrapper>
          {errorCode ? (
            <Alert
              message={errorMessages[errorCode as keyof typeof errorMessages] || errorCode}
              type={ALERT_ERROR}
            />
          ) : null}
          <form onSubmit={this.handleSubmit}>
            <InputText
              label="New Password"
              icon="lock"
              name="password"
              placeholder="Enter your password"
              type={TYPE_PASSWORD}
              errorServer={
                (submitted || hasTypedPassword) && password.length < 8
                  ? "The password must be at least 8 characters long"
                  : ("" as any)
              }
              sendValue={({
                value,
                isValid,
              }: {
                value: string;
                isValid: boolean;
              }) => {
                this.setState({ hasTypedPassword: true, password: value });
              }}
            />
            <InputText
              label="Confirm Password"
              icon="lock"
              name="passwordConfirm"
              placeholder="Re-Enter your password"
              type={TYPE_PASSWORD}
              errorServer={
                (submitted || (hasTypedPassword && hasTypedPasswordConfirm)) &&
                password !== passwordConfirm
                  ? "The confirmation password does not match"
                  : ("" as any)
              }
              sendValue={({
                value,
                isValid,
              }: {
                value: string;
                isValid: boolean;
              }) => {
                this.setState({
                  hasTypedPasswordConfirm: true,
                  passwordConfirm: value,
                });
              }}
            />
          </form>
          <Button
            label={this.getButtonText(action)}
            isLoading={pending}
            type={BUTTON_CTA}
            onClick={this.handleSubmit}
          />
          <LinkButton
            to="/welcome"
            label="Have an account already? Log in here."
          />
        </FormWrapper>
      </div>
    );
  }

  render() {
    const { success: createPasswordSuccess } = this.props.createPasswordStatus;
    const { error: verifyTokenError } = this.props.verifyTokenStatus;

    if (createPasswordSuccess) {
      return this.renderSuccess();
    }

    if (verifyTokenError) {
      return this.renderVerifyTokenError();
    }

    return this.renderPasswordForm();
  }
}

export default WelcomeCreatePassword;

import {
  BOOKING,
  BOOKINGS_PROPERTY,
  CALENDAR_BLOCK,
} from "Constants/actionTypes";
import { CREATE, DELETE } from "Constants/crud";

const initialState = {
  bookings: [],
  error: false,
  errorCode: 0,
  errorMessage: "",
  calendar: {
    eventsArray: [],
    eventsMap: {},
    vacanciesMap: {},
  },
  pending: true,
  rates: {},
  success: false,
  tasks: [],
};

export function bookingsByCode(state = initialState, action: {[key: string]: any}) {
  switch (action.type) {
    case CALENDAR_BLOCK.SUCCESS:
    case BOOKING.SUCCESS:
      const { booking, mode } = action;
      const nextBookings = [].concat(state.bookings) as any;
      const existingIndex = nextBookings.findIndex(
        ({ id }: { id: string | number }) => id === booking.id,
      );
      if (mode === CREATE) {
        const insertionIndex = nextBookings.findIndex(
          ({ startDate }: { startDate: string | Date }) => startDate > booking.startDate, // bookings should always be sorted by startDate date.
        );
        if (insertionIndex === -1) {
          // last one!
          nextBookings.push(booking);
        } else {
          nextBookings.splice(insertionIndex, 0, booking);
        }
      } else if (mode === DELETE) {
        if (existingIndex > -1) {
          nextBookings.splice(existingIndex, 1);
        }
        // otherwise the bookings list has not been loaded.
      } else {
        // mode is UPDATE or RESTORE
        if (existingIndex > -1) {
          nextBookings[existingIndex] = booking;
        } else {
          nextBookings.push(booking);
        }
      }

      return {
        ...state,
        pending: false,
        success: true,
        bookings: nextBookings,
      };
    case BOOKINGS_PROPERTY.SUCCESS:
      const { bookings, pending, rates, calendar, tasks } = action;
      const nextState = {
        ...state,
        pending,
        success: true,
      };
      if (bookings) nextState.bookings = bookings;
      if (rates) nextState.rates = rates;
      if (calendar) nextState.calendar = calendar;
      if (tasks) nextState.tasks = tasks;
      return nextState;
    case BOOKINGS_PROPERTY.PENDING:
      return { ...state, pending: action.status };
    case BOOKINGS_PROPERTY.ERROR:
      return {
        ...state,
        error: true,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        pending: false,
      };
    case BOOKINGS_PROPERTY.RESET:
      return initialState;
    default:
      return state;
  }
}

import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Body, H4 } from "Components/Typography";
import {
  Button,
  BUTTON_INLINE,
  BUTTON_LARGE,
  BUTTON_PRIMARY,
} from "@madecomfy/webooi";

import { AddNewsLetter } from "./NewsletterPopup";
import { Card } from "./Card";
import { ROLE } from "Constants/userRoles";

const Wrapper = styled.div`
  position: relative;
  padding-top: 32px;
  h4 {
    margin-bottom: 24px;
  }
`;
const Container = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 16px;
  align-self: stretch;
  flex-wrap: wrap;
`;

interface IProps {
  user: { [key: string]: any };
  newsletterRestore: (...args: any) => any;
  newsletters: any[];
  showNew?: boolean;
}
const Newsletter: React.FC<IProps> = (props) => {
  const { newsletterRestore, newsletters, showNew = true, user } = props;
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const asyncLoadAvailable = async () => {
      await newsletterRestore();
    };
    asyncLoadAvailable();
  }, []);
  const isMarketingAdmin =
    user?.roles?.includes(ROLE.MARKETING) || user?.roles?.includes(ROLE.ADMIN);
  return (
    <Wrapper>
      <H4>Newsletters</H4>
      {isMarketingAdmin && showNew && (
        <Button
          icon="plus"
          label="Add New"
          mt={8}
          mb={32}
          onClick={() => {
            setShowPopup(true);
          }}
          size={BUTTON_LARGE}
          styling={BUTTON_INLINE}
          testId="add-newsletter"
          type={BUTTON_PRIMARY}
        />
      )}
      {showPopup && (
        <AddNewsLetter
          handleClose={() => setShowPopup(false)}
          newsletterRestore={newsletterRestore}
        />
      )}
      <Container>
        {newsletters && newsletters?.length > 0 ? (
          newsletters.map((newsletter, i) => {
            return (
              <Card
                key={i}
                data={newsletter}
                isMarketingAdmin={isMarketingAdmin}
                newsletterRestore={newsletterRestore}
              />
            );
          })
        ) : (
          <Body>There are no newsletters available yet</Body>
        )}
      </Container>
    </Wrapper>
  );
};

export default Newsletter;

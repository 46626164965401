import theme from "Constants/theme";

import alertDiamond from "../assets/alert-diamond.svg";
import arrowDown from "../assets/arrow-down.svg";
import arrowUp from "../assets/arrow-up.svg";
import diamond from "../assets/diamond.svg";
import doubleChevronDown from "../assets/double-chevron-down.svg";
import doubleChevronUp from "../assets/double-chevron-up.svg";
import paused from "../assets/paused.svg";
import questionCircle from "../assets/question-circle.svg";

export const cleaningStatusContent = new Map([
  [
    "unassigned",
    {
      label: "Unassigned",
      icon: questionCircle,
      color: theme.warnOrange,
    },
  ],
  [
    "assigned",
    {
      label: "Assigned",
      color: theme.neutral500,
    },
  ],
  [
    "overdue",
    {
      label: "Overdue",
      icon: alertDiamond,
      color: theme.alertRed,
    },
  ],
  [
    "in_progress",
    {
      label: "In progress",
      color: theme.infoBlue,
    },
  ],
  [
    "paused",
    {
      label: "Paused",
      icon: paused,
      color: theme.infoBlue,
    },
  ],
  [
    "closed",
    {
      label: "Closed",
      color: theme.successGreen,
    },
  ],
  [
    "finished",
    {
      label: "Finished",
      color: theme.successGreen,
    },
  ],
  [
    "approved",
    {
      label: "Approved",
      color: theme.successGreen,
    },
  ],
]);

export const cleaningPriorityContent = new Map([
  [
    "normal",
    {
      priorityLabel: "Medium",
      priorityIcon: diamond,
    },
  ],
  [
    "high",
    {
      priorityLabel: "High",
      priorityIcon: arrowUp,
    },
  ],
  [
    "urgent",
    {
      priorityLabel: "Urgent",
      priorityIcon: doubleChevronUp,
    },
  ],
  [
    "low",
    {
      priorityLabel: "Low",
      priorityIcon: arrowDown,
    },
  ],
  [
    "watch",
    {
      priorityLabel: "Watch",
      priorityIcon: doubleChevronDown,
    },
  ],
]);

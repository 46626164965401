import React, { Component } from "react";
import { ThemeProvider } from "styled-components";

import theme, { set } from "Constants/theme";

export default class extends Component<any> {
  state = { reload: 0 };

  componentDidMount() {
    // POC;
    // setInterval(this.update, 1000);
    this.bindListener();
  }

  bindListener = () => {
    window.flip = this.update; // let's you call `flip()` in the console
  };

  update = (theme: any) => {
    set(theme);
    this.setState(
      { reload: this.state.reload + 1 },
      // () => {
      //   setTimeout(() => {
      //     this.bindListener();
      //   }, 5);
      // },
    );
  };

  render() {
    return (
      <ThemeProvider key={this.state.reload} theme={theme}>
        {this.props.children}
      </ThemeProvider>
    );
  }
}

import { API_URL } from "Config";
import { generateErrorHandler, getJSON } from "Utils/Network";
import { BOOKING_QUOTES } from "Constants/actionTypes";
import { RESTORE as mode } from "Constants/crud";

const handleError = generateErrorHandler(BOOKING_QUOTES.ERROR);

export const quoteRestore = async (quoteId: string) => {
  const json = await getJSON(`${API_URL}v3/quotes/${quoteId}?include=payout-summary,base-quote,base-quote.payout-summary`);
  return transformQuote(json);
};

export const quotesRestore = (bookingId: string) => {
  return async (dispatch: (...args: any) => any) => {
    try {
      dispatch({ mode, type: BOOKING_QUOTES.PENDING, status: true });

      const json = await getJSON(`${API_URL}v3/bookings/${bookingId}?include=quotes`);
      const quotes = json.included.map((data: any) => transformQuote({ data }));

      dispatch({ mode, type: BOOKING_QUOTES.SUCCESS, bookingId, quotes });
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.warn("quotesRestore err");
      dispatch(handleError(err));
    }
  };
};

export const transformQuote = ({ data, included }: { [key: string]: any }): { [key: string]: any } => {
  const baseQuoteId = data.relationships?.["base-quote"]?.data?.id;
  const baseQuote = baseQuoteId && included?.find((res: any) => res.id === baseQuoteId && res.type === "quote");

  return {
    id: data.id,
    ...data.attributes,
    payoutSummary: included?.find((res: any) => res.id === data.id && res.type === "payout-summary")?.attributes,
    baseQuote: baseQuote && transformQuote({ data: baseQuote, included }),
  };
};

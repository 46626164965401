import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

import { H4, Body } from "Components/Typography/Typography";

const Wrapper = styled.div``;
const WrapperContent = styled.div`
  margin-bottom: 16px;
  overflow: hidden;
  p {
    margin: 0;
  }
  ul {
    padding-left: 20px;
    margin-top: 8px;
  }
`;

const ExpandText = styled(Body)`
  color: ${({ theme }) => theme.text.brand};
  cursor: pointer;
  margin-bottom: 16px;
  font-weight: 500;
`;

const Title = styled(H4)`
  margin-bottom: 24px;
`;

interface ContentProps {
  childItem?: any;
  children?: any;
  expanded?: boolean;
  heights?: any;
  html?: string;
  parentItem?: any;
}

const Content: React.FC<ContentProps> = ({
  childItem,
  children,
  expanded,
  heights,
  html,
  parentItem,
}) => {
  const maxHeight = (() => {
    if (expanded) {
      return "none";
    }
    if (heights.minimised) {
      return heights.minimised;
    }
    return "9em";
  })();
  return (
    <WrapperContent
      data-test="expandable-content"
      ref={parentItem}
      style={{ maxHeight }}
    >
      {html ? (
        <div
          ref={childItem}
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        />
      ) : (
        <div ref={childItem}>{children}</div>
      )}
    </WrapperContent>
  );
};

interface FixedProps {
  children?: { [key: string]: any };
  expanded?: boolean;
  html?: string;
  title?: string;
}
const Fixed: React.FC<FixedProps> = (props) => {
  const { children, html, title, expanded = true } = props;
  const contentProps = { children, html, expanded };
  return (
    <Wrapper data-test="expandable">
      {title && <Title>{title}</Title>}
      <Content {...contentProps} />
    </Wrapper>
  );
};

interface IProps {
  children?: { [key: string]: any };
  expandText?: string;
  isExpandable?: boolean;
  heights?: {
    minimised: string;
  };
  html?: string;
  lineSpacer?: boolean;
  title?: string;
}
const Expandable: React.FC<IProps> = (props) => {
  const {
    children,
    expandText,
    html,
    isExpandable = true,
    heights = {},
    title,
  } = props;
  if (!isExpandable) {
    return <Fixed {...props} />;
  }

  const [expanded, setExpanded] = useState(false);
  const childItem = useRef<any>(null);
  const parentItem = useRef<any>(null);
  const [actualHeight, setActualHeight] = useState("auto");
  const [actualParentHeight, setActualParentHeight] = useState("auto");

  useEffect(() => {
    if (childItem.current) {
      setActualHeight(childItem.current.offsetHeight);
    }
    if (parentItem.current) {
      setActualParentHeight(parentItem.current.offsetHeight);
    }
  }, [childItem, parentItem]);

  const contentProps = {
    childItem,
    children,
    expanded,
    html,
    heights,
    parentItem,
  };

  return (
    <Wrapper data-test="expandable">
      {title && <Title>{title}</Title>}
      <Content {...contentProps} />
      {actualHeight > actualParentHeight && (
        <ExpandText
          data-test="expand-text"
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <>{expanded ? expandText?.replace("All", "Less") : expandText}</>
        </ExpandText>
      )}
    </Wrapper>
  );
};

export default Expandable;

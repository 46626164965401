import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { channelsRestore } from "Actions/channels/channelsRestore";

import Channels from "./Channels";

import withSessionCheck from "HOC/withSessionCheck";

const mapStateToProps = (state: any) => ({
  channelData: state.channelData,
});

const mapDispatchToProps = (dispatch: any) => ({
  channelsRestore: bindActionCreators(channelsRestore, dispatch),
});

const ChannelsConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Channels);

export default withSessionCheck(ChannelsConnected);

export { ChannelsConnected };

import React, { useState } from "react";
import styled from "styled-components";

import { Body } from "Components/Typography";

import { ButtonCopy } from "../components/ButtonCopy";
import { ButtonExternal } from "../components/ButtonExternal";
import ButtonEdit from "Components/Buttons/ButtonEdit";
import ButtonDelete from "../components/ButtonDelete";
import { DeleteNewsLetter, EditNewsLetter } from "./NewsletterPopup";
import default1 from "../assets/default1.png";

const Wrapper = styled.div`
  border-radius: 8px;
  box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.08);
  background: ${({ theme }) => theme.background.white};
  width: 200px;
  display: flex;
  flex-direction: column;
  &:hover {
    box-shadow: 1px 1px 24px 0px rgba(0, 0, 0, 0.08);
  }
`;
const Image = styled.img`
  width: 100%;
  height: 80px;
  border-radius: 8px 8px 0 0;
  cursor: pointer;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
`;
const Buttons = styled.div`
  display: flex;
  gap: 8px;
`;
const ButtonWrapper = styled.div`
  background: ${({ theme }) => theme.background.subtle3};
  padding: 8px;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  button {
    height: auto;
  }
  svg {
    fill: ${({ theme }) => theme.text.default} !important;
    margin-right: 0 !important;
  }
  &:hover {
    background: ${({ theme }) => theme.background.subtle3Hover};
  }
`;
const Title = styled(Body)`
  text-overflow: ellipsis;
  overflow: hidden;
  width: 178px;
  white-space: nowrap;
`;

interface IProps {
  data: { [key: string]: any };
  newsletterRestore: (...args: any) => any;
  isMarketingAdmin: boolean;
}
export const Card: React.FC<IProps> = ({
  data,
  isMarketingAdmin,
  newsletterRestore,
}) => {
  const { heroImageUrl, id, title, url } = data;
  const [showPopup, setShowPopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);

  const isImage =
    heroImageUrl?.startsWith("http") || heroImageUrl?.startsWith("https");
  return (
    <Wrapper data-test="card">
      <Image
        alt="hero image"
        src={isImage ? heroImageUrl : default1}
        onClick={() => window.open(url)}
      />
      <Content>
        <Title title={title}>{title}</Title>
        <Buttons>
          <ButtonCopy url={url} />
          <ButtonExternal url={url} />
          {isMarketingAdmin && (
            <>
              <ButtonWrapper>
                <ButtonEdit
                  onClick={() => setShowEditPopup(true)}
                  label=""
                  testId="edit"
                />
                {showEditPopup && (
                  <EditNewsLetter
                    data={data}
                    handleClose={() => setShowEditPopup(false)}
                    newsletterRestore={newsletterRestore}
                  />
                )}
              </ButtonWrapper>
              <ButtonWrapper>
                <ButtonDelete
                  onClick={() => setShowPopup(true)}
                  testId="delete"
                />
                {showPopup && (
                  <DeleteNewsLetter
                    id={id}
                    handleClose={() => setShowPopup(false)}
                    newsletterRestore={newsletterRestore}
                  />
                )}
              </ButtonWrapper>
            </>
          )}
        </Buttons>
      </Content>
    </Wrapper>
  );
};

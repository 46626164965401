import React, { Component } from "react";
import styled from "styled-components";

import { Alert, ALERT_ERROR } from "@madecomfy/webooi";

import { Body, H4 } from "Components/Typography/Typography";
import Loading from "Components/Loading";

const Wrapper = styled.div`
  display: flex;
  margin: 16px 0 24px;
  h4 {
    margin: 8px 0 0;
  }
`;

const Image = styled.img`
  border-radius: 4px;
  margin-right: 16px;
`;

interface IProps {
  currentPropertyStatus: {
    currentProperty: {
      photos: any[];
      address: {
        full: string;
      };
      name: string;
    };
    error: string;
    errorCode: string;
    errorMessage: string;
    success: boolean;
    pending: boolean;
  };
  propertyId: string;
  propertyRestore: (...args: any) => any;
}
class PropertyHeader extends Component<IProps> {
  componentDidMount() {
    const {
      currentPropertyStatus: { success },
      propertyId,
      propertyRestore,
    } = this.props;
    if (success) {
      // already loaded from previous user activity...
      return;
    }
    propertyRestore(propertyId);
  }

  render() {
    const {
      currentPropertyStatus: {
        currentProperty,
        error,
        errorCode,
        errorMessage,
        pending,
        success,
      },
    } = this.props;

    if (error) {
      return (
        <Alert
          title={`${errorCode ? errorCode + " " : ""}Could not load property`}
          message={errorMessage}
          type={ALERT_ERROR}
        />
      );
    }

    if (pending || !success) {
      return <Loading task="restoringPropertyHeader" />;
    }

    const img = currentProperty.photos[0] && currentProperty.photos[0].url;

    return (
      <Wrapper>
        {img && (
          <div>
            <Image src={`https://img.madecomfy.com.au/width/120/80/${img}`} />
          </div>
        )}
        <div>
          <H4 color="theme.darkPetrol">{currentProperty.name}</H4>
          <Body>{currentProperty.address.full}</Body>
        </div>
      </Wrapper>
    );
  }
}

export default PropertyHeader;

import React from "react";
import styled from "styled-components";

import arrow from "./assets/arrow.svg";
import hidden from "./assets/hidden.svg";
import minus from "./assets/minus.svg";
import plus from "./assets/plus.svg";
import visible from "./assets/visible.svg";
import { Body, H5 } from "Components/Typography";
import { DoubleCol } from "Containers/BookingView/components/Elements";
import { formatDate } from "Utils";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0 16px 0;
  @media (min-width: ${({ theme }) => theme.minDesktop}) {
    width: 30%;
  }
  div {
    padding: 2px 0;
    text-transform: capitalize;
  }
`;
const Comment = styled.div`
  display: flex;
  align-items: start;
  padding: 8px 0;
`;
const Image = styled.img`
  margin-right: 16px;
`;
const ImageMC = styled.img`
  margin-right: 12px;
  width: 16px;
  height: 16px;
`;
const WrapperResponse = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 16px 16px 16px 24px;
`;
const ResponseContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 16px;
`;
export const Dot = styled(Body)`
  font-size: 42px;
  position: relative;
  bottom: 12px;
  margin: 0 16px;
`;
const Date = styled(Body)`
  flex: 1;
`;
const Headline = styled(Body)<any>`
  padding-bottom: 8px;
`;
const WrapperOverall = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;
export const Stars = styled.div<any>`
  display: flex;
  font-size: 24px;
  line-height: 1;
  margin-right: 4px;
  &::before {
    content: "★★★★★";
    letter-spacing: 3px;
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.lemon} calc(${({ rating }) => rating} / 5 * 100%),
      #c4c4c4 1%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media (max-width: ${({ theme }) => theme.maxMobile}) {
    font-size: 22px;
  }
`;

interface IProps {
  overall: string;
  reviewedAt: string;
}
export const Overall: React.FC<IProps> = ({ overall, reviewedAt }) => {
  return (
    <WrapperOverall>
      <Stars rating={overall} />
      <Body bold>{overall}</Body>
      <Dot>.</Dot>
      <Date>{formatDate(reviewedAt)}</Date>
    </WrapperOverall>
  );
};

export const Rating: React.FC<any> = ({ rating, reviewedAt, star }) => {
  const { overall } = rating;
  const { ...newObj } = rating;
  delete newObj.overall;
  return (
    <Wrapper data-test="ratings">
      {star && <Overall overall={overall} reviewedAt={reviewedAt} />}
      {Object.entries(newObj).map(([key, value]) => {
        const newkey = key === "checkin" ? "Check-In" : key;
        return (
          value && (
            <DoubleCol key={key}>
              <Body>{newkey}</Body>
              <Body>{value as any}</Body>
            </DoubleCol>
          )
        );
      })}
    </Wrapper>
  );
};

export const Comments: React.FC<any> = ({ comments }) => {
  const {
    comment,
    headline,
    negativeComment,
    positiveComment,
    privateComment,
  } = comments;
  return (
    <div data-test="comments">
      {headline && <Headline>{headline}</Headline>}
      {comment && (
        <Comment>
          <Image src={visible} />
          <Body>{comment}</Body>
        </Comment>
      )}
      {privateComment && (
        <Comment>
          <Image src={hidden} />
          <Body>{privateComment}</Body>
        </Comment>
      )}
      {positiveComment && (
        <Comment>
          <Image src={plus} />
          <Body>{positiveComment}</Body>
        </Comment>
      )}
      {negativeComment && (
        <Comment>
          <Image src={minus} />
          <Body>{negativeComment}</Body>
        </Comment>
      )}
    </div>
  );
};

interface ResProps {
  respData: {
    respondedAt: string;
    response: string;
  };
  timezone?: string;
}
export const Response: React.FC<ResProps> = ({ respData, timezone }) => {
  const { respondedAt, response } = respData;
  return (
    <WrapperResponse>
      <Image src={arrow} />
      <ResponseContainer data-test="response">
        <Header>
          <ImageMC src="/img/portals/madecomfy.png" />
          <H5>MadeComfy</H5>
          <Dot>.</Dot>
          <Body>{formatDate(respondedAt, timezone)}</Body>
        </Header>
        <Body>{response}</Body>
      </ResponseContainer>
    </WrapperResponse>
  );
};

import React from "react";
import styled from "styled-components";

import { H5, Body } from "Components/Typography";

interface IProps {
  handleClick: (...args: any) => any;
  companies?: any[];
}

const Wrapper = styled.div<any>`
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 360px;
`;

const Item = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.sand96};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: 15px 30px;
  transition: background 0.05s linear;
  &:hover {
    background: ${({ theme }) => theme.sand96};
  }
  &:last-of-type {
    border-radius: 0 0 4px 4px;
  }
`;

const ColAddress = styled.div`
  flex-grow: 1;
`;

export const CompanyTable: React.FC<IProps> = (props) => {
  const { handleClick, companies } = props;
  return (
    <Wrapper data-test="company-table-list">
      {companies?.map((company) => {
        const { companyName, partnerType } = company;
        return (
          <Item
            data-test="company-item"
            key={company?.id || company?.companyId}
            onClick={(event) => handleClick(event, company)}
          >
            <ColAddress>
              <H5>{companyName}</H5>
              <Body>{partnerType}</Body>
            </ColAddress>
          </Item>
        );
      })}
    </Wrapper>
  );
};

import { connect } from "react-redux";
import { withRouter } from "react-router";

import Navigation from "./Navigation";

const mapStateToProps = (state: any) => ({
  user: state.authentication.sessionStatus.user,
});

const NavigationConnected = withRouter(connect(mapStateToProps)(Navigation));

export default NavigationConnected;
